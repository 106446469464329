import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoOr: CultureInfo = {
  name: 'or',
  displayName: 'Odia',
  iso3LanguageCode: 'ori',
  iso2LanguageCode: 'or',
  ietfLanguageTag: 'or',
  parent: null
};
