import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoVe: CultureInfo = {
  name: 've',
  displayName: 'Venda',
  iso3LanguageCode: 'ven',
  iso2LanguageCode: 've',
  ietfLanguageTag: 've',
  parent: null
};
