import { CultureInfo } from '../culture-info.model';
import { CultureInfoAsa } from './asa';

/**
 * @ignore
 */
export const CultureInfoAsaTZ: CultureInfo = {
  name: 'asa-TZ',
  displayName: 'Kipare (Tadhania)',
  iso3LanguageCode: 'asa',
  iso2LanguageCode: 'asa',
  ietfLanguageTag: 'asa-TZ',
  parent: CultureInfoAsa
};
