import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import { Component, ViewEncapsulation, Input, ChangeDetectorRef, HostListener, ElementRef, Inject, HostBinding } from '@angular/core';
import { WebComponent } from '../../decorators/web-component.decorator';
import { TswpUiLibraryConfigToken } from '../../models/tswp-ui-library-config.token';
import { TswpUiLibraryConfig } from '../../models/tswp-ui-library.config';
import { HeliosControl } from '../helios-control';
import { Align } from './align.enum';

/**
 * Menu from the Helios Design system
 * @doctab "Examples" "menu.component.examples.md"
 * @title Menus
 * @named_slot "toogle" "Contains the element which should toggle the menu list"
 * @named_slot "entry" "Contains one menu entry"
 */
@WebComponent('hls-menu')
@Component({
  selector: 'hls-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  preserveWhitespaces: true,
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class MenuComponent extends HeliosControl {
    //#region Private Fields
    private _wasInside: boolean;
    //#endregion

    //#region Ctor
    /*
     * Creates a new instance of a Helios Menu Control
     *
     * @param document The document that this control is attached to
     * @param elementRef The control's element reference
     * @param config The configuration that this control was initialized with
     * @param changeDetector The change detector service that this control may use.
     */
    public constructor(
     @Inject(DOCUMENT) document: Document,
     elementRef: ElementRef,
     @Inject(TswpUiLibraryConfigToken) config: TswpUiLibraryConfig,
     private readonly _changeDetector: ChangeDetectorRef) {

      super(document, elementRef, config);
    }
    //#endregion

    //#region Properties
    private _listVisible = false;
    /**
     * Gets or sets a value indicating wether this the contained list element is visible.
     */
    @Input()
    @HostBinding('attr.list-visible')
    public get listVisible(): boolean {
      return this._listVisible || false;
    }
    public set listVisible(v: boolean) {
      this._listVisible = coerceBooleanProperty(v);
    }

    private _align: Align = Align.Left;
    /**
     * Gets or sets the aligment of the menu, relative to the hosting element.
     */
    @Input()
    @HostBinding('attr.align')
    public get align(): Align {
      return this._align;
    }
    public set align(v: Align) {
      this._align = v;
    }
    //#endregion

    //#region Public Methods
    /**
     * Detects inside clicks
     */
    @HostListener('click')
    public clickInside(): void {
      this._wasInside = true;
      this.toggleList();
    }

    /**
     * Closes menu when click outside is detected.
     */
    @HostListener('document:click')
    public clickout(): void {
      if (!this._wasInside) {
        this.listVisible = false;
        this._changeDetector.detectChanges();
      }
      this._wasInside = false;
    }

    public toggleList(): void {
      this.listVisible = !this.listVisible;
      this._changeDetector.detectChanges();
    }
    //#endregion
}
