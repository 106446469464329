import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoFa: CultureInfo = {
  name: 'fa',
  displayName: 'Persian',
  iso3LanguageCode: 'fas',
  iso2LanguageCode: 'fa',
  ietfLanguageTag: 'fa',
  parent: null
};
