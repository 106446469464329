import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpgradePopupComponent } from './upgrade-popup.component';
import { UpgradePopupServiceImpl } from './upgrade-popup.service';
import { TOKENS } from '@trustedshops/tswp-core-ui';
import { UpgradePageModule } from '../upgrade-page/upgrade-page.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TswpCoreUiSharedModule } from '../../common/tswp-core-ui-shared.module';

@NgModule({
  declarations: [UpgradePopupComponent],
  imports: [CommonModule, UpgradePageModule, FormsModule, TswpCoreUiSharedModule, ReactiveFormsModule],
  providers: [
    { provide: TOKENS.UpgradePopupService, useClass: UpgradePopupServiceImpl },
  ],
  exports: [UpgradePopupComponent],
})
export class UpgradePopupModule {}
