import { CultureInfo } from '../culture-info.model';
import { CultureInfoZhHans } from './zh-Hans';

/**
 * @ignore
 */
export const CultureInfoZhHansMO: CultureInfo = {
  name: 'zh-Hans-MO',
  displayName: '简体中文（澳门）',
  iso3LanguageCode: 'zho',
  iso2LanguageCode: 'zh',
  ietfLanguageTag: 'zh-Hans-MO',
  parent: CultureInfoZhHans
};
