import { CultureInfo } from '../culture-info.model';
import { CultureInfoEnUS } from './en-US';

/**
 * @ignore
 */
export const CultureInfoEnUSPOSIX: CultureInfo = {
  name: 'en-US-POSIX',
  displayName: 'English (United States, Computer)',
  iso3LanguageCode: 'eng',
  iso2LanguageCode: 'en',
  ietfLanguageTag: 'en-US-POSIX',
  parent: CultureInfoEnUS
};
