import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoNso: CultureInfo = {
  name: 'nso',
  displayName: 'Northern Sotho',
  iso3LanguageCode: 'nso',
  iso2LanguageCode: 'nso',
  ietfLanguageTag: 'nso',
  parent: null
};
