import { Inject, Injectable } from '@angular/core';
import { Router, NavigationEnd, Route, UrlSegmentGroup, UrlSegment, UrlMatchResult, ParamMap } from '@angular/router';
import { PluginControlComponent } from '../../components/plugin-control/plugin-control.component';
import {
  PluginRoute,
  TOKENS,
  PluginRouteData,
  PluginContext,
  PluginRouter,
  RouteOperationDelegate,
  PluginService,
  PluginRegistration
} from '@trustedshops/tswp-core-plugins';
import { AbstractPersistentEvent, ExportToken, InvalidOperationError } from '@trustedshops/tswp-core-common';
import { PluginLoadedRouteGuard } from './plugin-loaded.route-guard';
import { filter, map } from 'rxjs/operators';

/**
 * Service responsible for handling routes of plugins
 */
@Injectable()
@ExportToken(TOKENS.PluginRouter)
export class PluginRouterImpl implements PluginRouter {
  //#region Private Fields
  private _routeCreationHandler: RouteOperationDelegate;
  private readonly _defaultRouteCreationHandler: RouteOperationDelegate;
  //#endregion

  //#region Properties
  public get currentRoute(): AbstractPersistentEvent<string> {
    const currentRoute = new AbstractPersistentEvent<string>(this._router.url);

    this._router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map((x: NavigationEnd) => x.urlAfterRedirects))
      .subscribe(route => currentRoute.emit(route));

    return currentRoute;
  }
  //#endregion

  //#region Ctor
  /** ignore */
  public constructor(
    private readonly _router: Router,

    @Inject(TOKENS.PluginService)
    private readonly _pluginService: PluginService
  ) {
    this._defaultRouteCreationHandler = (route, parent: Route) => this.createRoute(route, parent);
  }
  //#endregion

  //#region Public Methods
  /**
   * Broadcasts the currently active route
   *
   * @param url The url that is currently active, including the base url
   * @returns A Promise that resolves to 'true' when navigation succeeds, to 'false' when navigation fails, or is rejected on error.
   */
  public updateRoute(url: string): Promise<boolean> {
    return this._router.navigateByUrl(url);
  }

  /**
   * Add routes
   * @param routes Array of Routes
   */
  public registerRoutes(routes: PluginRoute[], pluginContext: PluginContext, parentId?: string): PluginRouterImpl {
    return this.addRoutes(routes, parentId);
  }

  public addRoutes(routes: PluginRoute[], parentId?: string): PluginRouterImpl {
    let parentRoute: Route = {
      children: this._router.config
    };

    for (const route of routes) {
      if (parentId) {
        parentRoute = this.getRouteById(parentId);

        if (!parentRoute) {
          throw new InvalidOperationError(
            `The specified parent route was not found: ${parentId}`);
        }
      }

      this.registerRoute(route, parentRoute);
    }

    this.reload();

    return this;
  }

  public getPluginRegistrationOfActiveRoute(): PluginRegistration | undefined {
    const name = this._router.routerState.root.firstChild.snapshot.children[0]?.data?.['pluginName'];
    if (!name || typeof name !== 'string') {
      return undefined;
    }

    return this._pluginService.tryFindRegistration(name);
  }

  private getRouteById(routeId: string, container?: Route): Route {
    container = container || { children: this._router.config };
    let possibleRoute = (container.children || [])
      .find(x => x.data?.['id'] === routeId);

    if (!possibleRoute) {
      for (const child of (container.children || [])) {
        possibleRoute = this.getRouteById(routeId, child);
        if (possibleRoute) {
          return possibleRoute;
        }
      }
    }

    return possibleRoute;
  }



  /**
   * Overrides how creation of new routes are handled. Can only be used once in a runtime.
   *
   * @param overrideFunction The handler that updates the internal routing framework.
   */
  public overrideCreateRouteHandler(overrideFunction: (defaultHandler: RouteOperationDelegate) => RouteOperationDelegate): void {
    this._routeCreationHandler = this._routeCreationHandler || overrideFunction(this._defaultRouteCreationHandler);
  }
  //#endregion

  //#region Private Methods
  private registerRoute(route: PluginRoute, parentRoute: Route): void {
    const routeCreationHandler = this._routeCreationHandler || this._defaultRouteCreationHandler;
    if (parentRoute) {
      parentRoute.children = parentRoute.children || [];
    }
    routeCreationHandler(route, parentRoute);
  }

  private createRoute(route: PluginRoute, parent: Route): void {
    const canActivate = route.canActivate || [];

    if (route.plugin) {
      canActivate.push(PluginLoadedRouteGuard);
    }

    const hostedWebComponentRoute: Route = {
      canActivate,
      canDeactivate: route.canDeactivate,
      canLoad: route.canLoad,
      redirectTo: route.redirectTo,
      pathMatch: route.pathMatch as any,
      path: '**',
      component: route.webComponent
        ? PluginControlComponent
        : undefined,
      data: Object.assign((route.data || {}), {
        webComponent: route.webComponent,
        pluginName: route.plugin,
        route,
        id: route.id
      } as PluginRouteData)
    };

    const routeWrapper: Route = {
      path: route.path,
      redirectTo: route.redirectTo,
      matcher: !route.path && route.matcher
        ? (segments, group, ngRoute) =>
          this.matchRoute(segments, group, ngRoute, route)
        : undefined,
      children: route.redirectTo
        ? undefined
        : [hostedWebComponentRoute]
    };

    if (!parent.children) {
      parent.children = [];
    }

    parent.path = '';

    parent.children.push(routeWrapper);
  }
  //#endregion

  //#region Private Methods
  private reload(): void {
    this._router.resetConfig(this._router.config);
  }

  private matchRoute(segments: UrlSegment[], group: UrlSegmentGroup, ngRoute: Route, route: PluginRoute): UrlMatchResult {
    const matched = route.matcher(
      segments.map(segment => ({
        parameters: this.convertToMap(segment.parameterMap),
        path: segment.path,
        pathAsString: segment.toString()
      })));

    if (typeof matched === 'boolean') {
      return matched
        ? { consumed: segments }
        : undefined;
    }

    const posParams: any = Object
      .keys(matched.posParams)
      .reduce(
        (prev, key) => {
          prev[key] = new UrlSegment(matched.posParams[key].segment, matched.posParams[key].parameters || {});
          return prev;
        },
        {} as { [name: string]: UrlSegment });

    return {
      consumed: matched.consumed.map(x => new UrlSegment(x.path, {})),
      posParams
    };
  }

  private convertToMap(parameterMap: ParamMap): Map<string, any> {
    const targetMap = new Map<string, any>();
    parameterMap.keys
      .forEach(key =>
        targetMap.set(key, parameterMap.get(key)));
    return targetMap;
  }


  //#endregion
}
