import { CultureInfo } from '../culture-info.model';
import { CultureInfoEn } from './en';

/**
 * @ignore
 */
export const CultureInfoEnIM: CultureInfo = {
  name: 'en-IM',
  displayName: 'English (Isle of Man)',
  iso3LanguageCode: 'eng',
  iso2LanguageCode: 'en',
  ietfLanguageTag: 'en-IM',
  parent: CultureInfoEn
};
