import { CultureInfo } from '../culture-info.model';
import { CultureInfoNn } from './nn';

/**
 * @ignore
 */
export const CultureInfoNnNO: CultureInfo = {
  name: 'nn-NO',
  displayName: 'Norwegian Nynorsk (Norway)',
  iso3LanguageCode: 'nno',
  iso2LanguageCode: 'nn',
  ietfLanguageTag: 'nn-NO',
  parent: CultureInfoNn
};
