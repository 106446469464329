import { CultureInfo } from '../culture-info.model';
import { CultureInfoKpe } from './kpe';

/**
 * @ignore
 */
export const CultureInfoKpeGN: CultureInfo = {
  name: 'kpe-GN',
  displayName: 'Kpɛlɛɛ (GN)',
  iso3LanguageCode: 'kpe',
  iso2LanguageCode: 'kpe',
  ietfLanguageTag: 'kpe-GN',
  parent: CultureInfoKpe
};
