import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  EmbeddedViewRef,
  Injectable,
  InjectionToken,
  Injector,
} from '@angular/core';
import { ExportToken } from '@trustedshops/tswp-core-common';
import {
  TOKENS,
  UpgradePopupRef,
  UpgradePopupService,
  UpgradePopupViewType,
} from '@trustedshops/tswp-core-ui';
import { UpgradePopupRefImpl } from './upgrade-popup-ref';
import { UpgradePopupComponent } from './upgrade-popup.component';
import { UpgradePopupError } from './upgrade-popup.error';

export const UPGRADE_POPUP_VIEW_TYPE = new InjectionToken<UpgradePopupViewType>(
  'UpgradePopupViewType'
);

@Injectable()
@ExportToken(TOKENS.UpgradePopupService)
export class UpgradePopupServiceImpl implements UpgradePopupService {
  public constructor(
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly appRef: ApplicationRef
  ) {}

  public open(viewType: UpgradePopupViewType = 'modal'): UpgradePopupRef {
    const popupRef = new UpgradePopupRefImpl();

    const popupComponentRef = this.createPopupComponent(popupRef, viewType);
    this.attachPopupToPlugin(popupComponentRef);

    let sub = popupRef.afterClosed$.subscribe(() => {
      this.closePopup(popupComponentRef);
      sub?.unsubscribe();
      sub = null;
    });

    return popupRef;
  }

  private createPopupComponent(
    popupRef: UpgradePopupRef,
    viewType: UpgradePopupViewType
  ): ComponentRef<UpgradePopupComponent> {
    const componentFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        UpgradePopupComponent
      );

    const injector = Injector.create({
      providers: [
        {
          provide: UpgradePopupRefImpl,
          useValue: popupRef,
        },
        {
          provide: UPGRADE_POPUP_VIEW_TYPE,
          useValue: viewType,
        },
      ],
    });

    const popupComponentRef = componentFactory.create(injector);
    this.appRef.attachView(popupComponentRef.hostView);

    return popupComponentRef;
  }

  private attachPopupToPlugin(
    popupComponentRef: ComponentRef<UpgradePopupComponent>
  ): HTMLElement {
    const popupRootNodes = (
      popupComponentRef.hostView as EmbeddedViewRef<unknown>
    ).rootNodes;
    if (!popupRootNodes.length) {
      throw new UpgradePopupError('No popup root node found');
    }

    if (popupRootNodes.length > 1) {
      throw new UpgradePopupError('More than one popup root node found');
    }

    const popupRootNode = popupRootNodes[0] as HTMLElement;

    const pluginControls = document.body.getElementsByTagName('plugin-control');
    if (!pluginControls.length) {
      throw new UpgradePopupError('No plugin control found');
    }

    if (pluginControls.length > 1) {
      throw new UpgradePopupError('More than one plugin control found');
    }

    return pluginControls[0].appendChild(popupRootNode);
  }

  private closePopup(componentRef: ComponentRef<UpgradePopupComponent>): void {
    this.appRef.detachView(componentRef.hostView);
    componentRef?.destroy();
  }
}
