import { HttpHeaders } from './http-headers.interface';
import { HttpParams } from './http-params.interface';

export interface HttpRequestOptions {
  headers?: HttpHeaders;
  params?: HttpParams;
  reportProgress?: boolean;
  responseType?: string;
  withCredentials?: boolean;
}
