import { CultureInfo } from '../culture-info.model';
import { CultureInfoLn } from './ln';

/**
 * @ignore
 */
export const CultureInfoLnCD: CultureInfo = {
  name: 'ln-CD',
  displayName: 'lingála (Republíki ya Kongó Demokratíki)',
  iso3LanguageCode: 'lin',
  iso2LanguageCode: 'ln',
  ietfLanguageTag: 'ln-CD',
  parent: CultureInfoLn
};
