import { BehaviorSubject } from 'rxjs';
import { KeycloakTokenParsed } from 'keycloak-js';
import { Session, SessionProviderService } from '@trustedshops/tswp-core-authorization';
import { AbstractPersistentEvent, Event } from '@trustedshops/tswp-core-common';

/**
 * Base class for implementing a session provider
 */
export abstract class SessionProviderServiceBase<T extends Session, TOptions> implements SessionProviderService<T, TOptions> {
  //#region Properties
  protected _session: AbstractPersistentEvent<T> = new AbstractPersistentEvent(null);

  /**
   * Gets the Keyckloak User Info form the token
   */
  public abstract parsedToken: BehaviorSubject<KeycloakTokenParsed>

  /**
   * Gets the current session that this provider manages actively
   */
  public get session(): Event<T> {
    return this._session;
  }

  /**
   * Gets the type of this session provider
   */
  public abstract get type(): string;

  /**
   * Indicates if the session provider has an active session
   */
  public get hasSession(): boolean {
    return !!this._session.value;
  }
  //#endregion

  //#region Public Methods
  /**
   * Establishes a session and verifies if the user has been logged in already.
   * @throws {SessionError} Thrown when an error occurs while establishing the session.
   */
  public abstract initialize(options: TOptions): Promise<void>;

  /**
   * Gets the uri showing the login form.
   * @param redirectUri The url to redirect to when having logged in successfully
   * @param locale The locale to use in the login UI
   */
  public abstract getLoginUri(redirectUri?: string, locale?: string): Promise<string>;

  /**
   * Gets the uri to logout.
   */
  public abstract getLogoutUri(): Promise<string>;
  //#endregion
}
