import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoMk: CultureInfo = {
  name: 'mk',
  displayName: 'Macedonian',
  iso3LanguageCode: 'mkd',
  iso2LanguageCode: 'mk',
  ietfLanguageTag: 'mk',
  parent: null
};
