import { OrganizationalContainer } from '../models/organizational-container.interface';
import { Event } from '@trustedshops/tswp-core-common';
import { RendererInfo } from '../models/renderer-info.interface';

/**
 * Selection backend for the organizational container selection control.
 * Manages current selection and rendering information.
 */
export interface OrganizationalContainerSelectionService {
  //#region Properties
  /**
   * Gets the current HTML element name rendering an organizational container
   */
  readonly currentContainerRenderer: Event<RendererInfo>;

  /**
   * Gets the default organizational container renderer
   */
  readonly defaultContainerRenderer: RendererInfo;

  /**
   * Changes the rendering instance of all organizational containers
   *
   * @param rendererInfo Info about web component name and possibly related plugin to load
   */
  changeContainerRenderer(rendererInfo: RendererInfo): void;

  /**
   * Sets the organizational containers to be selected and notifies subsribers listening to this list
   *
   * @param selectedContainers The containers to select
   * @returns A promise resolving when the new selection has been emitted.
   */
  selectContainers<T>(selectedContainers: OrganizationalContainer<T>[]): Promise<void>;

  /**
   * Gets an event stream notifiying about changes in the selection of organizational containers
   */
  getSelection<T>(): Event<OrganizationalContainer<T>[]>;

  /**
   * Gets all organizational containers available for selection
   */
  getAllContainers<T>(): Event<OrganizationalContainer<T>[]>;

  /**
   * Sets the action permissions required to see a container
   */
  setRequiredPermissions(...actions: string[][]): void | Promise<void>;

  /**
   * Requests to open the organizational container selector's list and show it to the User in the UI.
   */
  showSelectorUI(): void;
}
