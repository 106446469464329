import {
  TOKENS,
  Tracker,
  TrackerFactory,
  TrackerType,
  TrackerTypes,
  TrackingEventInfo,
  TrackingService,
} from '@trustedshops/tswp-core-analytics';
import {
  ExportToken,
  KeyNotFoundError,
  LogService,
} from '@trustedshops/tswp-core-common';

@ExportToken(TOKENS.TrackingService)
export class TrackingServiceImpl implements TrackingService {
  private readonly _trackers: Tracker[] = [];

  public get trackers(): string[] {
    return this._trackers.map((x) => x.id);
  }

  public constructor(
    private readonly _logService: LogService,
    private readonly _trackerFactories: TrackerFactory<any>[]
  ) {}

  public async trackEvent(
    eventInfo: TrackingEventInfo,
    trackerIds?: string[] | undefined
  ): Promise<void> {
    let trackers = Array.from(this._trackers.values());
    if (trackerIds) {
      trackers = trackers.filter((tracker) => trackerIds.includes(tracker.id));
    }

    for (const tracker of trackers) {
      await tracker.trackEvent(eventInfo);
    }
  }

  public registerTracker<T extends keyof TrackerTypes>(
    type: T,
    trackerConfiguration: TrackerTypes[T]
  ): void {
    const trackerType = type as TrackerType;

    const trackerFactory = this._trackerFactories.find(
      (x) => x.type === trackerType
    );
    if (!trackerFactory) {
      throw new KeyNotFoundError(
        `Could not register tracker. Tracker type "${trackerType}" could not found for registration.`,
        type,
        this._trackerFactories.map((x) => x.type)
      );
    }

    const trackerInstance = trackerFactory.create(this, trackerConfiguration);
    if (
      trackerInstance &&
      this._trackers.find((tracker) => tracker.id === trackerInstance.id)
    ) {
      this._logService.warning(
        'tswp-core-analytics:TrackingService',
        `Ignored tracker registration with id ${trackerInstance.id} because there is already one registered with that id.`
      );
      return;
    }

    if (
      trackerInstance &&
      this._trackers.find((tracker) => tracker.id === trackerInstance.id)
    ) {
      this._logService.warning(
        'tswp-core-analytics:TrackingService',
        `Ignored tracker registration with id ${trackerInstance.id} because there is already one registered with that id.`
      );
      return;
    }

    if (trackerInstance) {
      this._trackers.push(trackerInstance);
    }
  }
}
