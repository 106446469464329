import { AbstractPersistentEvent } from '@trustedshops/tswp-core-common';
import {
  BreadcrumbsCompositionService,
  PageTitleProvider,
  ProvidedPageTitleResult,
} from '@trustedshops/tswp-core-ui';

export class DefaultPageTitleProvider implements PageTitleProvider {
  private readonly _defaultPageTitleEvent: AbstractPersistentEvent<string>;

  public constructor(
    private readonly _breadcrumbsCompositionService: BreadcrumbsCompositionService,
    private readonly _defaultTitle: string,
  ) {
    this._defaultPageTitleEvent = new AbstractPersistentEvent<string>(
      _defaultTitle,
    );
  }

  public get order(): number {
    return 0;
  }

  public getPageTitle(_: string): ProvidedPageTitleResult {
    this._breadcrumbsCompositionService.currentBreadcrumbTrail.subscribe(
      (breadcrumbTrail) => {
        if (breadcrumbTrail.length > 0) {
          const label = breadcrumbTrail[breadcrumbTrail.length - 1].label;
          this._defaultPageTitleEvent.emit(`${label} - ${this._defaultTitle}`);
          return;
        }

        this._defaultPageTitleEvent.emit(this._defaultTitle);
      },
    );

    return {
      canProvide: true,
      pageTitle: this._defaultPageTitleEvent,
    };
  }
}
