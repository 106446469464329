import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoZgh: CultureInfo = {
  name: 'zgh',
  displayName: 'ⵜⴰⵎⴰⵣⵉⵖⵜ',
  iso3LanguageCode: 'zgh',
  iso2LanguageCode: 'zgh',
  ietfLanguageTag: 'zgh',
  parent: null
};
