import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoBs: CultureInfo = {
  name: 'bs',
  displayName: 'Bosnian',
  iso3LanguageCode: 'bos',
  iso2LanguageCode: 'bs',
  ietfLanguageTag: 'bs',
  parent: null
};
