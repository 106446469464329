import { CultureInfo } from '../culture-info.model';
import { CultureInfoBm } from './bm';

/**
 * @ignore
 */
export const CultureInfoBmML: CultureInfo = {
  name: 'bm-ML',
  displayName: 'bamanakan (Mali)',
  iso3LanguageCode: 'bam',
  iso2LanguageCode: 'bm',
  ietfLanguageTag: 'bm-ML',
  parent: CultureInfoBm
};
