import { CultureInfo } from '../culture-info.model';
import { CultureInfoEs } from './es';

/**
 * @ignore
 */
export const CultureInfoEsCR: CultureInfo = {
  name: 'es-CR',
  displayName: 'Spanish (Costa Rica)',
  iso3LanguageCode: 'spa',
  iso2LanguageCode: 'es',
  ietfLanguageTag: 'es-CR',
  parent: CultureInfoEs
};
