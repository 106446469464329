import { enableProdMode, Type } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableUiElements, allControls } from '@trustedshops/tswp-core-ui-elements-ng';
import { Observable } from 'rxjs';
import { environment } from './app/configuration/get-environment.util';
import { carrierWebComponents } from './app/web-components/carrier-web-components';
import { BrowserAppModule } from './browser.module';

import { debugRxJs } from './debug';
if (localStorage.getItem('d3bug')) {
  debugRxJs(Observable);
}

if (environment.production) {
  enableProdMode();
}

const platform = platformBrowserDynamic();

platform.bootstrapModule(BrowserAppModule)
  .catch(err => console.error(err));

carrierWebComponents.forEach((webComponentModule: Type<any>) =>
  platform.bootstrapModule(webComponentModule));

enableUiElements(
  {
    platform,
    heliosUri: environment.layout.resources.heliosUri,
    heliosIconFontUri:  environment.layout.resources.heliosIconUri,
    controlModules: allControls
  });
