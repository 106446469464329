import { Injectable, Type } from '@angular/core';
import {
  DependencyContainer,
} from '@trustedshops/tswp-core-composition';
import {
  Container,
  injectable,
  inject as tagParameterInjection,
} from 'inversify';
import "reflect-metadata";

/**
 * Wraps the Inversify IoC Container
 */
@Injectable()
export class InversifyDependencyContainer extends DependencyContainer {
  //#region Properties
  /**
   * Returns the wrapped container
   */
  private get inversifyContainer(): Container {
    return this._container;
  }

  /**
   * Sets the parent container
   */
  protected set parent(value: InversifyDependencyContainer) {
    this._container.parent = value.inversifyContainer;
  }
  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of InversifyDependencyContainer
   * @param _container The underlying inversify container
   */
  public constructor(private readonly _container?: Container) {
    super();
    if (!_container) {
      this._container = new Container();
    }
  }
  //#endregion

  //#region Public Methods
  /**
   * Tries to load a service from a container.
   * @param type Type of Service to load
   */
  public get<T>(type: Type<T> | string): T {
    return this._container.get(type);
  }

  /**
   * Tries to create a service with the container, without inserting this particular service.
   * @param type Type of Service to load
   */
  public create<T>(type: Type<T>): T {
    const injectableType = type as any;
    injectable()(injectableType);

    (injectableType.tswpDependencies || []).forEach((dependency) => {
      tagParameterInjection(dependency.type)(
        type,
        dependency.key as string,
        dependency.index
      );
    });

    return this._container.resolve(type);
  }

  /**
   * Maps an existing singleton value to a service and registers this combination to the container
   * @param token Type of service to register
   * @param value Value to register for the type of service
   */
  public registerTokenizedValue<T>(
    token: string | Type<T>,
    value?: T,
    shared?: boolean
  ): DependencyContainer {
    this._container.bind(token).toConstantValue(value);

    if (shared) {
      this._container.parent
        .bind(token)
        .toFactory(() => this._container.get(token));
    }

    return this;
  }
  /**
   * Maps an existing singleton value to a service and registers this combination to the container
   * @param type Type of service to register
   * @param value Value to register for the type of service
   */
  public registerType<T>(type: Type<T>, shared?: boolean): DependencyContainer {
    const injectableType = type as any;
    injectable()(injectableType);
    (injectableType.tswpDependencies || []).forEach((dependency) => {
      tagParameterInjection(dependency.type)(
        type,
        dependency.key as string,
        dependency.index
      );
    });

    this._container.bind(type).toSelf().inSingletonScope();

    if (shared) {
      this._container.parent
        .bind(type)
        .toFactory(() => this._container.get(type));
    }

    return this;
  }
  /**
   * Maps an service type to a service token and registers this combination to the container
   * @param token Injection token for the service
   * @param type Type of the service to register
   */
  public registerTokenizedType<T>(
    token: string | Type<T>,
    type: Type<T>,
    shared?: boolean
  ): DependencyContainer {
    const injectableType = type as any;
    injectable()(injectableType);
    (injectableType.tswpDependencies || []).forEach((dependency) => {
      tagParameterInjection(dependency.type)(
        type,
        dependency.key as string,
        dependency.index
      );
    });

    this._container.bind(token).to(type).inSingletonScope();

    if (shared) {
      this._container.parent
        .bind(token)
        .toFactory(() => this._container.get(token));
    }

    return this;
  }
  //#endregion
}
