import { CultureInfo } from '../culture-info.model';
import { CultureInfoSat } from './sat';

/**
 * @ignore
 */
export const CultureInfoSatDeva: CultureInfo = {
  name: 'sat-Deva',
  displayName: 'सानताड़ी',
  iso3LanguageCode: 'sat',
  iso2LanguageCode: 'sat',
  ietfLanguageTag: 'sat-Deva',
  parent: CultureInfoSat
};
