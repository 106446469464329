import { ViewEncapsulation, Component, HostBinding, Input } from '@angular/core';
import { WebComponent } from '../../decorators/web-component.decorator';
import { HeliosControl } from '../helios-control';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { TooltipPosition } from './tooltip-position';

/**
 * <hls-status-badge type="noun" label="EXPERIMENTAL"></hls-status-badge>
 * Not visually implemented yet: Shows a tooltip on hovering the wrapped element
 * @default_slot Content of the tooltip
 */
@WebComponent('hls-tooltip')
@Component({
  selector: 'hls-tooltip',
  templateUrl: 'tooltip.component.html',
  styleUrls: ['tooltip.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class TooltipComponent extends HeliosControl {
  //#region Properties
  private _disabled = false;
  /**
   * Gets or sets a value indicating wether the tooltip is disabled
   */
  @HostBinding('attr.disabled')
  @Input()
  public get disabled(): boolean {
    return this._disabled || null;
  }
  public set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _message = '';
  /**
   * Gets or sets the message displayed in the tooltip
   */
  @Input()
  @HostBinding('attr.message')
  public get message(): string {
    return this._message;
  }
  public set message(v: string) {
    this._message = v;
  }

  private _tooltipClass = '';
  /**
   * Gets or sets a class modifying the tooltips default layout
   */
  @Input()
  @HostBinding('attr.tooltip-class')
  public get tooltipClass(): string {
    return this._tooltipClass;
  }
  public set tooltipClass(v: string) {
    this._tooltipClass = v;
  }

  private _hideDelay = 0;
  /**
   * Gets or sets the delay for when a tooltip should hide.
   * Default: `0`
   */
  @Input()
  @HostBinding('attr.hide-delay')
  public get hideDelay(): number {
    return this._hideDelay;
  }
  public set hideDelay(v: number) {
    this._hideDelay = v;
  }

  private _showDelay = 0;
  /**
   * Gets or sets the delay for when a tooltip should show up.
   * Default: `0`
   */
  @Input()
  @HostBinding('attr.show-delay')
  public get showDelay(): number {
    return this._showDelay;
  }
  public set showDelay(v: number) {
    this._showDelay = v;
  }

  private _position: TooltipPosition = TooltipPosition.Above;
  /**
   * Gets or sets the position of the tooltip relative to the wrapped element
   * Default: `0`
   */
  @Input()
  @HostBinding('attr.position')
  public get position(): TooltipPosition {
    return this._position;
  }
  public set position(v: TooltipPosition) {
    this._position = v;
  }
  //#endregion
}
