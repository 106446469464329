import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoSmn: CultureInfo = {
  name: 'smn',
  displayName: 'Inari Sami',
  iso3LanguageCode: 'smn',
  iso2LanguageCode: 'smn',
  ietfLanguageTag: 'smn',
  parent: null
};
