import { CultureInfo } from '../culture-info.model';
import { CultureInfoLo } from './lo';

/**
 * @ignore
 */
export const CultureInfoLoLA: CultureInfo = {
  name: 'lo-LA',
  displayName: 'Lao (Laos)',
  iso3LanguageCode: 'lao',
  iso2LanguageCode: 'lo',
  ietfLanguageTag: 'lo-LA',
  parent: CultureInfoLo
};
