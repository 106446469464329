import { GoogleAnalyticsTrackerConfiguration } from '../trackers/google-analytics-tracker-configuration';
import { HubSpotAnalyticsTrackerConfiguration } from '../trackers/hubspot-analytics-tracker-configuration';
import { TrackerType } from './tracker-type.enum';

/**
 * Interface managing the tracker type mapping against the desired configuration for creation of such.
 */
export interface TrackerTypes {
  [TrackerType.GoogleAnalytics]: GoogleAnalyticsTrackerConfiguration;
  [TrackerType.HubSpotAnalytics]: HubSpotAnalyticsTrackerConfiguration;
}
