import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoLag: CultureInfo = {
  name: 'lag',
  displayName: 'Kɨlaangi',
  iso3LanguageCode: 'lag',
  iso2LanguageCode: 'lag',
  ietfLanguageTag: 'lag',
  parent: null
};
