<mat-expansion-panel
  [disabled]="disabled"
  [hideToggle]="true"
  [expanded]="expanded"
  #expansionPanel
  [ngClass]="{'hls-expanded': expanded}">
  <mat-expansion-panel-header (click)="toggleExpanded()">
    <mat-panel-title>
      <div class="panel-title">
        <div class="panel-title__content"><slot name="headline"></slot></div>
        <div *ngIf="!hideToggle" class="panel-title__expander" [ngClass]="{'expanded': expanded}">
          <hls-icon icon="arrow-chevron-down"></hls-icon>
        </div>
      </div>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <slot></slot>
</mat-expansion-panel>