import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { BootstrapCustomElements } from '../../decorators/bootstrap-custom-elements.decorator';
import { SharedModule } from '../../shared.module';
import { WebComponentControlModule } from '../../web-component-control.module';
import { LoadingIndicatorComponent } from './loading-indicator.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [LoadingIndicatorComponent],
  exports: [LoadingIndicatorComponent],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
@BootstrapCustomElements([
  LoadingIndicatorComponent
])
export class HlsLoadingIndicatorModule extends WebComponentControlModule { }
