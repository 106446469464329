export interface OrganizationalContainerSelectorInfo {
  /**
   * Gets or sets the renderer to use when displaying one single container.
   */
  renderer: {
    webComponent: string;
    plugin?: string;
  };

  /**
   * Gets or sets the key of an organizational container source
   * that is to be used to get the list of containers.
   */
  source: string;

  /**
   * Sets the action permissions required to see a container
   */
  permissions: string[][];
}
