import { Event } from '@trustedshops/tswp-core-common';
import { RouteWatcherDelegate } from './router-watcher.delegate';

export interface RouteSourceService {
  readonly lastKnownRoute: string;

  /**
   * Registers an observable emitting the current active route.
   * All registered route sources are going to be merged.
   *
   * @param routeSource The route source to be registed.
   */
  registerRouteSource(routeSource: Event<any>): void;

  /**
   * Registers a route watcher.
   * All registered route watchers are going to be notified on route changes.
   *
   * @param routeWatcher The route watcher to be registered.
   */
  registerRouteWatcher(routeWatcher: RouteWatcherDelegate): void;
}
