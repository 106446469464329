import { CultureInfo } from '../culture-info.model';
import { CultureInfoJbo } from './jbo';

/**
 * @ignore
 */
export const CultureInfoJbo001: CultureInfo = {
  name: 'jbo-001',
  displayName: 'la .lojban. (001)',
  iso3LanguageCode: 'jbo',
  iso2LanguageCode: 'jbo',
  ietfLanguageTag: 'jbo-001',
  parent: CultureInfoJbo
};
