<div class="hls-menu">
  <div class="hls-menu__toggle">
    <slot name="toggle" #toggleSlot></slot>
  </div>
  <div *ngIf="listVisible" class="hls-menu__list hls-menu__list--{{align}}" >
    <div class="hls-menu__list__wrapper">
      <slot name="entry" #entrySlot>
      </slot>
    </div>
  </div>
</div>
