import { CultureInfo } from '../culture-info.model';
import { CultureInfoGa } from './ga';

/**
 * @ignore
 */
export const CultureInfoGaIE: CultureInfo = {
  name: 'ga-IE',
  displayName: 'Irish (Ireland)',
  iso3LanguageCode: 'gle',
  iso2LanguageCode: 'ga',
  ietfLanguageTag: 'ga-IE',
  parent: CultureInfoGa
};
