import { CultureInfo } from '../culture-info.model';
import { CultureInfoVi } from './vi';

/**
 * @ignore
 */
export const CultureInfoViVN: CultureInfo = {
  name: 'vi-VN',
  displayName: 'Vietnamese (Vietnam)',
  iso3LanguageCode: 'vie',
  iso2LanguageCode: 'vi',
  ietfLanguageTag: 'vi-VN',
  parent: CultureInfoVi
};
