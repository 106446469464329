import { AbstractPersistentEvent, AbstractReplayEvent, TOKENS as TOKENS_COMMON } from '@trustedshops/tswp-core-common';
import { Inject, ChangeDetectorRef, Component, Input, Type, ComponentFactoryResolver, AfterViewInit, OnInit } from '@angular/core';
import {
  UserInteractionHandler,
  UserInteractionRendererProvider,
  UserInteractionRenderer
} from '@trustedshops/tswp-core-ui';
import { SubscriberComponent } from '../../common/subscriber.component';
import { TOKENS } from '../../../export-tokens';
import { ViewContainerRef, ViewChild } from '@angular/core';
import { LogService } from '@trustedshops/tswp-core-common';

/**
 * Abstract component for fetching a specific component renderer from the registry
 */
@Component({
  selector: 'user-interaction',
  templateUrl: 'user-interaction.component.html',
  standalone: false,
})
export class UserInteractionComponent extends SubscriberComponent implements AfterViewInit, OnInit {
  //#region Statics
  public static readonly TYPE: string = '@tswp-core-ui:UserInteractionComponent';
  //#endregion

  //#region Private fields
  private readonly _userInteractionHandlerProvider: UserInteractionRendererProvider;
  private readonly _componentFactoryResolver: ComponentFactoryResolver;
  private readonly _onAfterViewInit: AbstractReplayEvent<boolean> = new AbstractReplayEvent();
  private readonly _changeDetectorRef: ChangeDetectorRef;
  private readonly _logService: LogService;

  private _isInitialized = false;
  //#endregion

  //#region Properties
  /**
   * The renderer container element
   */
  @ViewChild('rendererContainer', { read: ViewContainerRef })
  public rendererContainer: ViewContainerRef;

  /**
   * The passed content container element
   */
  @ViewChild('content', { read: ViewContainerRef })
  public content: ViewContainerRef;

  private _interaction: AbstractPersistentEvent<UserInteractionHandler> = new AbstractPersistentEvent(null);
  @Input()
  /**
   * Gets or sets the underlying interaction
   */
  public get interaction(): UserInteractionHandler {
    return this._interaction.value;
  }
  public set interaction(v: UserInteractionHandler) {
    this._interaction.emit(v);
  }

  private _renderingOptions: AbstractPersistentEvent<any> = new AbstractPersistentEvent({});
  /**
   * Gets or sets the rendering options passed to this component
   */
  @Input()
  public get renderingOptions(): any {
    return this._renderingOptions.value;
  }
  public set renderingOptions(v: any) {
    this._renderingOptions.emit(v);
  }

  private _renderingComponentType: Type<any>;
  /**
   * Gets or sets the component type that is capable of rendering the underlying interaction
   */
  public get renderingComponentType(): Type<any> {
    return this._renderingComponentType;
  }

  private _renderingComponent: UserInteractionRenderer<any>;
  /**
   * Gets or sets an instance of the current renderingComponentType
   */
  public get renderingComponent(): UserInteractionRenderer<any> {
    return this._renderingComponent;
  }

  //#endregion

  //#region Ctor
  /**
   * Creates an instance of a `UserInteractionComponent`
   * @param userInteractionRendererProvider The user interaction renderer provider to get the user interaction renderer from
   * @param componentFactoryResolver The component factory resolver to dynamically create child components
   * @param changeDetectorRef The change detector reference to trigger change detection cycles at
   * @param logService The log service
   */
  public constructor(
    @Inject(TOKENS.UserInteractionRendererProvider) userInteractionRendererProvider: UserInteractionRendererProvider,
    componentFactoryResolver: ComponentFactoryResolver,
    changeDetectorRef: ChangeDetectorRef,
    @Inject(TOKENS_COMMON.LogService) logService: LogService) {

    super();

    this._logService = logService;
    this._changeDetectorRef = changeDetectorRef;
    this._userInteractionHandlerProvider = userInteractionRendererProvider;
    this._componentFactoryResolver = componentFactoryResolver;
  }

  /**
   * Initializes the user interaction renderer
   */
  public ngAfterViewInit(): void {
    this._onAfterViewInit.emit(true);
  }

  /**
   * Initializes the component
   */
  public ngOnInit(): void {
    this.rememberEventSubscription(this._onAfterViewInit.subscribe(() => {
      if (this._isInitialized) {
        return;
      }

      this._isInitialized = true;

      this.rememberEventSubscription(this._interaction.subscribe(interaction => {
        if (!interaction) {
          return;
        }

        this.updateUserInteractionHandler(interaction);
      }));
    }));
  }
  //#endregion

  //#region Private Methods
  /**
   * Runs an update on the underlying user interaction
   * @param interaction The interaction to process the update for
   */
  private updateUserInteractionHandler(interaction: UserInteractionHandler): void {
    const previousRenderingComponentType = this.renderingComponentType;
    this._renderingComponentType = this._userInteractionHandlerProvider.getRenderer(interaction.type);
    this._logService.trace(UserInteractionComponent.TYPE, 'Resolved interaction renderer', this.renderingComponentType);

    if (previousRenderingComponentType !== this._renderingComponentType) {
      this._logService.trace(UserInteractionComponent.TYPE, 'Resolved interaction renderer differs, triggering rerender');
      this.rerender();
    }

    this._logService.trace(UserInteractionComponent.TYPE, 'Updating interaction renderer with interaction properties');

    this._renderingComponent.interaction = interaction;
    this._renderingComponent.renderingOptions = this._renderingOptions;
    this._changeDetectorRef.detectChanges();
  }

  /**
   * Triggers a rerender of the user interaction
   */
  private rerender(): void {
    const componentFactory = this._componentFactoryResolver.resolveComponentFactory(this._renderingComponentType);
    const content = this.content.element.nativeElement;

    this.rendererContainer.clear();
    const componentRef = this.rendererContainer.createComponent(componentFactory, 0, undefined, [
      [content]
    ]);
    this._renderingComponent = componentRef.instance;
  }
  //#endregion
}
