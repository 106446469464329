import { CultureInfo } from '../culture-info.model';
import { CultureInfoShiTfng } from './shi-Tfng';

/**
 * @ignore
 */
export const CultureInfoShiTfngMA: CultureInfo = {
  name: 'shi-Tfng-MA',
  displayName: 'ⵜⴰⵛⵍⵃⵉⵜ (Tfng, ⵍⵎⵖⵔⵉⴱ)',
  iso3LanguageCode: 'shi',
  iso2LanguageCode: 'shi',
  ietfLanguageTag: 'shi-Tfng-MA',
  parent: CultureInfoShiTfng
};
