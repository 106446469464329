import { ResourceOptions } from './resource-options.interface';

/**
 * JavaScript specific resource options.
 */
export interface JavaScriptResourceOptions extends ResourceOptions {
  /**
   * Equivalent to the HTML "<script defer" attribute
   */
  defer?: boolean;

  /**
   * Equivalent to the HTML "<script async" attribute
   */
  async?: boolean;

  /**
   * Equivalent to the HTML "<script nomodule" attribute
   */
  nomodule?: boolean;

  /**
   * Where to place the script tag. Default is the document body.
   */
  position?: 'head' | 'body';
}
