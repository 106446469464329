import { Provider } from '@angular/core';
import { DomResourceService, LogService, TOKENS as TOKENS_COMMON } from '@trustedshops/tswp-core-common';
import {
  TOKENS as TOKENS_ANALYTICS,
  TrackerFactory,
  TrackerType,
} from '@trustedshops/tswp-core-analytics';
import {
  GoogleAnalyticsTrackerFactory,
  HubSpotAnalyticsTrackerFactory,
  TrackingServiceImpl
} from '@trustedshops/tswp-core-analytics-implementation';
import { DOCUMENT } from '@angular/common';

export const TrackingConfiguration: Provider[] = [
  {
    provide: TOKENS_ANALYTICS.TrackerFactories,
    useFactory: (domResourceService: DomResourceService, document: Document, logService: LogService) =>
      new GoogleAnalyticsTrackerFactory(domResourceService, document, logService),
    deps: [TOKENS_COMMON.DomResourceService, DOCUMENT, TOKENS_COMMON.LogService],
    multi: true
  },
  {
    provide: TOKENS_ANALYTICS.TrackerFactories,
    useFactory: (domResourceService: DomResourceService, document: Document, logService: LogService) =>
      new HubSpotAnalyticsTrackerFactory(domResourceService, document, logService),
    deps: [TOKENS_COMMON.DomResourceService, DOCUMENT, TOKENS_COMMON.LogService],
    multi: true
  },
  {
    provide: TOKENS_ANALYTICS.TrackingService,
    useFactory: (logService: LogService, trackerFactories: TrackerFactory<TrackerType>[]) =>
      new TrackingServiceImpl(logService, trackerFactories),
    deps: [TOKENS_COMMON.LogService, TOKENS_ANALYTICS.TrackerFactories]
  }
];
