import { CultureInfo } from '../culture-info.model';
import { CultureInfoSv } from './sv';

/**
 * @ignore
 */
export const CultureInfoSvFI: CultureInfo = {
  name: 'sv-FI',
  displayName: 'Swedish (Finland)',
  iso3LanguageCode: 'swe',
  iso2LanguageCode: 'sv',
  ietfLanguageTag: 'sv-FI',
  parent: CultureInfoSv
};
