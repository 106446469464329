import { DependencyContainer } from '@trustedshops/tswp-core-composition';
import { ApplicationEnvironment } from '@trustedshops/tswp-core-common';
import { PluginRegistration } from './plugin-registration.interface';

/**
 * Context object for every plugin. This is the primary interface between the platform and one plugin.
 */
export interface PluginContext {
  /**
   * Gets environment information about the platform running this plugin
   */
  readonly applicationEnvironment: ApplicationEnvironment;

  /**
   * Gets a dependency container including relevant services for the plugin.
   * Also provides the opportunity to share services with other plugins here.
   */
  readonly serviceProvider: DependencyContainer;

  /**
   * Gets the plugin's runtime registration information.
   */
  readonly registration: PluginRegistration;
}
