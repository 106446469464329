import { Injector } from '@angular/core';
import { ChildrenOutletContexts } from '@angular/router';

export class ChildRouteInjector implements Injector {
  //#region Ctor
  public constructor(
      private childContexts: ChildrenOutletContexts,
      private parent: Injector) {}
  //#endregion

  //#region Public Methods
  public get(token: any, notFoundValue?: any): any {
    if (token === ChildrenOutletContexts) {
      return this.childContexts;
    }

    return this.parent.get(token, notFoundValue);
  }
  //#endregion
}
