import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoCo: CultureInfo = {
  name: 'co',
  displayName: 'Corsican',
  iso3LanguageCode: 'cos',
  iso2LanguageCode: 'co',
  ietfLanguageTag: 'co',
  parent: null
};
