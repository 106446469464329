import { CultureInfo } from '../culture-info.model';
import { CultureInfoHa } from './ha';

/**
 * @ignore
 */
export const CultureInfoHaNG: CultureInfo = {
  name: 'ha-NG',
  displayName: 'Hausa (Najeriya)',
  iso3LanguageCode: 'hau',
  iso2LanguageCode: 'ha',
  ietfLanguageTag: 'ha-NG',
  parent: CultureInfoHa
};
