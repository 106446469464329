import { CultureInfo } from '../culture-info.model';
import { CultureInfoFa } from './fa';

/**
 * @ignore
 */
export const CultureInfoFaAF: CultureInfo = {
  name: 'fa-AF',
  displayName: 'فارسی (افغانستان)',
  iso3LanguageCode: 'fas',
  iso2LanguageCode: 'fa',
  ietfLanguageTag: 'fa-AF',
  parent: CultureInfoFa
};
