import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { CommonModule } from '@angular/common';
import { BootstrapCustomElements } from '../../decorators/bootstrap-custom-elements.decorator';
import { SharedModule } from '../../shared.module';
import { WebComponentControlModule } from '../../web-component-control.module';
import { ToggleButtonGroupComponent } from './toggle-button-group.component';
import { ToggleButtonComponent } from './toggle-button.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [ToggleButtonGroupComponent, ToggleButtonComponent],
  exports: [ToggleButtonGroupComponent, ToggleButtonComponent],
  imports: [CommonModule,
    SharedModule,
    FormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    BrowserAnimationsModule,
  ]
})
@BootstrapCustomElements([
  ToggleButtonComponent,
  ToggleButtonGroupComponent
])
export class HlsToggleButtonGroupModule extends WebComponentControlModule { }
