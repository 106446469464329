import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoVun: CultureInfo = {
  name: 'vun',
  displayName: 'Kyivunjo',
  iso3LanguageCode: 'vun',
  iso2LanguageCode: 'vun',
  ietfLanguageTag: 'vun',
  parent: null
};
