import { ResourceOptions } from './resource-options.interface';
import { ResourceType } from './resource-type.enum';

/**
 * Information about a resource to load.
 */
export interface ResourceInfo {
  /**
   * Type of resource to load.
   * For each Resource Type there is a Resource Loader that can be used from tswp-core
   */
  type: ResourceType | string;

  /**
   * Key of the resource. This is usually the path or URL.
   */
  key: string;

  /**
   * Additional information about the resource.
   * Also can control the loading mechanism inside a loader.
   */
  options: ResourceOptions;
}
