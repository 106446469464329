import { CultureInfo } from '../culture-info.model';
import { CultureInfoFo } from './fo';

/**
 * @ignore
 */
export const CultureInfoFoDK: CultureInfo = {
  name: 'fo-DK',
  displayName: 'føroyskt (Danmark)',
  iso3LanguageCode: 'fao',
  iso2LanguageCode: 'fo',
  ietfLanguageTag: 'fo-DK',
  parent: CultureInfoFo
};
