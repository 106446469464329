import { CultureInfo } from '../culture-info.model';
import { CultureInfoSes } from './ses';

/**
 * @ignore
 */
export const CultureInfoSesML: CultureInfo = {
  name: 'ses-ML',
  displayName: 'Koyraboro senni (Maali)',
  iso3LanguageCode: 'ses',
  iso2LanguageCode: 'ses',
  ietfLanguageTag: 'ses-ML',
  parent: CultureInfoSes
};
