import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, Inject, Injectable, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { UpgradePlusComponent } from './upgrade-plus.component';
import {
  BootstrapperBase,
} from '@trustedshops/tswp-core-common';

@Injectable()
export class UpgradePlusBootstrapper implements BootstrapperBase {
  public constructor(
    private readonly _injector: Injector,
    @Inject(DOCUMENT) private readonly _document: Document,
  ) {
  }

  public async initialize(): Promise<void> {
    const customElement = createCustomElement(UpgradePlusComponent, {
      injector: this._injector
    });

    this._document.defaultView.customElements
      .define(UpgradePlusComponent.selector, customElement);
  }
}

export const UpgradePlusInitializer = [
  UpgradePlusBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [UpgradePlusBootstrapper]
  }];
