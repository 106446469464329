import { NgModule } from '@angular/core';
import { DomResourceServiceImpl } from './services/resources/dom-resource.service';
import { JsDomResourceLoaderService } from './services/resources/js-dom-resource-loader.service';
import { CssDomResourceLoaderService } from './services/resources/css-dom-resource-loader.service';
import { ApplicationServiceImpl } from './services/runtime/application.service';
import { StopwatchFactoryImpl } from './services/diagnostics/stop-watch.factory';
import { BrowserDateProviderFactory } from './services/time/browser-date-provider';
import { DOCUMENT } from '@angular/common';
import { TOKENS } from '@trustedshops/tswp-core-common';
import { TimerServiceImpl } from './services/time/timer.service';
import { LogServiceImpl } from './services/diagnostics/log.service';
import { CultureInfoServiceImpl } from './services/globalization/culture-info.service';
import { HttpClientServiceImpl } from './services/networking/http-client.service';
import { LoggingHostInitializer } from './bootstrappers/logging.bootstrapper';
import { LocalStorageServiceImpl } from './services/storage/local-storage.service';
import { RootTranslationServiceImpl } from './services/globalization/root-translation.service';

@NgModule({
  declarations: [],
  imports: [
  ],
  providers: [
    { provide: TOKENS.LogService, useClass: LogServiceImpl },
    { provide: TOKENS.TimerService, useClass: TimerServiceImpl },
    { provide: TOKENS.DomResourceService, useClass: DomResourceServiceImpl },
    { provide: TOKENS.ApplicationService, useClass: ApplicationServiceImpl },
    { provide: TOKENS.CultureInfoService, useClass: CultureInfoServiceImpl },
    { provide: TOKENS.LocalStorageService, useClass: LocalStorageServiceImpl },
    { provide: TOKENS.HttpClientService, useClass: HttpClientServiceImpl },
    { provide: TOKENS.TranslationService, useClass: RootTranslationServiceImpl },
    JsDomResourceLoaderService,
    CssDomResourceLoaderService,
    { provide: TOKENS.StopwatchFactory, useClass: StopwatchFactoryImpl },
    { provide: TOKENS.DateProvider, useFactory: BrowserDateProviderFactory, deps: [DOCUMENT] },
    LoggingHostInitializer
  ],
  exports: []
})
export class TswpCoreCommonModule { }
