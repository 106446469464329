import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoXog: CultureInfo = {
  name: 'xog',
  displayName: 'Olusoga',
  iso3LanguageCode: 'xog',
  iso2LanguageCode: 'xog',
  ietfLanguageTag: 'xog',
  parent: null
};
