import { CultureInfo } from '../culture-info.model';
import { CultureInfoHe } from './he';

/**
 * @ignore
 */
export const CultureInfoHeIL: CultureInfo = {
  name: 'he-IL',
  displayName: 'Hebrew (Israel)',
  iso3LanguageCode: 'heb',
  iso2LanguageCode: 'he',
  ietfLanguageTag: 'he-IL',
  parent: CultureInfoHe
};
