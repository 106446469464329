import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoMr: CultureInfo = {
  name: 'mr',
  displayName: 'Marathi',
  iso3LanguageCode: 'mar',
  iso2LanguageCode: 'mr',
  ietfLanguageTag: 'mr',
  parent: null
};
