import { CultureInfo } from '../culture-info.model';
import { CultureInfoWo } from './wo';

/**
 * @ignore
 */
export const CultureInfoWoSN: CultureInfo = {
  name: 'wo-SN',
  displayName: 'Wolof (Senegal)',
  iso3LanguageCode: 'wol',
  iso2LanguageCode: 'wo',
  ietfLanguageTag: 'wo-SN',
  parent: CultureInfoWo
};
