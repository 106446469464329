import { CultureInfo } from '../culture-info.model';
import { CultureInfoEn } from './en';

/**
 * @ignore
 */
export const CultureInfoEnJM: CultureInfo = {
  name: 'en-JM',
  displayName: 'English (Jamaica)',
  iso3LanguageCode: 'eng',
  iso2LanguageCode: 'en',
  ietfLanguageTag: 'en-JM',
  parent: CultureInfoEn
};
