import { ViewEncapsulation, Component, Input, ViewChild, HostBinding } from '@angular/core';
import { MatExpansionPanel, MatAccordion } from '@angular/material/expansion';
import { WebComponent } from '../../../decorators/web-component.decorator';
import { HeliosControl } from '../../helios-control';
import { BehaviorSubject } from 'rxjs';

import { filter } from 'rxjs/operators';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ElementMethod } from '../../../decorators/element-method.decorator';

/**
 * Expansion Panel from the Helios Design system. Always to be used in an Expansion Group.
 * @doctab "Examples" "expansion-panel.component.examples.md"
 * @default_slot Content of the expansion panel
 * @named_slot "headline" "Headline content of the expansion panel"
 */
@WebComponent('hls-expansion-panel')
@Component({
  selector: 'hls-expansion-panel',
  templateUrl: 'expansion-panel.component.html',
  styleUrls: ['expansion-panel.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class ExpansionPanelComponent extends HeliosControl {
  //#region Private Fields
  private readonly _currentGroup: BehaviorSubject<MatAccordion> = new BehaviorSubject(null);
  //#endregion

  //#region Properties
  private _disabled = false;
  /**
   * Gets or sets a value indicating wether this expansion panel is disabled.
   * Default: `false`
   */
  @Input()
  @HostBinding('attr.disabled')
  public get disabled(): boolean {
    return this._disabled || null;
  }
  public set disabled(v: boolean) {
    this._disabled = coerceBooleanProperty(v);
  }

  private _hideToggle: boolean;
  /**
   * Gets or sets a value indicating wether this expansion panel shows an expand/collapse icon in the title bar.
   * Default: `true`
   */
  public get hideToggle(): boolean {
    return this._hideToggle;
  }
  public set hideToggle(v: boolean) {
    this._hideToggle = coerceBooleanProperty(v);
  }


  private _expanded = true;
  /**
   * Gets or sets a value indicating wether this expansion panel is expanded.
   * Default: `true`
   */
  @Input()
  @HostBinding('attr.expanded')
  public get expanded(): boolean {
    return this._expanded;
  }
  public set expanded(v: boolean) {
    this._expanded = coerceBooleanProperty(v);
  }

  /**
   * Sets the underlying Material UI expansion panel
   */
  @ViewChild('expansionPanel', { read: MatExpansionPanel })
  public set $expansionPanel(v: MatExpansionPanel) {
    this.rememberSubscription(this._currentGroup
      .pipe(filter(group => !!group))
      .subscribe(group => {
        v.accordion = group;
      }));
  }

  /**
   * Sets the parent Material UI expansion group
   * @ignore
   */
  @Input()
  public get $accordion(): MatAccordion {
    return this._currentGroup.value;
  }
  public set $accordion(v: MatAccordion) {
    this._currentGroup.next(v);
  }

  //#endregion

  //#region Public Methods
  /**
   * Toggles the expansion state of this expansion panel.
   */
  @ElementMethod('toggleExpanded')
  public toggleExpanded(): void {
    if (this.disabled) {
      return;
    }
    this.expanded = !this.expanded;
  }
  //#endregion
}
