import { CultureInfo } from '../culture-info.model';
import { CultureInfoPaAran } from './pa-Aran';

/**
 * @ignore
 */
export const CultureInfoPaAranPK: CultureInfo = {
  name: 'pa-Aran-PK',
  displayName: 'پنجابی (نستعلیق, پاکستان)',
  iso3LanguageCode: 'pan',
  iso2LanguageCode: 'pa',
  ietfLanguageTag: 'pa-Aran-PK',
  parent: CultureInfoPaAran
};
