import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoSa: CultureInfo = {
  name: 'sa',
  displayName: 'Sanskrit',
  iso3LanguageCode: 'san',
  iso2LanguageCode: 'sa',
  ietfLanguageTag: 'sa',
  parent: null
};
