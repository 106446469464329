import { CultureInfo } from '../culture-info.model';
import { CultureInfoPl } from './pl';

/**
 * @ignore
 */
export const CultureInfoPlPL: CultureInfo = {
  name: 'pl-PL',
  displayName: 'Polish (Poland)',
  iso3LanguageCode: 'pol',
  iso2LanguageCode: 'pl',
  ietfLanguageTag: 'pl-PL',
  parent: CultureInfoPl
};
