import { ApplicationEnvironment } from '../../models/application-environment.interface';

/**
 * Service providing information about the applications runtime
 */
export interface ApplicationService {
  //#region Properties
  /**
   * Gets data about the environment this application runs in
   */
  readonly environment: ApplicationEnvironment;
  //#endregion

  //#region Public Methods
  /**
   * Initializes this ApplicationService
   * @param environment The environment this application runs in
   */
  initialize(environment: ApplicationEnvironment): void;
  //#endregion
}
