import { CultureInfo } from '../culture-info.model';
import { CultureInfoMniBeng } from './mni-Beng';

/**
 * @ignore
 */
export const CultureInfoMniBengIN: CultureInfo = {
  name: 'mni-Beng-IN',
  displayName: 'মৈতৈলোন্ (বাংলা, IN)',
  iso3LanguageCode: 'mni',
  iso2LanguageCode: 'mni',
  ietfLanguageTag: 'mni-Beng-IN',
  parent: CultureInfoMniBeng
};
