import { Event } from '@trustedshops/tswp-core-common';
import { OrganizationalContainerSource } from '../models/organizational-container-source.interface';

/**
 * Composition provider for organizational containers.
 * Responsible for collecting all container sources and distributing information about the current active container source
 */
export interface OrganizationalContainerProvider {
  //#region Properties
  /**
   * Gets the default organizational container source type
   */
  readonly defaultContainerSource: string;
  //#endregion

  //#region Public Methods
  /**
   * Registers an organization container source that's able to pull containers using a specific implementation
   *
   * @param source A organizational container source to register
   */
  registerSource<T>(source: OrganizationalContainerSource<T>): void;

  /**
   * Activates the container source with the specified key
   *
   * @param key The key of the container source to activate
   */
  activateSource(key: string): void;

  /**
   * Gets the currently active container source
   */
  getCurrentSource<T>(): Event<OrganizationalContainerSource<T>>;
  //#endregion
}
