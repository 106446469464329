import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoId: CultureInfo = {
  name: 'id',
  displayName: 'Indonesian',
  iso3LanguageCode: 'ind',
  iso2LanguageCode: 'id',
  ietfLanguageTag: 'id',
  parent: null
};
