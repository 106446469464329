import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { BootstrapCustomElements } from '../../decorators/bootstrap-custom-elements.decorator';
import { SharedModule } from '../../shared.module';
import { WebComponentControlModule } from '../../web-component-control.module';
import { StarRatingComponent } from './star-rating.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [StarRatingComponent],
  exports: [StarRatingComponent],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
@BootstrapCustomElements([
  StarRatingComponent
])
export class HlsStarRatingModule extends WebComponentControlModule { }
