import { CultureInfo } from '../culture-info.model';
import { CultureInfoKam } from './kam';

/**
 * @ignore
 */
export const CultureInfoKamKE: CultureInfo = {
  name: 'kam-KE',
  displayName: 'Kikamba (Kenya)',
  iso3LanguageCode: 'kam',
  iso2LanguageCode: 'kam',
  ietfLanguageTag: 'kam-KE',
  parent: CultureInfoKam
};
