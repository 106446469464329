import { CultureInfo } from '../culture-info.model';
import { CultureInfoFur } from './fur';

/**
 * @ignore
 */
export const CultureInfoFurIT: CultureInfo = {
  name: 'fur-IT',
  displayName: 'furlan (Italie)',
  iso3LanguageCode: 'fur',
  iso2LanguageCode: 'fur',
  ietfLanguageTag: 'fur-IT',
  parent: CultureInfoFur
};
