import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKn: CultureInfo = {
  name: 'kn',
  displayName: 'Kannada',
  iso3LanguageCode: 'kan',
  iso2LanguageCode: 'kn',
  ietfLanguageTag: 'kn',
  parent: null
};
