import { ApplicationInitStatus, APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { isPromise } from '@trustedshops/tswp-core-common-eventing-rxjs';


@Injectable()
export class ApplicationInitStatusEx extends ApplicationInitStatus {
  public asyncInitPromises: Promise<void>[];

  public constructor(@Inject(APP_INITIALIZER) private appInitializer: Array<() => void | Promise<void>>) {
    super();
  }

  public runInitializers(): void {
    if ((this as any).initialized) {
      return;
    }

    this.asyncInitPromises = [];
    const complete = (() => {
      (this as any).done = true;
      (this as any).resolve();
    });

    if ((this as any).appInits) {
      for (const element of (this as any).appInits) {
        const initResult: unknown = element();
        if (isPromise(initResult)) {
          this.asyncInitPromises.push(initResult as Promise<void>);
        }
      }
    }
    Promise.all(this.asyncInitPromises)
      .then((() => { complete(); }))
      .catch((e => { (this as any).reject(e); }));

    if (this.asyncInitPromises.length === 0) {
      complete();
    }

    (this as any).initialized = true;
  }
}
