import { EventListener, EventListenerBridge, PersistingEventListener, Type } from '@trustedshops/tswp-core-common';
import { Subject } from 'rxjs';

export function RxJsBridge<TEmission, TOutput extends Subject<TEmission>>(target: Type<TOutput>)
  : Type<EventListenerBridge<Subject<TEmission>, TEmission>> {

  return class implements  EventListenerBridge<Subject<TEmission>, TEmission> {
    //#region Public Methods
    public createBridgedEventListener(event: EventListener<TEmission>): Subject<TEmission> {
      const persistingEventListener = event as PersistingEventListener<TEmission>;
      const currentValueStream = new target();
      persistingEventListener
        .subscribe(
          x => currentValueStream.next(x),
          error => currentValueStream.error(error));
      return currentValueStream;
    }
    //#endregion
  };
}
