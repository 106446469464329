import { CultureInfo } from '../culture-info.model';
import { CultureInfoEl } from './el';

/**
 * @ignore
 */
export const CultureInfoElCY: CultureInfo = {
  name: 'el-CY',
  displayName: 'Ελληνικά (Κύπρος)',
  iso3LanguageCode: 'ell',
  iso2LanguageCode: 'el',
  ietfLanguageTag: 'el-CY',
  parent: CultureInfoEl
};
