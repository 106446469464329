import { CultureInfo } from '../culture-info.model';
import { CultureInfoSq } from './sq';

/**
 * @ignore
 */
export const CultureInfoSqAL: CultureInfo = {
  name: 'sq-AL',
  displayName: 'Albanian (Albania)',
  iso3LanguageCode: 'sqi',
  iso2LanguageCode: 'sq',
  ietfLanguageTag: 'sq-AL',
  parent: CultureInfoSq
};
