import { Type } from '@trustedshops/tswp-core-common';

/**
 * Dependency Container Abstraction interface
 */
export abstract class DependencyContainer {
  //#region Properties
  /**
   * Gets the parent of this container
   */
  protected abstract set parent(value: DependencyContainer);

  /**
   * Name of this container, used for identification
   */
  protected _name = 'ROOT';
  public get name(): string {
    return this._name;
  }
  //#endregion

  //#region Public Methods
  /**
   * Tries to load a service from a container.
   * @param type Type of Service to load
   */
  public abstract get<T>(type: Type<T>|string): T;

  /**
   * Tries to create a service with the container, without inserting this particular service.
   * @param type Type of Service to load
   */
  public abstract create<T>(type: Type<T>): T;

  /**
   * Registers a service to the container
   * @param type Type of service to register
   */
  public abstract registerType<T>(type: Type<T>, shared?: boolean): DependencyContainer;

  /**
   * Maps an existing singleton value to a service and registers this combination to the container
   * @param type Type of service to register
   * @param value Value to register for the type of service
   */
  // tslint:disable-next-line: unified-signatures
  public abstract registerTokenizedValue<T>(type: string, value: T, shared?: boolean): DependencyContainer;

  /**
   * Maps an service type to a service token and registers this combination to the container
   * @param token Injection token for the service
   * @param type Type of the service to register
   */
  // tslint:disable-next-line: unified-signatures
  public abstract registerTokenizedType<T>(token: string, type: Type<T>, shared?: boolean): DependencyContainer;

  /**
   * Creates a sub-container of this one
   * @param name Optional, name of the container, to identify it later
   */
  public createChild(name?: string): DependencyContainer {
    const containerType = this.constructor as Type<DependencyContainer>;
    const childContainer = new containerType();
    childContainer.parent = this;
    childContainer._name = `${this.name}.${name || 'CHILD'}`;
    return childContainer;
  }
  //#endregion
}
