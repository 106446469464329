import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKs: CultureInfo = {
  name: 'ks',
  displayName: 'Kashmiri',
  iso3LanguageCode: 'kas',
  iso2LanguageCode: 'ks',
  ietfLanguageTag: 'ks',
  parent: null
};
