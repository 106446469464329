import { RendererFactory2, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import {
  InvalidOperationError,
  ResourceType,
  DomResourceLoader,
  ResourceInfo,
  JavaScriptResourceOptions } from '@trustedshops/tswp-core-common';
import { ResourceLoadError } from '../../error/resource-load.error';

/**
 * TODO
 */
@Injectable()
export class JsDomResourceLoaderService implements DomResourceLoader {
  //#region Ctor
  /** ignore */
  public constructor(
    private readonly _rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private readonly _document: Document) {
  }
  //#endregion

  //#region Public Methods
  /**
   * Loads a script into the DOM.
   * @param scriptInfo Detailed data concering origin, type and many more metadata about the resource.
   */
  public async load(scriptInfo: ResourceInfo): Promise<void> {
    if (scriptInfo.type !== ResourceType.JavaScript) {
      throw new InvalidOperationError(
        `A resource of type "${scriptInfo.type}" cannot be loaded via tswp-core/JsDomResourceLoader`);
    }

    const scriptResourceOptions = scriptInfo.options as JavaScriptResourceOptions;

    const renderer = this._rendererFactory.createRenderer(null, null);

    const script = renderer.createElement('script') as HTMLScriptElement;
    script.src = scriptInfo.key;
    script.type = 'text/javascript';

    if (scriptResourceOptions.async) {
      script.setAttribute('async', '');
    }

    if (scriptResourceOptions.defer) {
      script.setAttribute('defer', '');
    }

    if (scriptResourceOptions.nomodule) {
      script.setAttribute('nomodule', '');
    }

    if (scriptResourceOptions.position && scriptResourceOptions.position === 'head') {
      renderer.appendChild(this._document.head, script);
    } else {
      renderer.appendChild(this._document.body, script);
    }

    if (scriptResourceOptions.nomodule && this.supportsES2015()) {
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      script.addEventListener('load', () => resolve());
      script.addEventListener('error', ev => reject(new ResourceLoadError(
        `An error occurred when loading resource "${scriptInfo.key}"`,
        ev)));
    });
  }
  //#endregion

  //#region Private Methods
  private supportsES2015(): boolean {
    try {
      new Function('(a = 0) => a'); // tslint:disable-line
      return true;
    } catch {
      return false;
    }
  }
  //#endregion
}
