import { NavigationEnd, Router } from '@angular/router';
import {
  BreadcrumbProvider,
  NavigateUserInteractionHandler,
  NavigationItem,
  NavigationService,
  UriLocationType,
  UserInteractionHandler,
} from '@trustedshops/tswp-core-ui';
import { BehaviorSubject, combineLatest, concat, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

interface NavigationItemWrapper<T extends UserInteractionHandler> {
  item: NavigationItem;
  interaction: T;
}

export class DefaultBreadcrumbProvider implements BreadcrumbProvider {
  private _currentItems: NavigationItem[];
  private readonly _router: Router;
  private readonly _navigationService: NavigationService;
  private readonly _primaryMenuRegion: string;
  private readonly _secondaryMenuRegion: string;

  public constructor(
    router: Router,
    navigationService: NavigationService,
    primaryMenuRegion: string,
    secondaryMenuRegion: string,
  ) {
    this._primaryMenuRegion = primaryMenuRegion;
    this._secondaryMenuRegion = secondaryMenuRegion;
    this._router = router;
    this._navigationService = navigationService;
  }

  /**
   * Gets or sets the order index at which this provider should be executed
   */
  public get order(): number {
    return 0;
  }

  public initialize(): void {
    const routingEvents = this.getRoutingEvents();
    const primaryNavigationItem = this.getActiveNavigationItemStream(
      routingEvents,
      this._primaryMenuRegion,
    );
    const secondaryNavigationItem = this.getActiveNavigationItemStream(
      routingEvents,
      this._secondaryMenuRegion,
    );

    combineLatest([primaryNavigationItem, secondaryNavigationItem]).subscribe(
      ([primary, secondary]) =>
        (this._currentItems = primary
          ? (this._currentItems = secondary ? [primary, secondary] : [primary])
          : []),
    );
  }

  /**
   * Gets the breadcrumbs for a given route
   *
   * @returns The breadcrumbs for the given route. Empty array, if no breadcrumbs found
   */
  public getBreadcrumbs(): NavigationItem[] {
    return this._currentItems;
  }

  private getActiveNavigationItemStream(
    routingEvents: Observable<NavigationEnd>,
    region: string,
  ): Observable<NavigationItem> {
    const navigationRegionItems = routingEvents.pipe(
      filter(
        () =>
          !!this._navigationService.getItemsForRegion(this._primaryMenuRegion),
      ),
      map(() =>
        this._navigationService.getItemsForRegion(this._primaryMenuRegion),
      ),
    );

    const allNavigationItems = navigationRegionItems.pipe(
      map((menuItems) =>
        menuItems.map(
          (item) =>
            ({
              item,
              interaction: item.userInteraction,
            }) as NavigationItemWrapper<any>,
        ),
      ),
    );

    const activeNavigationItems = allNavigationItems.pipe(
      map((items) => this.getInternalNavigationInteractions(items)),
      map((items) =>
        items.filter((dto) =>
          this._router.isActive(dto.interaction.uri, false),
        ),
      ),
    );

    return activeNavigationItems.pipe(
      map((items) => (items && items.length > 0 ? items[0] : null)),
      map((dto) => dto?.item || null),
    );
  }

  private getInternalNavigationInteractions(
    items: NavigationItemWrapper<UserInteractionHandler>[],
  ): NavigationItemWrapper<NavigateUserInteractionHandler>[] {
    return items
      .filter((item) => this.isNavigationInteraction(item.interaction))
      .map(
        (item) => item as NavigationItemWrapper<NavigateUserInteractionHandler>,
      )
      .filter(
        (item) => item.interaction.uriLocationType === UriLocationType.Internal,
      );
  }

  private getRoutingEvents(): Observable<NavigationEnd> {
    const routerEvents = concat(
      new BehaviorSubject(new NavigationEnd(0, '', '')).pipe(first()),
      this._router.events,
    );

    let lastRoute: string = null;

    return routerEvents.pipe(
      filter((event) => event instanceof NavigationEnd),
      filter((incomingEvent) => {
        const isSameRoute = lastRoute === incomingEvent.urlAfterRedirects;
        lastRoute = incomingEvent.urlAfterRedirects;
        return !isSameRoute;
      }),
    );
  }

  private isNavigationInteraction(
    interaction: UserInteractionHandler,
  ): boolean {
    const navigateInteraction = interaction as NavigateUserInteractionHandler;
    return !!navigateInteraction.uri && !!navigateInteraction.uriLocationType;
  }
}
