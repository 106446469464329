import { ModuleWithProviders, NgModule } from '@angular/core';
import {
  TswpCoreUiModule,
  UserInteractionRendererInitializer,
} from '@trustedshops/tswp-core-ui-implementation';
import { TswpCoreMasterdataModule } from '@trustedshops/tswp-core-masterdata-implementation';
import {
  IdentityInitializer,
  TswpCoreAuthorizationModule,
} from '@trustedshops/tswp-core-authorization-implementation';
import { TOKENS as TOKENS_COMMON } from '@trustedshops/tswp-core-common';
import {
  ApplicationDataInitializer,
  CssResourceLoaderInitializer,
  JsResourceLoaderInitializer,
  TswpCoreCommonModule,
} from '@trustedshops/tswp-core-common-implementation';
import { TswpCoreCompositionInversifyModule } from '@trustedshops/tswp-core-composition-inversify';
import {
  TswpCorePluginsModule,
  BrowserHostedPluginSystemModule,
  BrowserPluginHostInitializer,
} from '@trustedshops/tswp-core-plugins-implementation';
import { DiagnosticsInitializer } from './bootstrappers/diagnostics.bootstrapper';
import { NavigationInitializer } from './bootstrappers/navigation.bootstrapper';
import { CarrierConfig } from './models/carrier-config.interface';
import { CarrierConfigToken } from './models/carrier-config.token';
import {
  AngularKeycloakHttpInterceptor,
  KeycloakSessionProviderService,
  SetUpKeycloakDataInterceptor,
  SetUpLoginUiRedirectInterceptor,
  TswpCoreAuthorizationKeycloakModule,
} from '@trustedshops/tswp-core-authorization-keycloak-implementation';
import { ProtectedUrlsInitializer } from './bootstrappers/protected-urls.bootstrapper';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TOKENS } from '@trustedshops/tswp-core-authorization';
import { StartPluginInitializer } from './bootstrappers/start-plugins.bootstrapper';
import { GlobalizationInitializer } from './bootstrappers/globalization.bootstrapper';
import { ExtraOrdinaryServicesInitializer } from './bootstrappers/extra-ordinary-services.bootstrapper';
import { WebPlatformHttpInterceptor } from './services/non-angular-http-interceptor-translator';
import { AnimationConnectionInitializer } from './bootstrappers/animation-connection.bootstrapper';
import { KC_INSTANCE_FACTORY } from '@trustedshops/tswp-core-authorization-keycloak';
import { ConnectedKeycloakInstanceFactory } from './services/connected-keycloak-instance.factory';
import { CurrentCultureInfoProviderImpl } from './services/culture-info-provider.service';
import {
  InstanaLoggingInitializer,
  TswpCoreCommonDiagInstanaModule,
} from '@trustedshops/tswp-core-common-diag-instana';
import { ErrorService } from './services/error.service';
import { ErrorComponent } from './components/error/error.component';

@NgModule({
  declarations: [
    ErrorComponent,
  ],
  imports: [
    TswpCoreCommonModule,
    TswpCorePluginsModule,
    TswpCoreCompositionInversifyModule,
    TswpCoreUiModule,
    TswpCoreAuthorizationModule,
    TswpCoreMasterdataModule,
    BrowserHostedPluginSystemModule,
    TswpCoreAuthorizationKeycloakModule,
  ],
  exports: [
    TswpCoreCommonModule,
    TswpCorePluginsModule,
    TswpCoreCompositionInversifyModule,
    TswpCoreUiModule,
    TswpCoreMasterdataModule,
    TswpCoreAuthorizationModule,

    BrowserHostedPluginSystemModule,
    TswpCoreAuthorizationKeycloakModule,
    ErrorComponent,
  ],
  providers: [
    TswpCoreCommonDiagInstanaModule,
    DiagnosticsInitializer,

    NavigationInitializer,

    {
      provide: TOKENS_COMMON.CurrentCultureInfoProvider,
      useClass: CurrentCultureInfoProviderImpl,
    },
    GlobalizationInitializer,

    ProtectedUrlsInitializer,
    AngularKeycloakHttpInterceptor,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: WebPlatformHttpInterceptor,
      multi: true,
    },
    SetUpLoginUiRedirectInterceptor,
    SetUpKeycloakDataInterceptor,

    AnimationConnectionInitializer,
    ExtraOrdinaryServicesInitializer,
    JsResourceLoaderInitializer,
    CssResourceLoaderInitializer,
    StartPluginInitializer,
    BrowserPluginHostInitializer,

    UserInteractionRendererInitializer,

    ErrorService,
  ],
})
export class TswpCarrierDefaultConfigModule {
  public static withConfig(config: CarrierConfig): ModuleWithProviders<unknown> {
    return {
      ngModule: TswpCarrierDefaultConfigModule,
      providers: [
        { provide: CarrierConfigToken, useValue: config },
        ApplicationDataInitializer(config.environment),

        InstanaLoggingInitializer(config.diagnostics.logging.instana),

        {
          provide: KC_INSTANCE_FACTORY,
          useClass: ConnectedKeycloakInstanceFactory,
        },
        IdentityInitializer(
          KeycloakSessionProviderService as any,
          config.authorization.sessionProviderConfig.initializationOptions,
        ),
        {
          provide: TOKENS.SessionProviderConfiguration,
          useValue: config.authorization.sessionProviderConfig,
        },
      ],
    };
  }
}
