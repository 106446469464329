import { CultureInfo } from '../culture-info.model';
import { CultureInfoSs } from './ss';

/**
 * @ignore
 */
export const CultureInfoSsZA: CultureInfo = {
  name: 'ss-ZA',
  displayName: 'siSwati (ZA)',
  iso3LanguageCode: 'ssw',
  iso2LanguageCode: 'ss',
  ietfLanguageTag: 'ss-ZA',
  parent: CultureInfoSs
};
