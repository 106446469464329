import { Injectable } from '@angular/core';
import { UpgradePopupRef } from '@trustedshops/tswp-core-ui';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class UpgradePopupRefImpl implements UpgradePopupRef {
  private readonly _afterClosed$: Subject<void>  = new Subject<void>();
  public readonly afterClosed$: Observable<void> = this._afterClosed$.asObservable();

  public close(): void {
    this._afterClosed$.next();
  }
}
