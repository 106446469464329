import { CultureInfo } from '../culture-info.model';
import { CultureInfoTi } from './ti';

/**
 * @ignore
 */
export const CultureInfoTiET: CultureInfo = {
  name: 'ti-ET',
  displayName: 'Tigrinya (Ethiopia)',
  iso3LanguageCode: 'tir',
  iso2LanguageCode: 'ti',
  ietfLanguageTag: 'ti-ET',
  parent: CultureInfoTi
};
