import { Type } from '../system/type';
import { AbstractEventListener } from './abstract-event-listener';
import { EventListenerBridge } from './event-bridge.interface';
import { EventSubscription } from './event-subscription.interface';
import { SubscriptionHandler } from './subscription-handler-delegate.type';
import { Event } from './event.interface';
import { AbstractEventSubscription } from './abstract-event-subscription';

/**
 * Abstract class for a plain event stream listener to subscribe to
 */
export class AbstractEvent<TEmission> implements Event<TEmission> {
  //#region Private Fields
  protected _listener: AbstractEventListener<TEmission>;
  protected readonly _subscriptions: EventSubscription<TEmission>[] = [];
  //#endregion

  //#region Properties
  /**
   * Gets the event listener for this event stream.
   */
  public get listener(): AbstractEventListener<TEmission> {
    return this._listener;
  }
  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of an AbstractEvent
   */
  public constructor() {
    this._listener = new AbstractEventListener(
      (subscriptionHandler, errorHandler) => this._subscribe(subscriptionHandler, errorHandler),
      subscription => this._remove(subscription));
  }
  //#endregion

  //#region Public Methods
  /**
   * Subscribes to the event stream
   * @param subscriptionHandler The subscription handler that processes the event occured.
   */
  public subscribe(
    subscriptionHandler: SubscriptionHandler<TEmission>,
    errorHandler?: SubscriptionHandler<Error>): EventSubscription<TEmission> {
    return this._listener.subscribe(subscriptionHandler, errorHandler);
  }

  /**
   * Converts this abstract event stream to a specific event, e.g. RxJs Observables using a bridge
   *
   * @param bridgeType The bridge to use for converting
   * @param args The arguments to pass to the bridge
   */
  public convertWith<TOut>(type: Type<EventListenerBridge<TOut, TEmission>>, ...args: any[]): TOut {
    return this._listener.convertWith(type, ...args);
  }

  /**
   * Emits the given value in the event stream
   * @param value The value emitted.
   */
  public emit(value: TEmission): void {
    this._subscriptions.forEach(subscription =>
      subscription.handler(value));
  }

  /**
   * Emits an error on the event stream
   * @param error The error emitted
   */
  public emitError(error: Error): void {
    this._subscriptions.forEach(subscription => subscription.error(error));
  }

  /**
   * Removes the given subscription from the event stream for listening
   * @param subscription The subscription to remove
   */
  public remove(subscription: EventSubscription<TEmission>): void {
    this.listener.remove(subscription);
  }
  //#endregion

  //#region Private Methods
  protected _subscribe(handler: SubscriptionHandler<TEmission>, errorHandler: SubscriptionHandler<any>): EventSubscription<TEmission> {
    const subscription = new AbstractEventSubscription(this.listener, handler, errorHandler);
    this._subscriptions.push(subscription);
    return subscription;
  }

  protected _remove(subscription: EventSubscription<TEmission>): void {
    const index = this._subscriptions.indexOf(subscription);
    if (index < 0) {
      return;
    }

    this._subscriptions.splice(index, 1);
  }
  //#endregion
}
