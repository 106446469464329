import { CultureInfo } from '../culture-info.model';
import { CultureInfoNr } from './nr';

/**
 * @ignore
 */
export const CultureInfoNrZA: CultureInfo = {
  name: 'nr-ZA',
  displayName: 'isiNdebele (ZA)',
  iso3LanguageCode: 'nbl',
  iso2LanguageCode: 'nr',
  ietfLanguageTag: 'nr-ZA',
  parent: CultureInfoNr
};
