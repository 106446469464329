import { CultureInfo } from '../culture-info.model';
import { CultureInfoGez } from './gez';

/**
 * @ignore
 */
export const CultureInfoGezER: CultureInfo = {
  name: 'gez-ER',
  displayName: 'ግዕዝኛ (ኤርትራ)',
  iso3LanguageCode: 'gez',
  iso2LanguageCode: 'gez',
  ietfLanguageTag: 'gez-ER',
  parent: CultureInfoGez
};
