import { NgModule } from '@angular/core';
import { TOKENS as TOKENS_COMPOSITION } from '@trustedshops/tswp-core-composition';
import { InversifyDependencyContainer } from './services/inversify-dependency-container.service';
import { ServiceMappingInitializer } from './bootstrappers/service-mapping.bootstrapper';
import { Container } from 'inversify';
import { InversifyContainerFactory } from './util/inversify-container-factory.function';



@NgModule({
  providers: [
    { provide: TOKENS_COMPOSITION.DependencyContainer, useClass: InversifyDependencyContainer },
    { provide: Container, useFactory: InversifyContainerFactory },
    ServiceMappingInitializer
  ]
})
export class TswpCoreCompositionInversifyModule { }
