import { EventListener } from './event-listener.interface';
import { EventSubscription } from './event-subscription.interface';
import { SubscriptionHandler } from './subscription-handler-delegate.type';

export class AbstractEventSubscription<TEmission> implements EventSubscription<TEmission> {
  //#region Private Fields
  private readonly _eventListener: EventListener<TEmission>;
  //#endregion

  //#region Properties
  private readonly _handler: SubscriptionHandler<TEmission>;
  /**
   * Gets the handler that this subscription was initialized with
   */
  public get handler(): SubscriptionHandler<TEmission> {
    return this._handler;
  }

  private readonly _error: SubscriptionHandler<Error>;
  /**
   * Gets the handler that this subscription was initialized with
   */
  public get error(): SubscriptionHandler<Error> {
    return this._error;
  }
  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of a AbstractEventSubscription
   *
   * @param eventListener The event listener this subscription is attached to.
   * @param handler The handler that is called when an event occurs in the event stream.
   */
  public constructor(
    eventListener: EventListener<TEmission>,
    handler: SubscriptionHandler<TEmission>,
    error?: SubscriptionHandler<Error>) {
    this._eventListener = eventListener;
    this._handler = handler;
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    this._error = error || (() => {});
  }
  //#endregion

  //#region Public Methods
  /**
   * Removes this subscription from the event stream attached to.
   */
  public unsubscribe(): void {
    this._eventListener.remove(this);
  }
  //#endregion
}
