import { CultureInfo } from '../culture-info.model';
import { CultureInfoJmc } from './jmc';

/**
 * @ignore
 */
export const CultureInfoJmcTZ: CultureInfo = {
  name: 'jmc-TZ',
  displayName: 'Kimachame (Tanzania)',
  iso3LanguageCode: 'jmc',
  iso2LanguageCode: 'jmc',
  ietfLanguageTag: 'jmc-TZ',
  parent: CultureInfoJmc
};
