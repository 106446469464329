import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoCy: CultureInfo = {
  name: 'cy',
  displayName: 'Welsh',
  iso3LanguageCode: 'cym',
  iso2LanguageCode: 'cy',
  ietfLanguageTag: 'cy',
  parent: null
};
