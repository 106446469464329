import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKl: CultureInfo = {
  name: 'kl',
  displayName: 'Kalaallisut',
  iso3LanguageCode: 'kal',
  iso2LanguageCode: 'kl',
  ietfLanguageTag: 'kl',
  parent: null
};
