import { CultureInfo } from '../culture-info.model';
import { CultureInfoNqo } from './nqo';

/**
 * @ignore
 */
export const CultureInfoNqoGN: CultureInfo = {
  name: 'nqo-GN',
  displayName: 'ߒߞߏ (GN)',
  iso3LanguageCode: 'nqo',
  iso2LanguageCode: 'nqo',
  ietfLanguageTag: 'nqo-GN',
  parent: CultureInfoNqo
};
