import { CultureInfo } from '../culture-info.model';
import { CultureInfoKea } from './kea';

/**
 * @ignore
 */
export const CultureInfoKeaCV: CultureInfo = {
  name: 'kea-CV',
  displayName: 'kabuverdianu (Kabu Verdi)',
  iso3LanguageCode: 'kea',
  iso2LanguageCode: 'kea',
  ietfLanguageTag: 'kea-CV',
  parent: CultureInfoKea
};
