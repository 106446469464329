import { CultureInfo } from '../culture-info.model';
import { CultureInfoZh } from './zh';

/**
 * @ignore
 */
export const CultureInfoZhHant: CultureInfo = {
  name: 'zh-Hant',
  displayName: 'Chinese, Traditional',
  iso3LanguageCode: 'zho',
  iso2LanguageCode: 'zh',
  ietfLanguageTag: 'zh-Hant',
  parent: CultureInfoZh
};
