import { CultureInfo } from '../culture-info.model';
import { CultureInfoKo } from './ko';

/**
 * @ignore
 */
export const CultureInfoKoKR: CultureInfo = {
  name: 'ko-KR',
  displayName: 'Korean (South Korea)',
  iso3LanguageCode: 'kor',
  iso2LanguageCode: 'ko',
  ietfLanguageTag: 'ko-KR',
  parent: CultureInfoKo
};
