//#region Services
export * from './services/tracking.service';
//#endregion

//#region Models
export * from './models/tracker-type.enum';
export * from './models/tracker.interface';
export * from './models/tracking-event-info.model';
export * from './models/tracker-types.interface';
//#endregion

//#region Trackers
export * from './trackers/google-analytics-tracker-configuration';
export * from './trackers/hubspot-analytics-tracker-configuration';
export * from './trackers/tracker.factory';
export * from './trackers/google-analytics.delegate';
//#endregion

export const TOKENS = {
  TrackingService: '@trustedshops/tswp-core-analytics:TrackerService',
  TrackerFactories: '@trustedshops/tswp-core-analytics:TrackerFactories'
}
