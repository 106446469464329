import { Inject, Injectable, NgZone } from '@angular/core';

import {
  BreadcrumbProvider,
  NavigationItem,
  BreadcrumbsCompositionService,
  TOKENS,
  RouteSourceService,
} from '@trustedshops/tswp-core-ui';
import {
  TOKENS as TOKENS_COMMON,
  ExportToken,
  LogService,
  AbstractEvent,
  Event,
} from '@trustedshops/tswp-core-common';

/**
 * A service for locating an array of current breadcrumbs
 */
@Injectable()
@ExportToken(TOKENS.BreadcrumbsCompositionService)
export class BreadcrumbsCompositionServiceImpl implements BreadcrumbsCompositionService {
  //#region Private Fields
  private readonly _breadcrumbProviders: BreadcrumbProvider[] = [];
  //#endregion

  //#region Properties
  private _defaultBreadcrumbProvider: BreadcrumbProvider;
  public get defaultBreadcrumbProvider(): BreadcrumbProvider {
    return this._defaultBreadcrumbProvider;
  }

  private _currentBreadcrumbTrail: AbstractEvent<NavigationItem[]> =
    new AbstractEvent();
  /**
   * Gets the current trail of breadcrumbs
   */
  public get currentBreadcrumbTrail(): Event<NavigationItem[]> {
    return this._currentBreadcrumbTrail;
  }
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(TOKENS_COMMON.LogService)
    private readonly _logService: LogService,

    @Inject(TOKENS.RouteSourceService)
    private readonly _routeSourceService: RouteSourceService,

    private readonly _ngZone: NgZone
  ) {}
  //#endregion

  //#region Public Methods
  /**
   * Returns an ordered list of NavigationItems for the specified route to render the breadcrumbs for
   *
   * @param route The route to get the breadcrumbs for
   */
  public getBreadcrumbsForRoute(route: string): NavigationItem[] {
    const breadcrumbsByProviders = [...this._breadcrumbProviders]
      .sort((a, b) => a.order - b.order)
      .map((provider) => provider.getBreadcrumbs(route));

    const breadcrumbs =
      breadcrumbsByProviders.find(
        (providedBreadcrumbs) =>
          providedBreadcrumbs !== null && providedBreadcrumbs.length > 0
      ) ||
      this.defaultBreadcrumbProvider?.getBreadcrumbs(route) ||
      [];

    this._logService.trace(
      TOKENS.BreadcrumbsCompositionService,
      'getBreadcrumbsForRoute',
      { route, breadcrumbs }
    );

    return breadcrumbs;
  }

  /**
   * Registers one or more providers for locating business role specific breadcrumbs
   *
   * @param breadcrumbProviders The providers to register
   */
  public registerProvider(
    breadcrumbProviders: BreadcrumbProvider[]
  ): BreadcrumbsCompositionService {
    this._breadcrumbProviders.push(...breadcrumbProviders);
    return this;
  }

  /**
   * Requests for latest information of all registered providers and updates the current breadcrumb trail.
   */
  public update(): void {
    this.refreshBreadcrumbs(this._routeSourceService.lastKnownRoute);
  }

  /**
   * Configures a default provider for breadcrumbs for the case that no registered provider finds any result
   * @param provider The provider to register
   */
  public configureDefaultBreadcrumbProvider(
    provider: BreadcrumbProvider
  ): void {
    if (!this._defaultBreadcrumbProvider) {
      this._defaultBreadcrumbProvider = provider;
    }
  }
  //#endregion

  //#region Private Methods
  private refreshBreadcrumbs(route: string): void {
    this._ngZone.run(() =>
      this._currentBreadcrumbTrail.emit(this.getBreadcrumbsForRoute(route))
    );
  }
  //#endregion
}
