import { CultureInfo } from '../culture-info.model';
import { CultureInfoWal } from './wal';

/**
 * @ignore
 */
export const CultureInfoWalET: CultureInfo = {
  name: 'wal-ET',
  displayName: 'ወላይታቱ (ኢትዮጵያ)',
  iso3LanguageCode: 'wal',
  iso2LanguageCode: 'wal',
  ietfLanguageTag: 'wal-ET',
  parent: CultureInfoWal
};
