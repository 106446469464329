import { CultureInfo } from '../culture-info.model';
import { CultureInfoPaGuru } from './pa-Guru';

/**
 * @ignore
 */
export const CultureInfoPaGuruIN: CultureInfo = {
  name: 'pa-Guru-IN',
  displayName: 'ਪੰਜਾਬੀ (ਗੁਰਮੁਖੀ, ਭਾਰਤ)',
  iso3LanguageCode: 'pan',
  iso2LanguageCode: 'pa',
  ietfLanguageTag: 'pa-Guru-IN',
  parent: CultureInfoPaGuru
};
