import { CultureInfo } from '../culture-info.model';
import { CultureInfoEn } from './en';

/**
 * @ignore
 */
export const CultureInfoEnBZ: CultureInfo = {
  name: 'en-BZ',
  displayName: 'English (Belize)',
  iso3LanguageCode: 'eng',
  iso2LanguageCode: 'en',
  ietfLanguageTag: 'en-BZ',
  parent: CultureInfoEn
};
