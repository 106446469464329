import { DeploymentStageName } from './deployment-stage-name.enum';

/**
 * Describes the deployment stage of a platform instance.
 */
export interface DeploymentStage {
  /**
   * Gets or sets the name of the stage
   */
  name: DeploymentStageName | string;

  /**
   * Gets or sets a value indicating if this stage is operating with production-like conditions (e. g. caching, log levels).
   */
  productionModeEnabled: boolean;
}
