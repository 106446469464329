import { CultureInfo } from '../culture-info.model';
import { CultureInfoUz } from './uz';

/**
 * @ignore
 */
export const CultureInfoUzArab: CultureInfo = {
  name: 'uz-Arab',
  displayName: 'اوزبیک',
  iso3LanguageCode: 'uzb',
  iso2LanguageCode: 'uz',
  ietfLanguageTag: 'uz-Arab',
  parent: CultureInfoUz
};
