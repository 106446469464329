import { CultureInfo } from '../culture-info.model';
import { CultureInfoNe } from './ne';

/**
 * @ignore
 */
export const CultureInfoNeIN: CultureInfo = {
  name: 'ne-IN',
  displayName: 'Nepali (India)',
  iso3LanguageCode: 'nep',
  iso2LanguageCode: 'ne',
  ietfLanguageTag: 'ne-IN',
  parent: CultureInfoNe
};
