import { AbstractEvent } from './abstract-event';
import { EventSubscription } from './event-subscription.interface';
import { SubscriptionHandler } from './subscription-handler-delegate.type';

/**
 * An event stream inheriting from AbstractEvent but with the ability to replay all values emitted.
 */
export class AbstractReplayEvent<TEmission> extends AbstractEvent<TEmission> {
  //#region Properties
  private _value: TEmission[] = [];
  public get value(): TEmission[] {
    return this._value;
  }
  //#endregion

  //#region Public Methods
  /**
   * Adds the value to all previously emitted ones and emits the single value passed.
   *
   * @param value The value to emit
   */
  public override emit(value: TEmission): void {
    this._value.push(value);

    super.emit(value);
  }
  //#endregion

  //#region Private Methods
  protected override _subscribe(handler: SubscriptionHandler<TEmission>, errorHandler: SubscriptionHandler<any>): EventSubscription<TEmission> {
    this.value.forEach(value =>
      handler(value));

    return super._subscribe(handler, errorHandler);
  }
  //#endregion
}
