import {
  Component,
  OnInit,
  ViewEncapsulation,
  Output,
  EventEmitter,
  Inject,
} from '@angular/core';
import { LogService, TOKENS } from '@trustedshops/tswp-core-common';
import {
  TOKENS as UI_TOKENS,
  NavigationRegionService,
} from '@trustedshops/tswp-core-ui';
import { LocalStorageService, TOKENS as TOKENS_COMMON } from '@trustedshops/tswp-core-common';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NavigationRegions } from '@trustedshops/tswp-carrier-b2b-contracts';
import { RxJsBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import { LOCAL_STORAGE_COLLAPSED_KEY } from '../sidebar/sidebar.component';

@Component({
  selector: 'feedback-popup',
  templateUrl: './feedback-popup.component.html',
  styleUrls: ['./feedback-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class FeedbackPopupComponent implements OnInit {
  //#region Statics
  private static readonly TYPE = 'FeedbackPopupComponent';
  //#endregion

  public readonly isSidebarCollapsed: Observable<boolean>;

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public readonly onDismiss = new EventEmitter();

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public readonly onShowModal = new EventEmitter();
  //#endregion

  public constructor(
    @Inject(TOKENS.LogService) private readonly _logService: LogService,
    @Inject(UI_TOKENS.NavigationRegionService)
    private readonly _navigationRegionService: NavigationRegionService,
    @Inject(TOKENS_COMMON.LocalStorageService)
    private readonly _localStorageService: LocalStorageService,
  ) {
    this.isSidebarCollapsed = this._navigationRegionService
      .isRegionCollapsed(NavigationRegions.PrimaryMenu)
      .convertWith(RxJsBridge(BehaviorSubject))
      .pipe(
        map(isCollapsed =>
          isCollapsed ?? this.isSidebarInitiallyCollapsed()));
  }
  //#endregion

  public isSidebarInitiallyCollapsed(): boolean {
    return this._localStorageService.select<boolean | null>(LOCAL_STORAGE_COLLAPSED_KEY) ?? true;
  }

  //#region Public Methods
  public async ngOnInit(): Promise<void> {
    this._logService.debug(
      FeedbackPopupComponent.TYPE,
      'Feedback popup initialized'
    );
  }

  public async handleProvideFeedback(): Promise<void> {
    this._logService.debug(
      FeedbackPopupComponent.TYPE,
      'Feedback popup provide feedback clicked'
    );
    this.onShowModal.emit();
  }

  public async handleDismiss(): Promise<void> {
    this._logService.debug(
      FeedbackPopupComponent.TYPE,
      'Feedback popup dismiss clicked'
    );
    this.onDismiss.emit();
  }
  //#endregion
}
