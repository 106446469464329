export class Guid {
  /**
   * Creates a  new globally unique identifier (GUIDv4)
   */
  public static newGuid(): string {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const random = Math.random();
      // tslint:disable-next-line: no-bitwise
      const r = random * 16 | 0;

      // tslint:disable-next-line: no-bitwise
      const v = c === 'x' ? r : (r & 0x3 | 0x8);

      return v.toString(16);
    });
  }
}
