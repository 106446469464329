import { LogLevel } from './log-level.enum';

export interface LogEntry {
  /**
   * Gets or sets the time this log entry was created at
   */
  timestamp: Date;

  /**
   * Gets or sets the message that this log entry is about
   */
  message: string;

  /**
   * Gets or sets an optional payload for detailed analysis
   */
  payload: any;

  /**
   * Gets or sets a log level that this entry belongs to.
   */
  level: LogLevel;
}
