import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoIu: CultureInfo = {
  name: 'iu',
  displayName: 'Inuktitut',
  iso3LanguageCode: 'iku',
  iso2LanguageCode: 'iu',
  ietfLanguageTag: 'iu',
  parent: null
};
