export interface HubSpotAnalyticsTrackerConfiguration {

  // dummy comment to increase the version of tswp-core-common, https://github.com/trustedshops/tswp-core/tree/feat/PL-3138/integrate-hubspot-tracking

  /**
   * The script id
   */
  id: string

  /**
   * The hubspot analytics script URL
   */
  scriptUrl: string;
}
