import { CultureInfo } from '../culture-info.model';
import { CultureInfoMs } from './ms';

/**
 * @ignore
 */
export const CultureInfoMsArab: CultureInfo = {
  name: 'ms-Arab',
  displayName: 'بهاس ملايو',
  iso3LanguageCode: 'msa',
  iso2LanguageCode: 'ms',
  ietfLanguageTag: 'ms-Arab',
  parent: CultureInfoMs
};
