import { CultureInfo } from '../culture-info.model';
import { CultureInfoQu } from './qu';

/**
 * @ignore
 */
export const CultureInfoQuBO: CultureInfo = {
  name: 'qu-BO',
  displayName: 'Runasimi (Bolivia)',
  iso3LanguageCode: 'que',
  iso2LanguageCode: 'qu',
  ietfLanguageTag: 'qu-BO',
  parent: CultureInfoQu
};
