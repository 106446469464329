import { Type } from '@trustedshops/tswp-core-common';
import { InjectableType } from '../models/injectable-type.model';

export function inject(type: any): (target: Type<any>, key: string | symbol, index: number) => void {
  return (target: Type<any>, key: string | symbol, index: number) => {
    const injectable = target as InjectableType<any>;
    injectable.tswpDependencies = injectable.tswpDependencies || [];

    injectable.tswpDependencies.push({
      key,
      type,
      index
    });
  } ;
}
