import { Command } from './command';
import { LinkTarget } from '../models/link-target.enum';
import { Event } from '../eventing/event.interface';
import { AbstractPersistentEvent } from '../eventing/abstract-persistent-event';
import { EventSubscription } from '../eventing/event-subscription.interface';
import { AbstractEvent } from '../eventing/abstract-event';

/**
 * A simple implementation of a command opening a link in a desired target window.
 */
export class BrowserOpenLinkCommand extends Command<void> {
  //#region Private Fields
  private readonly _target: Event<LinkTarget | string>;
  private readonly _url: Event<string>;
  private readonly _window: Window;
  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of OpenLinkCommand
   * @param url An event stream of the URL to open
   * @param canExecute An event stream of a boolean indicating wether this command can be executed at the current time
   * @param target An event stream with a value describing the target window to open the URL within
   */
  public constructor(
    url: Event<string>,
    window: Window,
    canExecute?: Event<boolean>,
    target?: Event<LinkTarget | string>) {

    super(() => this.openLink(), canExecute);

    this._target = (target || new AbstractPersistentEvent(LinkTarget.SameWindow)) as Event<LinkTarget | string>;
    this._url = url;
    this._window = window;
  }
  //#endregion

  //#region Private Methods
  /**
   * Opens a URI inside a specific target
   */
  private openLink(): Event<void> {
    const resultEvent = new AbstractEvent<void>();

    let urlSubscription: EventSubscription<any>;
    let targetSubscription: EventSubscription<any>;


    urlSubscription = this._url.subscribe(url =>
      targetSubscription = this._target.subscribe(target => {
        if (!target || !url) {
          return;
        }

        this._window.open(url, target);
        targetSubscription?.unsubscribe();
        urlSubscription?.unsubscribe();
        targetSubscription = null;
        urlSubscription = null;
        resultEvent.emit();
      }));

    return resultEvent;
  }
  //#endregion
}
