import { CultureInfo } from '../culture-info.model';
import { CultureInfoUr } from './ur';

/**
 * @ignore
 */
export const CultureInfoUrIN: CultureInfo = {
  name: 'ur-IN',
  displayName: 'Urdu (India)',
  iso3LanguageCode: 'urd',
  iso2LanguageCode: 'ur',
  ietfLanguageTag: 'ur-IN',
  parent: CultureInfoUr
};
