import { ResourceInfo } from './resource-info.interface';

/**
 * Basic contract for an implementation of a resource loader
 * This resource loader may be registered to the DomResourceService
 */
export interface DomResourceLoader {
  /**
   * Load a resource
   * @param resource Detailed data concering origin, type and many more metadata about the resource.
   * @returns A promise resolving when loading the resource has finished.
   */
  load(resource: ResourceInfo): Promise<void>;
}
