/**
 * An enum describing a pre-defined button style, provided by the Helios design system.
 */
export enum ButtonStyle {
  /**
   * The primary button communicates strong emphasis and
   * is reserved for actions that are essential to an experience.
   * There should only be one visible call to action button per section.
   */
  Primary = 'primary',

  /**
   * The secondary button is used for medium to low emphasis.
   * It should be used in place of a call to action button when the action requires less prominence,
   * or if there are multiple primary actions of the same importance on the page.
   */
  Secondary = 'secondary',

  /**
   * The outline button is the most unobtrusive element,
   * usually used within another control.
   */
  Outline = 'outline',

  /**
   * The Go button indicates an opportunity to activate a specific resource or action, such as a feature or an element.
   */
  Go = 'go',

  /**
   * The stop button is not only used as the opposite of the `Go` button (_de_activation of resources/actions, like features/elements),
   * but also as a confirmation opportunity for destructive operations.
   *
   * To implement the first interaction to trigger a destructive operation workflow, use the `Delete` Button.
   */
  Stop = 'stop',

  /**
   * The Delete Button is designed only for indicating an opportunity to _delete_ resources
   * from the system and is only used for this purpose. If you wish to implement a confirmation of the deletion,
   * please use the `Stop` button for letting the user confirm.
   */
  Delete = 'delete'
}
