import { CultureInfo } from '../culture-info.model';
import { CultureInfoSyr } from './syr';

/**
 * @ignore
 */
export const CultureInfoSyrSY: CultureInfo = {
  name: 'syr-SY',
  displayName: 'Syriac (Syria)',
  iso3LanguageCode: 'syr',
  iso2LanguageCode: 'syr',
  ietfLanguageTag: 'syr-SY',
  parent: CultureInfoSyr
};
