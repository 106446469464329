import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoMy: CultureInfo = {
  name: 'my',
  displayName: 'Burmese',
  iso3LanguageCode: 'mya',
  iso2LanguageCode: 'my',
  ietfLanguageTag: 'my',
  parent: null
};
