import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoJgo: CultureInfo = {
  name: 'jgo',
  displayName: 'Ndaꞌa',
  iso3LanguageCode: 'jgo',
  iso2LanguageCode: 'jgo',
  ietfLanguageTag: 'jgo',
  parent: null
};
