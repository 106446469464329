/**
 * Manages access to the local storage.
 */
export interface LocalStorageService {
  /**
   * Inserts a new or updates an existing value into the local storage identified by `key`.
   * Serializes the value as JSON automatically.
   *
   * In case of plugin usage, automatically prefixes the `key` with the
   * plugin name to avoid duplicates and produce an isolated environment.
   *
   * @param key The key to identify the stored value with.
   * @param value The value to store.
   */
  insertOrUpdate<T>(key: string, value: T): void;

  /**
   * Selects the value identified by `key`. Deserializes stored JSON values automatically.
   *
   * @param key The key to identify the stored value with.
   * @returns The deserialized value with the desired type.
   * @template TObject The object type to deserialize to.
   */
  select<TObject>(key: string): TObject;

  /**
   * Deletes the value identified by `key`
   *
   * @param key The key to identify the stored value with.
   */
  delete(key: string): void;

  /**
   * Create a new subscoped local storage of this `LocalStorageService`
   *
   * @param baseKey  The key to prefix all incoming and outgoing storage keys with
   * @returns A new scoped instance of a `LocalStorageService`
   */
  createScoped(baseKey: string): LocalStorageService;
}
