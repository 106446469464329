import { CultureInfo } from '../culture-info.model';
import { CultureInfoMn } from './mn';

/**
 * @ignore
 */
export const CultureInfoMnMN: CultureInfo = {
  name: 'mn-MN',
  displayName: 'Mongolian (Mongolia)',
  iso3LanguageCode: 'mon',
  iso2LanguageCode: 'mn',
  ietfLanguageTag: 'mn-MN',
  parent: CultureInfoMn
};
