import { CultureInfo } from '../culture-info.model';
import { CultureInfoMoh } from './moh';

/**
 * @ignore
 */
export const CultureInfoMohCA: CultureInfo = {
  name: 'moh-CA',
  displayName: 'Mohawk (Canada)',
  iso3LanguageCode: 'moh',
  iso2LanguageCode: 'moh',
  ietfLanguageTag: 'moh-CA',
  parent: CultureInfoMoh
};
