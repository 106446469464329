import { CultureInfo } from '../culture-info.model';
import { CultureInfoLuo } from './luo';

/**
 * @ignore
 */
export const CultureInfoLuoKE: CultureInfo = {
  name: 'luo-KE',
  displayName: 'Dholuo (Kenya)',
  iso3LanguageCode: 'luo',
  iso2LanguageCode: 'luo',
  ietfLanguageTag: 'luo-KE',
  parent: CultureInfoLuo
};
