import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoSeh: CultureInfo = {
  name: 'seh',
  displayName: 'sena',
  iso3LanguageCode: 'seh',
  iso2LanguageCode: 'seh',
  ietfLanguageTag: 'seh',
  parent: null
};
