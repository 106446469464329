import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoUz: CultureInfo = {
  name: 'uz',
  displayName: 'Uzbek',
  iso3LanguageCode: 'uzb',
  iso2LanguageCode: 'uz',
  ietfLanguageTag: 'uz',
  parent: null
};
