import { Tracker, TrackerType, TrackingEventInfo } from '@trustedshops/tswp-core-analytics';
import {
  GoogleAnalyticsCommand,
  GoogleAnalyticsDelegate,
  GoogleAnalyticsTrackerConfiguration
} from '@trustedshops/tswp-core-analytics';
import { LogService } from '@trustedshops/tswp-core-common';

export class GoogleAnalyticsTracker implements Tracker {
  private _id: string | undefined;
  public get id(): string {
    return this._id as string;
  }

  public get type(): TrackerType {
    return TrackerType.GoogleAnalytics
  }

  public constructor(
    private readonly _config: GoogleAnalyticsTrackerConfiguration,
    private readonly _sendToGoogleAnalytics: GoogleAnalyticsDelegate,
    private readonly _logService: LogService) {

    this._id = this._config.property;
  }

  public async trackEvent(event: TrackingEventInfo): Promise<void> {
    const parameters = event.eventParameters || [];

    this._logService.debug(
      '@trustedshops/tswp-core-analytics:GoogleAnalyticsTracker',
      `Tracking event to ${this._config.property}`, event);

    if (typeof parameters[parameters.length - 1] === 'object') {
      parameters[parameters.length - 1] = {
        ...parameters[parameters.length - 1],
        send_to: this._config.property
      }
    } else {
      parameters.push({
        send_to: this._config.property
      })
    }

    this._sendToGoogleAnalytics(event.eventType as GoogleAnalyticsCommand, ...parameters);
  }

  public initialize(): void {
    this._logService.debug(
      '@trustedshops/tswp-core-analytics:GoogleAnalyticsTracker',
      `Initializing tracker for property "${this._config.property}" with configuration`, this._config);

    this._sendToGoogleAnalytics(
      'config',
      this._config.property,
      { ...this._config }
    )
  }
}
