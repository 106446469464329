import { CultureInfo } from '../culture-info.model';
import { CultureInfoSrCyrl } from './sr-Cyrl';

/**
 * @ignore
 */
export const CultureInfoSrCyrlME: CultureInfo = {
  name: 'sr-Cyrl-ME',
  displayName: 'Serbian (Cyrillic, Montenegro)',
  iso3LanguageCode: 'srp',
  iso2LanguageCode: 'sr',
  ietfLanguageTag: 'sr-Cyrl-ME',
  parent: CultureInfoSrCyrl
};
