import { ElementMethodRegistration } from '../models/element-method-registration.interface';

/**
 * Defines a method proxy on the HTML Element, causing this method to be callable from outside.
 *
 * @param methodName The method name that the assigned method should be available as. Default is the implemented name of the assigned method
 * @decorator
 */
export function ElementMethod(
  methodName?: string
): (target: any, propertyKey: string, descriptor: PropertyDescriptor) => any {
  return (target: any, propertyKey: string) => {
    methodName = methodName || propertyKey;
    const registration: ElementMethodRegistration = {
      name: methodName || propertyKey,
      methodName: propertyKey,
      target,
    };

    (target.constructor as any).elementMethods =
      (target.constructor as any).elementMethods || [];
    (
      (target.constructor as any).elementMethods as ElementMethodRegistration[]
    ).push(registration);
  };
}
