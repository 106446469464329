import {
  CultureInfo,
  CultureInfoService,
  TOKENS as COMMON_TOKENS,
  Guid,
  LogService,
} from '@trustedshops/tswp-core-common';
import {
  FeedbackBody,
  FeedbackCustomer,
  FeedbackMetadata,
  FeedbackRouteMetadata,
  FeedbackStarter,
  FeedbackTemplate,
  FeedbackTransaction,
  KeycloakTokenPayload,
} from '../models/feedback.interface';
import { Subscription } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import {
  PluginService,
  TOKENS as PLUGIN_TOKENS,
  PluginRegistration,
  PluginRouter
} from '@trustedshops/tswp-core-plugins';
import { ENVIRONMENT, Environment } from '../models/environment.type';
import {
  IdentityService,
  TOKENS as AUTH_TOKENS,
  Identity,
} from '@trustedshops/tswp-core-authorization';
import { KeycloakProfileData } from '@trustedshops/tswp-core-authorization-keycloak';
import { toPromise } from '@trustedshops/tswp-core-common-eventing-rxjs';
import { RouteSourceService, TOKENS as UI_TOKENS } from '@trustedshops/tswp-core-ui';

@Injectable()
export class FeedbackDataService {
  //#region Private Fields
  private static readonly TYPE: string = 'FeedbackDataService';
  private _routeMetadata: FeedbackRouteMetadata;
  private _routeChangeSubscription: Subscription;
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(PLUGIN_TOKENS.PluginService)
    private readonly _pluginService: PluginService,

    @Inject(COMMON_TOKENS.CultureInfoService)
    private readonly _cultureInfoService: CultureInfoService,

    @Inject(ENVIRONMENT) private readonly _environment: Environment,

    @Inject(AUTH_TOKENS.IdentityService)
    private readonly _identityService: IdentityService,

    @Inject(COMMON_TOKENS.LogService)
    private readonly _logService: LogService,

    @Inject(UI_TOKENS.RouteSourceService)
    private readonly _routeSourceService: RouteSourceService,

    @Inject(PLUGIN_TOKENS.PluginRouter)
    private readonly _pluginRouter: PluginRouter,
  ) { }

  //#endregion

  //#region Public Methods
  public initialize(): void {
    this._routeSourceService.registerRouteWatcher((route) => {
      this._logService.debug(FeedbackDataService.TYPE, 'Route changed', route);
      const cleanRoute = route.split('?')[0];
      const pluginRegistration = this._pluginRouter.getPluginRegistrationOfActiveRoute();

      if (!pluginRegistration) {
        this._routeMetadata = {
          page: cleanRoute,
          pluginName: 'unknown',
          pluginVersion: 'unknown',
        };
        return;
      }

      const version = this.determinePluginVersionFromPluginRegistration(pluginRegistration);
      this._routeMetadata = {
        page: cleanRoute,
        pluginName: pluginRegistration.name,
        pluginVersion: version,
      };
    });
  }

  public destroy(): void {
    if (this._routeChangeSubscription) {
      this._routeChangeSubscription?.unsubscribe();
      this._routeChangeSubscription = null;
    }
  }

  public async getFeedbackBody(
    starter: FeedbackStarter
  ): Promise<FeedbackBody> {
    const cultureInfo = await this.getActiveCultureInfo();

    const [questionnaireTemplate, customer, transaction, metadata]: [
      FeedbackTemplate,
      FeedbackCustomer,
      FeedbackTransaction,
      FeedbackMetadata
    ] = await Promise.all([
      this.getFeedbackTemplate(cultureInfo),
      this.getCustomerInformation(),
      this.getTransactionData(),
      this.getRouteMetadata(starter),
    ]);

    return {
      languageTag: cultureInfo.ietfLanguageTag,
      questionnaireLinkRequest: {
        type: 'ts-control-center-usage',
        questionnaireTemplate,
        customer,
        transaction,
        metadata,
      },
    };
  }
  //#endregion

  //#region Private Methods
  private async getRouteMetadata(
    starter: FeedbackStarter
  ): Promise<FeedbackMetadata> {
    const tokenPayload = await this.getTokenPayload();

    return {
      ...this._routeMetadata,
      starter,
      'customer-reference': tokenPayload['https://etrusted.com'].accountRef,
      'customer-reference-type': 'ETRUSTED_ACCOUNT_ID',
    };
  }

  private determinePluginVersionFromPluginRegistration(
    pluginRegistration: PluginRegistration
  ): string {
    const basePath = pluginRegistration.initialRequest.basePath;
    const name = pluginRegistration.initialRequest.name;
    return basePath.replace(`${name}/`, '');
  }

  private async getCustomerInformation(): Promise<FeedbackCustomer> {
    const tokenPayload = await this.getTokenPayload();

    const email = tokenPayload.email;
    const firstName = tokenPayload.given_name;
    const lastName = tokenPayload.family_name;

    return {
      email,
      ...(firstName && { firstName }),
      ...(lastName && { lastName }),
    };
  }

  private async getTokenPayload(): Promise<KeycloakTokenPayload> {
    const identity = await toPromise<Identity<KeycloakProfileData>>(
      this._identityService.identity
    );

    return await toPromise<KeycloakTokenPayload>(
      identity.profile.keycloak.token.payload
    );
  }

  private getTransactionData(): FeedbackTransaction {
    const guid = Guid.newGuid();
    const randomNumber = Math.floor(Math.random() * 1000000);
    return {
      reference: `${guid}ORDER-123-${randomNumber}`,
      date: new Date().toISOString(),
    };
  }

  private async getFeedbackTemplate(
    cultureInfo: CultureInfo
  ): Promise<FeedbackTemplate> {
    return {
      id: `${this._environment.feedback.templateBase}${cultureInfo.ietfLanguageTag}`,
    };
  }

  private async getActiveCultureInfo(): Promise<CultureInfo> {
    return new Promise((resolve) => {
      let subscription = this._cultureInfoService.currentCulture.subscribe(
        (cultureInfo) => {
          resolve(cultureInfo);
          subscription?.unsubscribe();
          subscription = null;
        }
      );
    });
  }
  //#endregion
}
