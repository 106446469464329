import { CultureInfo } from '../culture-info.model';
import { CultureInfoFo } from './fo';

/**
 * @ignore
 */
export const CultureInfoFoFO: CultureInfo = {
  name: 'fo-FO',
  displayName: 'Faroese (Faroe Islands)',
  iso3LanguageCode: 'fao',
  iso2LanguageCode: 'fo',
  ietfLanguageTag: 'fo-FO',
  parent: CultureInfoFo
};
