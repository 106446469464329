import { CultureInfo } from '../culture-info.model';
import { CultureInfoFr } from './fr';

/**
 * @ignore
 */
export const CultureInfoFrGP: CultureInfo = {
  name: 'fr-GP',
  displayName: 'français (Guadeloupe)',
  iso3LanguageCode: 'fra',
  iso2LanguageCode: 'fr',
  ietfLanguageTag: 'fr-GP',
  parent: CultureInfoFr
};
