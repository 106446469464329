import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { TOKENS as AUTH_TOKENS, IdentityService } from '@trustedshops/tswp-core-authorization';
import {
  BootstrapperBase,
  TOKENS as COMMON_TOKENS,
  EventSubscription,
  LogService
} from '@trustedshops/tswp-core-common';
import { TOKENS as PLUGIN_TOKENS, PluginRegistration, PluginRouter } from '@trustedshops/tswp-core-plugins';
import { RouteSourceService, TOKENS as UI_TOKENS } from '@trustedshops/tswp-core-ui';
import { LIBRARY_NAME } from '../constants/library-name.constant';
import { InstanaLoggingBootstrapperConfig } from './instana-logging-bootstrapper.config';

@Injectable()
export class InstanaLoggingBootstrapper implements BootstrapperBase {
  public static readonly NAME: string = `${LIBRARY_NAME}:${InstanaLoggingBootstrapper.name}`;

  public constructor(
    @Inject(COMMON_TOKENS.LogService)
    private readonly _logService: LogService,

    @Inject(UI_TOKENS.RouteSourceService)
    private readonly _routeSourceService: RouteSourceService,

    @Inject(PLUGIN_TOKENS.PluginRouter)
    private readonly _pluginRouter: PluginRouter,

    @Inject(AUTH_TOKENS.IdentityService)
    private readonly _identityService: IdentityService
  ) {
  }

  public async initialize(
    config?: InstanaLoggingBootstrapperConfig,
  ): Promise<void> {
    if (!config) {
      this._logService.warning(InstanaLoggingBootstrapper.NAME, 'Config is not provided, cannot initialize Instana.');
      return;
    }

    if (ineum === undefined || typeof ineum !== 'function') {
      this._logService.warning(InstanaLoggingBootstrapper.NAME, 'Instana is not available.');
      return;
    }

    ineum('reportingUrl', config.reportingUrl);
    ineum('key', config.key);
    ineum('page', 'loading-screen');
    ineum('user', 'unknown');

    // this is needed to not pollute the space
    (() => {
      let subscription: EventSubscription<any> | null = this._identityService.identity.subscribe(identity => {
        if (!identity) {
          return;
        }

        let payloadSubscription = identity.profile.keycloak.token.payload.subscribe(payload => {
          if (!payload) {
            return;
          }

          const identityRef = payload.identityRef ?? 'unknown';
          const accountRef = payload['https://etrusted.com']?.accountRef ?? 'unknown';

          ineum('user', identityRef);
          ineum('meta', 'accountRef', accountRef);

          if (subscription) {
            subscription.unsubscribe();
            subscription = null;
          }

          if (payloadSubscription) {
            payloadSubscription.unsubscribe();
            payloadSubscription = null;
          }
        });
      });
    })();

    this._routeSourceService.registerRouteWatcher((route) => {
      const cleanRoute = route.split('?')[0];
      const pluginRegistration = this._pluginRouter.getPluginRegistrationOfActiveRoute();

      if (!pluginRegistration) {
        this.setRouteInformation('unknown', 'unknown', cleanRoute);
        return;
      }

      const version = this.determinePluginVersionFromPluginRegistration(pluginRegistration);
      this.setRouteInformation(pluginRegistration.name, version, cleanRoute);
    });
  }

  private setRouteInformation(name: string, version: string, page: string): void {
    ineum('meta', 'pluginName', name);
    ineum('meta', 'pluginVersion', version);
    ineum('page', page);
  }

  private determinePluginVersionFromPluginRegistration(
    pluginRegistration: PluginRegistration
  ): string {
    const basePath = pluginRegistration.initialRequest.basePath;
    const name = pluginRegistration.initialRequest.name;
    return basePath.replace(`${name}/`, '');
  }
}

export const InstanaLoggingInitializer = (config: InstanaLoggingBootstrapperConfig) => ([
  InstanaLoggingBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: InstanaLoggingBootstrapper) => async () => initializer.initialize(config),
    deps: [InstanaLoggingBootstrapper],
  }
]);
