import { BehaviorSubject, Observable } from 'rxjs';
import { SystemCriticalError } from '../errors/system-critical.error';
import { Injectable } from '@angular/core';

@Injectable()
export class ErrorService {
  private _hasError$: BehaviorSubject<false | SystemCriticalError> =
    new BehaviorSubject<false | SystemCriticalError>(false);
  public get hasError$(): Observable<false | SystemCriticalError> {
    return this._hasError$;
  }

  public get errorCode(): string {
    return (this._hasError$.value as SystemCriticalError)?.errorCode;
  }

  public activateErrorState(error: SystemCriticalError): void {
    this._hasError$.next(error);
  }
}
