import { CultureInfo } from '../culture-info.model';
import { CultureInfoScn } from './scn';

/**
 * @ignore
 */
export const CultureInfoScnIT: CultureInfo = {
  name: 'scn-IT',
  displayName: 'sicilianu (IT)',
  iso3LanguageCode: 'scn',
  iso2LanguageCode: 'scn',
  ietfLanguageTag: 'scn-IT',
  parent: CultureInfoScn
};
