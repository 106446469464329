import { CultureInfo } from '../culture-info.model';
import { CultureInfoRwk } from './rwk';

/**
 * @ignore
 */
export const CultureInfoRwkTZ: CultureInfo = {
  name: 'rwk-TZ',
  displayName: 'Kiruwa (Tanzania)',
  iso3LanguageCode: 'rwk',
  iso2LanguageCode: 'rwk',
  ietfLanguageTag: 'rwk-TZ',
  parent: CultureInfoRwk
};
