import { CultureInfo } from '../culture-info.model';
import { CultureInfoCy } from './cy';

/**
 * @ignore
 */
export const CultureInfoCyGB: CultureInfo = {
  name: 'cy-GB',
  displayName: 'Welsh (United Kingdom)',
  iso3LanguageCode: 'cym',
  iso2LanguageCode: 'cy',
  ietfLanguageTag: 'cy-GB',
  parent: CultureInfoCy
};
