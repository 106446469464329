import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ChannelDto } from '@trustedshops/account-service-client';
import { OrganizationalContainer, OrganizationalContainerRenderer } from '@trustedshops/tswp-core-masterdata';

@Component({
  selector: 'default-organizational-container-renderer',
  templateUrl: 'default-organizational-container-renderer.component.html',
  styleUrls: ['default-organizational-container-renderer.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class DefaultOrganizationalContainerRendererComponent implements OrganizationalContainerRenderer<ChannelDto> {
  public static readonly selector: string = 'tswp-carrier-b2b-org-container';

  //#region Properties
  private _container: OrganizationalContainer<ChannelDto> = null;
  @Input()
  public get container(): OrganizationalContainer<ChannelDto> {
    return this._container;
  }
  public set container(v: OrganizationalContainer<ChannelDto>) {
    this._container = v;
  }

  private _containers: OrganizationalContainer<ChannelDto>[] = [];
  @Input()
  public get containers(): OrganizationalContainer<ChannelDto>[] {
    return this._containers;
  }
  public set containers(v: OrganizationalContainer<ChannelDto>[]) {
    this._containers = v;
  }

  private _selection: OrganizationalContainer<ChannelDto>[] = [];
  @Input()
  public get selection(): OrganizationalContainer<ChannelDto>[] {
    return this._selection;
  }
  public set selection(v: OrganizationalContainer<ChannelDto>[]) {
    this._selection = v;
  }

  private _isListItem: boolean;
  @Input()
  public get isListItem(): boolean {
    return this._isListItem;
  }
  public set isListItem(v: boolean) {
    this._isListItem = v;
  }


  public get allContainersSelected(): boolean {
    return this.selection.length === this.containers.length || this.selection.length === 0;
  }
  //#endregion
}
