import { Inject, Injectable } from '@angular/core';
import {
  ExportToken,
  KeyNotFoundError,
  TOKENS as TOKENS_COMMON,
  TimerService,
  LogService,
  AbstractEvent,
  Event,
  AbstractPersistentEvent
} from '@trustedshops/tswp-core-common';
import { fromObservable, RxJsBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import {
  TOKENS,
  NavigationRegionService,
  NavigationItem,
  NavigationRegionConfiguration,
  NavigationService,
  UserInteractionHandler
} from '@trustedshops/tswp-core-ui';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { InternalNavigationRegionState } from '../models/navigation-region-state.interface';

/**
 * A service for controlling a specific navigation region
 */
@ExportToken(TOKENS.NavigationRegionService)
@Injectable()
export class NavigationRegionServiceImpl implements NavigationRegionService {
  public static readonly TYPE: string = 'NavigationRegionService';

  //#region Private Fields
  private readonly _registeredRegions: Map<string, InternalNavigationRegionState> = new Map();
  //#endregion

  //#region Constructor
  /**
   * Creates a new instance of NavigationRegionServiceImpl
   * @param timerService The timer service used to schedule timed actions
   */
  public constructor(
    @Inject(TOKENS_COMMON.TimerService)
    private readonly _timerService: TimerService,
    @Inject(TOKENS_COMMON.LogService)
    private readonly _logService: LogService,
    @Inject(TOKENS.NavigationService)
    private readonly _navigationService: NavigationService) {
  }
  //#endregion

  //#region Public Methods
  /**
   * Registers a region to be managed by this service
   * @param configuration The region configuration to apply initially.
   */
  public registerRegionConfig(configuration: NavigationRegionConfiguration): void {

    const sub = new AbstractEvent<boolean>();
    const state: InternalNavigationRegionState = {
      configuration,
      isCollapsed: sub,
      activeItems: this.getRegionActiveItemStream(configuration.name),
      isCollapsedControlHandle: sub
    };

    const firstSubscription = configuration.initialState.collapsed.subscribe(x => {
      if (!state.$isCollapsed) {
        sub.emit(x);
      }

      // Can be invoked immediately, so we have to schedule it to avoid firstSubscription to be undefined.
      this._timerService.scheduleAction(0, () => firstSubscription?.unsubscribe());
    });

    state.isCollapsed.subscribe(isCollapsedValue => state.$isCollapsed = isCollapsedValue);
    this._registeredRegions.set(configuration.name, state);

    this._logService.information(NavigationRegionServiceImpl.TYPE, `Registering navigation region ${configuration.name}...`, state);
  }

  public getRegionActiveItemStream(name: string): Event<NavigationItem[]> {
    return fromObservable(
      this._navigationService.activeItems
        .convertWith(RxJsBridge(BehaviorSubject))
        .pipe(map((activeItems: NavigationItem<any, UserInteractionHandler>[]) => {
          if (!activeItems) {
            return [];
          }

          const itemsInRegion = this._navigationService.getItemsForRegion(name);
          return activeItems.filter(item => itemsInRegion.includes(item));
        })),
      AbstractPersistentEvent);
  }

  /**
   * Gets an observable stream emitting the currently active item
   *
   * @param region The region to get the active item for
   */
  public getActiveItems(region: string): Event<NavigationItem[]> {
    return this.getRegionState(region).activeItems;
  }

  /**
   * Gets an observable stream emitting the currently active item
   *
   * @deprecated This method is deprecated. Please use `getActiveItems()` in favor of this one.
   * @param region The region to get the active item for
   */
  public getActiveItem(region: string): Event<NavigationItem> {
    const converted$: Subject<NavigationItem<any, UserInteractionHandler>[]>
      = this.getActiveItems(region).convertWith(RxJsBridge(BehaviorSubject))
    return fromObservable(
      converted$
        .pipe(filter(x => !!x))
        .pipe(map(x => x[0] || null))
    );
  }

  /**
   * Gets a value indicating if the specified region is in a collapsed state
   * @param region The region to request the status for
   */
  public isRegionCollapsed(region: string): Event<boolean> {
    return this.getRegionState(region).isCollapsed;
  }

  /**
   * Toggles the state of the region between collapsed and expanded
   * @param region The region to toggle the state for
   */
  public async toggleRegion(region: string): Promise<void> {
    const regionState = this.getRegionState(region);

    regionState.isCollapsedControlHandle.emit(!regionState.$isCollapsed);

    await this._timerService.sleep(
      regionState.$isCollapsed
        ? regionState.configuration.animationDurations.expand
        : regionState.configuration.animationDurations.collapse);
  }

  /**
   * Sets the state of the region to expanded
   * @param region The region to toggle the state for
   */
  public async expandRegion(region: string): Promise<void> {
    const regionState = this.getRegionState(region);
    const isExpanded = !regionState.$isCollapsed;
    if (isExpanded) {
      return;
    }

    regionState.isCollapsedControlHandle.emit(false);

    await this._timerService.sleep(regionState.configuration.animationDurations.expand);
  }

  /**
   * Sets the state of the region to collapsed
   * @param region The region to toggle the state for
   */
  public async collapseRegion(region: string): Promise<void> {
    const regionState = this.getRegionState(region);
    if (regionState.$isCollapsed) {
      return;
    }

    regionState.isCollapsedControlHandle.emit(true);

    await this._timerService.sleep(regionState.configuration.animationDurations.collapse);
  }
  //#endregion

  //#region Private Methods
  private getRegionState(region: string): InternalNavigationRegionState {
    if (!this._registeredRegions.has(region)) {
      throw new KeyNotFoundError(
        `The specificed region is not managed by the '${TOKENS.NavigationRegionService}'`,
        region, Array.from(this._registeredRegions.keys()));
    }

    return this._registeredRegions.get(region);
  }
  //#endregion
}
