import { CultureInfo } from '../culture-info.model';
import { CultureInfoAgq } from './agq';

/**
 * @ignore
 */
export const CultureInfoAgqCM: CultureInfo = {
  name: 'agq-CM',
  displayName: 'Aghem (Kàmàlûŋ)',
  iso3LanguageCode: 'agq',
  iso2LanguageCode: 'agq',
  ietfLanguageTag: 'agq-CM',
  parent: CultureInfoAgq
};
