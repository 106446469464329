import { CultureInfo } from '../culture-info.model';
import { CultureInfoFi } from './fi';

/**
 * @ignore
 */
export const CultureInfoFiFI: CultureInfo = {
  name: 'fi-FI',
  displayName: 'Finnish (Finland)',
  iso3LanguageCode: 'fin',
  iso2LanguageCode: 'fi',
  ietfLanguageTag: 'fi-FI',
  parent: CultureInfoFi
};
