import { CultureInfo } from '../culture-info.model';
import { CultureInfoUzLatn } from './uz-Latn';

/**
 * @ignore
 */
export const CultureInfoUzLatnUZ: CultureInfo = {
  name: 'uz-Latn-UZ',
  displayName: 'Uzbek (Latin, Uzbekistan)',
  iso3LanguageCode: 'uzb',
  iso2LanguageCode: 'uz',
  ietfLanguageTag: 'uz-Latn-UZ',
  parent: CultureInfoUzLatn
};
