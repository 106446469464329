import { Event } from '@trustedshops/tswp-core-common';
import { NavigationItem } from '../../models/navigation/navigation-item.interface';
import { BreadcrumbProvider } from './breadcrumb-provider.interface';

/**
 * A service for locating an array of current breadcrumbs
 */
export interface BreadcrumbsCompositionService {
  /**
   * Gets the current trail of breadcrumbs
   */
  readonly currentBreadcrumbTrail: Event<NavigationItem[]>;

  /**
   * Returns an ordered list of NavigationItems for the specified route to render the breadcrumbs for
   *
   * @param route The route to get the breadcrumbs for
   */
  getBreadcrumbsForRoute(route: string): NavigationItem[];

  /**
   * Requests for latest information of all registered providers and updates the current breadcrumb trail.
   */
  update(): void;

  /**
   * Registers one or more providers for locating business role specific breadcrumbs
   *
   * @param breadcrumbProviders The providers to register
   */
  registerProvider(breadcrumbProviders: BreadcrumbProvider[]): BreadcrumbsCompositionService;

  /**
   * Configures a default provider for breadcrumbs for the case that no registered provider finds any result
   * @param provider The provider to register
   */
  configureDefaultBreadcrumbProvider(provider: BreadcrumbProvider): void;
}
