import { CultureInfo } from '../culture-info.model';
import { CultureInfoTeo } from './teo';

/**
 * @ignore
 */
export const CultureInfoTeoUG: CultureInfo = {
  name: 'teo-UG',
  displayName: 'Kiteso (Uganda)',
  iso3LanguageCode: 'teo',
  iso2LanguageCode: 'teo',
  ietfLanguageTag: 'teo-UG',
  parent: CultureInfoTeo
};
