import { CultureInfo } from '../culture-info.model';
import { CultureInfoLag } from './lag';

/**
 * @ignore
 */
export const CultureInfoLagTZ: CultureInfo = {
  name: 'lag-TZ',
  displayName: 'Kɨlaangi (Taansanía)',
  iso3LanguageCode: 'lag',
  iso2LanguageCode: 'lag',
  ietfLanguageTag: 'lag-TZ',
  parent: CultureInfoLag
};
