import { ObservableArray } from './observable-array';
import { ObservableArrayChangedItem } from './observable-array-changed-item';

export interface ObservableArrayChangedEvent<T> {
  collection: ObservableArray<T>;
  trigger: (...args: any[]) => any;
  deleted: Array<ObservableArrayChangedItem<T>>;
  moved?: Array<{
    from: ObservableArrayChangedItem<T>
    to: ObservableArrayChangedItem<T>
  }>;
  inserted: Array<ObservableArrayChangedItem<T>>;
}
