import { CultureInfo } from '../culture-info.model';
import { CultureInfoNl } from './nl';

/**
 * @ignore
 */
export const CultureInfoNlBE: CultureInfo = {
  name: 'nl-BE',
  displayName: 'Dutch (Belgium)',
  iso3LanguageCode: 'nld',
  iso2LanguageCode: 'nl',
  ietfLanguageTag: 'nl-BE',
  parent: CultureInfoNl
};
