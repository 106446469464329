import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKsf: CultureInfo = {
  name: 'ksf',
  displayName: 'rikpa',
  iso3LanguageCode: 'ksf',
  iso2LanguageCode: 'ksf',
  ietfLanguageTag: 'ksf',
  parent: null
};
