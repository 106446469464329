import { CultureInfo } from '../culture-info.model';
import { CultureInfoSk } from './sk';

/**
 * @ignore
 */
export const CultureInfoSkSK: CultureInfo = {
  name: 'sk-SK',
  displayName: 'Slovak (Slovakia)',
  iso3LanguageCode: 'slk',
  iso2LanguageCode: 'sk',
  ietfLanguageTag: 'sk-SK',
  parent: CultureInfoSk
};
