import { BootstrapperBase, ApplicationEnvironment, ApplicationService, ArgumentError, TOKENS } from '@trustedshops/tswp-core-common';
import { Inject, Injectable, APP_INITIALIZER, Provider } from '@angular/core';

/**
 * Initializes the ApplicationService with data like the ApplicationEnvironment
 */
@Injectable()
export class ApplicationDataBootstrapper implements BootstrapperBase {
  //#region Ctor
  /**
   * Creates an instance of ApplicationDataBoostrapper
   * @param _applicationService The ApplicationService to initialize
   */
  public constructor(@Inject(TOKENS.ApplicationService) private readonly _applicationService: ApplicationService) { }
  //#endregion

  //#region Public Methods
  /**
   * Initializes the ApplicationService
   * @param environment The environment to use when initializing
   */
  public async initialize(environment?: ApplicationEnvironment): Promise<void> {
    if (!environment) {
      throw new ArgumentError('Environment may not be null (ApplicationDataBoostrapper)');
    }

    this._applicationService.initialize(environment);
  }
  //#endregion
}

/**
 * Initializes the ApplicationService with data like the ApplicationEnvironment.
 * @param environment The environment to use when initializing
 */
export function ApplicationDataInitializer(environment: ApplicationEnvironment): Array<Provider> {
  return [
    ApplicationDataBootstrapper,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (initializer: BootstrapperBase) => {
        return () => (initializer as ApplicationDataBootstrapper).initialize(environment);
      },
      deps: [ApplicationDataBootstrapper]
    }
  ];
}
