/**
 * Enum describing a specific log level
 */
export enum LogLevel {
  /**
   * Declares a log entry in trace level
   * Useful for noisy outputs that are needed on highly detailed debugging processes
   */
  Trace = 'Trace',

  /**
   * Declares a log entry in debug level
   * Useful for less noisy outputs that are needed on usual debugging processes
   */
  Debug = 'Debug',

  /**
   * Declares a log entry in information level
   * Useful for outputs that are representing the state of a certain object or process
   */
  Information = 'Info',

  /**
   * Declares a log entry in warning level
   * Useful for stating situations that may possibly result in an error in certain circumstances
   */
  Warning = 'Warning',

  /**
   * Declares a log entry in error level
   * Useful for for events that have report a partially failed state in the application
   */
  Error = 'Error',


  /**
   * Declares a log entry in fatal level
   * Useful for for events that have report a broken application
   */
  Fatal = 'Fatal'
}
