import { CultureInfo } from '../culture-info.model';
import { CultureInfoEwo } from './ewo';

/**
 * @ignore
 */
export const CultureInfoEwoCM: CultureInfo = {
  name: 'ewo-CM',
  displayName: 'ewondo (Kamərún)',
  iso3LanguageCode: 'ewo',
  iso2LanguageCode: 'ewo',
  ietfLanguageTag: 'ewo-CM',
  parent: CultureInfoEwo
};
