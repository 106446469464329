import { Injectable, APP_INITIALIZER, Provider, Type, Inject, Injector } from '@angular/core';
import { BootstrapperBase, ArgumentError } from '@trustedshops/tswp-core-common';

import { IdentityService, SessionProviderService, Session, TOKENS } from '@trustedshops/tswp-core-authorization';

/**
 * Initializes the IdentityService with data
 */
@Injectable()
export class IdentityBootstrapper implements BootstrapperBase {
  //#region Private Fields
  private readonly _identityService: IdentityService;
  private readonly _injector: Injector;
  //#endregion

  //#region Ctor
  /**
   * Creates an instance of IdentityBootstrapper
   * @param identityService The IdentityService to initialize
   */
  public constructor(
    @Inject(TOKENS.IdentityService) identityService: IdentityService,
    @Inject(Injector) injector: Injector) {

    this._identityService = identityService;
    this._injector = injector;
  }
  //#endregion

  //#region Public Methods
  /**
   * Initializes the ApplicationService
   * @param environment The environment to use when initializing
   * @throws {ArgumentError} Thrown when sessionService argument is null or undefined.
   */
  public async initialize<TOptions>(sessionProvider?: Type<SessionProviderService<Session, TOptions>>, options?: TOptions): Promise<void> {
    if (!sessionProvider) {
      throw new ArgumentError('sessionProvider');
    }

    if (!options) {
      throw new ArgumentError('options');
    }

    const sessionProviderInstance = this._injector.get(sessionProvider);
    return this._identityService.loadIdentityFrom(sessionProviderInstance, options);
  }
  //#endregion
}

/**
 * Initializes the IdentityService with data like a session provider
 * @param The session provider to use in the application.
 */
export function IdentityInitializer<TOptions>(
  sessionProvider: Type<SessionProviderService<Session, TOptions>>,
  options: TOptions): Array<Provider> {

  return [
    IdentityBootstrapper,
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: (initializer: BootstrapperBase) => {
        return () => (initializer as IdentityBootstrapper).initialize(sessionProvider, options);
      },
      deps: [IdentityBootstrapper]
    }
  ];
}
