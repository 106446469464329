import { CultureInfo } from '../culture-info.model';
import { CultureInfoRm } from './rm';

/**
 * @ignore
 */
export const CultureInfoRmCH: CultureInfo = {
  name: 'rm-CH',
  displayName: 'Romansh (Switzerland)',
  iso3LanguageCode: 'roh',
  iso2LanguageCode: 'rm',
  ietfLanguageTag: 'rm-CH',
  parent: CultureInfoRm
};
