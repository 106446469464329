import { RootTranslationService } from './root-translation.service';
import { TranslationServiceConfiguration } from './translation-configuration.model';
import { TranslationFile } from './translation-file.model';
import { TranslationService } from './translation.service';

/**
 * Service responsible for translations.
 */
export interface ChildTranslationService<T = TranslationFile> extends TranslationService {
  /**
   * The parent instance of this translation service.
   */
  parent?: RootTranslationService;

  /**
   * Overrites the default behaviour with a given `configuration`.
   *
   * @param configuration The configuration to change the behaviour for.
   */
  overrideDefaultConfiguration(configuration: TranslationServiceConfiguration<T>): void;

  /**
   * Activate the use of child specific translations.
   *
   * Otherwise, child specific resolution is skipped and the platforms generic translations files are used.
   */
  useChildSpecificTranslations(): void;
}
