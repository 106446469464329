import { LoggingTarget, LogLevel } from '@trustedshops/tswp-core-common';

export class InstanaLoggingTarget implements LoggingTarget {
  public constructor(
    private readonly _instanaLogLevels: LogLevel[],
  ) {}

  public trace(message: string, payload?: any): void {
    if (!this.isLogLevelActive(LogLevel.Trace)) {
      return;
    }

    if (!this.ineumExists()) {
      return;
    }

    ineum('reportEvent', this.formatMessage('trace', message), payload ?? {});
  }

  public debug(message: string, payload?: any): void {
    if (!this.isLogLevelActive(LogLevel.Debug)) {
      return;
    }

    if (!this.ineumExists()) {
      return;
    }

    ineum('reportEvent', this.formatMessage('debug', message), payload ?? {});
  }

  public information(message: string, payload?: any): void {
    if (!this.isLogLevelActive(LogLevel.Information)) {
      return;
    }

    if (!this.ineumExists()) {
      return;
    }

    ineum('reportEvent', this.formatMessage('information', message), payload ?? {});
  }

  public warning(message: string, payload?: any): void {
    if (!this.isLogLevelActive(LogLevel.Warning)) {
      return;
    }

    if (!this.ineumExists()) {
      return;
    }

    ineum('reportEvent', this.formatMessage('warning', message), payload ?? {});
  }

  public error(message: string, payload?: any): void {
    if (!this.isLogLevelActive(LogLevel.Error)) {
      return;
    }

    ineum('reportError', this.formatMessage('error', message), payload ?? {});
  }

  public fatal(message: string, payload?: any): void {
    if (!this.isLogLevelActive(LogLevel.Fatal)) {
      return;
    }

    if (!this.ineumExists()) {
      return;
    }

    ineum('reportError', this.formatMessage('fatal', message), payload ?? {});
  }

  private isLogLevelActive(logLevel: LogLevel): boolean {
    return this._instanaLogLevels.includes(logLevel);
  }

  private formatMessage(target: string, message: string): string {
    return `[${target.trim().toUpperCase()}] ${message}`;
  }

  private ineumExists(): boolean {
    return ineum !== undefined && typeof ineum === 'function';
  }
}
