import { ViewEncapsulation, Component, ViewChild, ElementRef, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, ChildrenOutletContexts } from '@angular/router';
import { PluginRouteData, DynamicPluginControl, PluginService, TOKENS as PLUGINS } from '@trustedshops/tswp-core-plugins';
import { SubscriberComponent } from '@trustedshops/tswp-core-ui-implementation';
import { DOCUMENT } from '@angular/common';
import { EventSubscription, LogService, TOKENS } from '@trustedshops/tswp-core-common';
import { Subscription } from 'rxjs';

/**
 * Loads a plugins web component.
 * This is an angular component wrapping web components
 */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'plugin-control',
  template: '<div class="control-container" #container></div>',
  styleUrls: ['./plugin-control.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // eslint-disable-next-line @angular-eslint/prefer-standalone
  standalone: false
})
export class PluginControlComponent extends SubscriberComponent implements AfterViewInit, OnDestroy {
  //#region Statics
  public readonly LOG_ID: string = '@tswp-core-plugins-implementation:PluginControlComponent';
  //#endregion

  //#region Private Fields
  private readonly _subscriptions: Subscription[] = [];
  //#endregion

  //#region Properties
  /**
   * Container element inside the component
   */
  @ViewChild('container', { static: true })
  public container: ElementRef;

  private _currentRouteData: PluginRouteData;
  public get currentRouteData(): PluginRouteData {
    return this._currentRouteData;
  }
  public set currentRouteData(v: PluginRouteData) {
    this._currentRouteData = v;
  }

  public get childrenOutletContexts(): ChildrenOutletContexts {
    return this._childrenOutletContexts;
  }

  /**
   * Gets the currently rendered plugin control
   */
  public get pluginControl(): DynamicPluginControl {
    if (!this.container || !this.container.nativeElement || !this.container.nativeElement.children.length) {
      return null;
    }

    return this.container.nativeElement.children[0] as DynamicPluginControl;
  }
  //#endregion

  //#region Ctor
  /**
   * Creates an instance of PluginControlComponent
   * @param _activatedRoute The currently activated Route
   */
  public constructor(
    private readonly _activatedRoute: ActivatedRoute,
    private readonly _childrenOutletContexts: ChildrenOutletContexts,
    private readonly _elementRef: ElementRef,
    @Inject(TOKENS.LogService) private readonly _logService: LogService,
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(PLUGINS.PluginService) private readonly _pluginService: PluginService) {

    super();
    (this._elementRef.nativeElement as any).childrenOutletContexts = _childrenOutletContexts;
  }
  //#endregion

  //#region Public Methods
  /**
   * Life Cycle Hook run when this component is destroyed
   */
  public override async ngOnDestroy(): Promise<void> {
    super.ngOnDestroy();
    this._logService.trace(this.LOG_ID, 'Destroying');

    if (typeof this.pluginControl.beforeDestroyed === 'function') {
      await this.pluginControl.beforeDestroyed();
    }

    this._subscriptions
      .filter(x => !!x)
      .forEach(subscription => subscription?.unsubscribe());
  }

  /**
   * Life Cycle Hook run after the view has initialized
   */
  public async ngAfterViewInit(): Promise<void> {
    this._logService.trace(this.LOG_ID, 'Initializing');

    return new Promise(resolve =>
      this.rememberSubscription(this._activatedRoute.data.subscribe(async (routeDataPayload) => {
        await this.refreshPluginComponent(routeDataPayload as PluginRouteData);
        resolve();
      })));
  }
  //#endregion

  //#region Private Methods
  /**
   * TODO: Check if this is the case:
   * https://www.tutorialspoint.com/how-can-detached-dom-elements-cause-memory-leak-in-javascript
   */
  private async refreshPluginComponent(routeData: PluginRouteData): Promise<void> {
    if (this.currentRouteData && this.currentRouteData.webComponent === routeData.webComponent) {
      return;
    }

    this.currentRouteData = routeData;

    const pluginControl = this._document.createElement(routeData.webComponent) as DynamicPluginControl;

    if (typeof pluginControl.beforeAppended === 'function') {
      await pluginControl.beforeAppended();
    }

    if (routeData.pluginName) {
      let subscription: EventSubscription<any>;
      subscription = this._pluginService.pluginContextBus.subscribe(pluginContext => {
        if (routeData.pluginName !== pluginContext.registration.name) {
          return;
        }

        if (pluginControl.pluginContext) {
          if (subscription) {
            subscription?.unsubscribe();
            subscription = null;
          }
          return;
        }

        pluginControl.pluginContext = pluginContext;
        if (subscription) {
          subscription?.unsubscribe();
          subscription = null;
        }
      });
    }

    if (this.pluginControl && typeof this.pluginControl.beforeDestroyed === 'function') {
      await this.pluginControl.beforeDestroyed();
    }

    for (const child of this.container.nativeElement.children) {
      this.container.nativeElement.removeChild(child);
    }

    this.container.nativeElement.appendChild(pluginControl);

    if (typeof this.pluginControl.afterAppended === 'function') {
      await this.pluginControl.afterAppended();
    }
  }

  /**
   * Utility method to register a created subscription.
   * When the child class does not override/extend the `ngOnDestroy` method,
   * the registered subscriptions are going to be unsubscribed.
   *
   * @param subscription The subscription to unregister on component destruction
   */
  protected rememberSubscription(subscription: Subscription): SubscriberComponent {
    this._subscriptions.push(subscription);
    return this;
  }
  //#endregion
}
