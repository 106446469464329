import { CultureInfo } from '../culture-info.model';
import { CultureInfoMg } from './mg';

/**
 * @ignore
 */
export const CultureInfoMgMG: CultureInfo = {
  name: 'mg-MG',
  displayName: 'Malagasy (Madagasikara)',
  iso3LanguageCode: 'mlg',
  iso2LanguageCode: 'mg',
  ietfLanguageTag: 'mg-MG',
  parent: CultureInfoMg
};
