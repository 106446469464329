import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoAgq: CultureInfo = {
  name: 'agq',
  displayName: 'Aghem',
  iso3LanguageCode: 'agq',
  iso2LanguageCode: 'agq',
  ietfLanguageTag: 'agq',
  parent: null
};
