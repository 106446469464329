import { CultureInfo } from '../culture-info.model';
import { CultureInfoDav } from './dav';

/**
 * @ignore
 */
export const CultureInfoDavKE: CultureInfo = {
  name: 'dav-KE',
  displayName: 'Kitaita (Kenya)',
  iso3LanguageCode: 'dav',
  iso2LanguageCode: 'dav',
  ietfLanguageTag: 'dav-KE',
  parent: CultureInfoDav
};
