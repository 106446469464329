import { CultureInfo } from '../culture-info.model';
import { CultureInfoMua } from './mua';

/**
 * @ignore
 */
export const CultureInfoMuaCM: CultureInfo = {
  name: 'mua-CM',
  displayName: 'MUNDAŊ (kameruŋ)',
  iso3LanguageCode: 'mua',
  iso2LanguageCode: 'mua',
  ietfLanguageTag: 'mua-CM',
  parent: CultureInfoMua
};
