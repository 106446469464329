import { CultureInfo } from '../culture-info.model';
import { CultureInfoZhHans } from './zh-Hans';

/**
 * @ignore
 */
export const CultureInfoZhHansSG: CultureInfo = {
  name: 'zh-Hans-SG',
  displayName: '简体中文（新加坡）',
  iso3LanguageCode: 'zho',
  iso2LanguageCode: 'zh',
  ietfLanguageTag: 'zh-Hans-SG',
  parent: CultureInfoZhHans
};
