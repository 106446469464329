import { UserInteractionHandlerBase } from '../models/user-interaction-handler-base';
import { Command } from '@trustedshops/tswp-core-common';

/**
 * Base class for all command based user interactions
 */
export abstract class CommandUserInteractionHandler extends UserInteractionHandlerBase {
  //#region Properties
  private _command: Command<any>;
  /**
   * Gets or sets the command to execute when the underlying user interaction event fires
   */
  public get command(): Command<any> {
    return this._command;
  }
  public set command(v: Command<any>) {
    this._command = v;
  }
  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of CommandUserInteractionHandler
   *
   * @param command The command to execute when the underlying user interaction event fires
   */
  public constructor(command: Command<any>) {
    super();

    this._command = command;
  }
  //#endregion
}
