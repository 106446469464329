import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoUg: CultureInfo = {
  name: 'ug',
  displayName: 'Uyghur',
  iso3LanguageCode: 'uig',
  iso2LanguageCode: 'ug',
  ietfLanguageTag: 'ug',
  parent: null
};
