import { AbstractPersistentEvent } from '@trustedshops/tswp-core-common';

/**
 * Configuration Interface for NavigationItemBadges
 */
export interface NavigationItemBadge {
  /**
   * Event stream for the badge content.
   * If emitting `null`, the content is being removed from the badge.
   */
  content?: AbstractPersistentEvent<string | null>;

  /**
   * Controls the visibility of the badge.
   * If not set, the badge is always visible
   */
  isVisible?: AbstractPersistentEvent<boolean>;

  /**
   * Controls wether the bubble should pulse
   */
  pulsing?: AbstractPersistentEvent<boolean>;
}
