import { CultureInfo } from '../culture-info.model';
import { CultureInfoMgh } from './mgh';

/**
 * @ignore
 */
export const CultureInfoMghMZ: CultureInfo = {
  name: 'mgh-MZ',
  displayName: 'Makua (Umozambiki)',
  iso3LanguageCode: 'mgh',
  iso2LanguageCode: 'mgh',
  ietfLanguageTag: 'mgh-MZ',
  parent: CultureInfoMgh
};
