import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoCv: CultureInfo = {
  name: 'cv',
  displayName: 'Чӑвашла',
  iso3LanguageCode: 'chv',
  iso2LanguageCode: 'cv',
  ietfLanguageTag: 'cv',
  parent: null
};
