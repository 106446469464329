import { Injectable } from '@angular/core';
import { AbstractPersistentEvent, ExportToken, InvalidOperationError, KeyNotFoundError } from '@trustedshops/tswp-core-common';
import { TOKENS, VisibilityGuardFactory, VisibilityGuardFactoryRegistry } from '@trustedshops/tswp-core-ui';

/**
 * Registry for pre-defined visibility guards
 */
@Injectable()
@ExportToken(TOKENS.VisibilityGuardFactoryRegistry)
export class VisibilityGuardFactoryRegistryImpl implements VisibilityGuardFactoryRegistry {
  //#region Private Fields
  private readonly _visibilityGuardFactories: Map<string, VisibilityGuardFactory> = new Map();
  //#endregion

  //#region Public Methods
  /**
   * Registers a visibility guard factory for foreign usage
   *
   * @param type The unique key/type of the visibility guard
   * @param visibilityGuardFactory The factory of the visibility guard
   */
  public registerVisibilityGuardFactory(type: string, visibilityGuardFactory: VisibilityGuardFactory): VisibilityGuardFactoryRegistry {
    if (this._visibilityGuardFactories.has(type)) {
      throw new InvalidOperationError(`Cannot re-register visibility guard factory with type "${type}".`);
    }

    this._visibilityGuardFactories.set(type, visibilityGuardFactory);

    return this;
  }

  /**
   * Gets a visibility guard factory for instantiation of a new visibility guard
   *
   * @param type The unique key/type of the visibility guard factory registration
   * @param args The factory args
   */
  public createVisibilityGuard(type: string, ...args: any[]): AbstractPersistentEvent<boolean> {
    if (!this._visibilityGuardFactories.has(type)) {
      throw new KeyNotFoundError(`Unknown visibility guard factory type.`, type, Array.from(this._visibilityGuardFactories.keys()));
    }

    const factory = this._visibilityGuardFactories.get(type);
    return factory.create(...args);
  }
  //#endregion
}
