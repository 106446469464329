import { CultureInfo } from '../culture-info.model';
import { CultureInfoSbp } from './sbp';

/**
 * @ignore
 */
export const CultureInfoSbpTZ: CultureInfo = {
  name: 'sbp-TZ',
  displayName: 'Ishisangu (Tansaniya)',
  iso3LanguageCode: 'sbp',
  iso2LanguageCode: 'sbp',
  ietfLanguageTag: 'sbp-TZ',
  parent: CultureInfoSbp
};
