import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoSe: CultureInfo = {
  name: 'se',
  displayName: 'Northern Sami',
  iso3LanguageCode: 'sme',
  iso2LanguageCode: 'se',
  ietfLanguageTag: 'se',
  parent: null
};
