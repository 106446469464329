import { CultureInfo } from '../culture-info.model';
import { CultureInfoYue } from './yue';

/**
 * @ignore
 */
export const CultureInfoYueHans: CultureInfo = {
  name: 'yue-Hans',
  displayName: '粤语',
  iso3LanguageCode: 'yue',
  iso2LanguageCode: 'yue',
  ietfLanguageTag: 'yue-Hans',
  parent: CultureInfoYue
};
