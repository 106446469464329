import { CultureInfo } from '../culture-info.model';
import { CultureInfoDje } from './dje';

/**
 * @ignore
 */
export const CultureInfoDjeNE: CultureInfo = {
  name: 'dje-NE',
  displayName: 'Zarmaciine (Nižer)',
  iso3LanguageCode: 'dje',
  iso2LanguageCode: 'dje',
  ietfLanguageTag: 'dje-NE',
  parent: CultureInfoDje
};
