export enum NotificationType {
  /**
   * Represents an error notification
   */
  Error = 'error',

  /**
   * Represents an success notification
   */
  Success = 'success',

  /**
   * Represents an warning notification
   */
  Warning = 'warning',

  /**
   * Represents an info notification
   */
  Info = 'info',

  /**
   * Represents an default notification
   */
  Default = 'default',
}
