import { Event, IObservableArray } from '@trustedshops/tswp-core-common';
import { NavigationItem } from '../../models/navigation/navigation-item.interface';
import { UserInteractionHandlerFactory } from '../../models/user-interaction/models/user-interaction-handler.factory';
import { UserInteractionHandlerTypes } from '../../models/user-interaction/user-interaction-handler-types';

/**
 * A service responsible for registering and distributing navigation item information
 */
export interface NavigationService {
  /**
   * Gets an event stream emitting all currently active items
   */
  readonly activeItems: Event<NavigationItem[]>;
  readonly registeredItems: IObservableArray<NavigationItem>;
  readonly registeredRegions: IObservableArray<string>;

  /**
   * Gets an observable navigation items collection for a given regio
   * @param region The region to get the navigation item stream for
   */
  getItemsForRegion(region: string): IObservableArray<NavigationItem>;

  /**
   * Gets an item with the specified id.
   *
   * @param itemId The id of the item to resolve.
   * @returns A promise resolving as soon as the item has been registered.
   */
  getItemById(itemId: string): Promise<NavigationItem>;


  /**
   * Registers a set of navigation items in the specified regions
   * @param definitions The navigation item definition to register
   * @param regions The regions to register navigation items in
   */
  registerNavigationItems(definitions: NavigationItem[], ...regions: string[]): NavigationService;

  /**
   * Registers a set of children in the specified region.
   * Links the children beneath the given parent.
   *
   * @param parent The parent navigation item
   * @param children The child items to add
   * @param parentRegion The region of the parent navigation item. The region is defined for each child.
   *
   * @returns A promise resolving, when the parent item has been registered and the children registration has completed.
   */
  registerChildNavigationItem(parent: NavigationItem|string, children: NavigationItem[]): Promise<void>;

  /**
   * Gets a factory to use to apply interactions on navigation elements registered.
   *
   * @param type The type of interaction that should happen
   */
  getUserInteractionFactory<T extends keyof UserInteractionHandlerTypes>(type: T): UserInteractionHandlerFactory<T>;
}
