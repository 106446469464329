import { CultureInfo } from '../culture-info.model';
import { CultureInfoPa } from './pa';

/**
 * @ignore
 */
export const CultureInfoPaArab: CultureInfo = {
  name: 'pa-Arab',
  displayName: 'Punjabi (Naskh)',
  iso3LanguageCode: 'pan',
  iso2LanguageCode: 'pa',
  ietfLanguageTag: 'pa-Arab',
  parent: CultureInfoPa
};
