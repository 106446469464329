import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoFr: CultureInfo = {
  name: 'fr',
  displayName: 'French',
  iso3LanguageCode: 'fra',
  iso2LanguageCode: 'fr',
  ietfLanguageTag: 'fr',
  parent: null
};
