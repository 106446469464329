import { CultureInfo } from '../culture-info.model';
import { CultureInfoLuy } from './luy';

/**
 * @ignore
 */
export const CultureInfoLuyKE: CultureInfo = {
  name: 'luy-KE',
  displayName: 'Luluhia (Kenya)',
  iso3LanguageCode: 'luy',
  iso2LanguageCode: 'luy',
  ietfLanguageTag: 'luy-KE',
  parent: CultureInfoLuy
};
