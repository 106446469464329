import { CultureInfo } from '../culture-info.model';
import { CultureInfoWa } from './wa';

/**
 * @ignore
 */
export const CultureInfoWaBE: CultureInfo = {
  name: 'wa-BE',
  displayName: 'walon (BE)',
  iso3LanguageCode: 'wln',
  iso2LanguageCode: 'wa',
  ietfLanguageTag: 'wa-BE',
  parent: CultureInfoWa
};
