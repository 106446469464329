import { CultureInfo } from '../culture-info.model';
import { CultureInfoIo } from './io';

/**
 * @ignore
 */
export const CultureInfoIo001: CultureInfo = {
  name: 'io-001',
  displayName: 'Ido (001)',
  iso3LanguageCode: 'ido',
  iso2LanguageCode: 'io',
  ietfLanguageTag: 'io-001',
  parent: CultureInfoIo
};
