import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoMzn: CultureInfo = {
  name: 'mzn',
  displayName: 'مازرونی',
  iso3LanguageCode: 'mzn',
  iso2LanguageCode: 'mzn',
  ietfLanguageTag: 'mzn',
  parent: null
};
