import { CultureInfo } from '../culture-info.model';
import { CultureInfoVaiVaii } from './vai-Vaii';

/**
 * @ignore
 */
export const CultureInfoVaiVaiiLR: CultureInfo = {
  name: 'vai-Vaii-LR',
  displayName: 'ꕙꔤ (Vaii, ꕞꔤꔫꕩ)',
  iso3LanguageCode: 'vai',
  iso2LanguageCode: 'vai',
  ietfLanguageTag: 'vai-Vaii-LR',
  parent: CultureInfoVaiVaii
};
