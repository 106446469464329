import { CultureInfo } from '../culture-info.model';
import { CultureInfoNaq } from './naq';

/**
 * @ignore
 */
export const CultureInfoNaqNA: CultureInfo = {
  name: 'naq-NA',
  displayName: 'Khoekhoegowab (Namibiab)',
  iso3LanguageCode: 'naq',
  iso2LanguageCode: 'naq',
  ietfLanguageTag: 'naq-NA',
  parent: CultureInfoNaq
};
