import { AbstractEvent } from './abstract-event';
import { AbstractReplayEvent } from './abstract-replay-event';
import { Event } from './event.interface';
import { IObservableArray } from './observable-array.interface';
import { ObservableArrayChangedEvent } from './observable-array-changed-event';

interface Span {
  start: any;
  end: any;
}

export class ObservableArray<T> extends Array<T> implements IObservableArray<T> {
  //#region Properties
  private readonly _history: AbstractReplayEvent<ObservableArrayChangedEvent<T>> = new AbstractReplayEvent();
  /**
   * Returns an array replaying all array changes when subscribing
   */
  public get history(): Event<ObservableArrayChangedEvent<T>> {
    return this._history;
  }

  private readonly _onChanged: AbstractEvent<ObservableArrayChangedEvent<T>> = new AbstractEvent();
  public get onChanged(): Event<ObservableArrayChangedEvent<T>> {
    return this._onChanged;
  }
  //#endregion

  //#region Ctor
  public constructor(...initialItemsOrSize: Array<T>) {
    super(...initialItemsOrSize);
  }
  //#endregion

  //#region Public Methods
  public override push(...args: T[]): number {
    const currentLength = this.length;
    const newLength = super.push(...args);

    this.emitChange({
      collection: this,
      trigger: ObservableArray.prototype.push,
      inserted: args.map((item, i) => ({
        item,
        index: i + currentLength
      })),
      deleted: []
    });

    return newLength;
  }

  public override shift(): T {
    const oldArray = [...super.values()];
    const shiftedItem = super.shift();

    this.emitChange({
      collection: this,
      trigger: ObservableArray.prototype.shift,
      inserted: [],
      moved: oldArray
        .map((item, index) => ({
          from: {
            item,
            index
          },
          to: {
            item,
            index: index - 1
          }
        }))
        .filter((_, i) => i !== 0),
      deleted: [{
        item: shiftedItem,
        index: 0
      }]
    });

    return shiftedItem;
  }

  public override pop(): T {
    const currentLength = this.length;
    const item = super.pop();

    this.emitChange({
      collection: this,
      trigger: ObservableArray.prototype.pop,
      inserted: [],
      deleted: [{
        item,
        index: currentLength - 1
      }]
    });

    return item;
  }

  public override unshift(...items: T[]): number {
    const oldArray = [...super.values()];
    const newLength = super.unshift(...items);

    this.emitChange({
      collection: this,
      trigger: ObservableArray.prototype.unshift,
      inserted: items.map((item, i) => ({
        item,
        index: i
      })),
      deleted: [],
      moved: oldArray.map((item, index) => ({
        from: {
          item,
          index
        },
        to: {
          item,
          index: index + items.length
        }
      }))
    });

    return newLength;
  }

  public override splice(start: number, deleteCount?: number, ...items: T[]): T[] {
    if (start < 0) {
      start = this.length - start;
    }

    if (deleteCount === undefined) {
      deleteCount = this.length;
    }

    const itemsMoved = [...super.values()];
    const itemsRemoved = super.splice(start, deleteCount, ...items);
    this.emitChange({
      collection: this,
      trigger: ObservableArray.prototype.splice,
      inserted: items.map((item, index) => ({
        item,
        index: index + start
      })),
      moved: itemsMoved
        .map((item, index) => ({
          from: {
            item,
            index
          },
          to: {
            item,
            index: index - deleteCount + items.length
          }
        }))
        .slice(start + deleteCount),
      deleted: [...itemsRemoved].map((item, index) => ({
        item,
        index: start + index
      }))
    });

    return [...itemsRemoved];
  }

  public override reverse(): this {
    const itemsRemoved = [...this.values()];
    super.reverse();

    this.emitChange({
      collection: this,
      trigger: ObservableArray.prototype.reverse,
      inserted: [...this.map((item, index) => ({ item, index }))],
      deleted: itemsRemoved.map((item, index) => ({ item, index }))
    });

    return this;
  }

  public override sort(compareFn?: (a: T, b: T) => number): this {
    const oldArray = [...this];
    super.sort(compareFn);
    const newArray = [...this];

    this.emitChange({
      collection: this,
      trigger: ObservableArray.prototype.sort,
      inserted: newArray
        .map((item, index) => ({ item, index }))
        .filter(entry => oldArray[entry.index] !== newArray[entry.index]),
      deleted: oldArray
        .map((item, index) => ({ item, index }))
        .filter(entry => oldArray[entry.index] !== newArray[entry.index])
    });

    return this;
  }

  public override copyWithin(target: number, start?: number, end?: number): this {
    const { start: alignedStart, end: alignedEnd }: Span = this.realignStartEnd(start, end);

    const alignedTarget = target < 0
      ? target + this.length
      : target;

    const itemsRemoved = [...super.slice(target, target + (alignedEnd - alignedStart))];
    super.copyWithin(alignedTarget, alignedStart, alignedEnd);
    const itemsAdded = [...super.slice(target, target + (alignedEnd - alignedStart))];

    this.emitChange({
      collection: this,
      trigger: ObservableArray.prototype.copyWithin,
      inserted: itemsAdded.map((item, index) => ({
        item,
        index: target + index
      })),
      deleted: itemsRemoved.map((item, index) => ({
        item,
        index: target + index
      }))
    });

    return this;
  }

  public override fill(value: T, start?: number, end?: number): this {
    const { start: alignedStart, end: alignedEnd }: Span = this.realignStartEnd(start, end);

    const itemsRemoved = [...super.slice(alignedStart, alignedEnd)];
    super.fill(value, alignedStart, alignedEnd);
    const itemsAdded = [...super.slice(alignedStart, alignedEnd)];

    this.emitChange({
      collection: this,
      trigger: ObservableArray.prototype.fill,
      inserted: itemsAdded.map((item, index) => ({
        item,
        index: alignedStart + index
      })),
      deleted: itemsRemoved.map((item, index) => ({
        item,
        index: alignedStart + index
      }))
    });

    return this;
  }
  //#endregion

  //#region Private Methods
  private emitChange(event: ObservableArrayChangedEvent<T>): void {
    this.onChanged.emit(event);
    this.history.emit(event);
  }

  private realignStartEnd(start: any, end: any): Span {
    if (end === undefined) {
      end = this.length - 1;
    }

    if (start === undefined) {
      end = 0;
    }

    if (end < 0) {
      end += this.length;
    }

    if (start < 0) {
      start += this.length;
    }

    if (start > end) {
      const newEnd = start;
      start = end;
      end = newEnd;
    }

    return { start, end };
  }
  //#endregion
}
