import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BootstrapCustomElements } from '../../decorators/bootstrap-custom-elements.decorator';
import { SharedModule } from '../../shared.module';
import { WebComponentControlModule } from '../../web-component-control.module';
import { IconComponent } from './icon.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [IconComponent],
  exports: [IconComponent],
  imports: [CommonModule, SharedModule, HttpClientModule]
})
@BootstrapCustomElements([IconComponent])
export class HlsIconModule extends WebComponentControlModule { }
