import { CultureInfo } from '../culture-info.model';
import { CultureInfoUrArab } from './ur-Arab';

/**
 * @ignore
 */
export const CultureInfoUrArabPK: CultureInfo = {
  name: 'ur-Arab-PK',
  displayName: 'اردو (نسخ،پاکستان)',
  iso3LanguageCode: 'urd',
  iso2LanguageCode: 'ur',
  ietfLanguageTag: 'ur-Arab-PK',
  parent: CultureInfoUrArab
};
