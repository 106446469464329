import { AbstractControl, ValidationErrors } from '@angular/forms';

export const internationalPhoneNumberValidator = (control: AbstractControl): ValidationErrors | null => {
    // Regex pattern to match international phone numbers according to the ITU-T E.164 recommendations
    const validPhoneNumberPattern = /^\+(?:[1-9]\d{0,2}|\d{3})\d{4,14}$/;
    const value = control.value || '';

    if (value && !validPhoneNumberPattern.test(value)) {
        return { invalidPhoneNumber: true };
    }

    return null;
};
