import { CultureInfo } from '../culture-info.model';
import { CultureInfoLv } from './lv';

/**
 * @ignore
 */
export const CultureInfoLvLV: CultureInfo = {
  name: 'lv-LV',
  displayName: 'Latvian (Latvia)',
  iso3LanguageCode: 'lav',
  iso2LanguageCode: 'lv',
  ietfLanguageTag: 'lv-LV',
  parent: CultureInfoLv
};
