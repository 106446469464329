import { CultureInfo } from '../culture-info.model';
import { CultureInfoMi } from './mi';

/**
 * @ignore
 */
export const CultureInfoMiNZ: CultureInfo = {
  name: 'mi-NZ',
  displayName: 'Maori (New Zealand)',
  iso3LanguageCode: 'mri',
  iso2LanguageCode: 'mi',
  ietfLanguageTag: 'mi-NZ',
  parent: CultureInfoMi
};
