import { CultureInfo } from '../culture-info.model';
import { CultureInfoAr } from './ar';

/**
 * @ignore
 */
export const CultureInfoArPS: CultureInfo = {
  name: 'ar-PS',
  displayName: 'العربية (الأراضي الفلسطينية)',
  iso3LanguageCode: 'ara',
  iso2LanguageCode: 'ar',
  ietfLanguageTag: 'ar-PS',
  parent: CultureInfoAr
};
