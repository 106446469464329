<div class="hls-notification hls-notification--{{ type | lowercase }}">
  <hls-icon class="hls-notification__icon hls-notification__type-icon"
            [icon]="icon || typeIcon"
            *ngIf="!noIcon"
            [ngClass]="{'hls-notification__icon--adjusted-to-text': !isSlotEmpty(contentSlotRef) }"></hls-icon>
  <div class="hls-notification__content">
    <h3 [innerText]="headline"></h3>
    <div class="hls-notification__text"
         [ngClass]="{'hls-notification__text--hidden': isSlotEmpty(contentSlotRef) }">
      <span>
        <slot #contentSlot></slot>
      </span>
    </div>
  </div>
  <hls-icon class="hls-notification__icon hls-notification__close-icon"
            icon="action-dismiss"
            *ngIf="dismissable"
            (click)="dismissInternal(NotificationDismissSource.CloseAction)"
            [ngClass]="{'hls-notification__icon--adjusted-to-text': !isSlotEmpty(contentSlotRef) }"></hls-icon>
</div>
