import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Input,
  ViewEncapsulation,
  ElementRef,
  ViewChild,
  AfterViewInit,
  Output,
  EventEmitter,
  OnInit,
  ChangeDetectorRef,
  Inject,
  HostBinding} from '@angular/core';
import { ElementMethod } from '../../decorators/element-method.decorator';
import { WebComponent } from '../../decorators/web-component.decorator';
import { TswpUiLibraryConfigToken } from '../../models/tswp-ui-library-config.token';
import { TswpUiLibraryConfig } from '../../models/tswp-ui-library.config';
import { HeliosControl } from '../helios-control';
import { NotificationDismissTriggerType } from './notification-dismiss-trigger-type.enum';
import { NotificationType } from './notification-type.enum';

/**
 * Helios notification blocks. Can be embedded in templates.
 * @doctab "Examples" "notification.component.examples.md"
 * @title Notifications
 * @default_slot Optional, Notification content
 */
@WebComponent('hls-notification')
@Component({
  selector: 'hls-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  preserveWhitespaces: true,
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class NotificationComponent extends HeliosControl implements AfterViewInit, OnInit {
  //#region Private Fields
  private readonly _typeIconMap: Map<string, string> = new Map([
    [NotificationType.Error, 'feedback-warning-sign'],
    [NotificationType.Warning, 'feedback-warning-circle'],
    [NotificationType.Success, 'feedback-check-circle'],
    [NotificationType.Default, 'feedback-warning-circle-outlined'],
    [NotificationType.Info, 'feedback-warning-circle-outlined']
  ]);
  //#endregion

  //#region Properties
  @ViewChild('contentSlot')
  public contentSlotRef: ElementRef;

  public readonly NotificationDismissSource: typeof NotificationDismissTriggerType = NotificationDismissTriggerType;

  private _dismissable = false;
  /**
   * Gets or sets a value indicating wether this notification is dismissable.
   *
   * Default: `false`
   */
  @Input()
  @HostBinding('attr.dismissable')
  public get dismissable(): boolean {
    return this._dismissable;
  }
  public set dismissable(v: boolean) {
    this._dismissable = coerceBooleanProperty(v);
  }

  private _removeOnDismiss = true;
  /**
   * Gets or sets a value indicating wether this notification should be
   * removed from the DOM when dismissing.
   *
   * Default: `true`
   */
  @Input()
  @HostBinding('attr.remove-on-dismiss')
  public get removeOnDismiss(): boolean {
    return this._removeOnDismiss;
  }
  public set removeOnDismiss(v: boolean) {
    this._removeOnDismiss = coerceBooleanProperty(v);
  }

  /**
   * Emitted when this notification should be dismissed.
   */
  @Output()
  public dismissed: EventEmitter<NotificationDismissTriggerType> = new EventEmitter<NotificationDismissTriggerType>();

  private _type: NotificationType = NotificationType.Default;
  /**
   * Gets or sets the type of notification
   * Default: `NotificationType.DEFAULT`
   */
  @Input()
  @HostBinding('attr.type')
  public get type(): NotificationType {
    return this._type;
  }
  public set type(v: NotificationType) {
    this._type = v;
    this.updateTypeIcon();
  }

  private _icon: string;
  /**
   * Gets or sets an alternative icon for the notification
   */
  @Input()
  @HostBinding('attr.icon')
  public get icon(): string {
    return this._icon;
  }
  public set icon(v: string) {
    this._icon = v;
  }

  private _noIcon = false;
  /**
   * Gets or sets a value indicating if an icon should be hidden.
   * Defaults to `false`.
   */
  @Input()
  @HostBinding('attr.no-icon')
  public get noIcon(): boolean {
    return this._noIcon;
  }
  public set noIcon(v: boolean) {
    this._noIcon = coerceBooleanProperty(v);
  }

  private _typeIcon: string;
  public get typeIcon(): string {
    return this._typeIcon;
  }

  private _headline: string;
  /**
   * Gets or sets the notification headline, being a summary of the message
   */
  @Input()
  @HostBinding('attr.headline')
  public get headline(): string {
    return this._headline;
  }
  public set headline(v: string) {
    this._headline = v;
  }
  //#endregion

  //#region Ctor
  /*
   * Creates a new instance of a Helios Notifications Control
   *
   * @param document The document that this control is attached to
   * @param elementRef The control's element reference
   * @param config The configuration that this control was initialized with
   * @param changeDetector The change detector service that this control may use.
   */
  public constructor(
    @Inject(DOCUMENT) document: Document,
    elementRef: ElementRef,
    @Inject(TswpUiLibraryConfigToken) config: TswpUiLibraryConfig,
    private readonly _changeDetector: ChangeDetectorRef) {

    super(document, elementRef, config);
  }
  //#endregion

  //#region Public Methods
  public ngAfterViewInit(): void {
    this._document.defaultView.setTimeout(() =>
      this._changeDetector.detectChanges());
  }

  /**
   * Reclaims the notification after dismissing.
   * Only possible when having set `remove-on-dismiss` to `false`
   */
  @ElementMethod('reclaim')
  public reclaim(): void {
    if (this.removeOnDismiss) {
      console.warn(
        `Calling 'reclaim' when not having set 'remove-on-dismiss' to false is not valid. ` +
        `By default, the element is removed from the DOM and cannot be re-attached. ` +
        `Set 'remove-on-dismiss' to 'false' when wanting to reclaim the notification for later usage.`);
      return;
    }

    this.htmlElement.removeAttribute('is-dismissed');
  }

  /**
   * Triggers the `dismissed` event programatically.
   */
  @ElementMethod('dismiss')
  public dismiss(): void {
    this.dismissInternal(NotificationDismissTriggerType.Programmatic);
  }

  public dismissInternal(source: NotificationDismissTriggerType): void {
    this.htmlElement.setAttribute('is-dismissed', '');

    if (this._removeOnDismiss) {
      this.htmlElement.remove();
    }

    this.dismissed.emit(source);
  }

  public async ngOnInit(): Promise<void> {
    await super.ngOnInit();
    this.updateTypeIcon();
  }
  //#endregion

  //#region Private Methods
  private updateTypeIcon(): void {
    this._typeIcon = this._typeIconMap.get(this.type);
  }
  //#endregion
}
