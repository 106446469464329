import { CultureInfo } from '../culture-info.model';
import { CultureInfoLrc } from './lrc';

/**
 * @ignore
 */
export const CultureInfoLrcIQ: CultureInfo = {
  name: 'lrc-IQ',
  displayName: 'لۊری شومالی (IQ)',
  iso3LanguageCode: 'lrc',
  iso2LanguageCode: 'lrc',
  ietfLanguageTag: 'lrc-IQ',
  parent: CultureInfoLrc
};
