import { Inject, Injectable } from '@angular/core';
import {
  AbstractAsyncPersistentEvent,
  AbstractPersistentEvent,
  ChildTranslationService,
  CultureInfoService,
  EventSubscription,
  HttpClientService,
  LogService,
  RootTranslationService,
  TOKENS,
  TranslationFile,
  TranslationServiceConfiguration,
} from '@trustedshops/tswp-core-common';

@Injectable()
export class TranslationServiceImpl implements ChildTranslationService {
  //#endregion Private Fields
  private readonly _translations: Map<string, TranslationFile> = new Map();
  private readonly _keys: Map<string, AbstractPersistentEvent<string>> = new Map();
  private readonly _localeLoaders: Map<string, Promise<TranslationFile>> = new Map();
  private readonly _defaultLocale: string = 'en-GB';
  private readonly _locale: AbstractPersistentEvent<string> = new AbstractAsyncPersistentEvent();
  private _isSubscribedToLocale = false;
  private _useChildSpecificTranslations = false;
  //#endregion

  //#region Properties
  private _parent: RootTranslationService;
  public get parent(): RootTranslationService {
    return this._parent;
  }
  public set parent(v: RootTranslationService) {
    this._parent = v;
  }
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(TOKENS.HttpClientService) protected readonly _httpClient: HttpClientService,
    @Inject(TOKENS.LogService) protected readonly _logService: LogService,
    @Inject(TOKENS.CultureInfoService) protected readonly _cultureInfoService: CultureInfoService,
    @Inject(TOKENS.DefaultTranslationServiceConfig) protected _configuration: TranslationServiceConfiguration,
  ) {
  }
  //#endregion

  //#region Public Methods
  public getTranslationForKey(key: string): AbstractPersistentEvent<string> {
    if (!this._isSubscribedToLocale) {
      this.subscribeToCultureInfo();
    }

    if (this._keys.has(key)) {
      return this._keys.get(key);
    }

    const event = new AbstractAsyncPersistentEvent<string>();
    if (this._parent && !this._useChildSpecificTranslations) {
      return this.parent.getTranslationForKey(key);
    }

    this._locale.subscribe(async (locale: string) => {
      const translationResolvers: Array<() => Promise<string>> = [
        () => this.resolveKeyForLocale(locale, key),
        () => this.resolveKeyForLocale(
          this._cultureInfoService
            .resolveBaseCulture(locale)
            .ietfLanguageTag,
          key),
        () => this.resolveKeyForLocale(this._defaultLocale, key),
        () => this.tryResolveFromParent(key),
      ];

      for (const resolver of translationResolvers) {
          const value = await resolver();
          if (value) {
            event.emit(value);
            return;
          }
      }

      this._logService.warning(
        '@trustedshops/tswp-core-common:TranslationService',
        `Could not resolve requested translation for locale "${locale}" and key "${key}". `
        + `Please check your network tab in the developer console for more information. `
        + `Falling back to "${key}" as translation value`);

      event.emit(key);
    });

    this._keys
      .set(key, event);

    return event;
  }

  public overrideDefaultConfiguration(configuration: TranslationServiceConfiguration): void {
    this._configuration = {
      ...this._configuration,
      ...configuration
    };
  }

  public useChildSpecificTranslations(): void {
    this._useChildSpecificTranslations = true;
  }
  //#endregion

  //#region Private Methods
  private async tryResolveFromParent(key: string): Promise<string> {
    if (!this.parent) {
      return null;
    }

    return new Promise((resolve, reject) =>
      this.parent
        .getTranslationForKey(key)
        .subscribe(resolve as any, reject));
  }

  private async loadLocale(locale: string): Promise<TranslationFile> {
    const translationFileUrl = this._configuration.localeFileNameResolver(locale);
    let subscription: EventSubscription<any>;
    const translationFileResponse = await new Promise<TranslationFile>((resolve, reject) => {
      subscription = this._httpClient
        .get(translationFileUrl, {})
        .subscribe(resolve as any, reject);
    });

    subscription?.unsubscribe();
    this._translations.set(locale, translationFileResponse);
    return this._translations.get(locale);
  }

  private subscribeToCultureInfo(): void {
    this._cultureInfoService.currentCulture.subscribe(async (culture) => {
      this._locale.emit(culture.ietfLanguageTag);
    });

    this._isSubscribedToLocale = true;
  }

  private async resolveKeyForLocale(locale: string, key: string): Promise<string | null> {
    if (!(this._localeLoaders.has(locale))) {
      const localePromise = this.loadLocale(locale);
      this._localeLoaders.set(locale, localePromise);
    }

    const localeLoadingOperation = this._localeLoaders.get(locale);
    const translations = await localeLoadingOperation;

    return this._configuration.translationKeyResolver(translations, key);
  }
  //#endregion
}
