import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoDsb: CultureInfo = {
  name: 'dsb',
  displayName: 'Lower Sorbian',
  iso3LanguageCode: 'dsb',
  iso2LanguageCode: 'dsb',
  ietfLanguageTag: 'dsb',
  parent: null
};
