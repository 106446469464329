import { Observable } from 'rxjs';

export interface UpgradePopupRef {
  /**
   * Observable that emits when the popup is closed
   * @returns void
   */
  afterClosed$: Observable<void>;

  /**
   * Closes the popup
   * @returns void
   */
  close(): void;
}
