export function serializeTypedObject(obj: any): any {
  const plainDataEntity: any = {};

  const descriptors = {};
  let proto = Object.getPrototypeOf (obj);
  while (proto) {
    Object.assign(descriptors, Object.getOwnPropertyDescriptors(proto));
    proto = Object.getPrototypeOf (proto);
  }

  Object.getOwnPropertyNames(obj)
    .forEach(name => descriptors[name] = { get: true });

  Object
    .keys(descriptors)
    .filter(x => x.indexOf('_') !== 0)
    .filter(key => descriptors[key] && descriptors[key].get)
    .forEach(key => plainDataEntity[key] = obj[key]);

  return plainDataEntity;
}
