import { Session, IdentityResolverDelegate } from '@trustedshops/tswp-core-authorization';
import { AbstractPersistentEvent } from '@trustedshops/tswp-core-common';
import { KeycloakProfile, KeycloakInstance } from 'keycloak-js';

/**
 * Represents a session established using keyloak
 */
export class KeycloakSession implements Session {
  //#region Private Fields
  private readonly _sessionUserInfo: any;
  private readonly _sessionUserProfile: KeycloakProfile;
  //#endregion

  //#region Properties
  private readonly _identity: string;
  /**
   * Gets the id of the identity
   */
  public get identity(): string {
    return this._identity;
  }

  private readonly _token: AbstractPersistentEvent<string>;
  public get token(): AbstractPersistentEvent<string> {
    return this._token;
  }

  private readonly _payload: AbstractPersistentEvent<any>;
  public get payload(): AbstractPersistentEvent<any> {
    return this._payload;
  }

  public get userInfo(): any {
    return this._sessionUserInfo;
  }

  public get userProfile(): KeycloakProfile {
    return this._sessionUserProfile;
  }
  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of a KeycloakSession
   * @param sessionUserProfile The profile info located in the account area of keycloak.
   * @param sessionUserInfo The user info located within the token
   */
  public constructor(
    sessionUserProfile: KeycloakProfile,
    sessionUserInfo: any,
    token: string,
    payload: any,
    identityResolver: IdentityResolverDelegate) {

    this._identity = identityResolver(
      sessionUserProfile,
      sessionUserInfo,
      token,
      payload);

    this._sessionUserInfo = sessionUserInfo;
    this._sessionUserProfile = sessionUserProfile;
    this._token = new AbstractPersistentEvent(token);
    this._payload = new AbstractPersistentEvent(payload);
  }
  //#endregion

  //#region Public Method
  public update(keycloakConnector: KeycloakInstance): void {
    this._token.emit(keycloakConnector.token);
    this._payload.emit(keycloakConnector.tokenParsed);
  }
  //#endregion


}
