import { CultureInfo } from '../culture-info.model';
import { CultureInfoNmg } from './nmg';

/**
 * @ignore
 */
export const CultureInfoNmgCM: CultureInfo = {
  name: 'nmg-CM',
  displayName: 'nmg (Kamerun)',
  iso3LanguageCode: 'nmg',
  iso2LanguageCode: 'nmg',
  ietfLanguageTag: 'nmg-CM',
  parent: CultureInfoNmg
};
