import { CultureInfo } from '../culture-info.model';
import { CultureInfoTe } from './te';

/**
 * @ignore
 */
export const CultureInfoTeIN: CultureInfo = {
  name: 'te-IN',
  displayName: 'Telugu (India)',
  iso3LanguageCode: 'tel',
  iso2LanguageCode: 'te',
  ietfLanguageTag: 'te-IN',
  parent: CultureInfoTe
};
