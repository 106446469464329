import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Inject, Input, ViewEncapsulation, OnInit } from '@angular/core';
import { NavigationRegions } from '@trustedshops/tswp-carrier-b2b-contracts';
import { RxJsSubjectBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import { FeatureBookingService, TOKENS as MASTERDATA_TOKENS } from '@trustedshops/tswp-core-masterdata';
import {
  BreadcrumbsCompositionService,
  NavigationItem,
  NavigationService,
  TOKENS,
  UserInteractionHandler,
} from '@trustedshops/tswp-core-ui';
import { from, Observable } from 'rxjs';
import { UserFlowService } from '../../services/user-flow.service';

@Component({
  selector: 'header-bar',
  templateUrl: './header-bar.component.html',
  styleUrls: ['./header-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class HeaderBarComponent implements OnInit {
  public readonly isFreeAccount$: Observable<boolean>;
  public readonly isUnverifiedAccount$: Observable<boolean>;

  private readonly _navigationService: NavigationService;
  private readonly _breadcrumbsCompositionService: BreadcrumbsCompositionService;
  private _userSettingsTooltipVisible = true;
  /**
   * Gets or sets a value indicating wether the tooltip is visible.
   */
  @Input()
  public get userSettingsTooltipVisible(): boolean {
    return this._userSettingsTooltipVisible;
  }

  public set userSettingsTooltipVisible(v: boolean) {
    this._userSettingsTooltipVisible = coerceBooleanProperty(v);
  }

  private _userNotificationTooltipVisible = true;
  /**
   * Gets or sets a value indicating wether the tooltip is visible.
   */
  @Input()
  public get userNotificationTooltipVisible(): boolean {
    return this._userNotificationTooltipVisible;
  }

  public set userNotificationTooltipVisible(v: boolean) {
    this._userNotificationTooltipVisible = coerceBooleanProperty(v);
  }

  private _breadcrumbs: Observable<NavigationItem[]>;
  public get breadcrumbs(): Observable<NavigationItem[]> {
    return this._breadcrumbs;
  }

  private _metaMenuItems: NavigationItem[];
  public get metaMenuItems(): NavigationItem[] {
    return this._metaMenuItems;
  }

  public set metaMenuItems(v: NavigationItem[]) {
    this._metaMenuItems = v;
  }

  public progress$: Observable<number>;

  public constructor(
    @Inject(TOKENS.NavigationService) navigationService: NavigationService,
    @Inject(TOKENS.BreadcrumbsCompositionService) breadcrumbsCompositionService: BreadcrumbsCompositionService,
    @Inject(MASTERDATA_TOKENS.FeatureBookingService) readonly featureBookingService: FeatureBookingService,
    private readonly userFlowService: UserFlowService) {
    this._navigationService = navigationService;
    this._breadcrumbsCompositionService = breadcrumbsCompositionService;
    this.isFreeAccount$ = from(this.featureBookingService.isFreeAccount());
    this.isUnverifiedAccount$ = from(this.featureBookingService.isUnverifiedAccount());
  }

  public async ngOnInit(): Promise<void> {
    this._metaMenuItems = this._navigationService.getItemsForRegion(NavigationRegions.MetaMenu);
    this._breadcrumbs = this._breadcrumbsCompositionService.currentBreadcrumbTrail
      .convertWith(RxJsSubjectBridge<NavigationItem<any, UserInteractionHandler>[]>);
    this.progress$ = this.userFlowService.progress$;
  }

  public toggleUsersettingsTooltips(): void {
    this.userSettingsTooltipVisible = false;
    this.userNotificationTooltipVisible = true;
  }

  public toggleNotificationTooltip(): void {
    this.userNotificationTooltipVisible = false;
    this.userSettingsTooltipVisible = true;
  }
}
