import { CultureInfo } from '../culture-info.model';
import { CultureInfoKsh } from './ksh';

/**
 * @ignore
 */
export const CultureInfoKshDE: CultureInfo = {
  name: 'ksh-DE',
  displayName: 'Kölsch en Doütschland',
  iso3LanguageCode: 'ksh',
  iso2LanguageCode: 'ksh',
  ietfLanguageTag: 'ksh-DE',
  parent: CultureInfoKsh
};
