import { CultureInfo } from '../culture-info.model';
import { CultureInfoTr } from './tr';

/**
 * @ignore
 */
export const CultureInfoTrCY: CultureInfo = {
  name: 'tr-CY',
  displayName: 'Türkçe (Kıbrıs)',
  iso3LanguageCode: 'tur',
  iso2LanguageCode: 'tr',
  ietfLanguageTag: 'tr-CY',
  parent: CultureInfoTr
};
