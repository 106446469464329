import { CultureInfo } from '../culture-info.model';
import { CultureInfoKsArab } from './ks-Arab';

/**
 * @ignore
 */
export const CultureInfoKsArabIN: CultureInfo = {
  name: 'ks-Arab-IN',
  displayName: 'کٲشُر (نسخ, ہِندوستان)',
  iso3LanguageCode: 'kas',
  iso2LanguageCode: 'ks',
  ietfLanguageTag: 'ks-Arab-IN',
  parent: CultureInfoKsArab
};
