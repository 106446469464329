import { ExportTokenDefinition } from './export-token-defintion.interface';
import { ExportTokenDecorator } from './export-token-decorator.interface';
import { Type } from '../system/type';
import { AbstractReplayEvent } from '../eventing/abstract-replay-event';

/**
 * Maps a class to a string. Used for Dependency Containers and other exporting logic.
 * For example, services marked with this decorator are loaded into the PluginContext
 * @param exportToken The token that this service is exported as
 */
function ExportToken(exportToken: string): (constructor: Type<any>) => void {
  const exportTokenDecorator = ExportToken as unknown as ExportTokenDecorator;

  return (constructor: Type<any>) => {
    exportTokenDecorator.tokens.emit({
      token: exportToken,
      type: constructor
    });
  };
}


const localExportTokenDecorator = ExportToken as unknown as ExportTokenDecorator;
localExportTokenDecorator.tokens = localExportTokenDecorator.tokens || new AbstractReplayEvent<ExportTokenDefinition<any>>();

export { ExportToken };
