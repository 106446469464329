import { CultureInfo } from '../culture-info.model';
import { CultureInfoEbu } from './ebu';

/**
 * @ignore
 */
export const CultureInfoEbuKE: CultureInfo = {
  name: 'ebu-KE',
  displayName: 'Kĩembu (Kenya)',
  iso3LanguageCode: 'ebu',
  iso2LanguageCode: 'ebu',
  ietfLanguageTag: 'ebu-KE',
  parent: CultureInfoEbu
};
