import {
  ElementRef,
  ViewEncapsulation,
  Component,
  AfterViewInit,
  ViewChild,
  Input,
  HostBinding,
} from '@angular/core';

import { WebComponent } from '../../decorators/web-component.decorator';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { HeliosValueControl } from '../helios-value-control';

/**
 * Select control from the helios design system
 * @doctab "Examples" "select.component.examples.md"
 * @default_slot Options in the select
 * @named_slot "prefix" "Left-aligned text prefix inside the select control"
 * @named_slot "icon-prefix" "Left-aligned icon prefix inside the select control"
 * @named_slot "button-prefix" "Left-aligned button prefix inside the select control"
 */
@WebComponent('hls-select')
@Component({
  selector: 'hls-select',
  templateUrl: 'select.component.html',
  styleUrls: ['select.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class SelectComponent
  extends HeliosValueControl<string>
  implements AfterViewInit
{
  //#region Private Fields
  @ViewChild('prefixSlot')
  public prefixSlotRef: ElementRef;

  @ViewChild('iconPrefixSlot')
  public iconPrefixSlotRef: ElementRef;

  @ViewChild('buttonPrefixSlot')
  public buttonPrefixSlotRef: ElementRef;

  @ViewChild('slot')
  public slotRef: ElementRef;

  @ViewChild('select')
  public selectRef: ElementRef;

  private _autofocus = false;
  /**
   * Gets or sets a value indicating wether this input is automatically focused when the page is loaded.
   */
  @Input()
  @HostBinding('attr.autofocus')
  public get autofocus(): boolean {
    return this._autofocus || null;
  }
  public set autofocus(v: boolean) {
    this._autofocus = coerceBooleanProperty(v);
  }

  private _disabled = false;
  /**
   * Gets or sets the disabled state of the embedded HTML5 input field.
   */
  @HostBinding('attr.disabled')
  @Input()
  public get disabled(): any {
    return this._disabled || null;
  }
  public set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _name: string = null;
  /**
   * Gets or sets the name of this control
   */
  @Input()
  @HostBinding('attr.name')
  public get name(): string {
    return this._name;
  }
  public set name(v: string) {
    this._name = v;
  }

  private _placeholder: string = null;
  /**
   * Gets or sets the label of the control, displayed when nothing is selected
   */
  @Input()
  @HostBinding('attr.placeholder')
  public get placeholder(): string {
    return this._placeholder;
  }
  public set placeholder(v: string) {
    this._placeholder = v;
  }

  private _required = false;
  /**
   * Gets or sets a value indicating wether this control is required.
   */
  @HostBinding('attr.required')
  @Input()
  public get required(): any {
    return this._required || null;
  }
  public set required(value: any) {
    this._required = coerceBooleanProperty(value);
  }
  //#endregion

  //#region Public Methods
  public async ngAfterViewInit(): Promise<void> {
    const slot = this.slotRef.nativeElement as HTMLSlotElement;
    const select = this.selectRef.nativeElement as HTMLSelectElement;

    slot
      .assignedNodes()
      .forEach((option: Node) =>
        this.addOption(select, option as HTMLOptionElement)
      );

    slot.addEventListener('slotchange', () =>
      slot
        .assignedNodes()
        .forEach((option: Node) =>
          this.addOption(select, option as HTMLOptionElement)
        )
    );
  }
  //#endregion

  //#region Private Methods
  private addOption(
    select: HTMLSelectElement,
    option: HTMLOptionElement
  ): void {
    select.appendChild(option);
    if (option.selected) {
      this.value = option.value;
    }
  }
  //#endregion
}
