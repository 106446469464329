import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { ExportToken, LocalStorageService, TOKENS } from '@trustedshops/tswp-core-common';

@Injectable()
@ExportToken(TOKENS.LocalStorageService)
export class LocalStorageServiceImpl implements LocalStorageService {
  //#region Ctor
  /**
   * Creates a new instance of `LocalStorageServiceImpl`
   *
   * @param _baseKey The key to prefix all incoming and outgoing storage keys with
   * @param _document The document to use to access the local storage
   */
  public constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Optional()
    @Inject(TOKENS.LocalStorageBaseKey) private readonly _baseKey: string) {
      this._baseKey = _baseKey
        ? `${_baseKey}.`
        : '';
    }
  //#endregion

  //#region Public Methods
  /**
   * Inserts a new or updates an existing value into the local storage identified by `key`.
   * Serializes the value as JSON automatically.
   *
   * In case of plugin usage, automatically prefixes the `key` with the
   * plugin name to avoid duplicates and produce an isolated environment.
   *
   * @param key The key to identify the stored value with.
   * @param value The value to store.
   */
  public insertOrUpdate<T>(key: string, value: T): void {
    this._document.defaultView.localStorage.setItem(`${this._baseKey}${key}`, JSON.stringify(value));
  }

  /**
   * Selects the value identified by `key`. Deserializes stored JSON values automatically.
   *
   * @param key The key to identify the stored value with.
   * @returns The deserialized value with the desired type.
   * @template TObject The object type to deserialize to.
   */
  public select<T>(key: string): T {
    const value = this._document.defaultView.localStorage.getItem(`${this._baseKey}${key}`);
    if (!value) {
      return null;
    }

    return JSON.parse(value);
  }

  /**
   * Deletes the value identified by `key`
   *
   * @param key The key to identify the stored value with.
   */
  public delete(key: string): void {
    this._document.defaultView.localStorage.removeItem(`${this._baseKey}${key}`);
  }

  /**
   * Create a new subscoped local storage of this `LocalStorageService`
   *
   * @param baseKey  The key to prefix all incoming and outgoing storage keys with
   * @returns A new scoped instance of a `LocalStorageService`
   */
  public createScoped(baseKey: string): LocalStorageService {
    return new LocalStorageServiceImpl(this._document, `${this._baseKey}${baseKey}`);
  }
  //#endregion
}
