import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoNe: CultureInfo = {
  name: 'ne',
  displayName: 'Nepali',
  iso3LanguageCode: 'nep',
  iso2LanguageCode: 'ne',
  ietfLanguageTag: 'ne',
  parent: null
};
