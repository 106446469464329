import {Directive, ElementRef, Output, EventEmitter, HostListener, Input} from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[clickedOutside]',
  standalone: false
})
export class ClickedOutsideDirective {
  //#region Properties
  @Output()
  public clickedOutside: EventEmitter<void> = new EventEmitter();

  @Input()
  public clickedOutsideExclude: string;
  //#endregion

  //#region Ctor
  public constructor(private _elementRef: ElementRef) {
  }
  //#endregion

  //#region Public Methods
  @HostListener('document:click', ['$event'])
  public onClick(event: Event): void {
    const nodePath = event.composedPath()
      .filter(x => x instanceof HTMLElement)
      .map(x => x as HTMLElement);

    if (this.clickedOutsideExclude) {
      const excludedElement = document.querySelector(this.clickedOutsideExclude);
      if (excludedElement && nodePath.some(targetElement =>
          targetElement === excludedElement
          || excludedElement.contains(targetElement))) {
        return;
      }
    }

    const clickedInside = nodePath.some(targetElement =>
      targetElement === this._elementRef.nativeElement
      || this._elementRef.nativeElement.contains(targetElement));
    if (!clickedInside) {
      this.clickedOutside.emit();
    }
  }
  //#endregion
}
