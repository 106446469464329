import { CultureInfo } from '../culture-info.model';
import { CultureInfoTn } from './tn';

/**
 * @ignore
 */
export const CultureInfoTnBW: CultureInfo = {
  name: 'tn-BW',
  displayName: 'Tswana (Botswana)',
  iso3LanguageCode: 'tsn',
  iso2LanguageCode: 'tn',
  ietfLanguageTag: 'tn-BW',
  parent: CultureInfoTn
};
