import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoOm: CultureInfo = {
  name: 'om',
  displayName: 'Oromo',
  iso3LanguageCode: 'orm',
  iso2LanguageCode: 'om',
  ietfLanguageTag: 'om',
  parent: null
};
