/**
 * Object representing metadata about a renderer
 */
export interface RendererInfo {
  /**
   * Gets or sets the name of the custom element to render
   */
  webComponent: string;

  /**
   * Gets or sets the name of the plugin to load
   */
  plugin?: string;
}
