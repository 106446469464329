import { LoggingTarget } from './logging-target.interface';
import { LogLevel } from './log-level.enum';
import { LogFilter } from './log-filter.interface';

/**
 * A service for logging things to
 */
export interface LogService {
  /**
   * Adds a log entry with `LogLevel.Trace`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  trace(logSource: string, message: string, payload?: any): LogService;

  /**
   * Adds a log entry with `LogLevel.Debug`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  debug(logSource: string, message: string, payload?: any): LogService;

  /**
   * Adds a log entry with `LogLevel.Information`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  information(logSource: string, message: string, payload?: any): LogService;

  /**
   * Adds a log entry with `LogLevel.Warning`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  warning(logSource: string, message: string, payload?: any): LogService;

  /**
   * Adds a log entry with `LogLevel.Error`.
   *
   * @param logSource The source that this event is coming from
   * @param error The error that should be logged
   * @param payload The optional payload that should be attached to the log entry
   * @returns The error that was passed in to optionally throw it afterwards
   */
  error(logSource: string, error: Error, payload?: any): Error;

  /**
   * Adds a log entry with `LogLevel.Error`.
   *
   * @param logSource The source that this event is coming from
   * @param error The error that should be logged
   * @param payload The optional payload that should be attached to the log entry
   * @returns The error that was passed in to optionally throw it afterwards
   */
  fatal(logSource: string, error: Error, payload?: any): Error;

  /**
   * Enables log levels for being logged
   * @param logLevel The log Levels to enable
   */
  activateLogLevels(logLevels: LogLevel[]): LogService;

  /**
   * Registers an instance of a `LoggingTarget` to proxy the logs to.
   *
   * @param loggingTarget The logging target to register
   */
  registerLoggingTarget(loggingTarget: LoggingTarget): LogService;

  /**
   * Registers a new `LogFilter` indicating if an incoming log
   * entry should be proxied to the registered `LoggingTarget`s
   *
   * @param logFilter The log filter to register
   */
  registerLogFilter(logFilter: LogFilter): LogService;

  /**
   * Gets a value indicating wether a given log level is activated for logging
   * @param logLevel The log level to check the state for
   */
  isLogLevelActive(logLevel: LogLevel): boolean;
}
