import {
  BootstrapperBase,
  ConsoleLoggingTarget,
  LogLevel,
  LogService,
  TOKENS,
} from '@trustedshops/tswp-core-common';
import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { InstanaLoggingTarget } from '@trustedshops/tswp-core-common-diag-instana';
import { CarrierConfigToken } from '../models/carrier-config.token';
import { CarrierConfig } from '../models/carrier-config.interface';

@Injectable()
export class DiagnosticsBootstrapper implements BootstrapperBase {
  public constructor(
    @Inject(CarrierConfigToken) private readonly carrierConfig: CarrierConfig,
    @Inject(TOKENS.LogService) private readonly logService: LogService,
  ) {}

  public async initialize(): Promise<void> {
    this.logService
      .activateLogLevels(this.carrierConfig.diagnostics.logging.logLevels)
      .registerLoggingTarget(
        new InstanaLoggingTarget(
          this.carrierConfig.diagnostics.logging.instana.logLevels,
        ),
      );

    this.carrierConfig.diagnostics.logging.filters.forEach((filter) =>
      this.logService.registerLogFilter(filter),
    );

    if (this.logService.isLogLevelActive(LogLevel.Trace)) {
      this.logService.fatal('DiagnosticsBootstrapper', new Error(), '');
      this.logService.error('DiagnosticsBootstrapper', new Error(), '');
      this.logService.warning('DiagnosticsBootstrapper', '', '');
      this.logService.information('DiagnosticsBootstrapper', '', '');
      this.logService.trace('DiagnosticsBootstrapper', '', '');
      this.logService.debug('DiagnosticsBootstrapper', '', '');
    }

    if (!this.carrierConfig.environment.stage.productionModeEnabled) {
      this.logService.registerLoggingTarget(new ConsoleLoggingTarget());
    }
  }
}

export const DiagnosticsInitializer = [
  DiagnosticsBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [DiagnosticsBootstrapper],
  },
];
