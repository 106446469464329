import { OrganizationalContainerSelectorInfo } from './organizational-container-selector-info.interface';

export interface OrganizationalContainerSelectorConfiguration {
  /**
   * Gets or sets a value indicating whether the selector should be shown.
   *
   * Setting a value of `true` activates the selector with defaults.
   *
   * Setting an object of `OrganizationalContainerSelectorInfo` will
   * provide more detailed information about the way the selector is displayed.
   * Check `OrganizationalContainerSelectorConfiguration` for more information
   * about configuring the selector without defaults.
   */
  organizationalContainerSelector: boolean | OrganizationalContainerSelectorInfo;
}
