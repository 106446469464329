import { CultureInfo } from '../culture-info.model';
import { CultureInfoTr } from './tr';

/**
 * @ignore
 */
export const CultureInfoTrTR: CultureInfo = {
  name: 'tr-TR',
  displayName: 'Turkish (Turkey)',
  iso3LanguageCode: 'tur',
  iso2LanguageCode: 'tr',
  ietfLanguageTag: 'tr-TR',
  parent: CultureInfoTr
};
