import { CultureInfo } from '../culture-info.model';
import { CultureInfoSw } from './sw';

/**
 * @ignore
 */
export const CultureInfoSwTZ: CultureInfo = {
  name: 'sw-TZ',
  displayName: 'Kiswahili (Tanzania)',
  iso3LanguageCode: 'swa',
  iso2LanguageCode: 'sw',
  ietfLanguageTag: 'sw-TZ',
  parent: CultureInfoSw
};
