import { CultureInfo } from '../culture-info.model';
import { CultureInfoKpe } from './kpe';

/**
 * @ignore
 */
export const CultureInfoKpeLR: CultureInfo = {
  name: 'kpe-LR',
  displayName: 'Kpɛlɛɛ (LR)',
  iso3LanguageCode: 'kpe',
  iso2LanguageCode: 'kpe',
  ietfLanguageTag: 'kpe-LR',
  parent: CultureInfoKpe
};
