import { OnDestroy } from '@angular/core';
import { EventSubscription } from '@trustedshops/tswp-core-common';
import { Component } from '@angular/core';

/**
 * Base class for all angular components creating subscriptions
 * that are to be cleaned up on `ngOnDestroy` invocation
 */

@Component({
  selector: 'app-subscriber',
  template: ''
})
export abstract class SubscriberComponent implements OnDestroy {
  //#region Private Fields
  private readonly _eventSubscriptions: EventSubscription<any>[] = [];
  //#endregion

  //#region Public Methods
  /**
   * Removes all remembered subscriptions
   */
  public ngOnDestroy(): void {
    this._eventSubscriptions.forEach(subscription =>
      subscription?.unsubscribe());
  }
  //#endregion

  //#region Private Methods
  /**
   * Utility method to register a created subscription.
   * When the child class does not override/extend the `ngOnDestroy` method,
   * the registered subscriptions are going to be unsubscribed.
   *
   * @param subscription The subscription to unregister on component destruction
   */
  protected rememberEventSubscription(subscription: EventSubscription<any>): SubscriberComponent {
    this._eventSubscriptions.push(subscription);
    return this;
  }
  //#endregion
}
