import { CultureInfo } from '../culture-info.model';
import { CultureInfoKy } from './ky';

/**
 * @ignore
 */
export const CultureInfoKyKG: CultureInfo = {
  name: 'ky-KG',
  displayName: 'Kyrgyz (Kyrgyzstan)',
  iso3LanguageCode: 'kir',
  iso2LanguageCode: 'ky',
  ietfLanguageTag: 'ky-KG',
  parent: CultureInfoKy
};
