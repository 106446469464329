<ng-template #content>
  <ng-content></ng-content>
</ng-template>
<a [href]="uri" *ngIf="interaction.uriLocationType === UriLocationType.External" [target]="interaction.target" [attr.data-user-flow-id]="userFlowId">
  <ng-template [ngTemplateOutlet]="content"></ng-template>
</a>
<a *ngIf="interaction.uriLocationType !== UriLocationType.External"
   [routerLink]="uri"
   routerLinkActive="navigate_interaction--route-active"
   [ngClass]="{ 'navigate_interaction--active': (((renderingOptions | subscribe) || {})).forcedActive }"
   [routerLinkActiveOptions]="(((renderingOptions | subscribe) || {}).linkActiveOptions) || {}"
   [queryParams]="interaction.queryParams"
   [target]="interaction.target"
   [attr.data-user-flow-id]="userFlowId">
 <ng-template [ngTemplateOutlet]="content"></ng-template>
</a>
