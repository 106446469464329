import { CultureInfo } from '../culture-info.model';
import { CultureInfoKs } from './ks';

/**
 * @ignore
 */
export const CultureInfoKsIN: CultureInfo = {
  name: 'ks-IN',
  displayName: 'کٲشُر (ہِندوستان)',
  iso3LanguageCode: 'kas',
  iso2LanguageCode: 'ks',
  ietfLanguageTag: 'ks-IN',
  parent: CultureInfoKs
};
