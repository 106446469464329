import { CultureInfo } from '../culture-info.model';
import { CultureInfoSt } from './st';

/**
 * @ignore
 */
export const CultureInfoStZA: CultureInfo = {
  name: 'st-ZA',
  displayName: 'Southern Sotho (South Africa)',
  iso3LanguageCode: 'sot',
  iso2LanguageCode: 'st',
  ietfLanguageTag: 'st-ZA',
  parent: CultureInfoSt
};
