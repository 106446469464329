import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoGn: CultureInfo = {
  name: 'gn',
  displayName: 'Guarani',
  iso3LanguageCode: 'grn',
  iso2LanguageCode: 'gn',
  ietfLanguageTag: 'gn',
  parent: null
};
