import { DeploymentStageName, LogLevel, TargetUserGroup } from '@trustedshops/tswp-core-common';

export const environment = {
  production: false,
  targetGroup: TargetUserGroup.BusinessToBusiness,
  name: DeploymentStageName.Integr,
  pageTitle: 'eTrusted Control Centre',
  tracking: {
    googleAnalytics: 'UA-53707107-44',
    googleAnalytics4: 'G-B9ZGJXQ654',
    hubspot: {
      id: '21303160',
      scriptUrl: '//js.hs-scripts.com/21303160.js',
    }
  },
  apis: {
    plugins: 'https://app-api.etrusted.koeln/v0',
    permissions: 'https://sso-apis-b2b-integr.etrusted.com/pm-v1',
    users: 'https://sso-apis-b2b-integr.etrusted.com/um-v1',
    accounts: 'https://api.etrusted.koeln',
    featureBooking: 'https://booking-api.etrusted.koeln/v0'
  },
  startPage: {
    url: '/home'
  },
  diagnostics: {
    logging: {
      logLevels: [
        // LogLevel.Trace,
        LogLevel.Debug,
        LogLevel.Information,
        LogLevel.Warning,
        LogLevel.Error,
        LogLevel.Fatal
      ],
      filters: [],
      sentry: {
        environment: DeploymentStageName.Integr,
        logLevels: [
          LogLevel.Error,
          LogLevel.Fatal
        ],
        dsn: 'https://ad6c7ff7bab74ba1a5923520c97a6e42@o128203.ingest.sentry.io/5614223',
        browserTracingOrigins: [
          'app-integr.trustedshops.com',
          'app-api-integr.trustedshops.com',
          'app.etrusted.koeln',
          'app-api.etrusted.koeln',
          'api.etrusted.koeln',
          'admin.etrusted.koeln'
        ]
      },
      instana: {
        logLevels: [
          LogLevel.Error,
          LogLevel.Fatal,
        ],
        browserTracingOrigins: [],
        reportingUrl: 'https://eum-blue-saas.instana.io',
        key: 'NoXRTxZ3SdWkAwGqNjVOiQ',
        allowedOrigins: JSON.stringify([
          '/.*sso-apis-b2b-integr.etrusted.com.*/i',
          '/.*login-integr.etrusted.com.*/i',
          '/.*sso-client-b2b-integr.etrusted.com.*/i',
          '/.*task-management-integr.trustedshops.com.*/i',
          '/.*cns-apis.trustedshops-integr.com.*/i',
          '/.*api.etrusted.koeln.*/i',
        ]),
        scriptUrl: 'https://eum.instana.io/eum.min.js',
      }
    },
  },
  authorization: {
    keycloakUrl: 'https://login-integr.etrusted.com/auth/',
    clientId: 'tswp-carrier-b2b',
    realm: 'business-INTEGR',
    sessionVerificationType: 'check-sso',
    silentCheckSsoRedirectUri: '/assets/authentication/token-proxy.html'
  },
  layout: {
    resources: {
      heliosIconUri: 'https://cdn-integr.trustedshops.com/helios/helios-icons/1.7.20/raw/',
      heliosUri: 'https://app.etrusted.koeln/helios.css'
    }
  },
  storage: {
    plugins: {
      rootPath: 'https://app.etrusted.koeln/plugins'
    }
  },
  feedback: {
    api: 'https://api.etrusted.koeln/nps-questionnaire-link',
    templateBase: 'tpl-qst-46d24265-bf2c-4b2d-b35b-2b19f6c153bc_',
  },
  freeAccount: {
    hubspot: {
      upgradePage: {
        baseUrl: 'https://api.hsforms.com',
        authToken: 'pat-na1-1ef73205-7556-4d23-86b4-699de9408909',
        portalId: '21303160',
        fullContactFormId: '8258e5b1-7dd5-49a1-a9a1-82d972fb5c6c',
        shortContactFormId: '25ee45cd-5070-44b3-a75f-b6767fb6dcae',
        ctaContactFormId: '60c1ee9b-f27b-45b4-847b-653087e4a9b2',
      },
    },
  },
  userFlow: {
    proxyApi: 'https://platform-apis-b2b-integr.etrusted.com/userflow-proxy-v1',
    apiKey: 'ct_r36fodd4bjh6xg7oyqdougtydi',
    checklistId: '374ecae6-7093-4b02-95d3-510a2c1c70ae'
  }
};
