import {
  GoogleAnalyticsTrackerConfiguration,
  TrackerFactory,
  TrackerType,
  TrackingService,
} from '@trustedshops/tswp-core-analytics';
import {
  DomResourceService,
  InvalidOperationError,
  LogService,
} from '@trustedshops/tswp-core-common';
import { GoogleAnalyticsTracker } from './google-analytics.tracker';

export class GoogleAnalyticsTrackerFactory
  implements TrackerFactory<TrackerType.GoogleAnalytics>
{
  //#region Private Fields
  private _scriptsLoaded = false;
  //#endregion

  //#region Properties
  public get type(): TrackerType {
    return TrackerType.GoogleAnalytics;
  }
  //#endregion

  //#region Ctor
  public constructor(
    private readonly _resourceLoader: DomResourceService,
    private readonly _document: Document,
    private readonly _logService: LogService
  ) {}
  //#endregion

  //#region Public Methods
  public create(
    _: TrackingService,
    config: GoogleAnalyticsTrackerConfiguration
  ): GoogleAnalyticsTracker {
    if (!this._document.defaultView) {
      throw new InvalidOperationError(
        `Registering Google Analytics in a non-browser environment is not supported.`
      );
    }

    const window: Window = this._document.defaultView.window;

    this._logService.debug(
      '@trustedshops/tswp-core-analytics:GoogleAnalyticsTrackerFactory',
      `Creating event tracker instance.`,
      config
    );

    const anonWindow = this._document.defaultView.window as any;
    anonWindow.dataLayer = anonWindow.dataLayer || [];
    anonWindow.gtag = function gtag(): void {
      // eslint-disable-next-line prefer-rest-params
      anonWindow.dataLayer.push(arguments);
    };

    const tracker = new GoogleAnalyticsTracker(
      config,
      anonWindow.gtag,
      this._logService
    );
    (async () => {
      await this.loadGoogleAnalyticsResources(window, config);
      tracker.initialize();
    })();

    return tracker;
  }
  //#endregion

  //#region Private Methods
  private async loadGoogleAnalyticsResources(
    window: Window,
    config: GoogleAnalyticsTrackerConfiguration
  ): Promise<void> {
    if (this._scriptsLoaded) {
      return;
    }

    this._logService.debug(
      '@trustedshops/tswp-core-analytics:GoogleAnalyticsTrackerFactory',
      `Loading gtag script from ${config.gtagScriptUri}`
    );

    await this._resourceLoader.loadResource({
      key: config.gtagScriptUri,
      options: {
        async: 'async',
      } as any,
      type: 'js',
    });

    this._logService.debug(
      '@trustedshops/tswp-core-analytics:GoogleAnalyticsTrackerFactory',
      `Sending gtag 'js' flag.`
    );
    (window as any).gtag('js', new Date());
    this._scriptsLoaded = true;
  }
  //#endregion
}
