import { CultureInfo } from '../culture-info.model';
import { CultureInfoJa } from './ja';

/**
 * @ignore
 */
export const CultureInfoJaJP: CultureInfo = {
  name: 'ja-JP',
  displayName: 'Japanese (Japan)',
  iso3LanguageCode: 'jpn',
  iso2LanguageCode: 'ja',
  ietfLanguageTag: 'ja-JP',
  parent: CultureInfoJa
};
