import { CultureInfo } from '../culture-info.model';
import { CultureInfoTg } from './tg';

/**
 * @ignore
 */
export const CultureInfoTgTJ: CultureInfo = {
  name: 'tg-TJ',
  displayName: 'тоҷикӣ (Тоҷикистон)',
  iso3LanguageCode: 'tgk',
  iso2LanguageCode: 'tg',
  ietfLanguageTag: 'tg-TJ',
  parent: CultureInfoTg
};
