/**
 * Defines service token that are available from this library through the service provider
 */
export const TOKENS = {
  /**
   * PluginService Dependency Injection Token
   */
  PluginService: '@trustedshops/tswp-core-plugins:PluginService',

  /**
   * PluginRouter Dependency Injection Token
   */
  PluginRouter: '@trustedshops/tswp-core-plugins:PluginRouter',

  /**
   * PluginHostProvider Dependency Injection Token
   */
  PluginHostProvider: '@trustedshops/tswp-core-plugins:PluginHostProvider',

};
