import { CultureInfo } from '../culture-info.model';
import { CultureInfoYo } from './yo';

/**
 * @ignore
 */
export const CultureInfoYoBJ: CultureInfo = {
  name: 'yo-BJ',
  displayName: 'Èdè Yorùbá (Orílɛ́ède Bɛ̀nɛ̀)',
  iso3LanguageCode: 'yor',
  iso2LanguageCode: 'yo',
  ietfLanguageTag: 'yo-BJ',
  parent: CultureInfoYo
};
