import { CultureInfo } from '../culture-info.model';
import { CultureInfoFr } from './fr';

/**
 * @ignore
 */
export const CultureInfoFrDJ: CultureInfo = {
  name: 'fr-DJ',
  displayName: 'français (Djibouti)',
  iso3LanguageCode: 'fra',
  iso2LanguageCode: 'fr',
  ietfLanguageTag: 'fr-DJ',
  parent: CultureInfoFr
};
