import { DependencyContainer } from '@trustedshops/tswp-core-composition';
import { PluginEntryPoint } from './plugin-entry-point.interface';

/**
 * Combines the arrived plugin entry point and its requirements for bootstrapping
 */
export interface PluginArrival {
  /**
   * Gets the name of the entry point's plugin
   */
  entryPointName: string;

  /**
   * Resolves an instance of an Entry Point of the plugin
   */
  entryPointResolver: (dependencyContainer: DependencyContainer) => PluginEntryPoint;

  /**
   * The extra-ordinary services the plugin requires
   */
  extraOrdinaryDependencies: string[];
}
