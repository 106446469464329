import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKu: CultureInfo = {
  name: 'ku',
  displayName: 'Kurdish',
  iso3LanguageCode: 'kur',
  iso2LanguageCode: 'ku',
  ietfLanguageTag: 'ku',
  parent: null
};
