import { CultureInfo } from '../culture-info.model';
import { CultureInfoYo } from './yo';

/**
 * @ignore
 */
export const CultureInfoYoNG: CultureInfo = {
  name: 'yo-NG',
  displayName: 'Yoruba (Nigeria)',
  iso3LanguageCode: 'yor',
  iso2LanguageCode: 'yo',
  ietfLanguageTag: 'yo-NG',
  parent: CultureInfoYo
};
