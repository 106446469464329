import { CultureInfo } from '../culture-info.model';
import { CultureInfoUzCyrl } from './uz-Cyrl';

/**
 * @ignore
 */
export const CultureInfoUzCyrlUZ: CultureInfo = {
  name: 'uz-Cyrl-UZ',
  displayName: 'Uzbek (Cyrillic, Uzbekistan)',
  iso3LanguageCode: 'uzb',
  iso2LanguageCode: 'uz',
  ietfLanguageTag: 'uz-Cyrl-UZ',
  parent: CultureInfoUzCyrl
};
