import { Inject, Injectable } from '@angular/core';
import {
  HttpClientService, TOKENS as COMMON_TOKENS,
  ExportToken, LogService
} from '@trustedshops/tswp-core-common';
import { FeatureBookingResponse } from '../models/feature-booking-response.interface';
import {
  FeatureBookingConfig,
  FeatureBookingService,
  TOKENS as MASTERDATA_TOKENS
} from '@trustedshops/tswp-core-masterdata';
import { toPromise } from '@trustedshops/tswp-core-common-eventing-rxjs';

@Injectable()
@ExportToken(MASTERDATA_TOKENS.FeatureBookingService)
export class FeatureBookingServiceImpl implements FeatureBookingService {
  private static readonly TYPE: string = 'FeatureBookingService';

  private validBookingStates: string[] = ['completed'];
  private accountWideFeatureCache: Set<string> = new Set<string>();
  private featuresByChannel: Map<string, string[]> = new Map<string, string[]>();

  private featureBookingConfig: Required<Pick<FeatureBookingConfig, 'freeAccountFeatureName'>>
    & Required<Pick<FeatureBookingConfig, 'unverifiedAccountFeatureName'>>
    & Required<Pick<FeatureBookingConfig, 'unverifiedAccountChannelRef'>>
    & FeatureBookingConfig;

  private readonly loadingOperation: Promise<void>;
  private _resolveInitialization: (value: void | PromiseLike<void>) => void;
  private _rejectInitialization: (reason?: any) => void;

  public constructor(
    @Inject(COMMON_TOKENS.HttpClientService)
    private readonly _httpClient: HttpClientService,

    @Inject(COMMON_TOKENS.LogService)
    private readonly _logService: LogService,
  ) {
    this.loadingOperation = new Promise<void>((resolve, reject) => {
      this._resolveInitialization = resolve;
      this._rejectInitialization = reject;
    });
  }

  public async initialize(config: FeatureBookingConfig): Promise<void> {
    this._logService.trace(FeatureBookingServiceImpl.TYPE, 'Initializing FeatureBookingService...');

    try {
      this.featureBookingConfig = {
        ...config,
        freeAccountFeatureName: config.freeAccountFeatureName ?? 'FREE_ACCOUNT',
        unverifiedAccountFeatureName: config.unverifiedAccountFeatureName ?? 'CONTROL_CENTER_ACCESS',
        unverifiedAccountChannelRef: config.unverifiedAccountChannelRef ?? 'DUMMY_CHANNEL'
      };

      this._logService.trace(FeatureBookingServiceImpl.TYPE, 'Fetching features...');
      const bookedFeatures
        = await toPromise<FeatureBookingResponse | null>(
          this._httpClient
            .get<FeatureBookingResponse | null>(`${config.basePath}/account/features`, {})
        ) ?? [];

      this._logService.trace(FeatureBookingServiceImpl.TYPE, 'Features fetched', bookedFeatures);

      if (!bookedFeatures?.length) {
        this._resolveInitialization();
        this._logService.trace(FeatureBookingServiceImpl.TYPE, 'Finished initialization', this.featuresByChannel);
        return;
      }

      for (const bookedFeature of bookedFeatures) {
        this.featuresByChannel.set(bookedFeature.channelRef, (bookedFeature.bookedFeatures ?? [])
          .filter(feature => this.validBookingStates.includes(feature.bookingState))
          .map(feature => feature.featureName));
      }

      this._resolveInitialization();

      this._logService.trace(FeatureBookingServiceImpl.TYPE, 'Finished initialization', this.featuresByChannel);
    } catch (error) {
      this._rejectInitialization(error);
      this._logService.error(FeatureBookingServiceImpl.TYPE, error as unknown as Error);
    }
  }


  public async hasAccountFeatureBooked(featureName: string): Promise<boolean> {
    await this.loadingOperation;

    if (this.featuresByChannel.size === 0) {
      return false;
    }

    if (this.accountWideFeatureCache.has(featureName)) {
      return true;
    }

    for (const [_, features] of this.featuresByChannel) {
      if (!features.find(feature => feature === featureName)) {
        return false;
      }
    }

    this.accountWideFeatureCache.add(featureName);

    return true;
  }


  public async hasChannelFeatureBooked(channelRef: string, featureName: string): Promise<boolean> {
    await this.loadingOperation;

    return this.featuresByChannel.get(channelRef)?.find(feature => feature === featureName) !== undefined;
  }

  /**
   * @alias isFreeAccount
   * @description checks all channels of the account to verify if its a free account
   * @returns true if all channels have only one feature 'FREE_ACCOUNT' each
   */
  public async isFreeAccount(): Promise<boolean> {
    await this.loadingOperation;

    // if no channels available, then its not a free account
    if (this.featuresByChannel.size === 0) {
      return false;
    }

    // verify every channel of account has only one feature 'FREE_ACCOUNT'
    return [...this.featuresByChannel].every(
      ([_, channelBookedFeatures]) =>
        channelBookedFeatures.length === 1 &&
        channelBookedFeatures[0] === this.featureBookingConfig.freeAccountFeatureName
    );
  }

  public async isUnverifiedAccount(): Promise<boolean> {
    await this.loadingOperation;

    return this.hasChannelFeatureBooked(
      this.featureBookingConfig.unverifiedAccountChannelRef, this.featureBookingConfig.unverifiedAccountFeatureName
    );
  }

  /*
   * This method encapsulates  the logic to check whether the account has `PAID` state.
   */
  public async isPaidAccount(): Promise<boolean> {
    const isFreeAccount =
        await this.isFreeAccount();

    const isUnverifiedAccount =
        await this.isUnverifiedAccount();

    return !isFreeAccount && !isUnverifiedAccount;
  }
}
