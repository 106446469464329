<div class="hls-star-ratings">
  <div class="hls-star-ratings__stars">
    <div class="icon"
      *ngFor="let rating of starRating"
      [ngStyle]="getStarColor(rating)"
      [ngClass]="{
        'hls-star-ratings__star--active': rating.active
      }"
      class="hls-star-ratings__star">

      <svg *ngIf="!rating.isHalf" width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
          <path id="icon-star_svg__a" d="M15.999 5l-2.833 8.717L4 13.721l7.415 5.388-2.83 8.721 7.414-5.389 7.417 5.389-2.829-8.721L28 13.721l-9.164-.004z"/>
        </defs>
        <use fill="currentColor" xlink:href="#icon-star_svg__a" fill-rule="evenodd"/>
      </svg>


      <svg *ngIf="rating.isHalf" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="32" height="32" viewBox="0 0 32 32">
        <defs>
          <polygon id="star-a" points="15.999 5 13.166 13.717 4 13.721 11.415 19.109 8.585 27.83 15.999 22.441 23.416 27.83 20.587 19.109 28 13.721 18.836 13.717"/>
        </defs>
        <g fill="none" fill-rule="evenodd">
          <mask id="star-b" fill="#fff">
            <use xlink:href="#star-a"/>
          </mask>
          <use fill="#CCC" xlink:href="#star-a"/>
          <rect width="16" height="37" fill="currentColor" mask="url(#star-b)"/>
        </g>
      </svg>
    </div>
  </div>
</div>

