import { CultureInfo } from '../culture-info.model';
import { CultureInfoMni } from './mni';

/**
 * @ignore
 */
export const CultureInfoMniMtei: CultureInfo = {
  name: 'mni-Mtei',
  displayName: 'ꯃꯤꯇꯩꯂꯣꯟ',
  iso3LanguageCode: 'mni',
  iso2LanguageCode: 'mni',
  ietfLanguageTag: 'mni-Mtei',
  parent: CultureInfoMni
};
