import { Inject, Injectable } from '@angular/core';
import { KeycloakInstanceFactory } from '@trustedshops/tswp-core-authorization-keycloak';
import {
  DomResourceService,
  ResourceType,
  TOKENS,
} from '@trustedshops/tswp-core-common';
import Keycloak, { KeycloakConfig } from 'keycloak-js';
import { CarrierConfigToken } from '../models/carrier-config.token';
import { CarrierConfig } from '../models/carrier-config.interface';

@Injectable()
export class ConnectedKeycloakInstanceFactory
  implements KeycloakInstanceFactory
{
  public constructor(
    @Inject(CarrierConfigToken) private readonly _config: CarrierConfig,
    @Inject(TOKENS.DomResourceService)
    private readonly _resourceLoaderService: DomResourceService,
  ) {}

  /**
   * Creates a new instances of a Keycloak Connector
   *
   * @param config The keycloak configuration
   */
  public async create(config: KeycloakConfig): Promise<Keycloak> {
    const keycloakBaseUrl: string =
      this._config.authorization.sessionProviderConfig.url.replace(/\/$/, '');

    const extension = this._config.environment.stage.productionModeEnabled
      ? 'min.js'
      : 'js';

    await this._resourceLoaderService.loadResource({
      key: `${keycloakBaseUrl}/js/keycloak.${extension}`,
      type: ResourceType.JavaScript,
      options: {},
    });

    const keycloakWrapper = window as any as {
      Keycloak: (config: KeycloakConfig) => Keycloak;
    };

    return keycloakWrapper.Keycloak(config);
  }
}
