import { CultureInfo } from '../culture-info.model';
import { CultureInfoKn } from './kn';

/**
 * @ignore
 */
export const CultureInfoKnIN: CultureInfo = {
  name: 'kn-IN',
  displayName: 'Kannada (India)',
  iso3LanguageCode: 'kan',
  iso2LanguageCode: 'kn',
  ietfLanguageTag: 'kn-IN',
  parent: CultureInfoKn
};
