import { CultureInfo } from '../culture-info.model';
import { CultureInfoMk } from './mk';

/**
 * @ignore
 */
export const CultureInfoMkMK: CultureInfo = {
  name: 'mk-MK',
  displayName: 'Macedonian (North Macedonia)',
  iso3LanguageCode: 'mkd',
  iso2LanguageCode: 'mk',
  ietfLanguageTag: 'mk-MK',
  parent: CultureInfoMk
};
