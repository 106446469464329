import { Identity } from '../models/identity.interface';
import { SessionProviderService } from './session-provider.service.interface';
import { SessionInterceptor } from '../models/session.interceptor.interface';
import { Session } from '../models/session.interface';
import { AbstractPersistentEvent } from '@trustedshops/tswp-core-common';

/**
 * Service to handle the user identity based on session events
 */
export interface IdentityService {
    /**
     * List of interceptors running on session or identity changes
     */
    readonly interceptors: any;
    /**
     * The instance of the session provider that the identity service utilizes
     */
    readonly sessionProvider: SessionProviderService<Session, any>;
    /**
     * The current identity that is active within the session.
     */
    readonly identity: AbstractPersistentEvent<Identity<any>>;
    /**
     * Loads an identity from a specific session provider
     * @param source The session provider to load the session provider
     */
    loadIdentityFrom<TOptions>(source: SessionProviderService<Session, TOptions>, options: TOptions): any;
    /**
     * Registers an interceptor and resorts all interceptors by order.
     * @param interceptor The interceptor to register
     */
    registerInterceptor(interceptor: SessionInterceptor): void;
}
