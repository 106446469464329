import { CultureInfo } from '../culture-info.model';
import { CultureInfoBsLatn } from './bs-Latn';

/**
 * @ignore
 */
export const CultureInfoBsLatnBA: CultureInfo = {
  name: 'bs-Latn-BA',
  displayName: 'Bosnian (Latin, Bosnia & Herzegovina)',
  iso3LanguageCode: 'bos',
  iso2LanguageCode: 'bs',
  ietfLanguageTag: 'bs-Latn-BA',
  parent: CultureInfoBsLatn
};
