import { CultureInfo } from '../culture-info.model';
import { CultureInfoYi } from './yi';

/**
 * @ignore
 */
export const CultureInfoYi001: CultureInfo = {
  name: 'yi-001',
  displayName: 'Yiddish (World)',
  iso3LanguageCode: 'yid',
  iso2LanguageCode: 'yi',
  ietfLanguageTag: 'yi-001',
  parent: CultureInfoYi
};
