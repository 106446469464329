import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoXh: CultureInfo = {
  name: 'xh',
  displayName: 'Xhosa',
  iso3LanguageCode: 'xho',
  iso2LanguageCode: 'xh',
  ietfLanguageTag: 'xh',
  parent: null
};
