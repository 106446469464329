import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { BootstrapperBase } from '@trustedshops/tswp-core-common';
import {
  BreadcrumbsCompositionService,
  RouteSourceService,
  TOKENS,
} from '@trustedshops/tswp-core-ui';

@Injectable()
export class BreadcrumbsCompositionBootstrapper implements BootstrapperBase {
  //#region Ctor
  public constructor(
    @Inject(TOKENS.RouteSourceService)
    private readonly _routeSourceService: RouteSourceService,

    @Inject(TOKENS.BreadcrumbsCompositionService)
    private readonly _breadcrumbsCompositionService: BreadcrumbsCompositionService
  ) {}
  //#endregion

  //#region Public Methods
  public async initialize(): Promise<void> {
    this._routeSourceService.registerRouteWatcher(() =>
      this._breadcrumbsCompositionService.update()
    );
  }
  //#endregion
}

/**
 * Bootstrapper for connecting the breadcrumbs composition service to the route source service.
 */
export const BreadcrumbsCompositionBootstrapperInitializer = [
  BreadcrumbsCompositionBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => () =>
      initializer.initialize(),
    deps: [BreadcrumbsCompositionBootstrapper],
  },
];
