import { CultureInfo } from '../culture-info.model';
import { CultureInfoBe } from './be';

/**
 * @ignore
 */
export const CultureInfoBeBY: CultureInfo = {
  name: 'be-BY',
  displayName: 'Belarusian (Belarus)',
  iso3LanguageCode: 'bel',
  iso2LanguageCode: 'be',
  ietfLanguageTag: 'be-BY',
  parent: CultureInfoBe
};
