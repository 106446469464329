import { PluginContext } from './plugin-context.interface';

/**
 * Entry point that every plugin communicates to the platform
 */
export interface PluginEntryPoint {
  /**
   * Name of the plugin. Must be identical to the one provided from the Plugin Catalog API
   * TODO: Correct "Plugin Catalog API" name here if we found a new one.
   */
  name: string;

  /**
   * Main Entry Point for each plugin to be called from the platform.
   */
  main: (pluginContext: PluginContext) => Promise<void>;
}
