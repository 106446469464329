export enum BadgeType {
  /**
   * Muted badge style, usually like greyed out.
   */
  Mute = 'mute',

  /**
   * Badge style indicating positive status
   */
  Go = 'go',

  /**
   * Badge style indicating possibly problematic status
   */
  Noun = 'noun',

  /**
   * Badge style indicating negative status
   */
  Stop = 'stop'
}

