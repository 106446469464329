import { CultureInfo } from '../culture-info.model';
import { CultureInfoRw } from './rw';

/**
 * @ignore
 */
export const CultureInfoRwRW: CultureInfo = {
  name: 'rw-RW',
  displayName: 'Kinyarwanda (Rwanda)',
  iso3LanguageCode: 'kin',
  iso2LanguageCode: 'rw',
  ietfLanguageTag: 'rw-RW',
  parent: CultureInfoRw
};
