import { CultureInfo } from '../culture-info.model';
import { CultureInfoOc } from './oc';

/**
 * @ignore
 */
export const CultureInfoOcFR: CultureInfo = {
  name: 'oc-FR',
  displayName: 'Occitan (France)',
  iso3LanguageCode: 'oci',
  iso2LanguageCode: 'oc',
  ietfLanguageTag: 'oc-FR',
  parent: CultureInfoOc
};
