import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoCs: CultureInfo = {
  name: 'cs',
  displayName: 'Czech',
  iso3LanguageCode: 'ces',
  iso2LanguageCode: 'cs',
  ietfLanguageTag: 'cs',
  parent: null
};
