import { CultureInfo } from '../culture-info.model';
import { CultureInfoRo } from './ro';

/**
 * @ignore
 */
export const CultureInfoRoMD: CultureInfo = {
  name: 'ro-MD',
  displayName: 'Romanian (Moldova)',
  iso3LanguageCode: 'ron',
  iso2LanguageCode: 'ro',
  ietfLanguageTag: 'ro-MD',
  parent: CultureInfoRo
};
