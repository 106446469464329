import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BootstrapCustomElements } from '../../decorators/bootstrap-custom-elements.decorator';
import { SharedModule } from '../../shared.module';
import { WebComponentControlModule } from '../../web-component-control.module';
import { MenuComponent } from './menu.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [MenuComponent],
  exports: [MenuComponent],
  imports: [CommonModule, SharedModule]
})
@BootstrapCustomElements([MenuComponent])
export class HlsMenuModule extends WebComponentControlModule { }
