import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoDua: CultureInfo = {
  name: 'dua',
  displayName: 'duálá',
  iso3LanguageCode: 'dua',
  iso2LanguageCode: 'dua',
  ietfLanguageTag: 'dua',
  parent: null
};
