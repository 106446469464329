import { CultureInfo } from '../culture-info.model';
import { CultureInfoPaArab } from './pa-Arab';

/**
 * @ignore
 */
export const CultureInfoPaArabPK: CultureInfo = {
  name: 'pa-Arab-PK',
  displayName: 'Punjabi (Naskh, Pakistan)',
  iso3LanguageCode: 'pan',
  iso2LanguageCode: 'pa',
  ietfLanguageTag: 'pa-Arab-PK',
  parent: CultureInfoPaArab
};
