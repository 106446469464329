import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoAm: CultureInfo = {
  name: 'am',
  displayName: 'Amharic',
  iso3LanguageCode: 'amh',
  iso2LanguageCode: 'am',
  ietfLanguageTag: 'am',
  parent: null
};
