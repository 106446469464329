import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoLkt: CultureInfo = {
  name: 'lkt',
  displayName: 'Lakȟólʼiyapi',
  iso3LanguageCode: 'lkt',
  iso2LanguageCode: 'lkt',
  ietfLanguageTag: 'lkt',
  parent: null
};
