<div class="section-1">
  <div class="text-container">
    <div
      class="heading heading-xl heading-primary heading-semibold"
      style="margin: 0 0 24px 0"
    >
      {{
        translationService.getTranslationForKey(
          'page.upgrade.section-1.heading-1'
        ) | subscribe
      }}
    </div>
    <div
      class="text text-secondary text-xl text-medium"
      style="margin: 0 0 40px 0"
    >
      {{
        translationService.getTranslationForKey(
          'page.upgrade.section-1.heading-2'
        ) | subscribe
      }}
    </div>
    <button
      (click)="handleCTAFormSubmit('top_button')"
      class="button button-primary text-lg text-semibold"
      style="margin: 0 0 24px 0"
    >
      {{
        translationService.getTranslationForKey(
          'page.upgrade.section-1.button.label'
        ) | subscribe
      }}
    </button>
  </div>
  <div class="gradient-layer"></div>
  <div class="icon--dashboard-illustration"></div>
  <span class="icon--wave-top-1"></span>
  <span class="icon--wave-top-2"></span>
</div>

<div class="section-2">
  <div>
    <div>
      <img
        alt="Simone Nierhaus"
        class="image"
        src="/assets/tswp-core-ui-implementation/icons/simone-nierhaus.png"
      />
    </div>

    <div>
      <span
        style="
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
        "
      >
        <span class="icon--quote"></span>
        <span
          class="text text-xl text-primary text-italic"
          style="margin-left: 12px"
        >
          {{
            translationService.getTranslationForKey(
              'page.upgrade.section-2.heading-1'
            ) | subscribe
          }}
        </span>
      </span>

      <span class="text text-primary text-md" style="margin-top: 24px">
        {{
          translationService.getTranslationForKey(
            'page.upgrade.section-2.heading-2'
          ) | subscribe
        }}
      </span>

      <span class="text text-secondary text-sm" style="margin: 24px 0 0 12px">
        {{
          translationService.getTranslationForKey(
            'page.upgrade.section-2.heading-3'
          ) | subscribe
        }}
      </span>
      <span class="icon--flayeralarm" style="margin: 0 0 0 12px"></span>
    </div>
  </div>
</div>

<div class="section-3">
  <span class="heading heading-primary heading-sm heading-semibold">
    {{
      translationService.getTranslationForKey(
        'page.upgrade.section-3.heading-1'
      ) | subscribe
    }}
  </span>

  <div class="logos">
    <span class="icon--baur"></span>
    <span class="icon--pixum"></span>
    <span class="icon--shop-apotheke"></span>
    <span class="icon--vorwerk"></span>
    <span class="icon--hunkemoller"></span>
    <span class="icon--ltur"></span>
  </div>
</div>

<span class="icon--wave-contact-form-top" style="margin-bottom: -1px"></span>
<div #contactFormRef class="section-4" id="hubspot-form">
  <div class="container" style="margin-left: 60px">
    <span class="heading heading-primary heading-md">
      {{
        translationService.getTranslationForKey(
          'page.upgrade.section-4.heading-1'
        ) | subscribe
      }}
    </span>
    <span class="text text-tertiary text-sm" style="max-width: 480px">
      {{
        translationService.getTranslationForKey(
          'page.upgrade.section-4.heading-2'
        ) | subscribe
      }}
    </span>

    <span class="text text-secondary text-sm" style="margin-top: 24px">
      {{
        translationService.getTranslationForKey(
          'page.upgrade.section-4.input.label.firstname'
        ) | subscribe
      }}*
    </span>
    <input
      [class.invalid]="isFieldInvalid('firstname')"
      [formControl]="getControl('firstname')"
      class="input"
      type="text"
    />
    <div *ngIf="isFieldInvalid('firstname')" class="error-message">
      {{
        translationService.getTranslationForKey(
          'page.upgrade.section-4.input.label.firstname.error-message'
        ) | subscribe
      }}
    </div>

    <span class="text text-secondary text-sm" style="margin-top: 24px">
      {{
        translationService.getTranslationForKey(
          'page.upgrade.section-4.input.label.lastname'
        ) | subscribe
      }}*
    </span>
    <input
      [class.invalid]="isFieldInvalid('lastname')"
      [formControl]="getControl('lastname')"
      class="input"
      type="text"
    />
    <div *ngIf="isFieldInvalid('lastname')" class="error-message">
      {{
        translationService.getTranslationForKey(
          'page.upgrade.section-4.input.label.lastname.error-message'
        ) | subscribe
      }}
    </div>

    <span class="text text-secondary text-sm" style="margin-top: 24px">
      {{
        translationService.getTranslationForKey(
          'page.upgrade.section-4.input.label.email'
        ) | subscribe
      }}*
    </span>
    <input
      [class.invalid]="isFieldInvalid('email')"
      [formControl]="getControl('email')"
      class="input"
      type="text"
    />
    <div *ngIf="isFieldInvalid('email')" class="error-message">
      {{
        translationService.getTranslationForKey(
          'page.upgrade.section-4.input.label.email.error-message'
        ) | subscribe
      }}
    </div>

    <span class="text text-secondary text-sm" style="margin-top: 24px">
      {{
        translationService.getTranslationForKey(
          'page.upgrade.section-4.input.label.phone'
        ) | subscribe
      }}
    </span>
    <input
      [class.invalid]="isFieldInvalid('phone')"
      [formControl]="getControl('phone')"
      class="input"
      placeholder="+491234567890"
      type="text"
    />
    <div *ngIf="isFieldInvalid('phone')" class="error-message">
      {{
        translationService.getTranslationForKey(
          'page.upgrade.section-4.input.label.phone.error-message'
        ) | subscribe
      }}
    </div>

    <button
      (click)="handleFullContactFormSubmit()"
      [disabled]="form.invalid || (isLoading | async)"
      class="button button-primary form-submit-button"
    >
      <ng-container *ngIf="isLoading | async">
        <div class="button-content">
          <span class="icon--spinner"></span>
          {{
            translationService.getTranslationForKey(
              'page.upgrade.section-4.button.submitting'
            ) | subscribe
          }}
        </div>
      </ng-container>
      <ng-container *ngIf="!(isLoading | async)">
        {{
          translationService.getTranslationForKey(
            'page.upgrade.section-4.button.label'
          ) | subscribe
        }}
      </ng-container>
    </button>
  </div>
  <div class="container">
    <span class="icon--contact-form-illustration"></span>
  </div>
</div>
<span class="icon--wave-contact-form-bottom" style="margin-top: -1px"></span>

<div class="section-5">
  <span class="heading heading-primary heading-lg" style="margin-bottom: 64px">
    {{
      translationService.getTranslationForKey(
        'page.upgrade.section-5.heading-1'
      ) | subscribe
    }}
  </span>
  <tswp-gallery></tswp-gallery>
</div>

<span class="icon--wave-bottom" style="margin-bottom: -1px"></span>
<div class="section-6" style="padding: 70px 0">
  <span
    class="heading heading-primary heading-lg"
    style="padding: 10px 0 8px 0"
  >
    {{
      translationService.getTranslationForKey(
        'page.upgrade.section-6.heading-1'
      ) | subscribe
    }}
  </span>
  <span class="text text-secondary text-md">
    {{
      translationService.getTranslationForKey(
        'page.upgrade.section-6.heading-2'
      ) | subscribe
    }}
  </span>
  <button
    (click)="handleCTAFormSubmit('bottom_button')"
    class="button button-primary"
    style="width: 180px; margin-top: 40px"
  >
    {{
      translationService.getTranslationForKey(
        'page.upgrade.section-6.button.label'
      ) | subscribe
    }}
  </button>
</div>
