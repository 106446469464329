import { CultureInfo } from '../culture-info.model';
import { CultureInfoUrAran } from './ur-Aran';

/**
 * @ignore
 */
export const CultureInfoUrAranPK: CultureInfo = {
  name: 'ur-Aran-PK',
  displayName: 'اردو (نستعلیق،پاکستان)',
  iso3LanguageCode: 'urd',
  iso2LanguageCode: 'ur',
  ietfLanguageTag: 'ur-Aran-PK',
  parent: CultureInfoUrAran
};
