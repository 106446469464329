<mat-button-toggle
  [value]="value"
  #toggleButton
  *ngIf="$buttonToggleGroup"
  appearance="legacy"
  [hideSingleSelectionIndicator]="true"
  [disableRipple]="true"
  [disabled]="disabled"
>
  <slot></slot>
</mat-button-toggle>
