import { CultureInfo } from '../culture-info.model';
import { CultureInfoTo } from './to';

/**
 * @ignore
 */
export const CultureInfoToTO: CultureInfo = {
  name: 'to-TO',
  displayName: 'lea fakatonga (Tonga)',
  iso3LanguageCode: 'ton',
  iso2LanguageCode: 'to',
  ietfLanguageTag: 'to-TO',
  parent: CultureInfoTo
};
