import { Event } from '../eventing/event.interface';

export type NextInterceptorHandlerFunction<TInterceptor, TInterceptedObject, TOut = TInterceptedObject>  = (
  interceptor: TInterceptor,
  interceptedObject: TInterceptedObject,
  next: NextInterceptorHandler<TInterceptor, TInterceptedObject, TOut>) => Event<TOut>;

export type InterceptedOperationDelegate<TInterceptedObject, TOut = TInterceptedObject> =
  (interceptedObject: TInterceptedObject) => Event<TOut>;

/**
 * A wrapper for interceptors being used internally when implementing an interception.
 * Wraps the interception function and controls the order of interceptors within a chain.
 */
export class NextInterceptorHandler<TInterceptor, TInterceptedObject, TOut = TInterceptedObject> {
  //#region Private Fields
  private readonly _interceptorDelegate: NextInterceptorHandlerFunction<TInterceptor, TInterceptedObject, TOut>;
  private readonly _interceptedOperation: InterceptedOperationDelegate<TInterceptedObject, TOut>;
  private readonly _interceptors: Array<TInterceptor>;
  private readonly _currentInterceptorIndex: number;
  //#endregion

  //#region Constructor
  /**
   * Creates a new instance of a NextInterceptorHandler
   * @param currentInterceptorIndex The index of this interceptor within the interception chain
   * @param handler The function to call to apply the interception
   * @param interceptors The list of interceptors within the interception chain
   */
  public constructor(
    currentInterceptorIndex: number,
    handler: NextInterceptorHandlerFunction<TInterceptor, TInterceptedObject, TOut>,
    interceptors: Array<TInterceptor>,
    interceptedOperation: InterceptedOperationDelegate<TInterceptedObject, TOut>) {

    this._interceptorDelegate = handler;
    this._interceptedOperation = interceptedOperation;
    this._interceptors = interceptors;
    this._currentInterceptorIndex = currentInterceptorIndex;
  }
  //#endregion

  //#region Public Methods
  /**
   * Calls the interception function and runs the subsequent interceptors
   * @param interceptedObject The object that the interception needs to proceed on
   */
  public handle(interceptedObject?: TInterceptedObject): Event<TOut> {
    const nextIndex = this._currentInterceptorIndex + 1;

    const interceptor = this._interceptors[this._currentInterceptorIndex];
    if (!interceptor) {
      return this._interceptedOperation(interceptedObject);
    }

    return this._interceptorDelegate(interceptor, interceptedObject, new NextInterceptorHandler(
      nextIndex,
      this._interceptorDelegate,
      this._interceptors,
      this._interceptedOperation));
  }
  //#endregion
}
