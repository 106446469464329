/* Ignore because: Config file */
/* istanbul ignore file */
import { Injectable, Inject, APP_INITIALIZER } from '@angular/core';
import { BootstrapperBase } from '@trustedshops/tswp-core-common';
import { UserInteractionRendererProvider, UserInteractionType } from '@trustedshops/tswp-core-ui';
import { TOKENS } from '../../export-tokens';
import { NavigateInteractionComponent } from '../components/navigate-interaction/navigate-interaction.component';
import { ClickInteractionComponent } from '../components/click-interaction/click-interaction.component';
import { NavigateFirstInteractionComponent } from '../components/navigate-first-interaction/navigate-first-interaction.component';

/**
 * Bootstrapper for activating the rendering of Navigate and Click interaction renderers
 */
@Injectable()
export class UserInteractionRendererBootstrapper implements BootstrapperBase {
  //#region Ctor
  /** ignore */
  public constructor(
    @Inject(TOKENS.UserInteractionRendererProvider) private readonly _userInteractionRendererProvider: UserInteractionRendererProvider) {
  }
  //#endregion

  //#region Public Methods
  /**
   * Runs this bootstrapper. Applies the PluginHost Interface implementation to Window.
   */
  public async initialize(): Promise<void> {
    this._userInteractionRendererProvider
      .registerRenderer(UserInteractionType.Navigate, NavigateInteractionComponent)
      .registerRenderer(UserInteractionType.NavigateToFirstChild, NavigateFirstInteractionComponent)
      .registerRenderer(UserInteractionType.Click, ClickInteractionComponent);
  }
  //#endregion
}

/**
 * Bootstrapper for activating the rendering of Navigate and Click interaction renderers
 */
export const UserInteractionRendererInitializer = [
  UserInteractionRendererBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [UserInteractionRendererBootstrapper]
  }
];
