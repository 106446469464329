import { CultureInfo } from '../culture-info.model';
import { CultureInfoSd } from './sd';

/**
 * @ignore
 */
export const CultureInfoSdPK: CultureInfo = {
  name: 'sd-PK',
  displayName: 'سنڌي (پاڪستان)',
  iso3LanguageCode: 'snd',
  iso2LanguageCode: 'sd',
  ietfLanguageTag: 'sd-PK',
  parent: CultureInfoSd
};
