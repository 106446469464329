import { CultureInfo } from '../culture-info.model';
import { CultureInfoKk } from './kk';

/**
 * @ignore
 */
export const CultureInfoKkKZ: CultureInfo = {
  name: 'kk-KZ',
  displayName: 'Kazakh (Kazakhstan)',
  iso3LanguageCode: 'kaz',
  iso2LanguageCode: 'kk',
  ietfLanguageTag: 'kk-KZ',
  parent: CultureInfoKk
};
