import { NavigationItem } from './navigation-item.interface';

/**
 * Child navigation item
 */
export interface ChildNavigationItem extends NavigationItem {
  /**
   * Gets or sets the region this child should be rendered to
   */
  region: string;

  /**
   * Gets or sets a value indicating whether the child navigation item should be rendered with a plus sign
   */
  plus?: boolean;
}
