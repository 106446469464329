import Keycloak, { KeycloakConfig } from 'keycloak-js';

export const KC_INSTANCE_FACTORY = '@trustedshops/tswp-core-authorization-keycloak:KC_INSTANCE_FACTORY';

/* istanbul ignore next */
export interface KeycloakInstanceFactory {
  //#region Public Methods
  /**
   * Creates a new instances of a Keycloak Connector
   *
   * @param config The keycloak configuration
   */
  create(config: KeycloakConfig): Promise<Keycloak>;
  //#endregion
}
