import { CultureInfo } from '../culture-info.model';
import { CultureInfoBs } from './bs';

/**
 * @ignore
 */
export const CultureInfoBsLatn: CultureInfo = {
  name: 'bs-Latn',
  displayName: 'Bosnian (Latin)',
  iso3LanguageCode: 'bos',
  iso2LanguageCode: 'bs',
  ietfLanguageTag: 'bs-Latn',
  parent: CultureInfoBs
};
