import { PageTitleResult } from './page-title-result.interface';

/**
 * Declaration for an object that provides a page title for a given route
 */
export interface PageTitleProvider {
  /**
   * The order of the page title provider. The lower the order, the earlier the page title provider is called.
   */
  readonly order: number;

  /**
   * Gets the page title for the given route
   *
   * @param route The route to get the page title for
   * @returns The page title result. If the provider can provide the given route, the result object will contain the pageTitle.
   */
  getPageTitle(route: string): PageTitleResult;
}
