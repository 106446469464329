import { AbstractPersistentEvent } from '@trustedshops/tswp-core-common';

export interface KeycloakProfileData {
  keycloak: {
    profile: any;
    userInfo: any;
    token: {
      encoded: AbstractPersistentEvent<string>;
      payload: AbstractPersistentEvent<any>;
    }
  };
}
