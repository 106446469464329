import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoNr: CultureInfo = {
  name: 'nr',
  displayName: 'isiNdebele',
  iso3LanguageCode: 'nbl',
  iso2LanguageCode: 'nr',
  ietfLanguageTag: 'nr',
  parent: null
};
