import { PermissionRequest } from './permission-request.interface';

export interface GrantInfo<TPermissionDefinition> {
  /**
   * Gets a value indicating wether the permission request was granted.
   */
  readonly granted: boolean;

  /**
   * Gets the original permission request.
   */
  readonly request: PermissionRequest;

  /**
   * Gets the matching permission definition that was taken to answer a granted permission.
   */
  readonly matchingDefinition: TPermissionDefinition;
}
