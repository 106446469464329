import { CultureInfo } from '../culture-info.model';
import { CultureInfoKsAran } from './ks-Aran';

/**
 * @ignore
 */
export const CultureInfoKsAranIN: CultureInfo = {
  name: 'ks-Aran-IN',
  displayName: 'کٲشُر (نستعلیق, ہِندوستان)',
  iso3LanguageCode: 'kas',
  iso2LanguageCode: 'ks',
  ietfLanguageTag: 'ks-Aran-IN',
  parent: CultureInfoKsAran
};
