import { AbstractEventListener } from './abstract-event-listener';
import { PersistingEventListener } from './persisting-event-listener.interface';
import { RemoveSubscriptionDelegate } from './remove-delegate.type';
import { SubscribeDelegate } from './subscribe-delegate.type';
import { GetValueDelegate } from './value-delegate.type';

/**
 * An event stream listener inheriting from AbstractEventListener but with the ability to remember the last value emitted.
 */
export class AbstractPersistingEventListener<TEmission>
  extends AbstractEventListener<TEmission>
  implements PersistingEventListener<TEmission> {

  //#region Private Fields
  private readonly _valueProxy: GetValueDelegate<TEmission>;
  //#endregion

  //#region Properties
  /**
   * Gets the last value emitted.
   */
  public get value(): TEmission {
    return this._valueProxy();
  }
  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of an AbstractPersistingEventListener
   *
   * @param subscriptionProxy Delegate pointing to the event stream's subscribe logic of a subscription.
   * @param removeProxy Delegate pointing to the event stream's remove logic of a subscription.
   * @param valueProxy Delegate retrieving the last value emitted in the event stream.
   */
  public constructor(
    subscriptionProxy: SubscribeDelegate<TEmission>,
    removeProxy: RemoveSubscriptionDelegate<TEmission>,
    valueProxy: GetValueDelegate<TEmission>) {

    super(subscriptionProxy, removeProxy);

    this._valueProxy = valueProxy;
  }
  //#endregion
}
