import { BehaviorSubject } from 'rxjs';
import {
  TOKENS,
  SessionError,
  IdentityResolverDelegate
} from '@trustedshops/tswp-core-authorization';
import {
  SessionProviderServiceBase
} from '@trustedshops/tswp-core-authorization-implementation';
import { Inject, Injectable, Optional } from '@angular/core';
import { AbstractEvent, ArgumentChecks, Event, EventSubscription, InvalidOperationError } from '@trustedshops/tswp-core-common';
import {
  SessionVerificationTypeEnum,
  KeycloakSession,
  KeycloakConfiguration,
  KeycloakInitializationOptions,
  KeycloakInstanceFactory,
  KC_INSTANCE_FACTORY
} from '@trustedshops/tswp-core-authorization-keycloak';
import { KeycloakInstance, KeycloakOnLoad, KeycloakTokenParsed } from 'keycloak-js';
import { DOCUMENT } from '@angular/common';
import * as Keycloak from 'keycloak-js';

/**
 * Implements session handling using keycloak as backend
 * @export
 */
@Injectable()
export class KeycloakSessionProviderService extends SessionProviderServiceBase<KeycloakSession, KeycloakInitializationOptions> {
  public readonly parsedToken: BehaviorSubject<KeycloakTokenParsed | undefined> = new BehaviorSubject<KeycloakTokenParsed | undefined>(undefined);

  //#region Private Fields
  private readonly _configuration: KeycloakConfiguration;
  private readonly _document: Document;
  private readonly _keycloakInstanceFactory: KeycloakInstanceFactory;
  private _keycloakConnector: KeycloakInstance;
  private _tokenChecking: Event<void>;
  private _tokenCheckingSubscription: EventSubscription<any>;
  private _intervalHandle: any;
  private readonly _identityResolver: IdentityResolverDelegate;
  //#endregion

  //#region Properties
  /**
   * Gets the type of this session provider
   */
  public get type(): string {
    return 'KEYCLOAK';
  }

  private _tokenCheckInterval = 10000;
  public get tokenCheckInterval(): number {
    return this._tokenCheckInterval;
  }
  public set tokenCheckInterval(v: number) {
    this._tokenCheckInterval = v;
  }

  //#endregion

  //#region Ctor
  /**
   * Creates an instance of KeycloakSessionProviderService.
   */
  public constructor(
    @Inject(TOKENS.SessionProviderConfiguration) configuration: KeycloakConfiguration,
    @Inject(KC_INSTANCE_FACTORY) keycloakInstanceFactory: KeycloakInstanceFactory,
    @Inject(DOCUMENT) document: Document,
    @Optional() @Inject(TOKENS.IdentityResolver) identityResolver?: IdentityResolverDelegate) {

    super();

    this._configuration = configuration;
    this._keycloakInstanceFactory = keycloakInstanceFactory;
    this._document = document;
    this._identityResolver = identityResolver || ((_, __, ___, token) => token.sub);
  }
  //#endregion

  //#region Public Methods
  /**
   * Gets the uri showing the login form.
   * @param redirectUri The url to redirect to when having logged in successfully
   * @param locale The locale to use in keycloak UI
   */
  public async getLoginUri(redirectUri?: string, locale = 'en'): Promise<string> {
    this.ensureConnectionIsSetUp();

    return this._keycloakConnector.createLoginUrl({ locale, redirectUri });
  }

  /**
   * Redirects to login form
   */
  public async login(iso2LanguageCode?: string, redirectUri?: string): Promise<void> {
    this.ensureConnectionIsSetUp();

    return this._keycloakConnector.login({
      locale: iso2LanguageCode,
      redirectUri
    });
  }

  /**
   * Redirects to logout form
   */
  public async logout(): Promise<void> {
    this.ensureConnectionIsSetUp();

    return this._keycloakConnector.logout();
  }

  /**
   * Gets the uri to logout from keycloak.
   */
  public async getLogoutUri(redirectUri?: string): Promise<string> {
    this.ensureConnectionIsSetUp();

    return this._keycloakConnector.createLogoutUrl({ redirectUri });
  }

  public getParsedToken(): KeycloakTokenParsed | undefined {
    return  this._keycloakConnector?.idTokenParsed;
  }

  /**
   * Establishes connection to keycloak and verifies if the user has been logged in already.
   * @throws {SessionError} Thrown when an error occurs while establishing the connection to keycloak.
   */
  public async initialize(options: KeycloakInitializationOptions): Promise<void> {
    this._keycloakConnector = await this._keycloakInstanceFactory.create({
      url: this._configuration.url,
      clientId: this._configuration.clientId,
      realm: this._configuration.realm
    });

    try {
      if (this._configuration.sessionVerificationType === SessionVerificationTypeEnum.SilentCheckSso) {
        ArgumentChecks.isNotNullOrUndefinedOrWhitespace(
          this._configuration.silentCheckSsoRedirectUri,
          'KeycloakConfiguration.silentCheckSsoRedirectUri');
      }

      let silentCheckSsoRedirectUri = this._configuration.silentCheckSsoRedirectUri;
      while (silentCheckSsoRedirectUri.startsWith('/')) {
        silentCheckSsoRedirectUri = silentCheckSsoRedirectUri.substr(1);
      }

      if (!/^http[s]?\/\//.test(silentCheckSsoRedirectUri)) {
        const location = this._document.location;
        silentCheckSsoRedirectUri = `${location.protocol}//${location.host}/${silentCheckSsoRedirectUri}`;
      }

      const authenticated = await this._keycloakConnector.init({
        onLoad: this._configuration.sessionVerificationType as KeycloakOnLoad,
        silentCheckSsoRedirectUri
      });

      if (!authenticated) {
        this._session.emit(null);
        return;
      }

      let loadUserProfile: Promise<any> = Promise.resolve({});
      let loadUserInfo: Promise<any> = Promise.resolve({});

      if (options?.loadUserProfile) {
          loadUserProfile = this._keycloakConnector.loadUserProfile()
            .catch(() => ({})); // Recover 403 when account client is not activated.
      }

      if (options?.loadUserInfo) {
          loadUserInfo = this._keycloakConnector.loadUserInfo()
            .catch(() => ({}));
      }

      const [userProfile, userInfo] = await Promise.all([loadUserProfile, loadUserInfo]);

      this._session.emit(
        new KeycloakSession(
          userProfile,
          userInfo,
          this._keycloakConnector.token,
          this._keycloakConnector.tokenParsed,
          this._identityResolver));

      this.parsedToken.next(this.getParsedToken());

      this.checkToken(this._keycloakConnector.tokenParsed);
    } catch (error) {
      throw new SessionError('The session could not be established', error as Error, null);
    }
  }
  //#endregion

  //#region Private Methods
  private checkToken(token: Keycloak.KeycloakTokenParsed): void {
    if (!this._keycloakConnector) {
      return;
    }

    this.tokenCheckInterval = this.getRefreshInterval(token);

    this._tokenChecking = this.getIntervalStream(this.tokenCheckInterval);

    if (this._tokenCheckingSubscription) {
      this._tokenCheckingSubscription.unsubscribe();
      this._tokenCheckingSubscription = null;
    }

    this._tokenCheckingSubscription = this._tokenChecking
      .subscribe(async () => {
        const refreshed = await this._keycloakConnector.updateToken(this.tokenCheckInterval);
        if (!refreshed) {
          return;
        }

        this._session.value.update(this._keycloakConnector);
        const newInterval = this.getRefreshInterval(token);
        if (newInterval !== this.tokenCheckInterval) {
          this.checkToken(this._keycloakConnector.tokenParsed);
        }
      });
  }

  private getRefreshInterval(token: Keycloak.KeycloakTokenParsed): number {
    return Math.floor((token.exp - token.iat) * 0.8);
  }

  private getIntervalStream(tokenCheckInterval: number): Event<void> {
    const event = new AbstractEvent<void>();
    this.resetInterval();
    this._intervalHandle = setInterval(() => event.emit(), tokenCheckInterval);
    return event;
  }

  private resetInterval(): void {
    if (this._intervalHandle) {
      clearInterval(this._intervalHandle);
    }
  }

  private ensureConnectionIsSetUp(): void {
    if (!this._keycloakConnector) {
      throw new InvalidOperationError('Keycloak connector has not yet been initialized with connection data');
    }
  }
  //#endregion
}
