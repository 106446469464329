import { CultureInfo } from '../culture-info.model';
import { CultureInfoRu } from './ru';

/**
 * @ignore
 */
export const CultureInfoRuRU: CultureInfo = {
  name: 'ru-RU',
  displayName: 'Russian (Russia)',
  iso3LanguageCode: 'rus',
  iso2LanguageCode: 'ru',
  ietfLanguageTag: 'ru-RU',
  parent: CultureInfoRu
};
