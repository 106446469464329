import {
  AbstractEvent,
  Event,
  NextInterceptorHandler,
} from '@trustedshops/tswp-core-common';
import {
  Identity,
  IdentityService,
  SessionInterceptor,
  TOKENS,
} from '@trustedshops/tswp-core-authorization';
import { Inject, Injectable, Injector, Provider } from '@angular/core';
import { KeycloakSessionProviderService } from '../services/keycloak-session-provider.service';
import {
  KeycloakProfileData,
  KeycloakSession,
} from '@trustedshops/tswp-core-authorization-keycloak';
import { SessionInterceptorBase } from '@trustedshops/tswp-core-authorization-implementation';

/**
 * Takes responsibility to redirect to login page if the a user visits the application without an authentication
 */
@Injectable()
export class KeycloakDataInterceptor extends SessionInterceptorBase {
  private readonly _injector: Injector;

  /**
   * Creates an instance of KeycloakDataInterceptor
   * @param injector The injector to use to request the IdentityService
   * @param document The document to use to change the current URL for.
   */
  public constructor(@Inject(Injector) injector: Injector) {
    super();

    this._injector = injector;
  }

  /**
   * Gets the order index of this Interceptor (1000)
   */
  public get order(): number {
    return 1000;
  }

  /**
   * Called when the session ended or the initial load of the session results in an unauthenticated state.
   * @param next the next interceptor to call. If not called, the interception chain will be broken.
   */
  public override onIdentityReceived(
    identity: Identity<KeycloakProfileData>,
    next: NextInterceptorHandler<
      SessionInterceptor,
      Identity<KeycloakProfileData>
    >,
  ): Event<Identity<KeycloakProfileData>> {
    const identityService = this._injector.get<IdentityService>(
      TOKENS.IdentityService as any,
    );
    const keycloakSessionProvider =
      identityService.sessionProvider as unknown as KeycloakSessionProviderService;

    const eventStream = new AbstractEvent<Identity<KeycloakProfileData>>();
    (async () => {
      const session = await new Promise<KeycloakSession>((resolve) =>
        keycloakSessionProvider.session.subscribe(resolve),
      );
      identity.profile.keycloak = {
        profile: {
          id: session.userProfile.id,
          firstName: session.userProfile.firstName,
          lastName: session.userProfile.lastName,
          email: session.userProfile.email,
          username: session.userProfile.username,
          createdTimestamp: session.userProfile.createdTimestamp,
        },
        userInfo: session.userInfo,
        token: {
          encoded: session.token,
          payload: session.payload,
        },
      };

      next.handle(identity).subscribe((res) => eventStream.emit(res));
    })();

    return eventStream;
  }
}

/**
 * Defines a default setup for the LoginUiRedirectInterceptor
 */
export const SetUpKeycloakDataInterceptor: Provider = {
  provide: TOKENS.SessionInterceptor,
  useClass: KeycloakDataInterceptor,
  multi: true,
};
