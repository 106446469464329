import {
  Component,
  Inject,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { NavigationRegions } from '@trustedshops/tswp-carrier-b2b-contracts';
import { RxJsBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import { NavigationItem, NavigationRegionService, NavigationService, TOKENS } from '@trustedshops/tswp-core-ui';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { tertiaryNavigationRegion } from './tertiary-menu.navigation-region';

@Component({
  selector: 'tertiary-menu-bar',
  templateUrl: './tertiary-menu-bar.component.html',
  styleUrls: ['./tertiary-menu-bar.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false,
})
export class TertiaryMenuBarComponent implements OnInit {
  //#region Public Static Constants
  public static readonly selector: string = 'tswp-tertiary-menu-bar';
  //#endregion

  //#region Properties
  private _items: Observable<NavigationItem[]>;
  public get items(): Observable<NavigationItem[]> {
    return this._items;
  }

  private _activeItems: Observable<NavigationItem[]>;
  public get activeItems(): Observable<NavigationItem[]> {
    return this._activeItems;
  }
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(TOKENS.NavigationRegionService)
    private readonly _navigationRegionService: NavigationRegionService,

    @Inject(TOKENS.NavigationService)
    private readonly _navigationService: NavigationService) { }
  //#endregion

  //#region Public Methods
  public ngOnInit(): void {
    this._navigationRegionService.registerRegionConfig(tertiaryNavigationRegion);
    this._activeItems = this._navigationRegionService
      .getActiveItems(NavigationRegions.TertiaryMenu)
      .convertWith(RxJsBridge(BehaviorSubject))
      .pipe(filter(x => !!x));

    this._items = this._navigationRegionService
      .getActiveItems(NavigationRegions.SecondaryMenu)
      .convertWith(RxJsBridge(BehaviorSubject))
      .pipe(filter(x => !!x))
      .pipe(map(secondaryItems => secondaryItems
        .map(secondaryItem =>
          (secondaryItem.children ?? []).filter(secondaryChild =>
            secondaryChild.region === NavigationRegions.TertiaryMenu))
        .reduce((previous, current) => previous.concat(current), [] as NavigationItem[])));
  }
  //#endregion
}
