export const TOKENS = {
  DomResourceService: '@trustedshops/tswp-core-common:DomResourceService',
  StopwatchFactory: '@trustedshops/tswp-core-common:StopwatchFactory',
  DateProvider: '@trustedshops/tswp-core-common:DateProvider',
  LocalStorageBaseKey: '@trustedshops/tswp-core-common:LocalStorageBaseKey',
  LocalStorageService: '@trustedshops/tswp-core-common:LocalStorageService',
  ApplicationService: '@trustedshops/tswp-core-common:ApplicationService',
  TimerService: '@trustedshops/tswp-core-common:TimerService',
  LogService: '@trustedshops/tswp-core-common:LogService',
  CultureInfoService: '@trustedshops/tswp-core-common:CultureInfoService',
  HttpClientService: '@trustedshops/tswp-core-common:HttpClientService',
  CurrentCultureInfoProvider: '@trustedshops/tswp-core-common:CultureInfoProvider',
  TranslationService: '@trustedshops/tswp-core-common:TranslationService',
  DefaultTranslationServiceConfig: '@trustedshops/tswp-core-common:DefaultTranslationServiceConfig'
};
