import {
  ExportToken,
  TOKENS,
  HttpClientService,
  HttpInterceptor,
  Event,
  HttpRequest as PlatformHttpRequest,
  HttpRequestOptions,
  AbstractEvent,
  HttpEvent } from '@trustedshops/tswp-core-common';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';

@ExportToken(TOKENS.HttpClientService)
@Injectable()
export class HttpClientServiceImpl implements HttpClientService {
  //#region Private Fields
  private readonly _httpClient: HttpClient;
  //#endregion

  //#region Properties
  private readonly _interceptors: HttpInterceptor[] = [];

  public get interceptors(): HttpInterceptor[] {
    return [...this._interceptors];
  }
  //#endregion

  //#region Ctor
  public constructor(httpClient: HttpClient) {
    this._httpClient = httpClient;
  }
  //#endregion

  //#region Public Methods
  /**
   * Registers an interceptor for HTTP calls
   *
   * @param type The instance of the interceptor
   */
  public registerInterceptor<T extends HttpInterceptor>(type: T): HttpClientService {
    this._interceptors
      .push(type);

    return this;
  }

  public request<T>(request: PlatformHttpRequest<any>): Event<HttpEvent<T>> {
    const httpRequest = this._httpClient
      .request<T>(this.toAngularHttpRequest(request))
      .pipe(map(x => x as any as HttpEvent<T>));

    return this.fromObservable(httpRequest);
  }

  public delete<T>(url: string, options: HttpRequestOptions): Event<T> {
    return this.fromObservable(this._httpClient.delete<T>(url, {
      ...options,
      headers: new HttpHeaders(options.headers),
      params: new HttpParams(options.params),
      responseType: options.responseType as any
    }));
  }

  public get<T>(url: string, options: HttpRequestOptions): Event<T> {
    return this.fromObservable(this._httpClient.get<T>(url, {
      ...options,
      headers: new HttpHeaders(options.headers),
      params: new HttpParams(options.params),
      responseType: options.responseType as any
    }));
  }

  public head<T>(url: string, options: HttpRequestOptions): Event<T> {
    return this.fromObservable(this._httpClient.head<T>(url, {
      ...options,
      headers: new HttpHeaders(options.headers),
      params: new HttpParams(options.params),
      responseType: options.responseType as any
    }));
  }

  public jsonp<T>(url: string, callbackParam: string): Event<T> {
    return this.fromObservable(this._httpClient.jsonp<T>(url, callbackParam));
  }

  public options<T>(url: string, options: HttpRequestOptions): Event<T> {
    return this.fromObservable(this._httpClient.options<T>(url, {
      ...options,
      headers: new HttpHeaders(options.headers),
      params: new HttpParams(options.params),
      responseType: options.responseType as any
    }));
  }
  public patch<T>(url: string, body: any, options: HttpRequestOptions): Event<T> {
    return this.fromObservable(this._httpClient.patch<T>(url, body, {
      ...options,
      headers: new HttpHeaders(options.headers),
      params: new HttpParams(options.params),
      responseType: options.responseType as any
    }));
  }
  public put<T>(url: string, body: any, options: HttpRequestOptions): Event<T> {
    return this.fromObservable(this._httpClient.put<T>(url, body, {
      ...options,
      headers: new HttpHeaders(options.headers),
      params: new HttpParams(options.params),
      responseType: options.responseType as any
    }));
  }
  public post<T>(url: string, body: any, options: HttpRequestOptions): Event<T> {
    return this.fromObservable(this._httpClient.post<T>(url, body, {
      ...options,
      headers: new HttpHeaders(options.headers),
      params: new HttpParams(options.params),
      responseType: options.responseType as any
    }));
  }
  //#endregion

  //#region Private Methods
  private toAngularHttpRequest(req: PlatformHttpRequest): HttpRequest<any> {
    return new HttpRequest(req.method, req.url, req.body, {
      headers: new HttpHeaders(req.headers),
      params: new HttpParams(req.params),
      reportProgress: req.reportProgress,
      responseType: req.responseType,
      withCredentials: req.withCredentials
    });
  }

  private fromObservable<T>(observable: Observable<T>): Event<T> {
    const event = new AbstractEvent<T>();
    const subscription = observable
      .subscribe(
        value => event.emit(value),
        error => event.emitError(error),
        () => subscription?.unsubscribe());
    return event;
  }
  //#endregion
}
