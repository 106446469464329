import { CultureInfo } from '../culture-info.model';
import { CultureInfoSaq } from './saq';

/**
 * @ignore
 */
export const CultureInfoSaqKE: CultureInfo = {
  name: 'saq-KE',
  displayName: 'Kisampur (Kenya)',
  iso3LanguageCode: 'saq',
  iso2LanguageCode: 'saq',
  ietfLanguageTag: 'saq-KE',
  parent: CultureInfoSaq
};
