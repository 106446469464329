import { CultureInfo } from '../culture-info.model';
import { CultureInfoSq } from './sq';

/**
 * @ignore
 */
export const CultureInfoSqXK: CultureInfo = {
  name: 'sq-XK',
  displayName: 'shqip (Kosovë)',
  iso3LanguageCode: 'sqi',
  iso2LanguageCode: 'sq',
  ietfLanguageTag: 'sq-XK',
  parent: CultureInfoSq
};
