import { CultureInfo } from '../culture-info.model';
import { CultureInfoEs } from './es';

/**
 * @ignore
 */
export const CultureInfoEsVC: CultureInfo = {
  name: 'es-VC',
  displayName: 'español (San Vicente y las Granadinas)',
  iso3LanguageCode: 'spa',
  iso2LanguageCode: 'es',
  ietfLanguageTag: 'es-VC',
  parent: CultureInfoEs
};
