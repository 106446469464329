import { CultureInfo } from '../culture-info.model';
import { CultureInfoCe } from './ce';

/**
 * @ignore
 */
export const CultureInfoCeRU: CultureInfo = {
  name: 'ce-RU',
  displayName: 'нохчийн (Росси)',
  iso3LanguageCode: 'che',
  iso2LanguageCode: 'ce',
  ietfLanguageTag: 'ce-RU',
  parent: CultureInfoCe
};
