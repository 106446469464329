import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoEbu: CultureInfo = {
  name: 'ebu',
  displayName: 'Kĩembu',
  iso3LanguageCode: 'ebu',
  iso2LanguageCode: 'ebu',
  ietfLanguageTag: 'ebu',
  parent: null
};
