import { PluginContext } from './plugin-context.interface';

/**
 * Basic interface with all possible life cycle hooks for web component controls
 */
export interface DynamicPluginControl extends HTMLElement {
  /**
   * Gets the context that's defined for the corresponding plugin
   */
  pluginContext: PluginContext;

  /**
   * Called when the carrying platform is up to append the element to the DOM
   */
  beforeAppended?(): Promise<void>;

  /**
   * Called when the carrying platform has appended the element to the DOM
   */
  afterAppended?(): Promise<void>;

  /**
   * Called when removing the web component from the DOM
   */
  beforeDestroyed?(): Promise<void>;
}
