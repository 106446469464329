import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoQu: CultureInfo = {
  name: 'qu',
  displayName: 'Runasimi',
  iso3LanguageCode: 'que',
  iso2LanguageCode: 'qu',
  ietfLanguageTag: 'qu',
  parent: null
};
