import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BootstrapCustomElements } from '../../decorators/bootstrap-custom-elements.decorator';
import { SharedModule } from '../../shared.module';
import { WebComponentControlModule } from '../../web-component-control.module';
import { ExpansionGroupComponent } from './expansion-group.component';
import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    ExpansionGroupComponent,
    ExpansionPanelComponent
  ],
  exports: [
    ExpansionGroupComponent,
    ExpansionPanelComponent
  ],
  imports: [
    OverlayModule,
    MatExpansionModule,
    CommonModule,
    SharedModule,
    BrowserAnimationsModule
  ]
})
@BootstrapCustomElements([
  ExpansionPanelComponent,
  ExpansionGroupComponent
])
export class HlsExpansionGroupModule extends WebComponentControlModule { }
