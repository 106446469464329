import { CultureInfo } from '../culture-info.model';
import { CultureInfoRof } from './rof';

/**
 * @ignore
 */
export const CultureInfoRofTZ: CultureInfo = {
  name: 'rof-TZ',
  displayName: 'Kihorombo (Tanzania)',
  iso3LanguageCode: 'rof',
  iso2LanguageCode: 'rof',
  ietfLanguageTag: 'rof-TZ',
  parent: CultureInfoRof
};
