import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoGu: CultureInfo = {
  name: 'gu',
  displayName: 'Gujarati',
  iso3LanguageCode: 'guj',
  iso2LanguageCode: 'gu',
  ietfLanguageTag: 'gu',
  parent: null
};
