import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, Input, ViewEncapsulation, HostBinding } from '@angular/core';
import { WebComponent } from '../../decorators/web-component.decorator';
import { HeliosControl } from '../helios-control';

/**
 * Cards from the Helios Design system
 * @doctab "Examples" "card.component.examples.md"
 * @title Cards
 * @default_slot Card content
 * @named_slot "action-bar" "Optional, Cards action bar"
 */
@WebComponent('hls-card')
@Component({
  selector: 'hls-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  preserveWhitespaces: true,
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class CardComponent extends HeliosControl {
  //#region Properties
  private _headline: string = null;
  /**
   * Gets or sets the caption of the card
   */
  @Input()
  @HostBinding('attr.headline')
  public get headline(): string {
    return this._headline;
  }
  public set headline(v: string) {
    this._headline = v;
  }

  private _padding = true;
  /**
   * Gets or sets a value indicating wether this card has inner paddings.
   */
  @Input()
  @HostBinding('attr.padding')
  public get padding(): boolean {
    return this._padding;
  }
  public set padding(v: boolean) {
    this._padding = coerceBooleanProperty(v);
  }

  private _border: string = null;
  /**
   * Gets or sets the border of the card
   */
  @Input()
  @HostBinding('attr.border')
  public get border(): string {
    return this._border;
  }
  public set border(v: string) {
    this._border = v;
  }

  private _background: string = null;
  /**
   * Gets or sets a background color of the card.
   * Default: `#ffffff`
   */
  @Input()
  @HostBinding('attr.background')
  public get background(): string {
    return this._background;
  }
  public set background(v: string) {
    this._background = v;
  }

  private _elevation = 1;
  /* tslint:disable:max-line-length */
  /**
   * Gets or sets the elevation level index. Default: 1
   * Set `0` to deactivate elevation.
   * See [Brand Center;Helios;Foundation;Shadows](https://brand.trustedshops.com/d/dorIFVeUmcN9/helios-design-system#/helios-foundation/shadows-elevation) for more info
   */
  /* tslint:enable:max-line-length */
  @Input()
  @HostBinding('attr.elevation')
  public get elevation(): number {
    return this._elevation;
  }
  public set elevation(v: number) {
    this._elevation = v;
  }
  // #endregion
}
