import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { OrganizationalContainerSources } from '@trustedshops/tswp-carrier-b2b-contracts';
import { BootstrapperBase, LocalStorageService, TOKENS as TOKENS_COMMON } from '@trustedshops/tswp-core-common';
import { OrganizationalContainerSelectionService, TOKENS, RendererInfo, OrganizationalContainer } from '@trustedshops/tswp-core-masterdata';
import {
  DefaultOrganizationalContainerRendererComponent
} from '../web-components/default-organizational-container-renderer/default-organizational-container-renderer.component';
import { DefaultOrganizationalContainerSource } from '../services/default-organizational-container.source';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { RxJsBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import { BehaviorSubject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { StartPluginBootstrapper } from '@trustedshops/tswp-carrier-core';
import { NavigationBarService } from '../services/navigation-bar.service';
import { OrganizationalContainerSelectorInfo } from '@trustedshops/tswp-core-ui';


@Injectable()
export class OrganizationalContainerMemoryBootstrapper implements BootstrapperBase {
  //#region PRivate fields
  private readonly _containersStorageKey: string = 'containers-selected';
  private readonly _localStorageScope: string = 'tswp-carrier-b2b';
  private _initialNavigation: Promise<void>;
  //#endregion

  //#region Ctor
  public constructor(
    private readonly _pluginsBootstrapper: StartPluginBootstrapper,
    private readonly _location: Location,
    private readonly _activatedRoute: ActivatedRoute,

    @Inject(DOCUMENT)
    private readonly _document: Document,

    private readonly _router: Router,

    @Inject(TOKENS_COMMON.LocalStorageService)
    private readonly _localStorageService: LocalStorageService,

    @Inject(TOKENS.OrganizationalContainerSelectionService)
    private readonly _organizationalContainerSelector: OrganizationalContainerSelectionService,

    private readonly _navigationBarService: NavigationBarService) {
  }
  //#endregion

  //#region Public Methods
  public async initialize(): Promise<void> {
    this.initializeAsynchronously();
  }

  private async initializeAsynchronously(): Promise<void> {
    await this._pluginsBootstrapper.completed;
    await this.getInitialNavigation();

    if (!await this.loadFromQueryParameter()) {
      await this.loadPersistedContainerSelection();
    }

    this.subscribeToRoute();
    this.subscribeToUserSelection();
  }

  public subscribeToUserSelection(): void {
    this._organizationalContainerSelector
      .getSelection()
      .subscribe(currentSelection =>
        this.updateSelectionStorages(currentSelection));
  }

  private async loadPersistedContainerSelection(): Promise<void> {
    const persistedSelection = this._localStorageService
      .createScoped(this._localStorageScope)
      .select<string[]>(this._containersStorageKey);

    if (!persistedSelection) {
      await this.selectContainersById([]);
      return;
    }

    await this.selectContainersById(persistedSelection);
  }

  private async loadFromQueryParameter(): Promise<boolean> {
    const queryParams = await this._activatedRoute.queryParams
      .pipe(take(1))
      .toPromise();

    if (!queryParams['channels']) {
      return false;
    }

    this.selectContainersById(queryParams['channels']);
    return true;
  }

  private async subscribeToRoute(): Promise<void> {
    this._router.events
      .pipe(filter(x => x instanceof NavigationEnd))
      .subscribe(async () => {
        const channels = await this.getQueryParameter();
        if (!channels) {
          const selection = await new Promise<OrganizationalContainer<any>[]>(resolve =>
            this._organizationalContainerSelector
              .getSelection<any>()
              .subscribe(resolve));

          this.updateRouteParameter(selection);
        }
      });
  }

  private async selectContainersById(channels: string[]): Promise<void> {
    if (!channels) {
      this._organizationalContainerSelector.selectContainers([]);
      return;
    }

    const containers = await this._organizationalContainerSelector.getAllContainers()
      .convertWith(RxJsBridge(BehaviorSubject))
      .pipe(filter(x => !!x))
      .pipe(take(1))
      .toPromise();

    const selectedContainers = containers.filter(x => channels.includes(x.id));
    this._organizationalContainerSelector.selectContainers(selectedContainers);
  }

  private async updateSelectionStorages(selection: OrganizationalContainer<unknown>[]): Promise<void> {
    await this.updateRouteParameter(selection);

    this._localStorageService
      .createScoped(this._localStorageScope)
      .insertOrUpdate(this._containersStorageKey, selection.map(x => x.id));
  }

  private async organizationalContainerActive(): Promise<boolean> {
    const configuration = await new Promise<OrganizationalContainerSelectorInfo>(resolve =>
      this._navigationBarService.organizationalContainerSelector.subscribe(resolve));

    return configuration !== null;
  }

  private async updateRouteParameter(selection: OrganizationalContainer<any>[]): Promise<void> {
    const currentQueryParams: any = {};
    const urlSearchParams = new URLSearchParams(this._document.location.search);
    urlSearchParams.forEach((value, key) => {
      currentQueryParams[key] = value;
    });

    if (!(await this.organizationalContainerActive())) {
      if (currentQueryParams.channels) {
        this._router.navigate([], {
          queryParams: { channels: null },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      }

      return;
    }

    const channels = await this.getQueryParameter();

    if (this.isEqualSelection(channels, selection.map(x => x.id))) {
      return;
    }

    const queryParams = Object.assign(
      currentQueryParams,
      { channels: null });


    if (selection.length) {
      queryParams.channels = [selection[0].id];
    }

    await this._pluginsBootstrapper.completed;
    await this._initialNavigation;

    this._router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  public async getQueryParameter(): Promise<string[]> {
    const currentParams = await this._activatedRoute.queryParams
      .pipe(take(1))
      .toPromise();

    return currentParams['channels'];
  }

  public isEqualSelection(currentChannels: string[], selection: string[]): boolean {
    if (currentChannels === selection) {
      return true;
    }

    if (!currentChannels && selection) {
      return false;
    }

    if (currentChannels && !selection) {
      return false;
    }

    const channelsLengthIsEqual = currentChannels.length === selection.length;
    if (!channelsLengthIsEqual) {
      return false;
    }

    const allChannelsIncluded = currentChannels
      .every(channel => selection.includes(channel));

    if (!allChannelsIncluded) {
      return false;
    }

    return true;
  }

  private async getInitialNavigation(): Promise<void> {
    while (this._router.url !== this._location.path()) {
      await this._router.events
        .pipe(filter(x => x instanceof NavigationEnd))
        .pipe(take(1))
        .toPromise();
    }
  }
  //#endregion
}

export const OrganizationalContainerBootstrapper = [
  OrganizationalContainerMemoryBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: OrganizationalContainerMemoryBootstrapper) => {
      return () => initializer.initialize();
    },
    deps: [OrganizationalContainerMemoryBootstrapper]
  }, {
    provide: TOKENS.DefaultOrganizationalContainerSource,
    useFactory: source => source,
    deps: [OrganizationalContainerSources.DefaultOrganizationalContainerSource]
  }, {
    provide: TOKENS.DefaultOrganizationalContainerRenderer,
    useValue: {
      webComponent: DefaultOrganizationalContainerRendererComponent.selector,
      plugin: null
    } as RendererInfo
  }, {
    provide: OrganizationalContainerSources.DefaultOrganizationalContainerSource,
    useClass: DefaultOrganizationalContainerSource
  }, {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase&OrganizationalContainerSelectionService) => {
      return () => initializer.initialize();
    },
    deps: [TOKENS.OrganizationalContainerSelectionService]
  }];
