/**
 * Describes a tooltip's position
 */
export enum TooltipPosition {
  /**
   * Position the tooltip above the wrapped element
   */
  Above = 'above',

  /**
   * Position the tooltip below the wrapped element
   */
  Below = 'below',

  /**
   * Position the tooltip right to the wrapped element
   */
  Right = 'right',

  /**
   * Position the tooltip left to the wrapped element
   */
  Left = 'left'
}
