import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoDv: CultureInfo = {
  name: 'dv',
  displayName: 'Dhivehi',
  iso3LanguageCode: 'div',
  iso2LanguageCode: 'dv',
  ietfLanguageTag: 'dv',
  parent: null
};
