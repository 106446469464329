import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  LogService,
  TimerService,
  TOKENS,
} from '@trustedshops/tswp-core-common';
import { catchError, EMPTY, from, switchMap } from 'rxjs';
import { FeedbackStarter } from '../../models/feedback.interface';
import { FeedbackService } from '../../services/feedback.service';

@Component({
  selector: 'feedback-modal',
  templateUrl: './feedback-modal.component.html',
  styleUrls: ['./feedback-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class FeedbackModalComponent implements OnInit {
  public static readonly TYPE = 'FeedbackModalComponent';

  @ViewChild('questionnaireIframe') public questionnaireIframe: ElementRef;

  @Input() starter: FeedbackStarter | null;

  private _questionnaireURL: SafeResourceUrl;
  public get questionnaireURI(): SafeResourceUrl {
    return this._questionnaireURL;
  }
  public set questionnaireURI(v: SafeResourceUrl) {
    this._questionnaireURL = v;
  }

  private _questionnairePromise: Promise<string>;
  public get questionnairePromise(): Promise<string> {
    return this._questionnairePromise;
  }
  public set questionnairePromise(v: Promise<string>) {
    this._questionnairePromise = v;
  }

  private _isUrlAssigned = false;
  public get isUrlAssigned(): boolean {
    return this._isUrlAssigned;
  }
  public set isUrlAssigned(v: boolean) {
    this._isUrlAssigned = v;
  }

  private _isReady = false;
  public get isReady(): boolean {
    return this._isReady;
  }
  public set isReady(v: boolean) {
    this._isReady = v;
  }

  private _hasError = false;
  public get hasError(): boolean {
    return this._hasError;
  }
  public set hasError(v: boolean) {
    this._hasError = v;
  }

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public readonly onClose = new EventEmitter();

  public constructor(
    private readonly _sanitizer: DomSanitizer,
    private readonly _feedbackService: FeedbackService,
    @Inject(TOKENS.LogService)
    private readonly _logService: LogService,
    @Inject(TOKENS.TimerService)
    private readonly _timerService: TimerService
  ) {}

  ngOnInit() {
    if (!this.starter) {
      const error = new Error(
        'No starter provided when opening feedback modal'
      );
      throw this._logService.error(FeedbackModalComponent.TYPE, error);
    } else {
      from(this.loadQuestionnaire())
        .pipe(
          catchError((error) => {
            this.hasError = true;
            // TODO: remove close and replace with error screen once received from UX
            console.error(error);
            this.onClose.emit();
            return EMPTY;
          }),
          switchMap(() => {
            this.isUrlAssigned = true;
            return from(this.awaitIFrame());
          })
        )
        .subscribe(() => {
          this._logService.debug(FeedbackModalComponent.TYPE, 'Iframe ready');

          this._logService.debug(
            FeedbackModalComponent.TYPE,
            'Feedback modal initialized'
          );

          this.isReady = true;
        });
    }
  }

  public handleClose() {
    this._logService.debug(
      FeedbackModalComponent.TYPE,
      'Feedback modal closed'
    );
    this.onClose.emit();
  }

  private async loadQuestionnaire(): Promise<void> {
    this._logService.debug(
      FeedbackModalComponent.TYPE,
      'Loading questionnaire'
    );

    const maxRetries = 3;
    let tryCounter = 1;

    do {
      try {
        if (tryCounter > 1) {
          this._logService.debug(
            FeedbackModalComponent.TYPE,
            `Retry loading questionnaire. Try ${tryCounter}`
          );
          await this._timerService.sleep(500);
        }

        this.questionnairePromise =
          this._feedbackService.requestQuestionnaireURI(this.starter);

        this.questionnaireURI = this._sanitizer.bypassSecurityTrustResourceUrl(
          await this._questionnairePromise
        );

        break;
      } catch (error) {
        if (tryCounter === maxRetries) {
          throw this._logService.error(
            FeedbackModalComponent.TYPE,
            error as Error
          );
        }

        this._logService.debug(
          FeedbackModalComponent.TYPE,
          `Error loading questionnaire. Try ${tryCounter}`
        );

        tryCounter++;
      }
    } while (tryCounter <= maxRetries);

    this.questionnairePromise = undefined;

    this._logService.debug(FeedbackModalComponent.TYPE, 'Questionnaire loaded');
  }

  private async awaitIFrame() {
    this._logService.debug(
      FeedbackModalComponent.TYPE,
      'Awaiting iframe to be loaded'
    );

    const iframe = await this.waitForIframeElement();

    return await new Promise<void>((resolve) => {
      iframe.onload = () => {
        this._logService.debug(FeedbackModalComponent.TYPE, 'Iframe loaded');
        resolve();
      };
    });
  }

  private async waitForIframeElement(): Promise<HTMLIFrameElement> {
    let iframe: HTMLIFrameElement;

    let counter = 0;

    do {
      if (counter > 10) {
        throw new Error('Iframe not found');
      }

      await this._timerService.sleep(100);

      if (this.questionnaireIframe === undefined) {
        counter++;
        continue;
      }

      iframe = this.questionnaireIframe.nativeElement as HTMLIFrameElement;
    } while (!iframe);

    return iframe;
  }
}
