import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoTrv: CultureInfo = {
  name: 'trv',
  displayName: 'patas Taroko',
  iso3LanguageCode: 'trv',
  iso2LanguageCode: 'trv',
  ietfLanguageTag: 'trv',
  parent: null
};
