import { CultureInfo } from '../culture-info.model';
import { CultureInfoSe } from './se';

/**
 * @ignore
 */
export const CultureInfoSeNO: CultureInfo = {
  name: 'se-NO',
  displayName: 'Northern Sami (Norway)',
  iso3LanguageCode: 'sme',
  iso2LanguageCode: 'se',
  ietfLanguageTag: 'se-NO',
  parent: CultureInfoSe
};
