import { Event } from '@trustedshops/tswp-core-common';
import { BusyState } from '../../models/busy/busy-state.interface';

/**
 * A service for managing controls that show progress animations
 */
export interface BusyService {
  /**
   * Queues a new operation to a specific region, causing the progress and the state to be recalculated.
   * The region will considered as "isBusy" as soon as it has one `operationProgress` stream in its queue
   * that is not completed yet.
   *
   * @param region The unique key for the region that should be updated
   * @param operationProgressStream A value indicating if the target state is "isBusy"
   */
  queueOperation(region: string, operationProgressStream: Event<number>): void;

  /**
   * Gets the state of the specified region
   *
   * @param region The region to get the status for
   * @returns The status of the region
   */
  getBusyState(region: string): Event<BusyState>;
}
