import { CultureInfo } from '../culture-info.model';
import { CultureInfoKab } from './kab';

/**
 * @ignore
 */
export const CultureInfoKabDZ: CultureInfo = {
  name: 'kab-DZ',
  displayName: 'Taqbaylit (Lezzayer)',
  iso3LanguageCode: 'kab',
  iso2LanguageCode: 'kab',
  ietfLanguageTag: 'kab-DZ',
  parent: CultureInfoKab
};
