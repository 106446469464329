import { ResourceInfo } from '@trustedshops/tswp-core-common';

/**
 * Request Model holding information about loading a plugin
 */
export interface LoadPluginRequest {
  /**
   * Name of the plugin. Must be unique
   */
  name: string;

  /**
   * Scripts of the plugin to load.
   */
  scripts?: ResourceInfo[];

  /**
   * Styles of the plugin to load.
   */
  styles?: ResourceInfo[];

  /**
   * Defines wether the plugin should be loaded on boot of the host
   */
  autostart: boolean;

  /**
   * Defines wether the plugin should be loaded on boot of the host even if the account is not verified
   */
  autostartUnverifiedAccount?: boolean;

  /**
   * Declares the base path of the plugin
   */
  basePath: string;

  /**
   * Declares the dependencies of the plugin
   */
  dependencies: string[];

  /**
   * Gets the URL all plugin files are located at
   */
  pluginStorageUrl: string;
}
