import { CultureInfo } from '../culture-info.model';
import { CultureInfoCs } from './cs';

/**
 * @ignore
 */
export const CultureInfoCsCZ: CultureInfo = {
  name: 'cs-CZ',
  displayName: 'Czech (Czechia)',
  iso3LanguageCode: 'ces',
  iso2LanguageCode: 'cs',
  ietfLanguageTag: 'cs-CZ',
  parent: CultureInfoCs
};
