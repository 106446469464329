import { CultureInfo } from '../culture-info.model';
import { CultureInfoNnh } from './nnh';

/**
 * @ignore
 */
export const CultureInfoNnhCM: CultureInfo = {
  name: 'nnh-CM',
  displayName: 'Shwóŋò ngiembɔɔn (Kàmalûm)',
  iso3LanguageCode: 'nnh',
  iso2LanguageCode: 'nnh',
  ietfLanguageTag: 'nnh-CM',
  parent: CultureInfoNnh
};
