import { CultureInfo } from '../culture-info.model';
import { CultureInfoHi } from './hi';

/**
 * @ignore
 */
export const CultureInfoHiIN: CultureInfo = {
  name: 'hi-IN',
  displayName: 'Hindi (India)',
  iso3LanguageCode: 'hin',
  iso2LanguageCode: 'hi',
  ietfLanguageTag: 'hi-IN',
  parent: CultureInfoHi
};
