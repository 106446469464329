import { CultureInfo } from '../culture-info.model';
import { CultureInfoAzLatn } from './az-Latn';

/**
 * @ignore
 */
export const CultureInfoAzLatnAZ: CultureInfo = {
  name: 'az-Latn-AZ',
  displayName: 'Azerbaijani (Latin, Azerbaijan)',
  iso3LanguageCode: 'aze',
  iso2LanguageCode: 'az',
  ietfLanguageTag: 'az-Latn-AZ',
  parent: CultureInfoAzLatn
};
