import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import {
  ChildTranslationService,
  TOKENS,
} from '@trustedshops/tswp-core-common';

@Component({
  selector: 'lib-upgrade-plus',
  templateUrl: './upgrade-plus.component.html',
  styleUrls: ['./upgrade-plus.component.scss'],
  /**
   * TODO: Styles of ViewEncapsulation.None styles are are copied inside this component,
   * it should be checked if the ViewEncapsulation.None is necessary in other components.
   */
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false,
})
export class UpgradePlusComponent implements OnInit {
  public static readonly selector: string = 'tswp-upgrade-plus';

  public label = 'PLUS';

  public constructor(
    @Inject(TOKENS.TranslationService)
    public readonly translationService: ChildTranslationService
  ) {}

  public ngOnInit(): void {
    this.translationService.useChildSpecificTranslations();
    this.translationService
      .getTranslationForKey('upgrade.plus.label')
      .subscribe(translate => {
        this.label =
          translate && translate !== 'upgrade.plus.label'
            ? translate
            : this.label;
      });
  }
}
// INFO: added dummy comment to test the pipeline release
