import { Type } from '../system/type';

/**
 * Defines a mapping between an export token and a service type
 */
export interface ExportTokenDefinition<T> {
  /**
   * The export token
   */
  token: string;

  /**
   * The exported type
   */
  type: Type<T>;
}
