import { CultureInfo } from '../culture-info.model';
import { CultureInfoSo } from './so';

/**
 * @ignore
 */
export const CultureInfoSoSO: CultureInfo = {
  name: 'so-SO',
  displayName: 'Somali (Somalia)',
  iso3LanguageCode: 'som',
  iso2LanguageCode: 'so',
  ietfLanguageTag: 'so-SO',
  parent: CultureInfoSo
};
