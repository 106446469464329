import { LocaleFileNameResolverDelegate } from './locale-file-name-resolver.delegate';
import { TranslationFile } from './translation-file.model';
import { TranslationKeyResolverDelegate } from './translation-key-resolver.delegate';

/**
 * Model representing the translation service configuration.
 */
export interface TranslationServiceConfiguration<T = TranslationFile> {
  /**
   * Override the default behaviour when resolving translation keys.
   *
   * Default behavior is accesing the translation file as flat object with `file[key]`.
   *
   * Example translation file:
   * ```json
   * {
   *    "key": "translation1",
   *    "key.separated.by.dots": "translation2"
   * }
   * ```
   */
  translationKeyResolver?: TranslationKeyResolverDelegate<T>;

  /**
   * Override the default behaviour when resolving locale file names.
   *
   * *Default resolution is:*
   *
   * `(locale) => 'assets/i18n/' + locale + '.json'`
   *
   * *Example:*
   *
   * `assets/i18n/de-DE.json`
   *
   * **=== NOTE ===**
   *
   * When returning relative paths the locale file is pulled from the artifacts.
   *
   * When returning absolute paths the path is used as is.
   *
   */
  localeFileNameResolver?: LocaleFileNameResolverDelegate;
}
