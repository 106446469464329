/**
 * Enumeration of commonly used target user groups
 */
export enum TargetUserGroup {
  /**
   * Declares the application to be consumer facing
   */
  BusinessToConsumer = 'b2c',

  /**
   * Declares the application to be business/customer facing
   */
  BusinessToBusiness = 'b2b',

  /**
   * Declares the application to be an internal one.
   */
  Employees = 'employees'
}
