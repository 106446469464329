/**
 * Describes how the notification was dismissed
 */
export enum NotificationDismissTriggerType {
  /**
   * The notification was dismissed using one of the control's dismiss action triggers.
   */
  CloseAction = 'close-action',

  /**
   * The notification was dismissed programatically.
   */
  Programmatic = 'programmatic'
}
