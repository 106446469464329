import { CultureInfo } from '../culture-info.model';
import { CultureInfoYav } from './yav';

/**
 * @ignore
 */
export const CultureInfoYavCM: CultureInfo = {
  name: 'yav-CM',
  displayName: 'nuasue (Kemelún)',
  iso3LanguageCode: 'yav',
  iso2LanguageCode: 'yav',
  ietfLanguageTag: 'yav-CM',
  parent: CultureInfoYav
};
