import { CultureInfo } from '../culture-info.model';
import { CultureInfoSw } from './sw';

/**
 * @ignore
 */
export const CultureInfoSwCD: CultureInfo = {
  name: 'sw-CD',
  displayName: 'Kiswahili (Jamhuri ya Kidemokrasia ya Kongo)',
  iso3LanguageCode: 'swa',
  iso2LanguageCode: 'sw',
  ietfLanguageTag: 'sw-CD',
  parent: CultureInfoSw
};
