import { CultureInfo } from '../culture-info.model';
import { CultureInfoCeb } from './ceb';

/**
 * @ignore
 */
export const CultureInfoCebPH: CultureInfo = {
  name: 'ceb-PH',
  displayName: 'Cebuano (Pilipinas)',
  iso3LanguageCode: 'ceb',
  iso2LanguageCode: 'ceb',
  ietfLanguageTag: 'ceb-PH',
  parent: CultureInfoCeb
};
