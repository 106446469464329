import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { HubspotService } from './hubspot.service';

export interface HubspotConfig {
  baseUrl: string;
  authToken: string;
  portalId: string;
  fullContactFormId: string;
  shortContactFormId: string;
  ctaContactFormId: string;
}

export const HUBSPOT_CONFIG = new InjectionToken<HubspotConfig>(
  'HUBSPOT_CONFIG'
);

@NgModule()
export class HubspotModule {
  public static forRoot(
    config: HubspotConfig
  ): ModuleWithProviders<HubspotModule> {
    return {
      ngModule: HubspotModule,
      providers: [
        HubspotService,
        { provide: HUBSPOT_CONFIG, useValue: config },
      ],
    };
  }
}
