import { CultureInfo } from '../culture-info.model';
import { CultureInfoShiLatn } from './shi-Latn';

/**
 * @ignore
 */
export const CultureInfoShiLatnMA: CultureInfo = {
  name: 'shi-Latn-MA',
  displayName: 'Tashelḥiyt (Latn, lmɣrib)',
  iso3LanguageCode: 'shi',
  iso2LanguageCode: 'shi',
  ietfLanguageTag: 'shi-Latn-MA',
  parent: CultureInfoShiLatn
};
