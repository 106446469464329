import { CultureInfo } from '../culture-info.model';
import { CultureInfoSi } from './si';

/**
 * @ignore
 */
export const CultureInfoSiLK: CultureInfo = {
  name: 'si-LK',
  displayName: 'Sinhala (Sri Lanka)',
  iso3LanguageCode: 'sin',
  iso2LanguageCode: 'si',
  ietfLanguageTag: 'si-LK',
  parent: CultureInfoSi
};
