import { CultureInfo } from '../culture-info.model';
import { CultureInfoYue } from './yue';

/**
 * @ignore
 */
export const CultureInfoYueHant: CultureInfo = {
  name: 'yue-Hant',
  displayName: '廣東話',
  iso3LanguageCode: 'yue',
  iso2LanguageCode: 'yue',
  ietfLanguageTag: 'yue-Hant',
  parent: CultureInfoYue
};
