import {
  Component,
  ElementRef,
  EventEmitter,
  Inject, Input, OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  ChildTranslationService,
  TOKENS as COMMON_TOKENS,
} from '@trustedshops/tswp-core-common';
import {
  Identity,
  IdentityService,
  TOKENS as AUTH_TOKENS,
} from '@trustedshops/tswp-core-authorization';
import {
  TOKENS as CORE_TOKENS,
  UpgradePopupService
} from '@trustedshops/tswp-core-ui';
import {
  TOKENS as TOKENS_ANALYTICS,
  TrackingService,
} from '@trustedshops/tswp-core-analytics';
import { switchMap, filter, map, tap, catchError } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { RxJsSubjectBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import { AbstractPersistentEvent } from '@trustedshops/tswp-core-common';
import { noWhitespaceValidator } from '../../common/no-whitespace.validator';
import { internationalPhoneNumberValidator } from '../../common/international-phone-number.validator';
import { HubspotService } from '../../services/hubspot.service';
import { HUBSPOT_CONFIG, HubspotConfig } from '../../services/hubspot.module';

interface FormRawValue {
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
}

export type UpgradePageComponentVariant = 'page' | 'sidebar';

@Component({
  selector: 'upgrade-page',
  templateUrl: './upgrade-page.component.html',
  styleUrls: ['./upgrade-page.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false,
})
export class UpgradePageComponent implements OnInit {
  public static readonly selector: string = 'tswp-upgrade-page';

  public isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public user: BehaviorSubject<any | undefined> = new BehaviorSubject<
    any | undefined
  >(undefined);

  public form: FormGroup;

  @ViewChild('contactFormRef') public contactFormRef: ElementRef;

  @Input() public variant: UpgradePageComponentVariant = 'page';

  @Output()
  public submitHubspotForm: EventEmitter<void> = new EventEmitter<void>();

  public constructor(
    @Inject(COMMON_TOKENS.TranslationService)
    public readonly translationService: ChildTranslationService,
    @Inject(AUTH_TOKENS.IdentityService)
    private readonly identityService: IdentityService,
    @Inject(HUBSPOT_CONFIG) private readonly hubspotConfig: HubspotConfig,
    @Inject(CORE_TOKENS.UpgradePopupService) readonly upgradePopupService: UpgradePopupService,
    @Inject(TOKENS_ANALYTICS.TrackingService)
    private readonly trackingService: TrackingService,
    private readonly hubspotService: HubspotService,
    private readonly formBuilder: FormBuilder
  ) {
    this.initForm();
    this.getUser();
  }

  public ngOnInit(): void {
    const event_label = this.variant === 'page' ? 'inline' : 'navi_button';

    this.trackingService.trackEvent({
      eventType: 'event',
      eventParameters: [
        'UPGRADE_VIEW',
        {
          event_category: 'freeaccount',
          event_action: 'Landingpage',
          event_label
        },
      ],
    });
  }

  public scrollToForm(): void {
    this.contactFormRef.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
    });
  }

  public getControl(field: string): FormControl {
    return this.form.get(field) as FormControl;
  }

  public isFieldInvalid(field: string): boolean {
    return this.getControl(field).invalid && this.getControl(field).dirty;
  }

  public handleFullContactFormSubmit(): void {
    this.isLoading.next(true);
    const { firstname, lastname, email, phone }: FormRawValue =
      this.form.getRawValue();

    const formData = {
      fields: [
        {
          name: 'firstname',
          value: firstname,
        },
        {
          name: 'lastname',
          value: lastname,
        },
        {
          name: 'email',
          value: email,
        },
        {
          name: 'source',
          value: 'FREE_ACCOUNT_GETINTOUCH',
        },
      ],
    };

    if (phone) {
      formData.fields.push({
        name: 'phone',
        value: phone,
      });
    }

    this.hubspotService
      .submitForm(this.hubspotConfig.fullContactFormId, formData)
      .pipe(
        tap(() => {
          this.isLoading.next(false);
          this.initForm();

          if (this.variant === 'page') {
            this.upgradePopupService.open('thank-you');
          } else {
            this.submitHubspotForm.emit();
          }
        }),
        tap(() => {
          this.trackingService.trackEvent({
            eventType: 'event',
            eventParameters: [
              'sign_up',
              {
                event_category: 'freeaccount',
                event_action: 'Landingpage',
              },
            ],
          });
        }),
        catchError(error => {
          this.isLoading.next(false);

          return of(error);
        })
      )
      .subscribe();
  }

  public handleCTAFormSubmit(buttonId: 'top_button' | 'bottom_button'): void {

    this.scrollToForm();

    const formData = {
      fields: [
        {
          name: 'email',
          value: this.user.getValue()?.email,
        },
        {
          name: 'source',
          value: 'FREE_ACCOUNT_SHOWEDINTEREST',
        },
      ],
    };

    this.hubspotService
      .submitForm(this.hubspotConfig.ctaContactFormId, formData)
        .pipe(
            tap(() => {
              this.trackingService.trackEvent({
                eventType: 'event',
                eventParameters: [
                  'CTA_CLICK',
                  {
                    event_category: 'freeaccount',
                    event_action: buttonId,
                  },
                ],
              });
            })
        )
      .subscribe();
  }

  private initForm(): void {
    this.form = this.formBuilder.group({
      firstname: ['', [Validators.required, noWhitespaceValidator]],
      lastname: ['', [Validators.required, noWhitespaceValidator]],
      email: [
        '',
        [Validators.required, Validators.email, noWhitespaceValidator],
      ],
      phone: ['', [internationalPhoneNumberValidator]],
    });
  }

  private getUser(): void {
    this.identityService.identity
      .convertWith<Observable<Identity<any>>>(RxJsSubjectBridge)
      .pipe(
        filter((identity: Identity<any>) => !!identity),
        map(
          (identity: Identity<any>) => identity.profile.keycloak.token.payload
        ),
        switchMap((payload: AbstractPersistentEvent<any>) =>
          payload.convertWith<Observable<any>>(RxJsSubjectBridge)
        ),
        tap(user => {
          this.user.next(user);
        })
      )
      .subscribe();
  }
}
