import { Component, Inject, OnInit } from '@angular/core';
import { NavigateUserInteractionHandler, UriLocationType } from '@trustedshops/tswp-core-ui';
import { UserInteractionComponentBase } from '../user-interaction-component-base';
import { Dictionary } from '@trustedshops/tswp-core-common';
import { DOCUMENT } from '@angular/common';

/**
 * Component rendering a navigate interaction
 */
@Component({
  selector: 'navigate-interaction',
  templateUrl: 'navigate-interaction.component.html',
  standalone: false,
})
export class NavigateInteractionComponent extends UserInteractionComponentBase<NavigateUserInteractionHandler> implements OnInit {
  public readonly UriLocationType: typeof UriLocationType = UriLocationType;
  public userFlowId: string;

  /**
   * Gets the uri of the interaction, including its query string
   */
  public get uri(): string {
    if (this.interaction.uriLocationType !== UriLocationType.External) {
      return this.interaction.uri;
    }

    return this.addQueryString(this.interaction.uri, this.interaction.queryParams);
  }

  public constructor(
    @Inject(DOCUMENT)
    private readonly _document: Document) {
    super();
  }

  public ngOnInit(): void {
    this.userFlowId = this.uriToId(this.uri);
  }

  public uriToId(path: string, maxLength = 100): string {
    const cleanPath = path.trim().replace(/^\/+|\/+$/g, '');

    let snakeCase = cleanPath
        .replace(/([a-z])([A-Z])/g, '$1_$2')
        .replace(/[^a-zA-Z0-9]+/g, '_')
        .toLowerCase();

    const maxSnakeCaseLength = Math.max(0, maxLength);

    if (snakeCase.length > maxSnakeCaseLength) {
      const lastUnderscoreIndex = snakeCase.lastIndexOf('_', maxSnakeCaseLength - 1);

      snakeCase = snakeCase.substr(0, lastUnderscoreIndex > 0 ? lastUnderscoreIndex : maxSnakeCaseLength);
    }

    snakeCase = snakeCase.replace(/_+$/, '');

    return snakeCase;
  }

  /**
   * Appends a URI to a query string
   * @param uri The uri to append the query string to
   * @param queryParams The query parameters to append
   */
  private addQueryString(uri: string, queryParams: Dictionary): string {
    const queryString = Object.keys(queryParams)
      .map(key => `${key}=${this.encode(queryParams[key].toString())}`)
      .join('&');

    return `${uri}${queryString ? `?${queryString}` : ''}`;
  }

  private encode(value: string): string {
    const window: Window & typeof globalThis = this._document.defaultView as any;
    return window.encodeURIComponent(value);
  }
}
