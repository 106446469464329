/**
 * An enum describing a pre-defined button style, provided by the Helios design system.
 */
export enum ButtonSize {
  /**
   * Big button size. See https://www.figma.com/file/BvD5AM7aQKUSkSo696RKJ8/Helios-Business-Products
   */
  Big = 'big',

  /**
   * Default button size. See https://www.figma.com/file/BvD5AM7aQKUSkSo696RKJ8/Helios-Business-Products
   */
  Default = 'default',

  /**
   * Small button size. See https://www.figma.com/file/BvD5AM7aQKUSkSo696RKJ8/Helios-Business-Products
   */
  Small = 'small'
}
