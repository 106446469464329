import { CultureInfo } from '../culture-info.model';
import { CultureInfoLb } from './lb';

/**
 * @ignore
 */
export const CultureInfoLbLU: CultureInfo = {
  name: 'lb-LU',
  displayName: 'Luxembourgish (Luxembourg)',
  iso3LanguageCode: 'ltz',
  iso2LanguageCode: 'lb',
  ietfLanguageTag: 'lb-LU',
  parent: CultureInfoLb
};
