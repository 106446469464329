import { HttpRequest} from './http-request.interface';

import { Event } from '../../../eventing/event.interface';
import { NextInterceptorHandler } from '../../../interception/next-interceptor.handler';
import { HttpEvent } from './http-event.interface';

/**
 * Interface for an HTTP interceptor
 */
export interface HttpInterceptor {
  /**
   * Called before a request is sent
   *
   * @param request The request that is to be sent at the time this interceptor is called
   * @param next The next interceptor that is to be called. If not called, the interception chain is broken.
   */
  beforeRequestSent(
    request: HttpRequest,
    next: NextInterceptorHandler<HttpInterceptor, HttpRequest, HttpEvent<any>>): Event<HttpEvent<any>>;
}
