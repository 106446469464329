import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoIo: CultureInfo = {
  name: 'io',
  displayName: 'Ido',
  iso3LanguageCode: 'ido',
  iso2LanguageCode: 'io',
  ietfLanguageTag: 'io',
  parent: null
};
