import { CultureInfo } from '../culture-info.model';
import { CultureInfoMy } from './my';

/**
 * @ignore
 */
export const CultureInfoMyMM: CultureInfo = {
  name: 'my-MM',
  displayName: 'Burmese (Myanmar [Burma])',
  iso3LanguageCode: 'mya',
  iso2LanguageCode: 'my',
  ietfLanguageTag: 'my-MM',
  parent: CultureInfoMy
};
