import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Action, TimerService, ExportToken, TOKENS } from '@trustedshops/tswp-core-common';

@Injectable()
@ExportToken(TOKENS.TimerService)
export class TimerServiceImpl implements TimerService {
  //#region Private Fields
  private readonly _window: Window;
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(DOCUMENT) document: Document) {
    this._window = document.defaultView;
  }
  //#endregion

  //#region Public Methods
  /**
   * Returns a promise resolving in a given minimum amount of time
   *
   * @param minimumWaitTime The minimum time to wait in milliseconds
   */
  public async sleep(minimumWaitTime: number): Promise<void> {
    return new Promise(resolve => this._window.setTimeout(() => resolve(), minimumWaitTime));
  }

  /**
   * Executes an action was after a given minimum amount of time
   * @param minimumWaitTime The minimum time to wait in milliseconds
   * @param action The action to execute
   *
   * @returns A promise resolving when the action was executed. If the action returns a promise it is awaited before resolution.
   */
  public scheduleAction(minimumWaitTime: number, action: Action): Promise<void> {
    return new Promise(resolve =>
      this._window.setTimeout(async () => {
        const result: any = action();
        if (result && result.then) {
          await result;
        }

        resolve();
      }, minimumWaitTime));
  }

  /**
   * Repetitively executes an action while waiting a given minimum amount of time between executions.
   * The repetition does not consider the actions return values - Promise resolution states are ignored.
   *
   * @param minimumWaitTime The minimum time to wait between the executions in milliseconds
   * @param action The action to execute
   *
   * @returns An action to cancel the repetition
   */
  public scheduleActionRepetition(minimumWaitTime: number, action: Action): Action {
    const intervalCancelHandle = this._window.setInterval(() => action(), minimumWaitTime);
    return () => this._window.clearInterval(intervalCancelHandle);
  }
  //#endregion
}
