import { Pipe, PipeTransform } from '@angular/core';
import { Event } from '@trustedshops/tswp-core-common';
import { RxJsBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';

/**
 * @deprecated this pipe is deprecated and will be removed in the next major version
 */
@Pipe({
  name: 'asObservable',
  standalone: false
})
export class AsObservablePipe implements PipeTransform {
  public transform<T>(value: Event<T>, ...args: any[]): Observable<T> {
    return value.convertWith(RxJsBridge(Subject));
  }
}
