import { CultureInfo } from '../culture-info.model';
import { CultureInfoKl } from './kl';

/**
 * @ignore
 */
export const CultureInfoKlGL: CultureInfo = {
  name: 'kl-GL',
  displayName: 'Kalaallisut (Greenland)',
  iso3LanguageCode: 'kal',
  iso2LanguageCode: 'kl',
  ietfLanguageTag: 'kl-GL',
  parent: CultureInfoKl
};
