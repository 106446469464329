import { CultureInfo } from '../culture-info.model';
import { CultureInfoRu } from './ru';

/**
 * @ignore
 */
export const CultureInfoRuMD: CultureInfo = {
  name: 'ru-MD',
  displayName: 'Russian (Moldova)',
  iso3LanguageCode: 'rus',
  iso2LanguageCode: 'ru',
  ietfLanguageTag: 'ru-MD',
  parent: CultureInfoRu
};
