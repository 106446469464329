import { EventListener } from './event-listener.interface';

/**
 * Defines a bridge between a target framework like RxJs and the Eventing Abstraction Layer
 */
export interface EventListenerBridge<TOutEvent, TEmission> {
  /**
   * Converts an EventListener to the desired target type
   * @param event The event to convert.
   */
  createBridgedEventListener(event: EventListener<TEmission>): TOutEvent;
}
