import { ResourceInfo } from './resource-info.interface';
import { DomResourceLoader } from './dom-resource-loader.interface';
import { ResourceType } from './resource-type.enum';

/**
 * Extensible service responsible for loading and injecting scripts to the dom
 * @exportToken TOKENS.DomResourceService
 */
export interface DomResourceService {
  //#region Public Methods
  /**
   * Loads a resource into the DOM.
   * @param resource Detailed data concering origin, type and many more metadata about the resource.
   */
  loadResource(resource: ResourceInfo): Promise<void>;

  /**
   * Registers a resource loader into the DomResourceService.
   * @param resourceType The type of resources the loader is capable of loading.
   * @param loader The instance of the loader.
   */
  registerLoader(resourceType: ResourceType | string, loader: DomResourceLoader): DomResourceService;
  //#endregion
}
