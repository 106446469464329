/* Ignore because: Config file */
/* istanbul ignore file */
import { Injectable, Inject, APP_INITIALIZER } from '@angular/core';
import { BootstrapperBase } from '@trustedshops/tswp-core-common';
import { TOKENS, VisibilityGuardFactoryRegistry, VisibilityGuards } from '@trustedshops/tswp-core-ui';
import { PermissionVisibilityGuard } from '../services/visibility-guards/permission.visibility-guard.factory';

/**
 * Bootstrapper for activating the rendering of Navigate and Click interaction renderers
 */
@Injectable()
export class VisibilityGuardsBootstrapper implements BootstrapperBase {
  //#region Ctor
  public constructor(
    private readonly _permissionVisibilityGuard: PermissionVisibilityGuard,
    @Inject(TOKENS.VisibilityGuardFactoryRegistry) private readonly _visibilityGuardFactoryRegistry: VisibilityGuardFactoryRegistry) {
  }

  /**
   * Runs this bootstrapper.
   */
  public async initialize(): Promise<void> {
    this._visibilityGuardFactoryRegistry
      .registerVisibilityGuardFactory(
        VisibilityGuards.Permission,
        this._permissionVisibilityGuard);
  }
}

/**
 * Bootstrapper for activating the rendering of Navigate and Click interaction renderers
 */
export const VisibilityGuardsInitializer = [
  VisibilityGuardsBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [VisibilityGuardsBootstrapper]
  }
];
