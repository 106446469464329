/**
 * Event info for an event that is to be tracked
 */
export interface TrackingEventInfo {
  /**
   * Type of the event
   */
  eventType: string;

  /**
   * The additional, optional parameters that are to be sent with this event
   */
  eventParameters?: any[];
}
