import { CultureInfo } from '../culture-info.model';
import { CultureInfoUzArab } from './uz-Arab';

/**
 * @ignore
 */
export const CultureInfoUzArabAF: CultureInfo = {
  name: 'uz-Arab-AF',
  displayName: 'اوزبیک (عربی, افغانستان)',
  iso3LanguageCode: 'uzb',
  iso2LanguageCode: 'uz',
  ietfLanguageTag: 'uz-Arab-AF',
  parent: CultureInfoUzArab
};
