import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKo: CultureInfo = {
  name: 'ko',
  displayName: 'Korean',
  iso3LanguageCode: 'kor',
  iso2LanguageCode: 'ko',
  ietfLanguageTag: 'ko',
  parent: null
};
