import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoDz: CultureInfo = {
  name: 'dz',
  displayName: 'རྫོང་ཁ',
  iso3LanguageCode: 'dzo',
  iso2LanguageCode: 'dz',
  ietfLanguageTag: 'dz',
  parent: null
};
