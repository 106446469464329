import { CultureInfo } from '../culture-info.model';
import { CultureInfoMas } from './mas';

/**
 * @ignore
 */
export const CultureInfoMasKE: CultureInfo = {
  name: 'mas-KE',
  displayName: 'Maa (Kenya)',
  iso3LanguageCode: 'mas',
  iso2LanguageCode: 'mas',
  ietfLanguageTag: 'mas-KE',
  parent: CultureInfoMas
};
