import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoNd: CultureInfo = {
  name: 'nd',
  displayName: 'isiNdebele',
  iso3LanguageCode: 'nde',
  iso2LanguageCode: 'nd',
  ietfLanguageTag: 'nd',
  parent: null
};
