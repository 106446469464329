import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoBez: CultureInfo = {
  name: 'bez',
  displayName: 'Hibena',
  iso3LanguageCode: 'bez',
  iso2LanguageCode: 'bez',
  ietfLanguageTag: 'bez',
  parent: null
};
