import { CultureInfo } from '../culture-info.model';
import { CultureInfoLu } from './lu';

/**
 * @ignore
 */
export const CultureInfoLuCD: CultureInfo = {
  name: 'lu-CD',
  displayName: 'Tshiluba (Ditunga wa Kongu)',
  iso3LanguageCode: 'lub',
  iso2LanguageCode: 'lu',
  ietfLanguageTag: 'lu-CD',
  parent: CultureInfoLu
};
