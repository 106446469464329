import { CultureInfo } from '../culture-info.model';
import { CultureInfoIg } from './ig';

/**
 * @ignore
 */
export const CultureInfoIgNG: CultureInfo = {
  name: 'ig-NG',
  displayName: 'Igbo (Nigeria)',
  iso3LanguageCode: 'ibo',
  iso2LanguageCode: 'ig',
  ietfLanguageTag: 'ig-NG',
  parent: CultureInfoIg
};
