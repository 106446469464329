import { Icon } from '../common/icon';
import { ChildNavigationItem } from './child-navigation-item.interface';
import {
  OrganizationalContainerSelectorConfiguration
} from '../organizational-container-selector/organizational-container-selector-configuration.interface';
import { UserInteractionHandler } from '../user-interaction/handlers/user-interaction-handler';
import { AbstractPersistentEvent } from '@trustedshops/tswp-core-common';
import { NavigationItemBadge } from './navigation-item-badge.interface';

/**
 * Describes a navigation item which can be rendered
 * into one of the available navigation regions
 */
export interface NavigationItem<T = any, TInteraction extends UserInteractionHandler = UserInteractionHandler> {
  /**
   * Gets an event stream emitting true when the item is visible.
   * Emits false when it is not visible.
   * `isVisible` is the cumulated result of `visibilityGuards`.
   * To control visibility, add your custom visibility control
   * logic to the `visibilityGuards` property.
   */
  readonly isVisible: AbstractPersistentEvent<boolean>;

  /**
   * An (observable) array containing event streams emitting
   * if the navigation item should be visible based on specific criteria.
   * The result will be cumulated and provided through `isVisible`.
   *
   * After this navigationItem is being registered, this array
   * is converted to an `ObservableArray` to ensure that
   * lazy added registrations during runtime will also be
   * reflected in `isVisible`.
   */
  readonly visibilityGuards: Array<AbstractPersistentEvent<boolean>>;

  /**
   * Gets or sets a unique identifier for this `NavigationItem`
   * managed by the NavigationService after registration.
   * Do not change this value!
   */
  readonly runtimeId?: string;

  /**
   * Gets or sets a *unique* identifier for this `NavigationItem`. Can be set by the business owner of the registration item.
   */
  id?: string;

  /**
   * Gets or sets an observable value emitting the label text
   */
  label: string;

  /**
   * Gets or sets an observable value emitting the underlying user interaction
   */
  userInteraction: TInteraction;

  /**
   * Gets or sets an observable value emitting the navigation item icon
   */
  icon?: Icon;

  /**
   * Gets or sets an observable value emitting the name for suffix icon shown next to navigation item label
   */
  suffixIcon?: Icon;

  /**
   * Gets or sets an observable value emitting the additional class of the rendered navigation item
   */
  class?: string;

  /**
   * Gets or sets the list of children that this navigation item has
   */
  children?: ChildNavigationItem[];

  /**
   * Gets or sets the order index of this navigation item
   */
  order: number;

  /**
   * Gets or sets a configuration object providing more options based on the navigation item.
   */
  configuration?: T & OrganizationalContainerSelectorConfiguration;

  /**
   * Gets or sets a value indicating wether a small red dot should be shown above the navigation item.
   *
   * If set as `NavigationItemBadge`, there are different options configurable for the badge,
   * such as content or position.
   *
   * Defaults to `false`.
   *
   * Hint: The badge is only shown in navigation regions supported.
   */
  badge?: boolean | NavigationItemBadge;
}
