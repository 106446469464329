import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoHu: CultureInfo = {
  name: 'hu',
  displayName: 'Hungarian',
  iso3LanguageCode: 'hun',
  iso2LanguageCode: 'hu',
  ietfLanguageTag: 'hu',
  parent: null
};
