import { ErrorBase } from '@trustedshops/tswp-core-common';
import { ErrorCode } from '../enums/error-codes';

export class SystemCriticalError extends ErrorBase {
  public readonly errorCode: ErrorCode;

  public constructor(error: Error, errorCode: ErrorCode) {
    const message = `${errorCode}: ${error.message}`;
    super(message, error);
    this.errorCode = errorCode;
    this.stack = error.stack;
  }

  public static forService(
    error: Error,
    errorCode: ErrorCode,
  ): SystemCriticalError {
    return new SystemCriticalError(error, errorCode);
  }
}
