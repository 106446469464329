import { NgModule } from '@angular/core';
import { TOKENS } from '@trustedshops/tswp-core-masterdata';
import { OrganizationalContainerSelectionServiceImpl } from './services/organizational-container-selection.service';
import { OrganizationalContainerProviderImpl } from './services/organizational-container.provider';
import { FeatureBookingServiceImpl } from './services/feature-booking.service';

@NgModule({
  providers: [
    { provide: TOKENS.OrganizationalContainerProvider, useClass: OrganizationalContainerProviderImpl },
    { provide: TOKENS.OrganizationalContainerSelectionService, useClass: OrganizationalContainerSelectionServiceImpl },
    { provide: TOKENS.FeatureBookingService, useClass: FeatureBookingServiceImpl }
  ],
})
export class TswpCoreMasterdataModule { }
