import { UrlSegment } from '@trustedshops/tswp-core-common';
import { ConsumedPath } from './consumed-path.interface';

/**
 * Describes a route that a plugin can define.
 */
export interface PluginRoute {
  /**
   * Gets or sets a unique id for this route to attach child routes at a later point of time.
   */
  id?: string;

  /**
   * If route needs a plugin, the platform ensures that the plugin is loaded.
   */
  plugin?: string;

  /**
   * The path to match against. Cannot be used together with a custom `matcher` function.
   * A URL string that uses router matching notation.
   * Can be a wild card (`**`) that matches any URL (see Usage Notes below).
   * Default is "/" (the root path).
   *
   */
  path?: string;

  /**
   * The path-matching strategy, one of 'prefix' or 'full'.
   * Default is 'prefix'.
   *
   * By default, the router checks URL elements from the left to see if the URL
   * matches a given  path, and stops when there is a match. For example,
   * '/team/11/user' matches 'team/:id'.
   *
   * The path-match strategy 'full' matches against the entire URL.
   * It is important to do this when redirecting empty-path routes.
   * Otherwise, because an empty path is a prefix of any URL,
   * the router would apply the redirect even when navigating
   * to the redirect destination, creating an endless loop.
   *
   */
  pathMatch?: string;

  /**
   * The component to instantiate when the path matches.
   * Can be empty if child routes specify components.
   */
  webComponent?: string;

  /**
   * A URL to which to redirect when a the path matches.
   * Absolute if the URL begins with a slash (/), otherwise relative to the path URL.
   * When not present, router does not redirect.
   */
  redirectTo?: string;

  /**
   * An array of dependency-injection tokens used to look up `CanActivate()`
   * handlers, in order to determine if the current user is allowed to
   * activate the component. By default, any user can activate.
   */
   canActivate?: any[];

  /**
   * An array of DI tokens used to look up `CanActivateChild()` handlers,
   * in order to determine if the current user is allowed to activate
   * a child of the component. By default, any user can activate a child.
   */
  canActivateChild?: any[];

  /**
   * An array of DI tokens used to look up `CanDeactivate()`
   * handlers, in order to determine if the current user is allowed to
   * deactivate the component. By default, any user can deactivate.
   *
   */
  canDeactivate?: any[];

  /**
   * An array of DI tokens used to look up `CanLoad()`
   * handlers, in order to determine if the current user is allowed to
   * load the component. By default, any user can load.
   */
  canLoad?: any[];

  /**
   * Additional developer-defined data provided to the component via
   * `ActivatedRoute`. By default, no additional data is passed.
   */
  data?: any;

  /**
   * Should return a value indicating if the passed segments are matching the route configuration.
   * Used as alternative to `path`. `path` will be taken into consideration first
   *
   * @param segments The url segments to check.
   */
  matcher?(segments: UrlSegment[]): boolean | ConsumedPath;
}
