import { CultureInfo } from '../culture-info.model';
import { CultureInfoPs } from './ps';

/**
 * @ignore
 */
export const CultureInfoPsPK: CultureInfo = {
  name: 'ps-PK',
  displayName: 'پښتو (پاکستان)',
  iso3LanguageCode: 'pus',
  iso2LanguageCode: 'ps',
  ietfLanguageTag: 'ps-PK',
  parent: CultureInfoPs
};
