import { EventListener, EventListenerBridge, PersistingEventListener } from '@trustedshops/tswp-core-common';
import { Subject } from 'rxjs';

export class RxJsSubjectBridge<TEmission> implements EventListenerBridge<Subject<TEmission>, TEmission> {
  //#region Public Methods
  public createBridgedEventListener(event: EventListener<TEmission>): Subject<TEmission> {
    const persistingEventListener = event as PersistingEventListener<TEmission>;
    const currentValueStream = new Subject<TEmission>();
    persistingEventListener
      .subscribe(
        x => currentValueStream.next(x),
        error => currentValueStream.error(error));
    return currentValueStream;
  }
  //#endregion
}
