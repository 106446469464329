import { CultureInfo } from '../culture-info.model';
import { CultureInfoSrLatn } from './sr-Latn';

/**
 * @ignore
 */
export const CultureInfoSrLatnBA: CultureInfo = {
  name: 'sr-Latn-BA',
  displayName: 'Serbian (Latin, Bosnia & Herzegovina)',
  iso3LanguageCode: 'srp',
  iso2LanguageCode: 'sr',
  ietfLanguageTag: 'sr-Latn-BA',
  parent: CultureInfoSrLatn
};
