import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ViewEncapsulation, Component, Input, Inject, ElementRef, HostBinding, AfterViewInit, NgZone } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { WebComponent } from '../../decorators/web-component.decorator';
import { IconCacheService } from '../../services/icon-cache.service';

/**
 * Helios standard icons
 * @doctab "Examples" "icon.component.examples.md"
 */
@WebComponent('hls-icon')
@Component({
  selector: 'ng-hls-icon',
  templateUrl: './icon.component.html',
  encapsulation: ViewEncapsulation.Emulated,
  standalone: false
})
export class IconComponent implements AfterViewInit {
  //#region Properties
  private _iconStyles: HTMLStyleElement;
  private _icon: string;
  private readonly _instanceId: string;

  /**
   * Gets or sets the displayed icon's name
   */
  @Input()
  @HostBinding('attr.icon')
  public get icon(): string {
    return this._icon;
  }
  public set icon(v: string) {
    this._icon = v;
    this.updateIconSource(v);
  }

  private _color: string;
  @Input()
  /**
   * Gets or sets the color of the icon.
   * The color is internally set as fill style attribute for the path element of the SVG element.
   * It can be overridden using CSS.
   */
  public get color(): string {
    return this._color;
  }
  public set color(v: string) {
    this._color = v;
    this.updateStyles();
  }

  private _iconSource: SafeHtml;
  public get iconSource(): SafeHtml {
    return this._iconSource;
  }
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    private readonly _elementRef: ElementRef,
    private readonly _safeUrlService: DomSanitizer,
    private readonly _zone: NgZone,
    private readonly _iconCacheService: IconCacheService) {

    this._iconStyles = this._document.createElement('style') as HTMLStyleElement;
    const element = this._elementRef.nativeElement as HTMLElement;
    element.appendChild(this._iconStyles);

    this._instanceId = (this._document.defaultView.crypto as any).randomUUID()
      .replace(/-/g, '')
      .substring(0, 10);

    element.classList.add(`helios-icon__${this._instanceId}`);
    this.updateStyles();
  }
  //#endregion

  //#region Public Methods
  public ngAfterViewInit(): void {
    const element = this._elementRef.nativeElement as HTMLElement;
    if (!element) {
      return;
    }

    if (this.color !== undefined && this.color !== null) {
      return;
    }

    const color = this.getFontColor(element);
    if (color !== undefined && color !== null) {
      this.color = color;
    }
  }
  //#endregion

  //#region Private Methods
  private updateStyles(): void {
    const elementPrefix = `.helios-icon__${this._instanceId}`;
    const styles = [`
      ${elementPrefix} > div {
        display: inline-block;
        margin-top: -0.125em;
      }

      ${elementPrefix} svg {
        height: 1em;
        width: 1em;
        top: 0.125em;
        position: relative;
      }

      ${elementPrefix} > div > svg > path {
        transition: fill .3s ease-in-out;
      }
    `];

    if (this.color) {
      styles.push(
        `${elementPrefix} svg path {
            fill: ${this.color};
          }`);
    }

    this._iconStyles.innerHTML = styles.join('');
  }

  private async updateIconSource(iconName: string): Promise<void> {
    if (!iconName) {
      this._iconSource = null;
      return;
    }

    const iconSource = await this._iconCacheService.getIcon(iconName);

    this._zone.run(() =>
      this._iconSource = this._safeUrlService.bypassSecurityTrustHtml(iconSource));
  }

  private getFontColor(element: HTMLElement): string {
    const style = this._document.defaultView.getComputedStyle(element);
    const color = style.color;
    if (!color) {
      return null;
    }

    return color;
  }
  //#endregion
}
