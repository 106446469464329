import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoLrc: CultureInfo = {
  name: 'lrc',
  displayName: 'لۊری شومالی',
  iso3LanguageCode: 'lrc',
  iso2LanguageCode: 'lrc',
  ietfLanguageTag: 'lrc',
  parent: null
};
