import { CultureInfo } from '../culture-info.model';
import { CultureInfoSv } from './sv';

/**
 * @ignore
 */
export const CultureInfoSvSE: CultureInfo = {
  name: 'sv-SE',
  displayName: 'Swedish (Sweden)',
  iso3LanguageCode: 'swe',
  iso2LanguageCode: 'sv',
  ietfLanguageTag: 'sv-SE',
  parent: CultureInfoSv
};
