import { CultureInfo } from '../culture-info.model';
import { CultureInfoSmn } from './smn';

/**
 * @ignore
 */
export const CultureInfoSmnFI: CultureInfo = {
  name: 'smn-FI',
  displayName: 'Inari Sami (Finland)',
  iso3LanguageCode: 'smn',
  iso2LanguageCode: 'smn',
  ietfLanguageTag: 'smn-FI',
  parent: CultureInfoSmn
};
