import { CultureInfo } from '../culture-info.model';
import { CultureInfoLg } from './lg';

/**
 * @ignore
 */
export const CultureInfoLgUG: CultureInfo = {
  name: 'lg-UG',
  displayName: 'Luganda (Yuganda)',
  iso3LanguageCode: 'lug',
  iso2LanguageCode: 'lg',
  ietfLanguageTag: 'lg-UG',
  parent: CultureInfoLg
};
