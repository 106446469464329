import { CultureInfo } from '../culture-info.model';
import { CultureInfoHi } from './hi';

/**
 * @ignore
 */
export const CultureInfoHiLatn: CultureInfo = {
  name: 'hi-Latn',
  displayName: 'Hindi',
  iso3LanguageCode: 'hin',
  iso2LanguageCode: 'hi',
  ietfLanguageTag: 'hi-Latn',
  parent: CultureInfoHi
};
