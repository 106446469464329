import { CultureInfo } from '../culture-info.model';
import { CultureInfoBn } from './bn';

/**
 * @ignore
 */
export const CultureInfoBnBD: CultureInfo = {
  name: 'bn-BD',
  displayName: 'Bangla (Bangladesh)',
  iso3LanguageCode: 'ben',
  iso2LanguageCode: 'bn',
  ietfLanguageTag: 'bn-BD',
  parent: CultureInfoBn
};
