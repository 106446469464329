import { AfterContentInit, Component, NgZone } from '@angular/core';
import userFlow from 'userflow.js';
import { environment } from '../../configuration/get-environment.util';
import { UserFlowService } from '../../services/user-flow.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-userflow-trigger',
  templateUrl: './userflow-trigger.component.html',
  styleUrls: ['./userflow-trigger.component.scss'],
  standalone: false
})
export class UserflowTriggerComponent implements AfterContentInit {
  private readonly checklistId: string = environment.userFlow.checklistId;

  public readonly progress$: Observable<number>;
  public isOpened = false;

  public constructor(
    private readonly userFlowService: UserFlowService,
    private readonly _ngZone: NgZone,
  ) {
    this.progress$ = this.userFlowService.progress$;
  }

  public ngAfterContentInit(): void {
    this.isOpened = this.isChecklistInDOM;
    userFlow.on('flowEnded', this.flowEnded.bind(this));
    this.userFlowService.loadProgress();
  }

  public get isChecklistInDOM(): boolean {
    return !!document.getElementById('userflow-ui');
  }

  public toggleChecklist(): void {
    if (this.isChecklistInDOM) {
      this.closeChecklist();
    } else {
      userFlow.start(this.checklistId).then(() => {
        this.isOpened = true;
      });
    }
  }

  public closeChecklist(): void {
    userFlow.endAll().then(() => {
      this.isOpened = false;
    });
  }

  /**
   * Workaround to update the progress after the flow has ended
   * We need to run the change detection manually, because the event is not triggered inside the Angular zone
   * INFO: We have to wait a bit, because the event is triggered before the progress is updated
   */
  private flowEnded() {
    setTimeout(() => {
      this._ngZone.run(() =>
        this.userFlowService.loadProgress()
      );
    }, 1000);
  }
}
