import { UserInteractionRendererProvider, UserInteractionType } from '@trustedshops/tswp-core-ui';
import { InvalidOperationError, ExportToken } from '@trustedshops/tswp-core-common';
import { Type } from '@angular/core';
import { TOKENS } from '../../export-tokens';

/**
 * Provider for registering and requesting user interaction renderers from/of
 */
@ExportToken(TOKENS.UserInteractionRendererProvider)
export class UserInteractionRendererProviderImpl implements UserInteractionRendererProvider {
  //#region Private Fields
  private readonly _knownHandlers: Map<UserInteractionType | string, Type<any>> = new Map();
  //#endregion

  //#region Public Methods
  /**
   * Registers a handler component rendering the specified interaction type
   * @param type The type of the interaction
   */
  public getRenderer(type: UserInteractionType | string): Type<any> {
    if (this._knownHandlers.has(type)) {
      return this._knownHandlers.get(type);
    }

    throw new InvalidOperationError(`The requested interaction type ${type} is not known.`);
  }

  /**
   * Registers a handler component rendering the specified interaction type
   * @param type The type of the interaction
   * @param handler The angular component being hostable for rendering the interaction type
   */
  public registerRenderer(type: UserInteractionType | string, handler: Type<any>): UserInteractionRendererProvider {
    this._knownHandlers.set(type, handler);
    return this;
  }
  //#endregion
}
