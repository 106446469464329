import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoTg: CultureInfo = {
  name: 'tg',
  displayName: 'Tajik',
  iso3LanguageCode: 'tgk',
  iso2LanguageCode: 'tg',
  ietfLanguageTag: 'tg',
  parent: null
};
