import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoJa: CultureInfo = {
  name: 'ja',
  displayName: 'Japanese',
  iso3LanguageCode: 'jpn',
  iso2LanguageCode: 'ja',
  ietfLanguageTag: 'ja',
  parent: null
};
