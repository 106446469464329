import { Event } from '../eventing/event.interface';
import { AbstractPersistentEvent } from '../eventing/abstract-persistent-event';
import { AbstractEvent } from '../eventing/abstract-event';

/**
 * Defines a command, wrapping its logic and execution metadata
 */
export class Command<TIn> {
  //#region Private Fields
  protected readonly _executeMethod: (parameter?: TIn) => Event<void>;
  //#endregion

  //#region Properties
  private _canExecute: Event<boolean>;
  /**
   * Gets a stream inidicating if this command is currently executable
   */
  public get canExecute(): Event<boolean> {
    return this._canExecute;
  }

  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of Command
   *
   * @param executeMethod Method defining the execution logic of this command
   * @param canExecute A stream indicating if the command is capable of execution when calling Command.execute
   */
  public constructor(executeMethod: (parameter?: TIn) => Event<void>, canExecute?: Event<boolean>) {
    this._executeMethod = executeMethod;
    this._canExecute = canExecute || new AbstractPersistentEvent(true);
  }

  //#endregion

  //#region Public Methods
  /**
   * Executes the command
   *
   * @param parameter Paramters to pass to the execution logic
   */
  public execute(parameter?: TIn): Event<void> {
    const abstractEvent = new AbstractEvent<void>();
    const subscription = this._canExecute.subscribe(canExecute => {
      subscription?.unsubscribe();

      if (!canExecute) {
        return;
      }

      const result = this._executeMethod(parameter);
      if (result && typeof result.subscribe === 'function') {
        result.subscribe(() => abstractEvent.emit());
      }
    });

    return abstractEvent;
  }
  //#endregion
}
