import { AbstractPersistentEvent } from '@trustedshops/tswp-core-common';

/**
 * The result of a provider which is not able to provide a page title.
 */
export interface UnprovidedPageTitleResult {
  canProvide: false;
}

/**
 * The result of a provider which is able to provide a page title.
 */
export interface ProvidedPageTitleResult {
  canProvide: true;
  pageTitle: AbstractPersistentEvent<string>;
}

/**
 * The result of a page title provider.
 */
export type PageTitleResult = ProvidedPageTitleResult | UnprovidedPageTitleResult;
