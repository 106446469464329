import { DOCUMENT } from '@angular/common';
import { Injectable, Inject, APP_INITIALIZER } from '@angular/core';
import { BootstrapperBase, LogService, TOKENS } from '@trustedshops/tswp-core-common';

import { LoggingHost } from '../models/logging-host.interface';

/**
 * Bootstrapper for activating the connector between plugins and the platform
 */
@Injectable()
export class LoggingHostBootstrapper implements BootstrapperBase {
  //#region Ctor
  /** ignore */
  public constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(TOKENS.LogService) private readonly _logService: LogService) {
  }
  //#endregion

  //#region Public Methods
  /**
   * Runs this bootstrapper. Applies the PluginHost Interface implementation to Window.
   */
  public async initialize(): Promise<void> {
    const window: LoggingHost = this._document.defaultView as any as LoggingHost;
    window.tswp = Object.assign(window.tswp || {}, {
      logging: this._logService
    });
  }
  //#endregion
}

/**
 * Bootstrapper for activating the connector between plugins and the platform
 */
export const LoggingHostInitializer = [
  LoggingHostBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [LoggingHostBootstrapper]
  }
];
