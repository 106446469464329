import { CultureInfo } from '../culture-info.model';
import { CultureInfoFfLatn } from './ff-Latn';

/**
 * @ignore
 */
export const CultureInfoFfLatnLR: CultureInfo = {
  name: 'ff-Latn-LR',
  displayName: 'Pulaar (Latn, Liberiyaa)',
  iso3LanguageCode: 'ful',
  iso2LanguageCode: 'ff',
  ietfLanguageTag: 'ff-Latn-LR',
  parent: CultureInfoFfLatn
};
