import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoBn: CultureInfo = {
  name: 'bn',
  displayName: 'Bangla',
  iso3LanguageCode: 'ben',
  iso2LanguageCode: 'bn',
  ietfLanguageTag: 'bn',
  parent: null
};
