import { Injectable } from '@angular/core';
import {
  Event,
  EventSubscription,
  ExportToken,
} from '@trustedshops/tswp-core-common';
import {
  RouteSourceService,
  RouteWatcherDelegate,
  TOKENS,
} from '@trustedshops/tswp-core-ui';

@Injectable()
@ExportToken(TOKENS.RouteSourceService)
export class RouteSourceServiceImpl implements RouteSourceService {
  //#region Private Fields
  private readonly _routeSources: Event<string>[] = [];
  private readonly _routeWatchers: RouteWatcherDelegate[] = [];
  private _routeSourceSubscriptions: EventSubscription<string>[] = [];
  //#endregion

  //#region Public Properties
  private _lastKnownRoute: string;
  public get lastKnownRoute(): string {
    return this._lastKnownRoute;
  }
  //#endregion

  //#region Public Methods
  public registerRouteSource(routeSource: Event<any>): void {
    if (this._routeSources.includes(routeSource)) {
      throw new Error('It is not possible to register the same route source more than once.');
    }

    this.removeExistingRouteSourceSubscriptions();

    this._routeSources.push(routeSource);

    this.createRouteSourceSubscriptions();
  }

  public registerRouteWatcher(routeWatcher: RouteWatcherDelegate): void {
    this._routeWatchers.push(routeWatcher);

    if (this._lastKnownRoute) {
      routeWatcher(this._lastKnownRoute);
    }
  }
  //#endregion

  //#region Private Methods
  private removeExistingRouteSourceSubscriptions(): void {
    this._routeSourceSubscriptions.forEach((subscription) =>
      subscription?.unsubscribe()
    );

    this._routeSourceSubscriptions = [];
  }

  private createRouteSourceSubscriptions(): void {
    this._routeSourceSubscriptions = this._routeSources.map(
      (currentRouteSource) =>
        currentRouteSource.subscribe((route) => {
          this._lastKnownRoute = route;
          this._routeWatchers.forEach((routeWatcher) => routeWatcher(route));
        })
    );
  }
  //#endregion
}
