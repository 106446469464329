import { CultureInfo } from '../culture-info.model';
import { CultureInfoKo } from './ko';

/**
 * @ignore
 */
export const CultureInfoKoKP: CultureInfo = {
  name: 'ko-KP',
  displayName: '한국어(조선민주주의인민공화국)',
  iso3LanguageCode: 'kor',
  iso2LanguageCode: 'ko',
  ietfLanguageTag: 'ko-KP',
  parent: CultureInfoKo
};
