import { CultureInfo } from '../culture-info.model';
import { CultureInfoKcg } from './kcg';

/**
 * @ignore
 */
export const CultureInfoKcgNG: CultureInfo = {
  name: 'kcg-NG',
  displayName: 'Katab (NG)',
  iso3LanguageCode: 'kcg',
  iso2LanguageCode: 'kcg',
  ietfLanguageTag: 'kcg-NG',
  parent: CultureInfoKcg
};
