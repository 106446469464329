import { CultureInfo } from '../culture-info.model';
import { CultureInfoTh } from './th';

/**
 * @ignore
 */
export const CultureInfoThTH: CultureInfo = {
  name: 'th-TH',
  displayName: 'Thai (Thailand)',
  iso3LanguageCode: 'tha',
  iso2LanguageCode: 'th',
  ietfLanguageTag: 'th-TH',
  parent: CultureInfoTh
};
