import { InvalidOperationError, ApplicationEnvironment, ApplicationService, ExportToken, TOKENS } from '@trustedshops/tswp-core-common';

/**
 * Service providing information about the applications runtime
 */
@ExportToken(TOKENS.ApplicationService)
export class ApplicationServiceImpl implements ApplicationService {
  //#region Properties
  private _environment: ApplicationEnvironment = null;
  /**
   * Gets data about the environment this application runs in
   */
  public get environment(): ApplicationEnvironment {
    if (!this._environment) {
      // TODO: Insert link to documentation in the exception message.
      throw new InvalidOperationError('ApplicationService has no configured environment yet. Have you set it up before?');
    }
    return this._environment;
  }
  //#endregion

  //#region Public Methods
  /**
   * Initializes this ApplicationService
   * @param environment The environment this application runs in
   */
  public initialize(environment: ApplicationEnvironment): void {
    this._environment = this._environment || environment;
  }
  //#endregion
}
