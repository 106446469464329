import { NgModule } from '@angular/core';
import { PluginServiceImpl } from './services/plugin.service';
import { PluginRouterImpl } from './services/router/plugin-router.service';
import { PluginLoadedRouteGuard } from './services/router/plugin-loaded.route-guard';
import { TOKENS } from '@trustedshops/tswp-core-plugins';
import { RouteContentModule } from './components/route-content/route-content.module';

@NgModule({
  providers: [
    { provide: TOKENS.PluginService, useClass: PluginServiceImpl },
    { provide: TOKENS.PluginRouter, useClass: PluginRouterImpl },
    PluginLoadedRouteGuard
  ],
  imports: [
    RouteContentModule
  ]
})
export class TswpCorePluginsModule { }
