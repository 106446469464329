import { CultureInfo } from '../culture-info.model';
import { CultureInfoCa } from './ca';

/**
 * @ignore
 */
export const CultureInfoCaFR: CultureInfo = {
  name: 'ca-FR',
  displayName: 'català (França)',
  iso3LanguageCode: 'cat',
  iso2LanguageCode: 'ca',
  ietfLanguageTag: 'ca-FR',
  parent: CultureInfoCa
};
