import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKab: CultureInfo = {
  name: 'kab',
  displayName: 'Taqbaylit',
  iso3LanguageCode: 'kab',
  iso2LanguageCode: 'kab',
  ietfLanguageTag: 'kab',
  parent: null
};
