import { CultureInfo } from '../culture-info.model';
import { CultureInfoMer } from './mer';

/**
 * @ignore
 */
export const CultureInfoMerKE: CultureInfo = {
  name: 'mer-KE',
  displayName: 'Kĩmĩrũ (Kenya)',
  iso3LanguageCode: 'mer',
  iso2LanguageCode: 'mer',
  ietfLanguageTag: 'mer-KE',
  parent: CultureInfoMer
};
