import { CultureInfo } from '../culture-info.model';
import { CultureInfoNds } from './nds';

/**
 * @ignore
 */
export const CultureInfoNdsNL: CultureInfo = {
  name: 'nds-NL',
  displayName: 'nds (NL)',
  iso3LanguageCode: 'nds',
  iso2LanguageCode: 'nds',
  ietfLanguageTag: 'nds-NL',
  parent: CultureInfoNds
};
