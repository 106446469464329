import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoAf: CultureInfo = {
  name: 'af',
  displayName: 'Afrikaans',
  iso3LanguageCode: 'afr',
  iso2LanguageCode: 'af',
  ietfLanguageTag: 'af',
  parent: null
};
