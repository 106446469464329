import { CultureInfo } from '../culture-info.model';
import { CultureInfoAr } from './ar';

/**
 * @ignore
 */
export const CultureInfoArMA: CultureInfo = {
  name: 'ar-MA',
  displayName: 'Arabic (Morocco)',
  iso3LanguageCode: 'ara',
  iso2LanguageCode: 'ar',
  ietfLanguageTag: 'ar-MA',
  parent: CultureInfoAr
};
