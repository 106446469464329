import { CultureInfo } from '../culture-info.model';
import { CultureInfoXog } from './xog';

/**
 * @ignore
 */
export const CultureInfoXogUG: CultureInfo = {
  name: 'xog-UG',
  displayName: 'Olusoga (Yuganda)',
  iso3LanguageCode: 'xog',
  iso2LanguageCode: 'xog',
  ietfLanguageTag: 'xog-UG',
  parent: CultureInfoXog
};
