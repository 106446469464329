import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoSg: CultureInfo = {
  name: 'sg',
  displayName: 'Sängö',
  iso3LanguageCode: 'sag',
  iso2LanguageCode: 'sg',
  ietfLanguageTag: 'sg',
  parent: null
};
