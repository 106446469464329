import { CultureInfo } from '../culture-info.model';
import { CultureInfoSa } from './sa';

/**
 * @ignore
 */
export const CultureInfoSaIN: CultureInfo = {
  name: 'sa-IN',
  displayName: 'Sanskrit (India)',
  iso3LanguageCode: 'san',
  iso2LanguageCode: 'sa',
  ietfLanguageTag: 'sa-IN',
  parent: CultureInfoSa
};
