import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoVai: CultureInfo = {
  name: 'vai',
  displayName: 'ꕙꔤ',
  iso3LanguageCode: 'vai',
  iso2LanguageCode: 'vai',
  ietfLanguageTag: 'vai',
  parent: null
};
