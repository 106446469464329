import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoRu: CultureInfo = {
  name: 'ru',
  displayName: 'Russian',
  iso3LanguageCode: 'rus',
  iso2LanguageCode: 'ru',
  ietfLanguageTag: 'ru',
  parent: null
};
