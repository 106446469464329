import { ErrorBase } from '@trustedshops/tswp-core-common';
import { Session } from '../models/session.interface';

/**
 * The exception that is thrown when a session is missing or in an errorneous state
 */
export class SessionError extends ErrorBase {
  //#region Properties
  private _session: Session;
  /**
   * Gets the session that the error was thrown about, if available.
   */
  public get session(): Session {
    return this._session;
  }
  //#endregion

  //#region Ctor
  /**
   * Initializes a new instance of the SessionError class.
   * @param msg The error message that explains the reason for the exception.
   * @param innerError The inner error causing this exception to be triggered, if available
   * @param session The session that the error was thrown about, if available.
   */
  public constructor(msg: string, innerError?: Error, session?: Session) {
    super(`SessionError: ` + msg, innerError);
    this._session = session;
  }
  //#endregion
}
