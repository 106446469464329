import { AbstractEvent } from './abstract-event';
import { AbstractPersistingEventListener } from './abstract-persisting-event-listener';
import { EventSubscription } from './event-subscription.interface';
import { SubscriptionHandler } from './subscription-handler-delegate.type';

/**
 * An event stream inheriting from AbstractEvent but with the ability to remember the last value emitted.
 */
export class AbstractPersistentEvent<TEmission> extends AbstractEvent<TEmission> {
  //#region Properties
  private _value: TEmission;
  /**
   * Gets the last value emitted.
   */
  public get value(): TEmission {
    return this._value;
  }

  public override get listener(): AbstractPersistingEventListener<TEmission> {
    return this._listener as AbstractPersistingEventListener<TEmission>;
  }
  //#endregion

  //#region Constructor
  /**
   * Creates a new instance of an AbstractPersistentEvent
   * @param value The value that should be emitted initially
   */
  public constructor(value: TEmission) {
    super();
    this._listener = new AbstractPersistingEventListener(
      (subscriptionHandler, errorHandler) => this._subscribe(subscriptionHandler, errorHandler),
      subscription => this._remove(subscription),
      () => this.value);

    this._value = value;
  }
  //#endregion

  //#region Public Methods
  /**
   * Emits a value and persists it inside this instance.
   * @param value The value to be emitted
   */
  public override emit(value: TEmission): void {
    this._value = value;

    super.emit(value);
  }
  //#endregion

  //#region Private Methods
  protected override _subscribe(handler: SubscriptionHandler<TEmission>, errorHandler: SubscriptionHandler<any>): EventSubscription<TEmission> {
    this._handleInstantSubscriptionEmission(handler, errorHandler);

    return super._subscribe(handler, errorHandler);
  }

  protected _handleInstantSubscriptionEmission(handler: SubscriptionHandler<TEmission>, errorHandler: SubscriptionHandler<any>): void {
    setTimeout(() => handler(this.value));
  }
  //#endregion
}
