import { CultureInfo } from '../culture-info.model';
import { CultureInfoDe } from './de';

/**
 * @ignore
 */
export const CultureInfoDeBE: CultureInfo = {
  name: 'de-BE',
  displayName: 'Deutsch (Belgien)',
  iso3LanguageCode: 'deu',
  iso2LanguageCode: 'de',
  ietfLanguageTag: 'de-BE',
  parent: CultureInfoDe
};
