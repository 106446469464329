import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ViewEncapsulation, Component, Input, HostBinding } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { WebComponent } from '../../decorators/web-component.decorator';
import { HeliosValueControl } from '../helios-value-control';

import { LabelPosition } from './label-position.enum';

/**
 * Toggle Switches from the Helios design system
 * @doctab "Examples" "toggle-switch.component.examples.md"
 * @default_slot Label for the toggle switch
 */
@WebComponent('hls-toggle-switch')
@Component({
  selector: 'hls-toggle-switch',
  templateUrl: 'toggle-switch.component.html',
  styleUrls: ['toggle-switch.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class ToggleSwitchComponent extends HeliosValueControl<boolean> {
  //#region Public Fields
  /**
   * Gets or sets a value indicating wether this toggle switch is checked.
   * Basically, this property is only a proxy for `value`
   */
  @Input()
  @Input('checked')
  @HostBinding('attr.checked')
  public get checked(): boolean {
    return this._checked;
  }
  public set checked(v: boolean) {
    this._checked = coerceBooleanProperty(v);

    this._changeDetector.detectChanges();
  }

  private _checked: boolean;
  public get checkedInternal(): boolean {
    return this._checked;
  }
  public set checkedInternal(v: boolean) {
    this._checked = coerceBooleanProperty(v);
    this._updateValue(v, true);
  }

  private _disabled = false;
  /**
   * Gets or sets a value indicating wether this toggle switch is disabled.
   */
  @Input()
  @HostBinding('attr.disabled')
  public get disabled(): boolean {
    return this._disabled || null;
  }
  public set disabled(v: boolean) {
    this._disabled = coerceBooleanProperty(v);
  }

  private _labelPosition: LabelPosition = LabelPosition.After;
  /**
   * Gets or sets a the position of the label, relative to the toggle switch control itself.
   */
  @Input()
  @HostBinding('attr.label-position')
  public get labelPosition(): LabelPosition {
    return this._labelPosition;
  }
  public set labelPosition(v: LabelPosition) {
    this._labelPosition = v;
  }
  //#endregion

  //#region Public Methods
  public updateCheckedState($event: MatSlideToggleChange): void {
    this.checkedInternal = $event.checked;
  }
  //#endregion

  //#region Protected Methods
  protected parseValue(value: any): boolean {
    return coerceBooleanProperty(value);
  }
  //#endregion
}
