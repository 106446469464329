import { CultureInfo } from '../culture-info.model';
import { CultureInfoByn } from './byn';

/**
 * @ignore
 */
export const CultureInfoBynER: CultureInfo = {
  name: 'byn-ER',
  displayName: 'ብሊን (ኤርትራ)',
  iso3LanguageCode: 'byn',
  iso2LanguageCode: 'byn',
  ietfLanguageTag: 'byn-ER',
  parent: CultureInfoByn
};
