import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoGaa: CultureInfo = {
  name: 'gaa',
  displayName: 'Gã',
  iso3LanguageCode: 'gaa',
  iso2LanguageCode: 'gaa',
  ietfLanguageTag: 'gaa',
  parent: null
};
