import { ExpansionPanelComponent } from './expansion-panel/expansion-panel.component';
import { MatAccordion } from '@angular/material/expansion';
import { ViewEncapsulation, Component, AfterViewInit, Input, ViewChild, ElementRef, HostBinding } from '@angular/core';
import { WebComponent } from '../../decorators/web-component.decorator';
import { HeliosControl } from '../helios-control';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

/**
 * Expansion Groups / Accordions from the Helios Design system
 * @doctab "Examples" "expansion-group.component.examples.md"
 * @default_slot Child panels
 */
@WebComponent('hls-expansion-group')
@Component({
  selector: 'hls-expansion-group',
  templateUrl: 'expansion-group.component.html',
  styleUrls: ['expansion-group.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class ExpansionGroupComponent extends HeliosControl implements AfterViewInit {
  //#region Public Fields
  private _multi = true;
  /**
   * Gets or sets a value indicating wether this expansion group is
   * capable of expanding multiple panels at the same time
   * Default: `true`
   */
  @Input()
  @HostBinding('attr.multi')
  public get multi(): boolean {
    return this._multi;
  }
  public set multi(v: boolean) {
    this._multi = coerceBooleanProperty(v);
  }

  @ViewChild('slot')
  public slotRef: ElementRef;

  @ViewChild('accordion', { read: MatAccordion })
  public matAccordion: MatAccordion;
  //#endregion

  //#region Public Methods
  public async ngAfterViewInit(): Promise<void> {
    const slotElement: HTMLSlotElement = this.slotRef.nativeElement;
    slotElement.assignedNodes().forEach(element => {
      const expansionPanel = element as any as ExpansionPanelComponent;
      expansionPanel.$accordion = this.matAccordion;
    });
  }
  //#endregion
}
