import { AbstractPersistentEvent } from './abstract-persistent-event';
import { AbstractPersistingEventListener } from './abstract-persisting-event-listener';
import { EventSubscription } from './event-subscription.interface';
import { SubscriptionHandler } from './subscription-handler-delegate.type';

/**
 * An event stream inheriting from AbstractEvent but with the ability to remember the last value emitted.
 */
export class AbstractAsyncPersistentEvent<
  TEmission
> extends AbstractPersistentEvent<TEmission> {
  //#region Properties
  private _initialValueWasSet = false;
  /**
   * Gets a value indicating wether the first value was emitted
   */
  public get initialValueWasSet(): boolean {
    return this._initialValueWasSet;
  }

  public override get listener(): AbstractPersistingEventListener<TEmission> {
    return this._listener as AbstractPersistingEventListener<TEmission>;
  }
  //#endregion

  //#region Constructor
  /**
   * Creates a new instance of an AbstractPersistentEvent
   * @param value The value that should be emitted initially
   */
  public constructor() {
    super(null);

    this._listener = new AbstractPersistingEventListener(
      (subscriptionHandler, errorHandler) =>
        this._subscribe(subscriptionHandler, errorHandler),
      (subscription) => this._remove(subscription),
      () => this.value
    );
  }
  //#endregion

  //#region Public Methods
  /**
   * Emits a value and persists it inside this instance.
   * @param value The value to be emitted
   */
  public override emit(value: TEmission): void {
    this._initialValueWasSet = true;

    super.emit(value);
  }
  //#endregion

  //#region Private Methods
  protected override _subscribe(
    handler: SubscriptionHandler<TEmission>,
    errorHandler: SubscriptionHandler<any>
  ): EventSubscription<TEmission> {
    return super._subscribe(handler, errorHandler);
  }

  protected override _handleInstantSubscriptionEmission(
    handler: SubscriptionHandler<TEmission>,
    errorHandler: SubscriptionHandler<any>
  ): void {
    if (this.initialValueWasSet) {
      setTimeout(() => handler(this.value));
    }
  }
  //#endregion
}
