import { CultureInfo } from '../culture-info.model';
import { CultureInfoFr } from './fr';

/**
 * @ignore
 */
export const CultureInfoFrNC: CultureInfo = {
  name: 'fr-NC',
  displayName: 'français (Nouvelle-Calédonie)',
  iso3LanguageCode: 'fra',
  iso2LanguageCode: 'fr',
  ietfLanguageTag: 'fr-NC',
  parent: CultureInfoFr
};
