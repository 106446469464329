import { CultureInfo } from '../culture-info.model';
import { CultureInfoBsCyrl } from './bs-Cyrl';

/**
 * @ignore
 */
export const CultureInfoBsCyrlBA: CultureInfo = {
  name: 'bs-Cyrl-BA',
  displayName: 'Bosnian (Cyrillic, Bosnia & Herzegovina)',
  iso3LanguageCode: 'bos',
  iso2LanguageCode: 'bs',
  ietfLanguageTag: 'bs-Cyrl-BA',
  parent: CultureInfoBsCyrl
};
