/**
 * Base class for a bootstrapper
 */
export interface BootstrapperBase {
  /**
   * Runs initialization logic this bootstrapper defines
   * @returns A promise resolving when the initialization is finished
   */
  initialize(): Promise<void>;
}
