import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
import { filter, switchMap, takeUntil, tap } from 'rxjs/operators';
import {
  TOKENS,
  UpgradePopupService,
  UpgradePopupViewType,
} from '@trustedshops/tswp-core-ui';

@Component({
  selector: 'app-upgrade-button',
  templateUrl: './upgrade-button.component.html',
  styleUrls: ['./upgrade-button.component.scss'],
  standalone: false
})
export class UpgradeButtonComponent implements OnInit, OnDestroy {
  public readonly isUpgradePopupOpened$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public readonly openPopup$: Subject<void> = new Subject<void>();

  private readonly destroyed$: Subject<void> = new Subject<void>();

  public constructor(
    @Inject(TOKENS.UpgradePopupService)
    readonly upgradePopupService: UpgradePopupService
  ) {}

  @Input()
  public viewType: UpgradePopupViewType = 'sidebar';

  public ngOnInit(): void {
    this.openPopup$
      .pipe(
        takeUntil(this.destroyed$),
        filter(this.canOpenUpgradePopup.bind(this)),
        switchMap(this.openUpgradePopup.bind(this)),
        tap(this.resetUpgradePopupState.bind(this))
      )
      .subscribe();
  }

  public handleUpgradePopupOpen(): void {
    this.openPopup$.next();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.destroyed$.complete();
  }

  private canOpenUpgradePopup(): boolean {
    return !this.isUpgradePopupOpened$.getValue();
  }

  private openUpgradePopup(): Observable<void> {
    const popup = this.upgradePopupService.open(this.viewType);
    this.isUpgradePopupOpened$.next(true);
    return popup.afterClosed$;
  }

  private resetUpgradePopupState(): void {
    this.isUpgradePopupOpened$.next(false);
  }
}
