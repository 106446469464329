import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoBe: CultureInfo = {
  name: 'be',
  displayName: 'Belarusian',
  iso3LanguageCode: 'bel',
  iso2LanguageCode: 'be',
  ietfLanguageTag: 'be',
  parent: null
};
