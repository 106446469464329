import {
  Component,
  Inject,
  OnDestroy,
  ViewEncapsulation, OnInit
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import {
  TOKENS as COMMON_TOKENS,
  EventSubscription,
  LogService,
  TimerService,
} from '@trustedshops/tswp-core-common';
import { FeatureBookingService, TOKENS as MASTERDATA_TOKENS } from '@trustedshops/tswp-core-masterdata';
import { distinctUntilChanged, filter, map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subscription } from 'rxjs';
import { FeedbackStarter } from '../../models/feedback.interface';
import { FeedbackService } from '../../services/feedback.service';

@Component({
  selector: 'feedback-control',
  templateUrl: './feedback-control.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class FeedbackControlComponent implements OnDestroy, OnInit {
  //#region Statics
  private static readonly TYPE = 'FeedbackControlComponent';
  //#endregion

  //#region Properties
  private _popupDismissed: boolean;
  public get popupDismissed(): boolean {
    return this._popupDismissed;
  }
  public set popupDismissed(v: boolean) {
    this._popupDismissed = v;
  }

  private _isFeedbackModalVisible: boolean;
  public get isFeedbackModalVisible(): boolean {
    return this._isFeedbackModalVisible;
  }

  private _currentStarter: FeedbackStarter | null = null;
  public get currentStarter(): FeedbackStarter | null {
    return this._currentStarter;
  }
  public set currentStarter(v: FeedbackStarter | null) {
    this._currentStarter = v;
  }

  private _isVisible: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public get isVisible$(): BehaviorSubject<boolean> {
    return this._isVisible
  }

  private _isFirstLoadDone = false;
  public get isFirstLoadDone(): boolean {
    return this._isFirstLoadDone;
  }
  public set isFirstLoadDone(v: boolean) {
    this._isFirstLoadDone = v;
  }
  //#endregion

  //#region Private properties
  private _subscriptions: (EventSubscription<unknown> | Subscription)[] = [];
  private _isUnverifiedAccount$: Observable<boolean>;
  public get isUnverifiedAccount$(): Observable<boolean> {
    return this._isUnverifiedAccount$;
  }
  //#endregion

  //#region Ctor
  public constructor(
    private readonly _feedbackService: FeedbackService,

    private readonly _router: Router,

    @Inject(COMMON_TOKENS.TimerService)
    private readonly _timerService: TimerService,

    @Inject(COMMON_TOKENS.LogService)
    private readonly _logService: LogService,

    @Inject(MASTERDATA_TOKENS.FeatureBookingService)
    private readonly _featureBookingService: FeatureBookingService
  ) {
    this._isUnverifiedAccount$ = from(this._featureBookingService.isUnverifiedAccount()).pipe(shareReplay(1));
  }

  //#region Public Methods
  public async ngOnInit(): Promise<void> {
    this._feedbackService.initialize();

    this.popupDismissed = this._feedbackService.isPopupHidden();

    this._subscriptions.push(
      this._router.events
        .pipe(filter((event) => event instanceof NavigationStart))
        .pipe(map((event) => event as NavigationStart))
        .pipe(distinctUntilChanged())
        .subscribe(() => {
          this._logService.debug(
            FeedbackControlComponent.TYPE,
            'NavigationEvent started - hiding all feedback components'
          );
          this._isVisible.next(false);
        })
    );

    this._subscriptions.push(
      this._router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .pipe(map((event) => event as NavigationEnd))
        .pipe(distinctUntilChanged())
        .subscribe(async () => {
          this._logService.debug(
            FeedbackControlComponent.TYPE,
            'NavigationEvent finished - showing feedback components after a short delay'
          );

          if (this.isFirstLoadDone) {
            await this._timerService.sleep(500);
          } else {
            this.isFirstLoadDone = true;
            await this._timerService.sleep(1000);
          }

          this._isVisible.next(true);
        })
    );
  }

  public async ngOnDestroy(): Promise<void> {
    this._feedbackService.destroy();
    while (this._subscriptions.length > 0) {
      let subscription = this._subscriptions.pop();
      subscription?.unsubscribe();
      subscription = undefined;
    }
  }

  public showModalFromSleeve() {
    this._logService.debug(
      FeedbackControlComponent.TYPE,
      'Showing feedback modal from sleeve'
    );

    this.showModal(FeedbackStarter.Sleeve);
  }

  public async showModalFromPopup(): Promise<void> {
    this._logService.debug(
      FeedbackControlComponent.TYPE,
      'Showing feedback modal from popup'
    );

    this.showModal(FeedbackStarter.Popup);
    await this.dismissPopup();
  }

  public async dismissPopup(): Promise<void> {
    this._logService.debug(
      FeedbackControlComponent.TYPE,
      'Dismissing feedback popup'
    );

    this._feedbackService.hidePopup();
    this.popupDismissed = this._feedbackService.isPopupHidden();
  }

  public async closeModal(): Promise<void> {
    this._logService.debug(
      FeedbackControlComponent.TYPE,
      'Closing feedback modal'
    );

    this._isFeedbackModalVisible = false;
    this._currentStarter = null;
  }

  private showModal(starter: FeedbackStarter) {
    this._currentStarter = starter;
    this._isFeedbackModalVisible = true;
  }
}
