import { Injectable } from '@angular/core';
import { LogService, LoggingTarget, LogLevel, ExportToken, TOKENS, LogFilter } from '@trustedshops/tswp-core-common';

@Injectable()
@ExportToken(TOKENS.LogService)
export class LogServiceImpl implements LogService {
  //#region Private Fields
  private readonly _loggingTargets: Array<LoggingTarget> = [];
  private readonly _filters: Array<LogFilter> = [];
  private readonly _activatedLogLevels: Array<LogLevel> = [];
  //#endregion

  //#region Public Methods
  public trace(logSource: string, message: string, payload?: any): LogService {
    return this.addEntry(logSource, message, payload, LogLevel.Trace);
  }

  public debug(logSource: string, message: string, payload?: any): LogService {
    return this.addEntry(logSource, message, payload, LogLevel.Debug);
  }

  public information(logSource: string, message: string, payload?: any): LogService {
    return this.addEntry(logSource, message, payload, LogLevel.Information);
  }

  public warning(logSource: string, message: string, payload?: any): LogService {
    return this.addEntry(logSource, message, payload, LogLevel.Warning);
  }

  public error(logSource: string, error: Error, payload?: any): Error {
    this.addEntry(logSource, error as any, [error, payload], LogLevel.Error);
    return error;
  }

  public fatal(logSource: string, error: Error, payload?: any): Error {
    this.addEntry(logSource, error as any, [error, payload], LogLevel.Fatal);
    return error;
  }

  public activateLogLevels(logLevel: LogLevel[]): LogService {
    this._activatedLogLevels.push(...logLevel.filter(x => this._activatedLogLevels.indexOf(x) < 0));
    return this;
  }

  public registerLoggingTarget(loggingTarget: LoggingTarget): LogService {
    this._loggingTargets.push(loggingTarget);
    return this;
  }

  public registerLogFilter(logFilter: LogFilter): LogService {
    this._filters.push(logFilter);

    return this;
  }

  public isLogLevelActive(logLevel: LogLevel): boolean {
    return this._activatedLogLevels.indexOf(logLevel) >= 0;
  }
  //#endregion

  //#region Private Methods
  private formatMessage(logSource: string, message: string, level: LogLevel): string {
    return `[${new Date().toISOString()}] [${level}] [${logSource}] ${message}`;
  }

  private mapLevelToTargetMethod(level: LogLevel): string {
    return new Map<LogLevel, string>([
      [LogLevel.Warning, 'warning'],
      [LogLevel.Trace, 'trace'],
      [LogLevel.Information, 'information'],
      [LogLevel.Fatal, 'fatal'],
      [LogLevel.Error, 'error'],
      [LogLevel.Debug, 'debug']
    ]).get(level);
  }

  //#endregion

  //#region Private Methods
  protected addEntry(logSource: string, message: string, payload: any, level: LogLevel): LogService {
    if (!this.isLogLevelActive(level)) {
      return this;
    }

    if (this._filters.length && !this._filters.every(filter => filter(logSource, message, payload, level))) {
      return this;
    }

    message = this.formatMessage(logSource, message, level);
    this._loggingTargets.forEach(target => target[this.mapLevelToTargetMethod(level)](message, payload));
    return this;
  }
  //#endregion
}
