import { CultureInfo } from '../culture-info.model';
import { CultureInfoIs } from './is';

/**
 * @ignore
 */
export const CultureInfoIsIS: CultureInfo = {
  name: 'is-IS',
  displayName: 'Icelandic (Iceland)',
  iso3LanguageCode: 'isl',
  iso2LanguageCode: 'is',
  ietfLanguageTag: 'is-IS',
  parent: CultureInfoIs
};
