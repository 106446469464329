import { HttpHeaders } from './http-headers.interface';
import { HttpParams } from './http-params.interface';

export interface HttpRequest<T = any> {
  readonly url: string;
  /**
   * The request body, or `null` if one isn't set.
   *
   * Bodies are not enforced to be immutable, as they can include a reference to any
   * user-defined data type. However, interceptors should take care to preserve
   * idempotence by treating them as such.
   */
  readonly body?: T | null;
  /**
   * Outgoing headers for this request.
   */
  readonly headers?: HttpHeaders;
  /**
   * Whether this request should be made in a way that exposes progress events.
   *
   * Progress events are expensive (change detection runs on each event) and so
   * they should only be requested if the consumer intends to monitor them.
   */
  readonly reportProgress?: boolean;
  /**
   * Whether this request should be sent with outgoing credentials (cookies).
   */
  readonly withCredentials?: boolean;
  /**
   * The expected response type of the server.
   *
   * This is used to parse the response appropriately before returning it to
   * the requestee.
   */
  readonly responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
  /**
   * The outgoing HTTP request method.
   */
  readonly method: string;
  /**
   * Outgoing URL parameters.
   */
  readonly params?: HttpParams;
  /**
   * The outgoing URL with all URL parameters set.
   */
  readonly urlWithParams?: string;
}
