import { CultureInfo } from '../culture-info.model';
import { CultureInfoPa } from './pa';

/**
 * @ignore
 */
export const CultureInfoPaAran: CultureInfo = {
  name: 'pa-Aran',
  displayName: 'پنجابی',
  iso3LanguageCode: 'pan',
  iso2LanguageCode: 'pa',
  ietfLanguageTag: 'pa-Aran',
  parent: CultureInfoPa
};
