import { CultureInfo } from '../culture-info.model';
import { CultureInfoTrv } from './trv';

/**
 * @ignore
 */
export const CultureInfoTrvTW: CultureInfo = {
  name: 'trv-TW',
  displayName: 'patas Taroko (TW)',
  iso3LanguageCode: 'trv',
  iso2LanguageCode: 'trv',
  ietfLanguageTag: 'trv-TW',
  parent: CultureInfoTrv
};
