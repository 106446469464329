import { CultureInfo } from '../culture-info.model';
import { CultureInfoAst } from './ast';

/**
 * @ignore
 */
export const CultureInfoAstES: CultureInfo = {
  name: 'ast-ES',
  displayName: 'asturianu (España)',
  iso3LanguageCode: 'ast',
  iso2LanguageCode: 'ast',
  ietfLanguageTag: 'ast-ES',
  parent: CultureInfoAst
};
