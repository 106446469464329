import { Type } from '../system/type';
import { EventListenerBridge } from './event-bridge.interface';
import { EventListener } from './event-listener.interface';
import { EventSubscription } from './event-subscription.interface';
import { RemoveSubscriptionDelegate } from './remove-delegate.type';
import { SubscribeDelegate } from './subscribe-delegate.type';
import { SubscriptionHandler } from './subscription-handler-delegate.type';

/**
 * Abstract class for a plain event stream listener to subscribe to
 */
export class AbstractEventListener<TEmission> implements EventListener<TEmission> {
  //#region Private Fields
  private readonly _subscriptionProxy: SubscribeDelegate<TEmission>;
  private readonly _removeProxy: RemoveSubscriptionDelegate<TEmission>;
  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of an AbstractEventListener
   * @param subscriptionProxy Delegate pointing to the event stream's subscribe logic of a subscription
   * @param removeProxy Delegate pointing to the event stream's remove logic of a subscription
   */
  public constructor(subscriptionProxy: SubscribeDelegate<TEmission>, removeProxy: RemoveSubscriptionDelegate<TEmission>) {
    this._subscriptionProxy = subscriptionProxy;
    this._removeProxy = removeProxy;
  }
  //#endregion

  //#region Public Methods
  /**
   * Subscribes to the event stream
   * @param subscriptionHandler The subscription handler that processes the event occured.
   */
  public subscribe(
    subscriptionHandler: SubscriptionHandler<TEmission>,
    errorHandler?: SubscriptionHandler<Error>): EventSubscription<TEmission> {
    return this._subscriptionProxy(subscriptionHandler, errorHandler);
  }

  /**
   * Converts this abstract event stream to a specific event, e.g. RxJs Observables using a bridge
   *
   * @param bridgeType The bridge to use for converting
   * @param args The arguments to pass to the bridge
   */
  public convertWith<TOut>(bridgeType: Type<EventListenerBridge<TOut, TEmission>>, ...args: any[]): TOut {
    const bridge = new bridgeType(...args);
    return bridge.createBridgedEventListener(this);
  }

  /**
   * Removes the given subscription from the event stream for listening
   * @param subscription The subscription to remove
   */
  public remove(subscription: EventSubscription<TEmission>): void {
    this._removeProxy(subscription);
  }
  //#endregion
}
