import { CultureInfo } from '../culture-info.model';
import { CultureInfoIa } from './ia';

/**
 * @ignore
 */
export const CultureInfoIa001: CultureInfo = {
  name: 'ia-001',
  displayName: 'interlingua (Mundo)',
  iso3LanguageCode: 'ina',
  iso2LanguageCode: 'ia',
  ietfLanguageTag: 'ia-001',
  parent: CultureInfoIa
};
