import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpgradePlusComponent } from './upgrade-plus.component';
import { UpgradePlusInitializer } from './upgrade-plus.bootstrapper';
import { TOKENS, TranslationFile } from '@trustedshops/tswp-core-common';

@NgModule({
  declarations: [
    UpgradePlusComponent,
  ],
  imports: [
    CommonModule,
  ],
  providers: [
    {
      provide: TOKENS.DefaultTranslationServiceConfig,
      useValue: {
        translationKeyResolver: (translations: TranslationFile, key: string) => translations[key],
        localeFileNameResolver: (locale: string) => `assets/tswp-core-ui-implementation/i18n/${locale}.json`
      },
    },
    UpgradePlusInitializer,
  ],
})
export class UpgradePlusModule { }
