import { CultureInfo } from '../culture-info.model';
import { CultureInfoEo } from './eo';

/**
 * @ignore
 */
export const CultureInfoEo001: CultureInfo = {
  name: 'eo-001',
  displayName: 'esperanto (Mondo)',
  iso3LanguageCode: 'epo',
  iso2LanguageCode: 'eo',
  ietfLanguageTag: 'eo-001',
  parent: CultureInfoEo
};
