import {
  BootstrapperBase,
  CurrentCultureInfoProvider,
  TOKENS,
} from '@trustedshops/tswp-core-common';
import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import {
  PluginService,
  TOKENS as TOKENS_PLUGINS,
} from '@trustedshops/tswp-core-plugins';

@Injectable()
export class ExtraOrdinaryServicesBootstrapper implements BootstrapperBase {
  public static readonly TYPE: string = 'ExtraOrdinaryServicesBootstrapper';

  public constructor(
    @Inject(TOKENS_PLUGINS.PluginService)
    private readonly pluginService: PluginService,
    @Inject(TOKENS.CurrentCultureInfoProvider)
    private readonly cultureInfoProvider: CurrentCultureInfoProvider,
  ) {}

  public async initialize(): Promise<void> {
    this.pluginService.enableExtraOrdinaryService(
      TOKENS.CurrentCultureInfoProvider,
      this.cultureInfoProvider,
    );
  }
}

export const ExtraOrdinaryServicesInitializer = [
  ExtraOrdinaryServicesBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [ExtraOrdinaryServicesBootstrapper],
  },
];
