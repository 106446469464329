import { CultureInfo } from '../culture-info.model';
import { CultureInfoGsw } from './gsw';

/**
 * @ignore
 */
export const CultureInfoGswCH: CultureInfo = {
  name: 'gsw-CH',
  displayName: 'Schwiizertüütsch (Schwiiz)',
  iso3LanguageCode: 'gsw',
  iso2LanguageCode: 'gsw',
  ietfLanguageTag: 'gsw-CH',
  parent: CultureInfoGsw
};
