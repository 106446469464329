import { CultureInfo } from '../culture-info.model';
import { CultureInfoCa } from './ca';

/**
 * @ignore
 */
export const CultureInfoCaES: CultureInfo = {
  name: 'ca-ES',
  displayName: 'Catalan (Spain)',
  iso3LanguageCode: 'cat',
  iso2LanguageCode: 'ca',
  ietfLanguageTag: 'ca-ES',
  parent: CultureInfoCa
};
