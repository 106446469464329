import { CultureInfo } from '../culture-info.model';
import { CultureInfoGsw } from './gsw';

/**
 * @ignore
 */
export const CultureInfoGswFR: CultureInfo = {
  name: 'gsw-FR',
  displayName: 'Swiss German (France)',
  iso3LanguageCode: 'gsw',
  iso2LanguageCode: 'gsw',
  ietfLanguageTag: 'gsw-FR',
  parent: CultureInfoGsw
};
