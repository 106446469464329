/**
 * Specific route data for the plugin
 */
export interface PluginRouteData {
  /**
   * The web component that should be rendered by the route
   */
  webComponent: string;

  /**
   * The plugin's name where the web component is loaded from.
   * Optional for rendering non-plugin based web components
   */
  pluginName?: string;
}
