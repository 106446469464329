import { Component, HostBinding, Input, ViewEncapsulation } from '@angular/core';
import { WebComponent } from '../../decorators/web-component.decorator';
import { Dictionary } from '../../models/dictionary.interface';
import { StarRating } from './star-rating.interface';
import { StarSize } from './star-size.enum';

/**
 * Displays a helios styled star rating
 * @doctab "Examples" "star-rating.component.examples.md"
 */
@WebComponent('hls-star-rating')
@Component({
  selector: 'hls-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  preserveWhitespaces: true,
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class StarRatingComponent {
  //#region Properties
  private _centerStars = false;
  /**
   * Gets or sets a value indicating if the stares are aligned centered.
   * Default: `false`
   */
  @Input()
  @HostBinding('attr.center-stars')
  public get centerStars(): boolean {
    return this._centerStars;
  }
  public set centerStars(v: boolean) {
    this._centerStars = v;
  }


  private _starSize: StarSize = StarSize.Default;
  /**
   * Gets or sets the size of the rating stars.
   * Default: `StarSize.Default`
   */
  @Input()
  @HostBinding('attr.star-size')
  public get starSize(): StarSize {
    return this._starSize;
  }
  public set starSize(v: StarSize) {
    this._starSize = v;
  }


  private _starColor = '#ffdc0f';
  /**
   * Gets or sets a custom star color to override the default yellow one (#ffdc0f).
   * Will accept anything that can be assigned to CSS's color property (hex, rgb, rgba, cross-browser color names, etc.)
   */
  @Input()
  @HostBinding('attr.star-color')
  public get starColor(): string {
    return this._starColor;
  }
  public set starColor(v: string) {
    this._starColor = v;
  }

  private _starRating: StarRating[] = [];
  public get starRating(): StarRating[] {
    return this._starRating;
  }
  public set starRating(v: StarRating[]) {
    this._starRating = v;
  }

  private _rating = 0;
  /**
   * Gets or sets the displayed rating
   */
  @Input()
  @HostBinding('attr.rating')
  public get rating(): number {
    return this._rating;
  }
  public set rating(rating: number) {
    this._rating = Number(rating);
    this.starRating = this.starRatingState(this._rating);
  }
  //#endregion


  //#region Public Methods
  public getStarColor(rating: StarRating): Dictionary<string|number> {
    if (!rating.active) {
      return {};
    }

    return {
      color: this.starColor
    };
  }

  public starRatingState(rating: number): StarRating[] {
    let isHalf: boolean;
    let halfStar: number;
    const roundedRating = rating.toFixed(1);
    const before = Number(roundedRating.substr(0, 1));
    const after = Number(roundedRating.substr(2, 1));

    const starRatingState = [];
    for (let i = 1; i <= 5; i++) {
      let activeState = false;
      isHalf = false;
      if (i <= before) {
        activeState = true;
      }
      if (i === before && after >= 5) {
        halfStar = i + 1;
      }
      if (i === halfStar) {
        activeState = true;
        isHalf = true;
      }

      starRatingState.push({
        active: activeState,
        isHalf,
      });
    }
    return starRatingState;
  }
  //#endregion

}
