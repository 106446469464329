import { CultureInfo } from '../culture-info.model';
import { CultureInfoTzm } from './tzm';

/**
 * @ignore
 */
export const CultureInfoTzmMA: CultureInfo = {
  name: 'tzm-MA',
  displayName: 'Tamaziɣt n laṭlaṣ (Meṛṛuk)',
  iso3LanguageCode: 'tzm',
  iso2LanguageCode: 'tzm',
  ietfLanguageTag: 'tzm-MA',
  parent: CultureInfoTzm
};
