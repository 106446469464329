import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoDa: CultureInfo = {
  name: 'da',
  displayName: 'Danish',
  iso3LanguageCode: 'dan',
  iso2LanguageCode: 'da',
  ietfLanguageTag: 'da',
  parent: null
};
