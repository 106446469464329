import { CultureInfo } from '../culture-info.model';
import { CultureInfoCkb } from './ckb';

/**
 * @ignore
 */
export const CultureInfoCkbIQ: CultureInfo = {
  name: 'ckb-IQ',
  displayName: 'کوردیی ناوەندی (عێراق)',
  iso3LanguageCode: 'ckb',
  iso2LanguageCode: 'ckb',
  ietfLanguageTag: 'ckb-IQ',
  parent: CultureInfoCkb
};
