import { CultureInfo } from '../culture-info.model';
import { CultureInfoHa } from './ha';

/**
 * @ignore
 */
export const CultureInfoHaNE: CultureInfo = {
  name: 'ha-NE',
  displayName: 'Hausa (Nijar)',
  iso3LanguageCode: 'hau',
  iso2LanguageCode: 'ha',
  ietfLanguageTag: 'ha-NE',
  parent: CultureInfoHa
};
