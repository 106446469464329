import { CultureInfo } from '../culture-info.model';
import { CultureInfoKde } from './kde';

/**
 * @ignore
 */
export const CultureInfoKdeTZ: CultureInfo = {
  name: 'kde-TZ',
  displayName: 'Chimakonde (Tanzania)',
  iso3LanguageCode: 'kde',
  iso2LanguageCode: 'kde',
  ietfLanguageTag: 'kde-TZ',
  parent: CultureInfoKde
};
