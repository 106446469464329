import { CultureInfo } from '../culture-info.model';
import { CultureInfoTt } from './tt';

/**
 * @ignore
 */
export const CultureInfoTtRU: CultureInfo = {
  name: 'tt-RU',
  displayName: 'Tatar (Russia)',
  iso3LanguageCode: 'tat',
  iso2LanguageCode: 'tt',
  ietfLanguageTag: 'tt-RU',
  parent: CultureInfoTt
};
