import { CultureInfo } from '../culture-info.model';
import { CultureInfoEe } from './ee';

/**
 * @ignore
 */
export const CultureInfoEeGH: CultureInfo = {
  name: 'ee-GH',
  displayName: 'Eʋegbe (Ghana nutome)',
  iso3LanguageCode: 'ewe',
  iso2LanguageCode: 'ee',
  ietfLanguageTag: 'ee-GH',
  parent: CultureInfoEe
};
