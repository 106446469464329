import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoChr: CultureInfo = {
  name: 'chr',
  displayName: 'Cherokee',
  iso3LanguageCode: 'chr',
  iso2LanguageCode: 'chr',
  ietfLanguageTag: 'chr',
  parent: null
};
