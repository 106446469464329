import { TrackerTypes } from '../models/tracker-types.interface';
import { TrackingEventInfo } from '../models/tracking-event-info.model';

export interface TrackingService {
  /**
   * Gets a list of ids of registered trackers
   */
  readonly trackers: string[];

  /**
   * Tracks an event to either all trackers or just the given trackers
   *
   * @param eventInfo The event to send
   * @param trackerIds Optional, filters trackers that receive the event
   */
  trackEvent(
    eventInfo: TrackingEventInfo,
    trackerIds?: string[]
  ): Promise<void>;
  /**
   * Registers a tracker given by type.
   *
   * @param type The type of tracker to register
   * @param trackerConfiguration The configuration initialize the tracker with.
   */
  registerTracker<T extends keyof TrackerTypes>(
    type: T,
    trackerConfiguration: TrackerTypes[T]
  ): void;
}
