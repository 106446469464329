import { Injectable, Inject, APP_INITIALIZER } from '@angular/core';
import { BootstrapperBase } from '@trustedshops/tswp-core-common';
import { PluginHost as BrowserPluginHost , PluginEntryPoint, TOKENS, PluginService } from '@trustedshops/tswp-core-plugins';
import { DOCUMENT } from '@angular/common';
import { PluginEntryPointType } from '@trustedshops/tswp-core-plugins';

/**
 * Bootstrapper for activating the connector between plugins and the platform
 */
@Injectable()
export class BrowserPluginHostBootstrapper implements BootstrapperBase {
  //#region Ctor
  /** ignore */
  public constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(TOKENS.PluginService) private readonly _pluginService: PluginService) {
  }
  //#endregion

  //#region Public Methods
  /**
   * Runs this bootstrapper. Applies the PluginHost Interface implementation to Window.
   */
  public async initialize(): Promise<void> {
    const window: BrowserPluginHost = this._document.defaultView as any as BrowserPluginHost;
    window.tswp = Object.assign(window.tswp || {}, {
      getPluginFilePath: (plugin: string, path: string) => {
        return this._pluginService.getPluginFilePath(plugin, path);
      },
      registerPlugin: (entryPoint: PluginEntryPoint, extraOrdinaryDependencies?: string[]) =>
        this._pluginService.notifyPluginArrived({
          entryPoint,
          extraOrdinaryServices: extraOrdinaryDependencies
        }),
      registerPluginType: (entryPointType: PluginEntryPointType, extraOrdinaryDependencies?: string[]) =>
        this._pluginService.notifyPluginArrived({
          entryPointType,
          extraOrdinaryServices: extraOrdinaryDependencies
        })
    });
  }
  //#endregion
}

/**
 * Bootstrapper for activating the connector between plugins and the platform
 */
export const BrowserPluginHostInitializer = [
  BrowserPluginHostBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [BrowserPluginHostBootstrapper]
  }
];
