import { UpgradePopupRef } from '../../models/upgrade-popup/upgrade-popup-ref.interface';
import { UpgradePopupViewType } from '../../models/upgrade-popup/upgrade-popup-view.type';

export interface UpgradePopupService {
  /**
   * Opens the upgrade popup
   */
  open(viewType: UpgradePopupViewType): UpgradePopupRef;

}
