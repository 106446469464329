import { CultureInfo } from '../culture-info.model';
import { CultureInfoSc } from './sc';

/**
 * @ignore
 */
export const CultureInfoScIT: CultureInfo = {
  name: 'sc-IT',
  displayName: 'Sardu (IT)',
  iso3LanguageCode: 'srd',
  iso2LanguageCode: 'sc',
  ietfLanguageTag: 'sc-IT',
  parent: CultureInfoSc
};
