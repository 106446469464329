import { Inject, Injectable } from '@angular/core';
import {
  Identity,
  IdentityService,
  TOKENS,
} from '@trustedshops/tswp-core-authorization';
import { KeycloakProfileData } from '@trustedshops/tswp-core-authorization-keycloak';
import {
  TOKENS as COMMON_TOKENS,
  LocalStorageService,
  LogService,
} from '@trustedshops/tswp-core-common';
import { toPromise } from '@trustedshops/tswp-core-common-eventing-rxjs';
import { ENVIRONMENT, Environment } from '../models/environment.type';
import { FeedbackStarter } from '../models/feedback.interface';
import { FeedbackDataService } from './feedback-data.service';

@Injectable()
export class FeedbackService {
  //#region Static Fields
  private static readonly localStorageScope: string = 'cc-feedback';
  private static readonly TYPE: string = 'FeedbackService';
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(COMMON_TOKENS.LocalStorageService)
    private readonly _localStorageService: LocalStorageService,

    @Inject(COMMON_TOKENS.LogService)
    private readonly _logService: LogService,

    @Inject(TOKENS.IdentityService)
    private readonly _identityService: IdentityService,

    @Inject(ENVIRONMENT) private readonly _environment: Environment,

    private readonly _feedbackDataService: FeedbackDataService
  ) {}
  //#endregion

  //#region Public Methods
  public initialize(): void {
    this._feedbackDataService.initialize();
  }

  public destroy(): void {
    this._feedbackDataService.destroy();
  }

  public hidePopup(): void {
    const localStorageService = this._localStorageService.createScoped(
      FeedbackService.localStorageScope
    );
    localStorageService.insertOrUpdate('hidePopup', true);
  }

  public isPopupHidden(): boolean {
    const localStorageService = this._localStorageService.createScoped(
      FeedbackService.localStorageScope
    );
    return localStorageService.select<boolean>('hidePopup') ?? false;
  }

  public async requestQuestionnaireURI(
    starter: FeedbackStarter
  ): Promise<string> {
    const requestBody = await this._feedbackDataService.getFeedbackBody(
      starter
    );
    const response = await fetch(this._environment.feedback.api, {
      method: 'POST',
      body: JSON.stringify(requestBody),
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + (await this.getEncodedToken()),
      },
    });

    if (!response.ok) {
      throw this._logService.error(
        FeedbackService.TYPE,
        new Error('Error requesting questionnaire URI')
      );
    }

    const json = await response.json();
    return json.link;
  }
  //#endregion

  //#region Private Methods
  private async getEncodedToken(): Promise<string> {
    const identity = await toPromise<Identity<KeycloakProfileData>>(
      this._identityService.identity
    );
    return await toPromise(identity.profile.keycloak.token.encoded);
  }
  //#endregion
}
