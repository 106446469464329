import { CultureInfo } from '../culture-info.model';
import { CultureInfoZh } from './zh';

/**
 * @ignore
 */
export const CultureInfoZhHans: CultureInfo = {
  name: 'zh-Hans',
  displayName: 'Chinese, Simplified',
  iso3LanguageCode: 'zho',
  iso2LanguageCode: 'zh',
  ietfLanguageTag: 'zh-Hans',
  parent: CultureInfoZh
};
