import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoMfe: CultureInfo = {
  name: 'mfe',
  displayName: 'kreol morisien',
  iso3LanguageCode: 'mfe',
  iso2LanguageCode: 'mfe',
  ietfLanguageTag: 'mfe',
  parent: null
};
