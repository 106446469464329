import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Directive, ElementRef, EventEmitter, HostBinding, Inject, Input, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TswpUiLibraryConfigToken } from '../models/tswp-ui-library-config.token';
import { TswpUiLibraryConfig } from '../models/tswp-ui-library.config';
import { HeliosControl } from './helios-control';

@Directive({
  standalone: false
})
// tslint:disable-next-line: directive-class-suffix
export class HeliosValueControl<T> extends HeliosControl {
  //#region Public Fields
  /**
   * Emitted whenever the underlying value changes.
   * Emissions are either triggered programmatically and/or by user change.
   */
  
  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-native
  public change: EventEmitter<any> = new EventEmitter();
  //#endregion

  //#region Properties
  protected _value: T;
  /**
   * Gets or sets the current value of this control
   */
  @Input()
  @HostBinding('attr.value')
  public get value(): T {
    return this._value;
  }
  public set value(v: T) {
    this._updateValue(v);
  }

  private _innerValue: BehaviorSubject<T>;
  private _innerValueHandle: Observable<T>;
  /**
   * Gets the internally managed value as an observable
   */
  public get innerValue(): Observable<T> {
    return this._innerValueHandle;
  }
  //#endregion


  //#region Ctor
  /**
   * Creates a new instance of a Helios Input Control
   *
   * @param document The document that this control is attached to
   * @param elementRef The control's element reference
   * @param config The configuration that this control was initialized with
   * @param changeDetector The change detector service that this control may use.
   */
   public constructor(
    @Inject(DOCUMENT) document: Document,
    elementRef: ElementRef,
    @Inject(TswpUiLibraryConfigToken) config: TswpUiLibraryConfig,
    protected readonly _changeDetector: ChangeDetectorRef) {
    super(document, elementRef, config);

    this._innerValue = new BehaviorSubject(undefined);
    this._innerValueHandle = this._innerValue.asObservable();
  }
  //#endregion

  //#region Protected Methods
  protected parseValue(value: any): T {
    return value;
  }
  //#endregion

  //#region Private Methods
  public _updateValue(value: T, innerComponentUpdate?: boolean): void {
    this._value = this.parseValue(value);

    if (!innerComponentUpdate) {
      this._innerValue.next(this._value);
    } else {
      this.change.emit(this._value);
      this._changeDetector.detectChanges();
    }
  }
  //#endregion
}
