import { CultureInfo } from '../culture-info.model';
import { CultureInfoEs } from './es';

/**
 * @ignore
 */
export const CultureInfoEsPM: CultureInfo = {
  name: 'es-PM',
  displayName: 'español (San Pedro y Miquelón)',
  iso3LanguageCode: 'spa',
  iso2LanguageCode: 'es',
  ietfLanguageTag: 'es-PM',
  parent: CultureInfoEs
};
