export enum InputType {
  /**
   * Defines a single-line text field
   */
  Text = 'text',

  /**
   * Defines a text field for entering a search string
   */
  Search = 'search',

  /**
   * Defines a password field with a mask for its content
   */
  Password = 'password',

  /**
   * Defines a field for entering a telephone number
   */
  Tel = 'tel',

  /**
   * 	Defines a field for entering a URL
   */
  Url = 'url',

  /**
   * Defines a field for an e-mail address
   */
  Email = 'email',

  /**
   * Defines a file-select field and a "Browse" button (for file uploads)
   */
  File = 'file',

  /**
   * Defines a color picker
   */
  Color = 'color',

  /**
   * Defines a field for entering a number
   */
  Number = 'number',

  /**
   * Defines a range control (like a slider control)
   */
  Range = 'range',

  /**
   * 	Defines a date control (year, month, day (no time))
   */
  Date = 'date',

  /**
   * Defines a date and time control (year, month, day, time (no timezone)
   */
  DatetimeLocal = 'datetime-local',

  /**
   * Defines a week and year control (no timezone)
   */
  Week = 'week',

  /**
   * Defines a month and year control (no timezone)
   */
  Month = 'month',

  /**
   * Defines a control for entering a time (no timezone)
   */
  Time = 'time',

  /**
   * Defines a control for choosing between multiple radio buttons
   */
  Radio = 'radio',

  /**
   * Defines a control for checking a true/false statement in the UI
   */
  Checkbox = 'checkbox'
}
