import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKw: CultureInfo = {
  name: 'kw',
  displayName: 'kernewek',
  iso3LanguageCode: 'cor',
  iso2LanguageCode: 'kw',
  ietfLanguageTag: 'kw',
  parent: null
};
