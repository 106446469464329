import { CultureInfo } from '../culture-info.model';
import { CultureInfoKw } from './kw';

/**
 * @ignore
 */
export const CultureInfoKwGB: CultureInfo = {
  name: 'kw-GB',
  displayName: 'kernewek (Rywvaneth Unys)',
  iso3LanguageCode: 'cor',
  iso2LanguageCode: 'kw',
  ietfLanguageTag: 'kw-GB',
  parent: CultureInfoKw
};
