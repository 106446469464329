import { CultureInfo } from '../culture-info.model';
import { CultureInfoGez } from './gez';

/**
 * @ignore
 */
export const CultureInfoGezET: CultureInfo = {
  name: 'gez-ET',
  displayName: 'ግዕዝኛ (ኢትዮጵያ)',
  iso3LanguageCode: 'gez',
  iso2LanguageCode: 'gez',
  ietfLanguageTag: 'gez-ET',
  parent: CultureInfoGez
};
