import { CultureInfo } from '../culture-info.model';
import { CultureInfoKa } from './ka';

/**
 * @ignore
 */
export const CultureInfoKaGE: CultureInfo = {
  name: 'ka-GE',
  displayName: 'Georgian (Georgia)',
  iso3LanguageCode: 'kat',
  iso2LanguageCode: 'ka',
  ietfLanguageTag: 'ka-GE',
  parent: CultureInfoKa
};
