import { CultureInfo } from '../culture-info.model';
import { CultureInfoZgh } from './zgh';

/**
 * @ignore
 */
export const CultureInfoZghMA: CultureInfo = {
  name: 'zgh-MA',
  displayName: 'ⵜⴰⵎⴰⵣⵉⵖⵜ (ⵍⵎⵖⵔⵉⴱ)',
  iso3LanguageCode: 'zgh',
  iso2LanguageCode: 'zgh',
  ietfLanguageTag: 'zgh-MA',
  parent: CultureInfoZgh
};
