
import { Pipe, PipeTransform } from '@angular/core';

/**
 * A pipe sorting an array by a given property
 */
@Pipe({
  name: 'sort',
  pure: false,
  standalone: false,
})
export class SortPipe implements PipeTransform {
  /**
   * Returns a copy of the input array, sorted by the given proeprty. If the property is not set, the array is sorted by its items.
   *
   * @param value The array that is to be sotred
   * @param property Optionally: The child property to sort by
   */
  public transform(value: any[], property?: string): any[] {
    if (!property) {
      value.sort();
    }

    value.sort((a, b) => {
      if (property && a[property] !== undefined && b[property] !== undefined) {
        return a[property] > b[property]
          ? 1
          : (a[property] < b[property]
            ? -1 : 0);
      }
      return 0;
    });

    return value;
  }
}
