import { ErrorBase } from './error-base';

/**
 * Thrown when requesting an object with a non-existend key
 */
export class KeyNotFoundError extends ErrorBase {
  //#region Properties
  private _missingKey: any;
  /**
   * Gets the duplicate object
   */
  public get missingKey(): any {
    return this._missingKey;
  }
  //#endregion

  //#region Ctor
  /**
   * Initializes a new instance of the KeyNotFoundError class by referencing the non-existend key, providing an error message,
   * and optionally specifying the exception that caused this exception to be thrown.
   * @param missingKey The non-existend key that caused the exception to be thrown.
   * @param availableKeys The keys in the collection that were available instead
   * @param msg The message to appear when the exception is thrown.
   * @param innerError The error that caused the exception to be thrown.
   */
  public constructor(msg: string, missingKey: any, availableKeys: any[], innerError?: Error) {
    super(`KeyNotFoundError: ${msg}. Available keys:\n${JSON.stringify(availableKeys, null, 2)}`, innerError);
    this._missingKey = missingKey;
  }
  //#endregion
}
