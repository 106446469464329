import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoHi: CultureInfo = {
  name: 'hi',
  displayName: 'Hindi',
  iso3LanguageCode: 'hin',
  iso2LanguageCode: 'hi',
  ietfLanguageTag: 'hi',
  parent: null
};
