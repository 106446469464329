import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKpe: CultureInfo = {
  name: 'kpe',
  displayName: 'Kpɛlɛɛ',
  iso3LanguageCode: 'kpe',
  iso2LanguageCode: 'kpe',
  ietfLanguageTag: 'kpe',
  parent: null
};
