import { NgModule } from '@angular/core';
import { NavigationServiceImpl } from './services/navigation.service';
import { TOKENS as TOKENS_CONTRACTS } from '@trustedshops/tswp-core-ui';
import { UserInteractionComponent } from './components/user-interaction/user-interaction.component';
import { NavigateInteractionComponent } from './components/navigate-interaction/navigate-interaction.component';
import { ClickInteractionComponent } from './components/click-interaction/click-interaction.component';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TOKENS } from '../export-tokens';
import { UserInteractionRendererProviderImpl } from './services/user-interaction-renderer.provider';
import { NavigationRegionServiceImpl } from './services/navigation-region.service';
import { BusyServiceImpl } from './services/busy.service';
import { BreadcrumbsCompositionServiceImpl } from './services/breadcrumbs-composition.service';
import { SortPipe } from './pipes/sort.pipe';
import { NavigateFirstInteractionComponent } from './components/navigate-first-interaction/navigate-first-interaction.component';
import { VisibilityGuardFactoryRegistryImpl } from './services/visibility-guard-factory-registry.service';
import { VisibilityGuardsInitializer } from './bootstrappers/visbility-guards.bootstrapper';
import { PermissionVisibilityGuard } from './services/visibility-guards/permission.visibility-guard.factory';
import { BreadcrumbsCompositionBootstrapperInitializer } from './bootstrappers/breadcrumbs-composition.boostrapper';
import { RouteSourceServiceImpl } from './services/route-source.service';
import { PageTitleCompositionBootstrapperInitializer } from './bootstrappers/page-title-composition.bootstrapper';
import { PageTitleCompositionServiceImpl } from './services/page-title-composition.service';
import { TswpCoreUiSharedModule } from './common/tswp-core-ui-shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TswpCoreUiSharedModule
  ],
  exports: [
    UserInteractionComponent,
    NavigateInteractionComponent,
    NavigateFirstInteractionComponent,
    ClickInteractionComponent,
    SortPipe
  ],
  declarations: [
    UserInteractionComponent,
    NavigateInteractionComponent,
    NavigateFirstInteractionComponent,
    ClickInteractionComponent,
    SortPipe,
  ],
  providers: [
    BreadcrumbsCompositionBootstrapperInitializer,
    PageTitleCompositionBootstrapperInitializer,
    VisibilityGuardsInitializer,
    PermissionVisibilityGuard,
    { provide: TOKENS_CONTRACTS.RouteSourceService, useClass: RouteSourceServiceImpl },
    { provide: TOKENS_CONTRACTS.BreadcrumbsCompositionService, useClass: BreadcrumbsCompositionServiceImpl },
    { provide: TOKENS_CONTRACTS.PageTitleCompositionService, useClass: PageTitleCompositionServiceImpl },
    { provide: TOKENS_CONTRACTS.BusyService, useClass: BusyServiceImpl },
    { provide: TOKENS_CONTRACTS.VisibilityGuardFactoryRegistry, useClass: VisibilityGuardFactoryRegistryImpl },
    { provide: TOKENS_CONTRACTS.NavigationRegionService, useClass: NavigationRegionServiceImpl },
    { provide: TOKENS_CONTRACTS.NavigationService, useClass: NavigationServiceImpl },
    { provide: TOKENS.UserInteractionRendererProvider, useClass: UserInteractionRendererProviderImpl },
  ],
})
export class TswpCoreUiModule { }
