import { CultureInfo } from '../culture-info.model';
import { CultureInfoSg } from './sg';

/**
 * @ignore
 */
export const CultureInfoSgCF: CultureInfo = {
  name: 'sg-CF',
  displayName: 'Sängö (Ködörösêse tî Bêafrîka)',
  iso3LanguageCode: 'sag',
  iso2LanguageCode: 'sg',
  ietfLanguageTag: 'sg-CF',
  parent: CultureInfoSg
};
