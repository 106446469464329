import { HlsButtonModule } from '../controls/button/button.module';
import { HlsCardModule } from '../controls/card/card.module';
import { HlsExpansionGroupModule } from '../controls/expansion-group/expansion-group.module';
import { HlsIconModule } from '../controls/icon/icon.module';
import { HlsInputModule } from '../controls/input/input.module';
import { HlsLoadingIndicatorModule } from '../controls/loading/loading-indicator.module';
import { HlsMenuModule } from '../controls/menu/menu.module';
import { HlsNotificationModule } from '../controls/notification/notification.module';
import { HlsSelectModule } from '../controls/select/select.module';
import { HlsStarRatingModule } from '../controls/star-rating/star-rating.module';
import { HlsStatusBadgeModule } from '../controls/status-badge/status-badge.module';
import { HlsToggleButtonGroupModule } from '../controls/toggle-button-group/toggle-button-group.module';
import { HlsToggleSwitchModule } from '../controls/toggle-switch/toggle-switch.module';
import { HlsTooltipModule } from '../controls/tooltip/tooltip.module';

/**
 * List of all controls available
 */
export const allControls = [
  HlsIconModule,
  HlsButtonModule,
  HlsCardModule,
  HlsInputModule,
  HlsSelectModule,
  HlsToggleButtonGroupModule,
  HlsToggleSwitchModule,
  HlsNotificationModule,
  HlsStarRatingModule,
  HlsLoadingIndicatorModule,
  HlsStatusBadgeModule,
  HlsTooltipModule,
  HlsExpansionGroupModule,
  HlsMenuModule
];
