import { PluginRoute } from '../models/plugin-route.interface';
import { PluginContext } from '../models/plugin-context.interface';
import { AbstractPersistentEvent } from '@trustedshops/tswp-core-common';
import { RouteOperationDelegate } from '../models/route-operation.delegate';
import { PluginRegistration } from '../models/plugin-registration.interface';

/**
 * Service Responsible for handling routes of plugins
 */
export interface PluginRouter {
  /**
   * Gets an observable string inidicating the current route
   */
  readonly currentRoute: AbstractPersistentEvent<string>;

  /**
   * Broadcasts the currently active route
   *
   * @param url The url that is currently active, including the base url
   * @returns A Promise that resolves to 'true' when navigation succeeds, to 'false' when navigation fails, or is rejected on error.
   */
  updateRoute(url: string): Promise<boolean>;

  /**
   * Add routes
   *
   * @deprecated This method is deprecated and will be removed in the next major release.
   * The plugin context parameter will not be considered anymore.
   * Please use `addRoutes` instead of this method. The route will be rendered with the
   * context of the plugin defined in the route itself.
   *
   * @param routes Array of Routes
   * @param pluginContext The plugin context of the route's component to render
   */
  registerRoutes(routes: PluginRoute[], pluginContext?: PluginContext, parentId?: string): void;

  /**
   * Add routes
   *
   * @param routes Array of Routes
   */
  addRoutes(routes: PluginRoute[], parentId?: string): PluginRouter;

  /**
   * Overrides how creation of new routes are handled. Can only be used once in a runtime.
   *
   * @param overrideFunction The handler that updates the internal routing framework.
   */
  overrideCreateRouteHandler(overrideFunction: (defaultHandler: RouteOperationDelegate) => RouteOperationDelegate): void;

  /**
   * Gets the plugin registration of the currently active route.
   *
   * @returns The plugin registration of the currently active route or undefined if plugin can't be resolved.
   */
  getPluginRegistrationOfActiveRoute(): PluginRegistration | undefined;
}
