import { CultureInfo } from '../culture-info.model';
import { CultureInfoSw } from './sw';

/**
 * @ignore
 */
export const CultureInfoSwUG: CultureInfo = {
  name: 'sw-UG',
  displayName: 'Kiswahili (Uganda)',
  iso3LanguageCode: 'swa',
  iso2LanguageCode: 'sw',
  ietfLanguageTag: 'sw-UG',
  parent: CultureInfoSw
};
