import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import {
  TOKENS as CORE_TOKENS,
  UpgradePopupService,
  UpgradePopupViewType,
} from '@trustedshops/tswp-core-ui';
import {
  ChildTranslationService,
  TOKENS as COMMON_TOKENS,
} from '@trustedshops/tswp-core-common';
import {
  TOKENS as TOKENS_ANALYTICS,
  TrackingService,
} from '@trustedshops/tswp-core-analytics';
import { catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { UpgradePopupRefImpl } from './upgrade-popup-ref';
import { UPGRADE_POPUP_VIEW_TYPE } from './upgrade-popup.service';
import { noWhitespaceValidator } from '../../common/no-whitespace.validator';
import { HubspotService } from '../../services/hubspot.service';
import { HUBSPOT_CONFIG, HubspotConfig } from '../../services/hubspot.module';

@Component({
  selector: 'tswp-upgrade-popup',
  templateUrl: './upgrade-popup.component.html',
  styleUrls: ['./upgrade-popup.component.scss'],
  standalone: false,
})
export class UpgradePopupComponent implements OnInit {
  private isVisible = false;
  public readonly emailControl: FormControl;
  public readonly isLoading: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );

  public constructor(
    public popupRef: UpgradePopupRefImpl,
    @Inject(UPGRADE_POPUP_VIEW_TYPE) public viewType: UpgradePopupViewType,
    @Inject(COMMON_TOKENS.TranslationService)
    public readonly translationService: ChildTranslationService,
    @Inject(HUBSPOT_CONFIG) private readonly hubspotConfig: HubspotConfig,
    private hubspotService: HubspotService,
    private formBuilder: FormBuilder,
    @Inject(CORE_TOKENS.UpgradePopupService)
    readonly upgradePopupService: UpgradePopupService,
    @Inject(TOKENS_ANALYTICS.TrackingService)
    private readonly trackingService: TrackingService,
  ) {
    this.emailControl = this.formBuilder.control('', {
      validators: [
        Validators.required,
        Validators.email,
        noWhitespaceValidator,
      ],
      updateOn: 'blur'
    });
  }

  public ngOnInit(): void {
    this.openWithDelay();

    this.trackingService.trackEvent({
      eventType: 'event',
      eventParameters: [
        'UPGRADE_VIEW',
        {
          event_category: 'freeaccount',
          event_action: 'Popup',
        },
      ],
    });
  }

  public onOverlayClicked(event: MouseEvent): void {
    event.stopPropagation();
    this.closeWithDelay();
  }

  public onDialogClicked(event: MouseEvent): void {
    event.stopPropagation();
  }

  public onCloseClicked(event: MouseEvent): void {
    event.stopPropagation();
    this.closeWithDelay();
  }

  public get overlayClass(): Record<string, boolean> {
    return {
      ['overlay-modal']: this.viewType === 'modal',
      ['overlay-sidebar']: this.viewType === 'sidebar',
    };
  }

  public get dialogClass(): Record<string, boolean> {
    return {
      ['dialog-modal']: this.viewType === 'modal',
      ['dialog-sidebar']: this.viewType === 'sidebar',
      ['dialog-thank-you']: this.viewType === 'thank-you',
      ['sidebar-visible']:
        this.isVisible === true && this.viewType === 'sidebar',
    };
  }

  public closeWithDelay(fn?: () => void): void {
    this.isVisible = false;

    setTimeout(() => {
      this.popupRef.close();
      if (typeof fn === 'function') {
        fn();
      }
    }, 300);
  }

  public handleShortContactFormSubmit(): void {
    this.emailControl.updateValueAndValidity();

    if (this.emailControl.invalid) {
      return;
    }

    this.isLoading.next(true);

    const formData = {
      fields: [
        {
          name: 'email',
          value: this.emailControl.value,
        },
        {
          name: 'source',
          value: 'FREE_ACCOUNT_GETINTOUCH',
        },
      ],
    };

    this.hubspotService
        .submitForm(this.hubspotConfig.shortContactFormId, formData)
        .pipe(
            tap(() => {
              this.isLoading.next(false);
              this.emailControl.reset('');
              this.popupRef.close();
              this.upgradePopupService.open('thank-you');
            }),
            tap(() => {
              this.trackingService.trackEvent({
                eventType: 'event',
                eventParameters: [
                  'sign_up',
                  {
                    event_category: 'freeaccount',
                    event_action: 'Popup',
                  },
                ],
              });
            }),
            catchError(error => {
              this.isLoading.next(false);
              return of(error);
        })
      )
      .subscribe();
  }

  public handleFullContactFormSubmit(): void {
    this.closeWithDelay(() => {
      this.upgradePopupService.open('thank-you');
    });
  }

  private openWithDelay(): void {
    setTimeout(() => {
      this.isVisible = true;
    }, 300);
  }
}
