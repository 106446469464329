import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoEs: CultureInfo = {
  name: 'es',
  displayName: 'Spanish',
  iso3LanguageCode: 'spa',
  iso2LanguageCode: 'es',
  ietfLanguageTag: 'es',
  parent: null
};
