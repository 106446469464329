import { ErrorBase } from './error-base';

/**
 * The exception that is thrown when one of the arguments provided to a method is not valid.
 */
export class ArgumentError extends ErrorBase {
  //#region Ctor
  /**
   * Initializes a new instance of the ArgumentError class.
   * @param msg The error message that explains the reason for the exception.
   */
  public constructor(msg: string, innerError?: Error) {
    super(`ArgumentError: ` + msg, innerError);
  }
  //#endregion
}
