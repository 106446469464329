import {
  Identity,
  Session,
  SessionInterceptor,
} from '@trustedshops/tswp-core-authorization';
import { Event, NextInterceptorHandler } from '@trustedshops/tswp-core-common';

/**
 * Base class for a session interceptor
 */
export abstract class SessionInterceptorBase implements SessionInterceptor {
  /**
   * Gets the order index of this Interceptor
   */
  public abstract get order(): number;

  /**
   * Called upon load of a session of which the user is considered as authenticated.
   *
   * @param session The session that has been loaded
   * @param next The next interceptor that is to be called. If not called, the interception chain is broken.
   */
  public onSessionStart(
    session: Session,
    next: NextInterceptorHandler<SessionInterceptor, Session>,
  ): Event<Session> {
    return next.handle(session);
  }

  /**
   * Called when the session ended or the initial load of the session results in an unauthenticated state.
   * @param next the next interceptor to call. If not called, the interception chain will be broken.
   */
  public onSessionEnd(
    next: NextInterceptorHandler<SessionInterceptor, void>,
  ): Event<void> {
    return next.handle();
  }

  /**
   * Called upon load of an identity. This method may update or change the identity during the interception chain
   *
   * @param identity The identity that has been loaded
   * @param next The next interceptor that is to be called. If not called, the interception chain is broken.
   */
  public onIdentityReceived(
    identity: Identity<any>,
    next: NextInterceptorHandler<SessionInterceptor, Identity<any>>,
  ): Event<Identity<any>> {
    return next.handle(identity);
  }
}
