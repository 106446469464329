import { ChildTranslationService } from './child-translation.service';
import { TranslationService } from './translation.service';

/**
 * The top most translation service instance provided by the platform instance.
 */
export interface RootTranslationService extends TranslationService {

  /**
   * Create a child scoped translation service instance.
   *
   * @params scope A scope identifier.
   * @returns A scoped translation service instance.
   */
  createChild(scope: string): ChildTranslationService;
}
