import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LogService, TOKENS } from '@trustedshops/tswp-core-common';
import { HUBSPOT_CONFIG, HubspotConfig } from './hubspot.module';

export interface HubspotFormField {
  name: string;
  value: any;
}

export interface HubspotFormContext {
  pageUri?: string;
  pageName?: string;
}

export interface HubspotFormData {
  fields: HubspotFormField[];
  context?: HubspotFormContext;
}

export interface SubmitHubspotFormOptions {
  pageUri?: string;
  pageName?: string;
  portalId?: string;
}

@Injectable()
export class HubspotService {
  private static readonly TYPE: string = 'HubspotService';

  public constructor(
    @Inject(TOKENS.LogService) private readonly logService: LogService,
    @Inject(HUBSPOT_CONFIG) private readonly config: HubspotConfig,
    private readonly http: HttpClient
  ) { }

  public submitForm(
    formId: string,
    formData: HubspotFormData,
    options?: SubmitHubspotFormOptions
  ): Observable<any> {
    const portalId = options?.portalId ?? this.config.portalId;
    const pageUri = options?.pageUri ?? window.location.href;
    const pageName = options?.pageName ?? document.title;
    const url = `${this.config.baseUrl}/submissions/v3/integration/submit/${portalId}/${formId}`;

    if (!formData.context) {
      formData.context = {
        pageUri,
        pageName
      };
    }

    return this.http
      .post(url, formData, {
        headers: this.getHeaders(),
      })
      .pipe(catchError(this.handleError));
  }

  private getHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.config.authToken}`,
    });
  }

  private handleError(error: { message?: string }): Observable<never> {
    this.logService.debug(
      HubspotService.TYPE,
      `Hubspot form submit with failed`,
      error
    );

    return throwError(error.message || 'Server error');
  }
}
