import { CultureInfo } from '../culture-info.model';
import { CultureInfoJgo } from './jgo';

/**
 * @ignore
 */
export const CultureInfoJgoCM: CultureInfo = {
  name: 'jgo-CM',
  displayName: 'Ndaꞌa (Kamɛlûn)',
  iso3LanguageCode: 'jgo',
  iso2LanguageCode: 'jgo',
  ietfLanguageTag: 'jgo-CM',
  parent: CultureInfoJgo
};
