import { CultureInfo } from '../culture-info.model';
import { CultureInfoTwq } from './twq';

/**
 * @ignore
 */
export const CultureInfoTwqNE: CultureInfo = {
  name: 'twq-NE',
  displayName: 'Tasawaq senni (Nižer)',
  iso3LanguageCode: 'twq',
  iso2LanguageCode: 'twq',
  ietfLanguageTag: 'twq-NE',
  parent: CultureInfoTwq
};
