import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoNn: CultureInfo = {
  name: 'nn',
  displayName: 'Norwegian Nynorsk',
  iso3LanguageCode: 'nno',
  iso2LanguageCode: 'nn',
  ietfLanguageTag: 'nn',
  parent: null
};
