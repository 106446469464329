import { CultureInfo } from '../culture-info.model';
import { CultureInfoOr } from './or';

/**
 * @ignore
 */
export const CultureInfoOrIN: CultureInfo = {
  name: 'or-IN',
  displayName: 'Odia (India)',
  iso3LanguageCode: 'ori',
  iso2LanguageCode: 'or',
  ietfLanguageTag: 'or-IN',
  parent: CultureInfoOr
};
