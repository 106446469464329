import { CultureInfo } from '../culture-info.model';
import { CultureInfoMsArab } from './ms-Arab';

/**
 * @ignore
 */
export const CultureInfoMsArabMY: CultureInfo = {
  name: 'ms-Arab-MY',
  displayName: 'بهاس ملايو (عرب، مليسيا)',
  iso3LanguageCode: 'msa',
  iso2LanguageCode: 'ms',
  ietfLanguageTag: 'ms-Arab-MY',
  parent: CultureInfoMsArab
};
