import { NgModule } from '@angular/core';
import { TswpUiLibraryConfig } from './models/tswp-ui-library.config';
import { TswpUiLibraryConfigToken } from './models/tswp-ui-library-config.token';
import { IconCacheService } from './services/icon-cache.service';
import { BrowserModule } from '@angular/platform-browser';

/**
 * @ignore
 */
declare let tswpCoreUiConfig: TswpUiLibraryConfig;

/**
 * Defines the module setup to be imported by any control modules
 */
@NgModule({
  imports: [BrowserModule],
  providers: [
    { provide: TswpUiLibraryConfigToken, useFactory: () => tswpCoreUiConfig },
    IconCacheService,
  ],
})
export class SharedModule {}
