import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoPs: CultureInfo = {
  name: 'ps',
  displayName: 'Pashto',
  iso3LanguageCode: 'pus',
  iso2LanguageCode: 'ps',
  ietfLanguageTag: 'ps',
  parent: null
};
