import { CultureInfo } from '../culture-info.model';
import { CultureInfoPt } from './pt';

/**
 * @ignore
 */
export const CultureInfoPtST: CultureInfo = {
  name: 'pt-ST',
  displayName: 'português (São Tomé e Príncipe)',
  iso3LanguageCode: 'por',
  iso2LanguageCode: 'pt',
  ietfLanguageTag: 'pt-ST',
  parent: CultureInfoPt
};
