import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoPl: CultureInfo = {
  name: 'pl',
  displayName: 'Polish',
  iso3LanguageCode: 'pol',
  iso2LanguageCode: 'pl',
  ietfLanguageTag: 'pl',
  parent: null
};
