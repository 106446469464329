import { CultureInfo } from '../culture-info.model';
import { CultureInfoEu } from './eu';

/**
 * @ignore
 */
export const CultureInfoEuES: CultureInfo = {
  name: 'eu-ES',
  displayName: 'Basque (Spain)',
  iso3LanguageCode: 'eus',
  iso2LanguageCode: 'eu',
  ietfLanguageTag: 'eu-ES',
  parent: CultureInfoEu
};
