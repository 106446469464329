import { CultureInfo } from '../culture-info.model';
import { CultureInfoMniMtei } from './mni-Mtei';

/**
 * @ignore
 */
export const CultureInfoMniMteiIN: CultureInfo = {
  name: 'mni-Mtei-IN',
  displayName: 'ꯃꯤꯇꯩꯂꯣꯟ (ꯃꯤꯇꯩ ꯃꯌꯦꯛ, IN)',
  iso3LanguageCode: 'mni',
  iso2LanguageCode: 'mni',
  ietfLanguageTag: 'mni-Mtei-IN',
  parent: CultureInfoMniMtei
};
