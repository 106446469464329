import { Action } from '../../models/action.interface';

/**
 * A service for scheduling time based operations
 */
export interface TimerService {
  /**
   * Returns a promise resolving in a given minimum amount of time
   *
   * @param minimumWaitTime The minimum time to wait in milliseconds
   */
  sleep(minimumWaitTime: number): Promise<void>;

  /**
   * Executes an action was after a given minimum amount of time
   * @param minimumWaitTime The minimum time to wait in milliseconds
   * @param action The action to execute
   *
   * @returns A promise resolving when the action was triggered
   */
  scheduleAction(minimumWaitTime: number, action: Action): Promise<void>;

  /**
   * Repetitively executes an action while waiting a given minimum amount of time between executions
   * @param minimumWaitTime The minimum time to wait between the executions in milliseconds
   * @param action The action to execute
   *
   * @returns An action to cancel the repetition
   */
  scheduleActionRepetition(minimumWaitTime: number, action: Action): Action;
}
