import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoTe: CultureInfo = {
  name: 'te',
  displayName: 'Telugu',
  iso3LanguageCode: 'tel',
  iso2LanguageCode: 'te',
  ietfLanguageTag: 'te',
  parent: null
};
