import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoRof: CultureInfo = {
  name: 'rof',
  displayName: 'Kihorombo',
  iso3LanguageCode: 'rof',
  iso2LanguageCode: 'rof',
  ietfLanguageTag: 'rof',
  parent: null
};
