<div [ngStyle]="{'background-color': background, 'border': border }"
     [attr.class]="'hls-card hls-elevation-' + elevation">
  <div class="hls-card-headline" *ngIf="headline">
    <h2 [innerText]="headline"></h2>
  </div>
  <div class="hls-card-body" [ngClass]="{'hls-card-body--no-padding': !padding, 'hls-card-body--has-headline': headline}">
    <slot></slot>
  </div>
  <div class="hls-card-action-bar">
    <slot name="action-bar"></slot>
  </div>
</div>
