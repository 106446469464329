/**
 * Describes the state of a busy region
 */
export interface BusyState {
  /**
   * Gets or sets a value indicating if a certain region is busy
   */
  isBusy: boolean;

  /**
   * Gets or sets a value resembling the total progress
   * of all operations registered for the busy region
   */
  progress: number;
}
