import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { BootstrapperBase, TOKENS } from '@trustedshops/tswp-core-common';
import { CurrentCultureInfoProviderImpl } from '../services/culture-info-provider.service';

@Injectable()
export class GlobalizationBootstrapper implements BootstrapperBase {

  public constructor(
    @Inject(TOKENS.CurrentCultureInfoProvider)
    private readonly cultureInfoProvider: CurrentCultureInfoProviderImpl,
  ) {}

  public async initialize(): Promise<void> {
    await this.cultureInfoProvider.initialize();
  }
}

export const GlobalizationInitializer = [
  GlobalizationBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [GlobalizationBootstrapper],
  },
];
