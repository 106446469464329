import { CultureInfo } from '../culture-info.model';
import { CultureInfoFf } from './ff';

/**
 * @ignore
 */
export const CultureInfoFfLatn: CultureInfo = {
  name: 'ff-Latn',
  displayName: 'Fulah (Latin)',
  iso3LanguageCode: 'ful',
  iso2LanguageCode: 'ff',
  ietfLanguageTag: 'ff-Latn',
  parent: CultureInfoFf
};
