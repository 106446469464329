import { TOKENS, ExportToken, Stopwatch, StopwatchFactory, DateProvider } from '@trustedshops/tswp-core-common';
import { Inject, Injectable } from '@angular/core';

/**
 * Creates new Stopwatches
 * @exportToken TOKENS.StopwatchFactory
 */
@Injectable()
@ExportToken(TOKENS.StopwatchFactory)
export class StopwatchFactoryImpl implements StopwatchFactory {
  //#region Ctor
  /**
   * Creates an instance of StopwatchFactory
   * @param _dateProvider The date provider to use when stopping and starting time.
   */
  public constructor(@Inject(TOKENS.DateProvider) private readonly _dateProvider: DateProvider) { }
  //#endregion

  //#region Public Methods
  /**
   * Creates a new instance of a stopwatch
   */
  public createStopwatch(): Stopwatch {
    return new Stopwatch(this._dateProvider);
  }
  //#endregion
}
