import { CultureInfo } from '../culture-info.model';
import { CultureInfoGl } from './gl';

/**
 * @ignore
 */
export const CultureInfoGlES: CultureInfo = {
  name: 'gl-ES',
  displayName: 'Galician (Spain)',
  iso3LanguageCode: 'glg',
  iso2LanguageCode: 'gl',
  ietfLanguageTag: 'gl-ES',
  parent: CultureInfoGl
};
