<mat-form-field>
  <mat-label *ngIf="!value && placeholder" [innerText]="placeholder"></mat-label>
  <span class="slot-wrapper prefix-slot" matPrefix>
    <slot name="prefix" #prefixSlot [ngClass]="{'empty-slot': isSlotEmpty(prefixSlotRef)}"></slot>
    <slot name="icon-prefix"  #iconPrefixSlot [ngClass]="{'empty-slot': isSlotEmpty(iconPrefixSlotRef)}"></slot>
    <slot name="button-prefix"  #buttonPrefixSlot [ngClass]="{'empty-slot': isSlotEmpty(buttonPrefixSlotRef)}"></slot>
  </span>
  <select matNativeControl #select
          [disabled]="disabled"
          [name]="name"
          [ngModel]="value"
          [required]="required"
          (ngModelChange)="_updateValue($event, true)">
  </select>
  <span class="slot-wrapper suffix-slot" matSuffix>
    <hls-icon icon="arrow-chevron-down"></hls-icon>
  </span>
</mat-form-field>
  <slot #slot></slot>
