import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoLt: CultureInfo = {
  name: 'lt',
  displayName: 'Lithuanian',
  iso3LanguageCode: 'lit',
  iso2LanguageCode: 'lt',
  ietfLanguageTag: 'lt',
  parent: null
};
