import { CultureInfo } from '../culture-info.model';
import { CultureInfoVun } from './vun';

/**
 * @ignore
 */
export const CultureInfoVunTZ: CultureInfo = {
  name: 'vun-TZ',
  displayName: 'Kyivunjo (Tanzania)',
  iso3LanguageCode: 'vun',
  iso2LanguageCode: 'vun',
  ietfLanguageTag: 'vun-TZ',
  parent: CultureInfoVun
};
