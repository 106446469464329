import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Inject,
  Input,
  ViewEncapsulation
} from '@angular/core';
import { WebComponent } from '../../decorators/web-component.decorator';
import { TswpUiLibraryConfigToken } from '../../models/tswp-ui-library-config.token';
import { TswpUiLibraryConfig } from '../../models/tswp-ui-library.config';
import { HeliosControl } from '../helios-control';
import { ButtonSize } from './button-size.enum';
import { ButtonStyle } from './button-style.enum';

/**
 * Buttons from the Helios Design system
 * @doctab "Examples" "button.component.examples.md"
 * @default_slot Label of the button
 */
@WebComponent('hls-button')
@Component({
  selector: 'ng-hls-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  preserveWhitespaces: true,
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class ButtonComponent extends HeliosControl {
  //#region Properties
  private _buttonStyle: ButtonStyle = ButtonStyle.Secondary;
  /**
   * Gets or sets the style of this button
   */
  @Input()
  @HostBinding('attr.button-style')
  public get buttonStyle(): ButtonStyle {
    return this._buttonStyle;
  }
  public set buttonStyle(v: ButtonStyle) {
    this._buttonStyle = v;
  }

  private _buttonSize: ButtonSize = ButtonSize.Default;
  /**
   * Gets or sets the style of this button
   */
  @Input()
  @HostBinding('attr.button-size')
  public get buttonSize(): ButtonSize {
    return this._buttonSize;
  }
  public set buttonSize(v: ButtonSize) {
    this._buttonSize = v;
  }


  private _iconLeft: string = null;
  /**
   * Gets or sets a an icon displayed on the left side of the caption
   */
  @Input()
  @HostBinding('attr.icon-left')
  public get iconLeft(): string {
    return this._iconLeft;
  }
  public set iconLeft(v: string) {
    this._iconLeft = v;
    this._changeDetector.detectChanges();
  }

  private _iconRight: string = null;
  /**
   * Gets or sets a an icon displayed on the right side of the caption
   */
  @Input()
  @HostBinding('attr.icon-right')
  public get iconRight(): string {
    return this._iconRight;
  }
  public set iconRight(v: string) {
    this._iconRight = v;
    this._changeDetector.detectChanges();
  }

  private _disabled = false;
  /**
   * Gets or sets a value indicating wether the button is disabled.
   */
  @HostBinding('attr.disabled')
  @Input()
  public get disabled(): boolean {
    return !!this._loading || this._disabled || null;
  }
  public set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    this._changeDetector.detectChanges();
  }

  private _loading: string;
  /**
   * Gets or sets the text displayed for a `loading` state.
   * Setting the text will cause the button to show a loading indicator.
   */
  @Input()
  @HostBinding('attr.loading')
  public get loading(): string {
    return this._loading;
  }
  public set loading(value: string) {
    this._loading = value;
    this._changeDetector.detectChanges();
  }
  public get isLoading(): boolean {
    if (this._loading) {
      return this._loading.length > 0;
    }
    return false;
  }
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(DOCUMENT) document: Document,
    elementRef: ElementRef,
    @Inject(TswpUiLibraryConfigToken) config: TswpUiLibraryConfig,
    private readonly _changeDetector: ChangeDetectorRef
  ) {
    super(document, elementRef, config);
  }
  //#endregion
}
