import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoGsw: CultureInfo = {
  name: 'gsw',
  displayName: 'Swiss German',
  iso3LanguageCode: 'gsw',
  iso2LanguageCode: 'gsw',
  ietfLanguageTag: 'gsw',
  parent: null
};
