/**
 * Defines an identity loaded within a session. The generic type defines the profile property.
 */
export interface Identity<T> {
  /**
   * The id of this identity
   */
  id: string;

  /**
   * Additional profile data extended by the application
   */
  profile: T;
}
