import { OrganizationalContainer } from './organizational-container.interface';

/**
 * Describes a source of organizational containers, responsible for getting data only.
 */
export interface OrganizationalContainerSource<T> {
  /**
   * Gets the type of organizational containers this source is loading.
   */
  readonly type: string;

  /**
   * Requests to load all containers available
   */
  getContainers(): Promise<OrganizationalContainer<T>[]>;
}
