import { CultureInfo } from '../culture-info.model';
import { CultureInfoBa } from './ba';

/**
 * @ignore
 */
export const CultureInfoBaRU: CultureInfo = {
  name: 'ba-RU',
  displayName: 'Bashkir (Russia)',
  iso3LanguageCode: 'bak',
  iso2LanguageCode: 'ba',
  ietfLanguageTag: 'ba-RU',
  parent: CultureInfoBa
};
