import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoCgg: CultureInfo = {
  name: 'cgg',
  displayName: 'Rukiga',
  iso3LanguageCode: 'cgg',
  iso2LanguageCode: 'cgg',
  ietfLanguageTag: 'cgg',
  parent: null
};
