import { SessionVerificationTypeEnum } from './session-verification-type.enum';

/**
 * Defines the endpoints and authentication flow to use when using keycloak
 */
export interface KeycloakConfiguration {
  /**
   * The client id of the application that needs to be connected to keycloak.
   */
  clientId: string;

  /**
   * The realm that the application belongs to
   */
  realm: string;

  /**
   * The base URL of Keycloak, e.g. https://auth.trustedshops.com/auth
   */
  url: string;

  /**
   * The verification type for loading tokens
   */
  sessionVerificationType: SessionVerificationTypeEnum;

  /**
   * The uri of a static file doing a postMessage
   * bundled with the tokens to the parent window
   *
   * **Required** when sessionVerificationType is {SessionVerificationTypeEnum.SilentCheckSso}
   *
   * see https://www.keycloak.org/docs/latest/securing_apps/#_javascript_adapter
   */
  silentCheckSsoRedirectUri?: string;
}
