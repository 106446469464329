import { AbstractPersistentEvent } from '../../eventing/abstract-persistent-event';
import { CultureInfo } from '../../globalization/culture-info.model';

/**
 * Service getting the currently active culture in the platfrom
 */
export interface CultureInfoService {
  /**
   * Gets an event stream emitting the currently active culture
   */
  readonly currentCulture: AbstractPersistentEvent<CultureInfo>;

  /**
   * Resolves the locales basic culture info defined for the platform.
   * Example: nl-BE will get nl-NL when configured this way.
   *
   * @param locale The locale to get the base culture for. Can be passed as localeIso2 or ietfLanguageTag
   */
  resolveBaseCulture(locale: string): CultureInfo;
}

