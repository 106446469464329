/**
 * Enumeration for analytical tracker types
 */
export enum TrackerType {
  /**
   * Declares a tracker of type "Google Analytics"
   */
  GoogleAnalytics,
  /**
   * Declares a tracker of type "HubSpot Analytics"
   */
  HubSpotAnalytics,
}
