import { isObject } from "./is-object";

/**
 * Deep merge two objects.
 */
export function mergeDeep(objectA: any, objectB: any, replaceArrays = false): any {
  return [objectA, objectB].reduce((prev, obj) => {
    Object.keys(obj).forEach(key => {
      const pVal = prev[key];
      const oVal = obj[key];

      if (Array.isArray(pVal) && Array.isArray(oVal)) {
        prev[key] = replaceArrays
          ? oVal
          : pVal.concat(...oVal);
      } else if (isObject(pVal) && isObject(oVal)) {
        prev[key] = mergeDeep(pVal, oVal);
      } else {
        prev[key] = oVal;
      }
    });

    return prev;
  }, {});
}
