import { RendererFactory2, Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { DomResourceLoader, ResourceInfo, ResourceType, InvalidOperationError } from '@trustedshops/tswp-core-common';
import { ResourceLoadError } from '../../error/resource-load.error';
/**
 * TODO
 */
@Injectable()
export class CssDomResourceLoaderService implements DomResourceLoader {
  //#region Ctor
  /** ignore */
  public constructor(
    private readonly _rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private readonly _document: Document) {
  }
  //#endregion

  //#region Public Methods
  /**
   * Loads a stylesheet into the DOM.
   * @param styleInfo Detailed data concering origin, type and many more metadata about the resource.
   */
  public async load(styleInfo: ResourceInfo): Promise<void> {
    if (styleInfo.type !== ResourceType.CascadingStyleSheet) {
      throw new InvalidOperationError(
        `A resource of type "${styleInfo.type}" cannot be loaded via tswp-core/CssDomResourceLoader`);
    }

    const renderer = this._rendererFactory.createRenderer(null, null);

    const link = renderer.createElement('link') as HTMLLinkElement;
    link.href = styleInfo.key;
    link.rel = `stylesheet`;

    renderer.appendChild(this._document.head, link);

    return new Promise((resolve, reject) => {
      link.addEventListener('load', () => resolve());
      link.addEventListener('error', ev =>
        reject(new ResourceLoadError(
          `An error occurred when loading resource "${styleInfo.key}"`,
          ev)));
    });
  }
  //#endregion
}
