import { CultureInfo } from '../culture-info.model';
import { CultureInfoFfLatn } from './ff-Latn';

/**
 * @ignore
 */
export const CultureInfoFfLatnGM: CultureInfo = {
  name: 'ff-Latn-GM',
  displayName: 'Pulaar (Latn, Gammbi)',
  iso3LanguageCode: 'ful',
  iso2LanguageCode: 'ff',
  ietfLanguageTag: 'ff-Latn-GM',
  parent: CultureInfoFfLatn
};
