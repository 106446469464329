import { CultureInfo } from '../culture-info.model';
import { CultureInfoKhq } from './khq';

/**
 * @ignore
 */
export const CultureInfoKhqML: CultureInfo = {
  name: 'khq-ML',
  displayName: 'Koyra ciini (Maali)',
  iso3LanguageCode: 'khq',
  iso2LanguageCode: 'khq',
  ietfLanguageTag: 'khq-ML',
  parent: CultureInfoKhq
};
