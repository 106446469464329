import { CultureInfo } from '../culture-info.model';
import { CultureInfoFy } from './fy';

/**
 * @ignore
 */
export const CultureInfoFyNL: CultureInfo = {
  name: 'fy-NL',
  displayName: 'Western Frisian (Netherlands)',
  iso3LanguageCode: 'fry',
  iso2LanguageCode: 'fy',
  ietfLanguageTag: 'fy-NL',
  parent: CultureInfoFy
};
