<div>
  <mat-button-toggle-group
    #toggleButtonGroup
    [hideSingleSelectionIndicator]="true"
    [value]="innerValue | async"
    (change)="_updateValue($event, true)"
    [disabled]="disabled"
    [multiple]="multiple"
  >
    <slot #slot></slot>
  </mat-button-toggle-group>
</div>
