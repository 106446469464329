import { CultureInfo } from '../culture-info.model';
import { CultureInfoDe } from './de';

/**
 * @ignore
 */
export const CultureInfoDeAT: CultureInfo = {
  name: 'de-AT',
  displayName: 'German (Austria)',
  iso3LanguageCode: 'deu',
  iso2LanguageCode: 'de',
  ietfLanguageTag: 'de-AT',
  parent: CultureInfoDe
};
