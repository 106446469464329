import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoSes: CultureInfo = {
  name: 'ses',
  displayName: 'Koyraboro senni',
  iso3LanguageCode: 'ses',
  iso2LanguageCode: 'ses',
  ietfLanguageTag: 'ses',
  parent: null
};
