import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoHaw: CultureInfo = {
  name: 'haw',
  displayName: 'Hawaiian',
  iso3LanguageCode: 'haw',
  iso2LanguageCode: 'haw',
  ietfLanguageTag: 'haw',
  parent: null
};
