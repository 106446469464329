import { ErrorBase } from '@trustedshops/tswp-core-common';

export class ResourceLoadError extends ErrorBase {
  //#region Properties
  private _errorEvent: ErrorEvent;
  public get errorEvent(): ErrorEvent {
    return this._errorEvent;
  }
  //#endregion

  //#region Ctor
  public constructor(message: string, event: ErrorEvent) {
    super(message);
    this._errorEvent = event;
  }
  //#endregion
}
