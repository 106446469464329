import { Event, EventSubscription } from '@trustedshops/tswp-core-common';

export function toPromise<T>(observable: Event<T>): Promise<T> {
  let subscription: EventSubscription<never>;
  return new Promise<T>((resolve, reject) => {
    subscription = observable.subscribe(
      value => {
        resolve(value);
        subscription?.unsubscribe();
      },
      error => reject(error)
    );
  });
}
