import { Component, ElementRef, ViewEncapsulation } from '@angular/core';
import { CommandUserInteractionHandler } from '@trustedshops/tswp-core-ui';
import { UserInteractionComponentBase } from '../user-interaction-component-base';

/**
 * Component rendering a click interaction
 */
@Component({
  selector: 'click-interaction',
  templateUrl: 'click-interaction.component.html',
  styles: ['click-interaction { display: block; }'],
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class ClickInteractionComponent  extends UserInteractionComponentBase<CommandUserInteractionHandler> {
  //#region Ctor
  public constructor(private readonly _elementRef: ElementRef) {
    super();
  }
  //#endregion

  //#region Public Methods
  /**
   * Executes the underlying command
   */
  public async executeInteraction(): Promise<void> {
    if (!this.interaction) {
      return;
    }

    await new Promise(resolve =>
      this.interaction.command
        .execute(this._elementRef.nativeElement)
        .subscribe(resolve));
  }
  //#endregion
}
