import { CultureInfo } from '../culture-info.model';
import { CultureInfoNl } from './nl';

/**
 * @ignore
 */
export const CultureInfoNlCW: CultureInfo = {
  name: 'nl-CW',
  displayName: 'Nederlands (Curaçao)',
  iso3LanguageCode: 'nld',
  iso2LanguageCode: 'nl',
  ietfLanguageTag: 'nl-CW',
  parent: CultureInfoNl
};
