import { ModuleWithProviders, NgModule } from '@angular/core';
import { KC_INSTANCE_FACTORY, KeycloakInstanceFactory } from '@trustedshops/tswp-core-authorization-keycloak';
import { Type } from '@trustedshops/tswp-core-common';
import { KeycloakSessionProviderService } from './services/keycloak-session-provider.service';

@NgModule({
  providers: [
    KeycloakSessionProviderService
  ]
})
export class TswpCoreAuthorizationKeycloakModule {
    public static withDefaultInstanceFactory<T extends KeycloakInstanceFactory>(factory: Type<T>): ModuleWithProviders<any> {
      return {
        ngModule: TswpCoreAuthorizationKeycloakModule,
        providers: [
          { provide: KC_INSTANCE_FACTORY, useClass: factory }
        ]
      };
    }
 }
