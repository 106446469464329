import { CultureInfo } from '../culture-info.model';
import { CultureInfoLrc } from './lrc';

/**
 * @ignore
 */
export const CultureInfoLrcIR: CultureInfo = {
  name: 'lrc-IR',
  displayName: 'لۊری شومالی (IR)',
  iso3LanguageCode: 'lrc',
  iso2LanguageCode: 'lrc',
  ietfLanguageTag: 'lrc-IR',
  parent: CultureInfoLrc
};
