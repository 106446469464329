import { CultureInfo } from '../culture-info.model';
import { CultureInfoDyo } from './dyo';

/**
 * @ignore
 */
export const CultureInfoDyoSN: CultureInfo = {
  name: 'dyo-SN',
  displayName: 'joola (Senegal)',
  iso3LanguageCode: 'dyo',
  iso2LanguageCode: 'dyo',
  ietfLanguageTag: 'dyo-SN',
  parent: CultureInfoDyo
};
