import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoNqo: CultureInfo = {
  name: 'nqo',
  displayName: 'ߒߞߏ',
  iso3LanguageCode: 'nqo',
  iso2LanguageCode: 'nqo',
  ietfLanguageTag: 'nqo',
  parent: null
};
