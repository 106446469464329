import { CultureInfo } from '../culture-info.model';
import { CultureInfoOm } from './om';

/**
 * @ignore
 */
export const CultureInfoOmET: CultureInfo = {
  name: 'om-ET',
  displayName: 'Oromo (Ethiopia)',
  iso3LanguageCode: 'orm',
  iso2LanguageCode: 'om',
  ietfLanguageTag: 'om-ET',
  parent: CultureInfoOm
};
