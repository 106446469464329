import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoSv: CultureInfo = {
  name: 'sv',
  displayName: 'Swedish',
  iso3LanguageCode: 'swe',
  iso2LanguageCode: 'sv',
  ietfLanguageTag: 'sv',
  parent: null
};
