export interface CultureInfo {
  /**
   * Gets the culture name in the format languagecode2-country/regioncode2.
   */
  readonly name: string;

  /**
   * Gets the parent of this CultureInfo.
   */
  readonly parent: CultureInfo;

  /**
   * Gets the full english localized culture name.
   */
  readonly displayName: string;

  /**
   * Gets the ISO 639-2 three-letter code for the language of the current CultureInfo.
   */
  readonly iso3LanguageCode: string;

  /**
   * Gets the ISO 639-1 two-letter code for the language of the current CultureInfo.
   */
  readonly iso2LanguageCode: string;

  /**
   * Gets the RFC 4646 standard identification for a language.
   */
  readonly ietfLanguageTag: string;

}
