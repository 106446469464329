import { CultureInfo } from '../culture-info.model';
import { CultureInfoDua } from './dua';

/**
 * @ignore
 */
export const CultureInfoDuaCM: CultureInfo = {
  name: 'dua-CM',
  displayName: 'duálá (Cameroun)',
  iso3LanguageCode: 'dua',
  iso2LanguageCode: 'dua',
  ietfLanguageTag: 'dua-CM',
  parent: CultureInfoDua
};
