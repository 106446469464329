import { CultureInfo } from '../culture-info.model';
import { CultureInfoGu } from './gu';

/**
 * @ignore
 */
export const CultureInfoGuIN: CultureInfo = {
  name: 'gu-IN',
  displayName: 'Gujarati (India)',
  iso3LanguageCode: 'guj',
  iso2LanguageCode: 'gu',
  ietfLanguageTag: 'gu-IN',
  parent: CultureInfoGu
};
