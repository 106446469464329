import { ErrorBase } from './error-base';

/**
 * The exception that is thrown when the time allotted for a process or operation has expired.
 */
export class TimeoutError extends ErrorBase {
  //#region Ctor
  /**
   * Initializes a new instance of the TimeoutException class with the specified error message.
   * @param msg The error message that explains the reason for the exception.
   * @param timeout Size of the timeout in miliseconds that has expired.
   */
  public constructor(msg: string, timeout?: number, innerError?: Error) {
    super(`TimeoutError:${timeout ? ` The operation timed out out after ${timeout} milliseconds.` : ''} ${msg}`, innerError);
  }
  //#endregion
}
