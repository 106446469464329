import { CultureInfo } from '../culture-info.model';
import { CultureInfoOm } from './om';

/**
 * @ignore
 */
export const CultureInfoOmKE: CultureInfo = {
  name: 'om-KE',
  displayName: 'Oromoo (Keeniyaa)',
  iso3LanguageCode: 'orm',
  iso2LanguageCode: 'om',
  ietfLanguageTag: 'om-KE',
  parent: CultureInfoOm
};
