import { CultureInfo } from '../culture-info.model';
import { CultureInfoMt } from './mt';

/**
 * @ignore
 */
export const CultureInfoMtMT: CultureInfo = {
  name: 'mt-MT',
  displayName: 'Maltese (Malta)',
  iso3LanguageCode: 'mlt',
  iso2LanguageCode: 'mt',
  ietfLanguageTag: 'mt-MT',
  parent: CultureInfoMt
};
