import { UserInteractionHandlerBase } from '../models/user-interaction-handler-base';
import { UserInteractionType } from '../models/user-interaction-type.enum';
import { UriLocationType } from '../models/uri-location-type.enum';
import { Dictionary, LinkTarget } from '@trustedshops/tswp-core-common';

/**
 * Pre-Defined user interaction for all navigate events (e.g. <a> tags)
 */
export class NavigateUserInteractionHandler extends UserInteractionHandlerBase {
  //#region Properties
  private _uri: string;
  /**
   * Gets or sets the uri excluding query params
   */
  public get uri(): string {
    return this._uri;
  }
  public set uri(v: string) {
    this._uri = v;
  }

  private _queryParams: Dictionary;
  /**
   * Gets or sets the dictionary containing the uri query params
   */
  public get queryParams(): Dictionary {
    return this._queryParams;
  }
  public set queryParams(v: Dictionary) {
    this._queryParams = v;
  }

  private _uriLocationType: UriLocationType;
  /**
   * Gets or sets a value indicating if the uri location is handled by the application host
   * or if the URI points to an external resource
   */
  public get uriLocationType(): UriLocationType {
    return this._uriLocationType;
  }
  public set uriLocationType(v: UriLocationType) {
    this._uriLocationType = v;
  }


  private _target: LinkTarget;
  /**
   * Gets or sets a value indicating where the navigate interaction is going to take place
   */
  public get target(): LinkTarget {
    return this._target;
  }
  public set target(v: LinkTarget) {
    this._target = v;
  }
  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of NavigateUserInteractionHandler
   *
   * @param uri The URI excluding query params
   * @param uriLocationType The value indicating if the URI location is handled by the application host
   * @param queryParams The dictionary containing the URI query params
   * @deprecated
   * Calling `ClickUserInteractionHandler` is deprecated and will be removed in version 2.0.0.
   * Please migrate to `NavigationService.getUserInteractionHandler`.
   * As of the next version, this export will be removed.
   */
  public constructor(uri: string, uriLocationType: UriLocationType =  UriLocationType.Internal, queryParams: Dictionary = {}) {
    super();

    this._uri = uri;
    this.type = UserInteractionType.Navigate;
    this._uriLocationType = uriLocationType;
    this._queryParams = queryParams;
  }
  //#endregion
}
