import { NgModuleRef } from '@angular/core';
import { TswpUiLibraryConfig } from '../models/tswp-ui-library.config';
import { allControls } from './all-controls';

/**
 * Bootstraps the control library with the given configuration
 *
 * @param config The configuration to use when bootstrapping the given control's modules
 */
export async function enableUiElements(
  config: TswpUiLibraryConfig): Promise<NgModuleRef<any>[]> {
  const platform = config.platform;
  const controlModules = config?.controlModules || allControls;

  return Promise.all(
    controlModules.map(controlModule => {
      (window as any).tswpCoreUiConfig = config || {};

      return platform.bootstrapModule(controlModule);
    }));
}
