import { OrganizationalContainer } from './organizational-container.interface';

/**
 * Represents the contract that a `OrganizationalContainerRenderer` element must fulfil.
 */
export interface OrganizationalContainerRenderer<T> {
  /**
   * Gets or sets the container to be rendered
   */
  container: OrganizationalContainer<T>;

  /**
   * Optional, is called by the organizational container selector viewport includes the current `OrganizationalContainerRenderer` instance.
   */
  onVisibilityChange?(isVisible: boolean): void;
}
