import { EventListener } from './event-listener.interface';

/**
 * Generic description of an event implementation.
 */
export interface Event<TEmission> extends EventListener<TEmission> {
  /**
   * Emits the given value in the event stream
   * @param value The value emitted.
   */
  emit(value: TEmission): void;

  /**
   * Emits an error in the event stream.
   * @param error The error to emit
   */
  emitError(error: Error): void;
}
