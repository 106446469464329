import { CultureInfo } from '../culture-info.model';
import { CultureInfoHaw } from './haw';

/**
 * @ignore
 */
export const CultureInfoHawUS: CultureInfo = {
  name: 'haw-US',
  displayName: 'Hawaiian (United States)',
  iso3LanguageCode: 'haw',
  iso2LanguageCode: 'haw',
  ietfLanguageTag: 'haw-US',
  parent: CultureInfoHaw
};
