import { TrackerType, TrackingService } from '@trustedshops/tswp-core-analytics';
import { TrackerFactory, HubSpotAnalyticsTrackerConfiguration } from '@trustedshops/tswp-core-analytics';
import { DomResourceService, InvalidOperationError, LogService } from '@trustedshops/tswp-core-common';

// dummy comment to increase the version of tswp-core-common, https://github.com/trustedshops/tswp-core/tree/feat/PL-3138/integrate-hubspot-tracking

export class HubSpotAnalyticsTrackerFactory implements TrackerFactory<TrackerType.HubSpotAnalytics> {
  private _scriptsLoaded = false;

  public get type(): TrackerType {
    return TrackerType.HubSpotAnalytics;
  }

  public constructor(
    private readonly _resourceLoader: DomResourceService,
    private readonly _document: Document,
    private readonly _logService: LogService) { }

  public create(_: TrackingService, config: HubSpotAnalyticsTrackerConfiguration) {
    if (!this._document.defaultView) {
      throw new InvalidOperationError(`Registering HupSpot Analytics in a non-browser environment is not supported.`);
    }

    this._logService.debug(
      '@trustedshops/tswp-core-analytics:HubSpotAnalyticsTrackerFactory',
      `Creating event tracker instance.`, config);

    (async () => {
      await this.loadHubSpotAnalyticsResources(config);
    })();

    return undefined;
  }

  private async loadHubSpotAnalyticsResources(config: HubSpotAnalyticsTrackerConfiguration): Promise<void> {
    if (this._scriptsLoaded) {
      return;
    }

    this._logService.debug(
      '@trustedshops/tswp-core-analytics:HubSpotAnalyticsTrackerFactory',
      `Loading hubspot script from ${config.scriptUrl}`);

    await this._resourceLoader.loadResource({
      key: config.scriptUrl,
      options: {
        async: 'async'
      } as any,
      type: 'js'
    });

    this._scriptsLoaded = true;
  }
}
