import { CultureInfo } from '../culture-info.model';
import { CultureInfoTig } from './tig';

/**
 * @ignore
 */
export const CultureInfoTigER: CultureInfo = {
  name: 'tig-ER',
  displayName: 'ትግረ (ኤርትራ)',
  iso3LanguageCode: 'tig',
  iso2LanguageCode: 'tig',
  ietfLanguageTag: 'tig-ER',
  parent: CultureInfoTig
};
