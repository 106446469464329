import { CultureInfo } from '../culture-info.model';
import { CultureInfoAz } from './az';

/**
 * @ignore
 */
export const CultureInfoAzLatn: CultureInfo = {
  name: 'az-Latn',
  displayName: 'Azerbaijani (Latin)',
  iso3LanguageCode: 'aze',
  iso2LanguageCode: 'az',
  ietfLanguageTag: 'az-Latn',
  parent: CultureInfoAz
};
