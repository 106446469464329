import { CultureInfo } from '../culture-info.model';
import { CultureInfoSatOlck } from './sat-Olck';

/**
 * @ignore
 */
export const CultureInfoSatOlckIN: CultureInfo = {
  name: 'sat-Olck-IN',
  displayName: 'ᱥᱟᱱᱛᱟᱲᱤ (ᱚᱞ ᱪᱤᱠᱤ, IN)',
  iso3LanguageCode: 'sat',
  iso2LanguageCode: 'sat',
  ietfLanguageTag: 'sat-Olck-IN',
  parent: CultureInfoSatOlck
};
