import {
  IdentityService,
  TOKENS as TOKENS_AUTH,
} from '@trustedshops/tswp-core-authorization';
import { Inject, Injectable, Injector, Provider } from '@angular/core';
import { KeycloakSessionProviderService } from '../services/keycloak-session-provider.service';
import { SessionInterceptorBase } from '@trustedshops/tswp-core-authorization-implementation';
import {
  AbstractEvent,
  CultureInfo,
  CultureInfoService,
  Event,
  TOKENS,
} from '@trustedshops/tswp-core-common';

/**
 * Takes responsibility to redirect to login page if the a user visits the application without an authentication
 */
@Injectable()
export class LoginUiRedirectInterceptor extends SessionInterceptorBase {
  private readonly _injector: Injector;

  /**
   * Creates an instance of LoginUIRedirectInterceptor
   * @param injector The injector to use to request the IdentityService
   * @param document The document to use to change the current URL for.
   */
  public constructor(@Inject(Injector) injector: Injector) {
    super();

    this._injector = injector;
  }

  /**
   * Gets the order index of this Interceptor (1000)
   */
  public get order(): number {
    return 100;
  }

  /**
   * Called when the session ended or the initial load of the session results in an unauthenticated state.
   * @param next the next interceptor to call. If not called, the interception chain will be broken.
   */
  public override onSessionEnd(): Event<void> {
    const identityService = this._injector.get<IdentityService>(
      TOKENS_AUTH.IdentityService as any,
    );
    const keycloakSessionProvider =
      identityService.sessionProvider as unknown as KeycloakSessionProviderService;

    const eventStream = new AbstractEvent<void>();
    (async () => {
      const cultureInfoService = this._injector.get<CultureInfoService>(
        TOKENS.CultureInfoService as any,
      );

      const currentCulture = await new Promise<CultureInfo>((resolve) =>
        cultureInfoService.currentCulture.subscribe(resolve),
      );

      await keycloakSessionProvider.login(currentCulture.iso2LanguageCode);

      eventStream.emit();
    })();
    return eventStream;
  }
}

/**
 * Defines a default setup for the LoginUiRedirectInterceptor
 */
export const SetUpLoginUiRedirectInterceptor: Provider = {
  provide: TOKENS_AUTH.SessionInterceptor,
  useClass: LoginUiRedirectInterceptor,
  multi: true,
};
