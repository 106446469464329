import { AbstractEvent, Event, Type } from '@trustedshops/tswp-core-common';
import { Observable } from 'rxjs';

export function fromObservable<T, TTarget extends AbstractEvent<T>>(observable: Observable<T>, targetType?: Type<TTarget>): Event<T> {
  if (!targetType) {
    targetType = AbstractEvent as any;
  }
  const event = new targetType(null) as TTarget;
  observable.subscribe(
    value => event.emit(value),
    error => event.emitError(error));

  return event;
}
