import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoNds: CultureInfo = {
  name: 'nds',
  displayName: 'nds',
  iso3LanguageCode: 'nds',
  iso2LanguageCode: 'nds',
  ietfLanguageTag: 'nds',
  parent: null
};
