import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoYo: CultureInfo = {
  name: 'yo',
  displayName: 'Yoruba',
  iso3LanguageCode: 'yor',
  iso2LanguageCode: 'yo',
  ietfLanguageTag: 'yo',
  parent: null
};
