import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoNl: CultureInfo = {
  name: 'nl',
  displayName: 'Dutch',
  iso3LanguageCode: 'nld',
  iso2LanguageCode: 'nl',
  ietfLanguageTag: 'nl',
  parent: null
};
