import { CultureInfo } from '../culture-info.model';
import { CultureInfoMs } from './ms';

/**
 * @ignore
 */
export const CultureInfoMsBN: CultureInfo = {
  name: 'ms-BN',
  displayName: 'Malay (Brunei)',
  iso3LanguageCode: 'msa',
  iso2LanguageCode: 'ms',
  ietfLanguageTag: 'ms-BN',
  parent: CultureInfoMs
};
