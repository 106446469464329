import { CultureInfo } from '../culture-info.model';
import { CultureInfoDe } from './de';

/**
 * @ignore
 */
export const CultureInfoDeCH: CultureInfo = {
  name: 'de-CH',
  displayName: 'German (Switzerland)',
  iso3LanguageCode: 'deu',
  iso2LanguageCode: 'de',
  ietfLanguageTag: 'de-CH',
  parent: CultureInfoDe
};
