import { CultureInfo } from '../culture-info.model';
import { CultureInfoTs } from './ts';

/**
 * @ignore
 */
export const CultureInfoTsZA: CultureInfo = {
  name: 'ts-ZA',
  displayName: 'Tsonga (South Africa)',
  iso3LanguageCode: 'tso',
  iso2LanguageCode: 'ts',
  ietfLanguageTag: 'ts-ZA',
  parent: CultureInfoTs
};
