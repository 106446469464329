import { CultureInfo } from '../culture-info.model';
import { CultureInfoDa } from './da';

/**
 * @ignore
 */
export const CultureInfoDaDK: CultureInfo = {
  name: 'da-DK',
  displayName: 'Danish (Denmark)',
  iso3LanguageCode: 'dan',
  iso2LanguageCode: 'da',
  ietfLanguageTag: 'da-DK',
  parent: CultureInfoDa
};
