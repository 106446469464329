import { CultureInfo } from '../culture-info.model';
import { CultureInfoSo } from './so';

/**
 * @ignore
 */
export const CultureInfoSoKE: CultureInfo = {
  name: 'so-KE',
  displayName: 'Soomaali (Kenya)',
  iso3LanguageCode: 'som',
  iso2LanguageCode: 'so',
  ietfLanguageTag: 'so-KE',
  parent: CultureInfoSo
};
