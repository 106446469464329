import { CultureInfo } from '../culture-info.model';
import { CultureInfoBrx } from './brx';

/**
 * @ignore
 */
export const CultureInfoBrxIN: CultureInfo = {
  name: 'brx-IN',
  displayName: 'बरʼ (भारत)',
  iso3LanguageCode: 'brx',
  iso2LanguageCode: 'brx',
  ietfLanguageTag: 'brx-IN',
  parent: CultureInfoBrx
};
