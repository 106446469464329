import { CultureInfo } from '../culture-info.model';
import { CultureInfoVe } from './ve';

/**
 * @ignore
 */
export const CultureInfoVeZA: CultureInfo = {
  name: 've-ZA',
  displayName: 'Venda (South Africa)',
  iso3LanguageCode: 'ven',
  iso2LanguageCode: 've',
  ietfLanguageTag: 've-ZA',
  parent: CultureInfoVe
};
