import { LoggingTarget } from './logging-target.interface';
import { LogEntry } from './log-entry.interface';
import { LogLevel } from './log-level.enum';


/**
 * Logging target forwarding incoming log entries to an in memory storage.
 */
export class MemoryLoggingTarget implements LoggingTarget {
  //#region Private Fields
  private _entries: Array<LogEntry> = [];
  //#endregion

  //#region Public Methods
  /**
   * Adds a log entry with `LogLevel.Trace`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  public trace(message: string, payload?: any): void {
    this.addEntry(message, payload, LogLevel.Trace);
  }

  /**
   * Adds a log entry with `LogLevel.Debug`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  public debug(message: string, payload?: any): void {
    this.addEntry(message, payload, LogLevel.Debug);
  }

  /**
   * Adds a log entry with `LogLevel.Information`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  public information(message: string, payload?: any): void {
    this.addEntry(message, payload, LogLevel.Information);
  }

  /**
   * Adds a log entry with `LogLevel.Warning`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  public warning(message: string, payload?: any): void {
    this.addEntry(message, payload, LogLevel.Warning);
  }

  /**
   * Adds a log entry with `LogLevel.Error`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  public error(message: string, payload?: any): void {
    this.addEntry(message, payload, LogLevel.Error);
  }

  /**
   * Adds a log entry with `LogLevel.Fatal`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  public fatal(message: string, payload?: any): void {
    this.addEntry(message, payload, LogLevel.Fatal);
  }

  /**
   * Exports the currently saved log entries as JSON string ordered by time
   */
  public export(): string {
    return JSON.stringify(
      [...this._entries].sort((a, b) =>
        a.timestamp.getTime() - b.timestamp.getTime()),
      null,
      2);
  }
  //#endregion

  //#region Private Methods
  /**
   * @ignore
   */
  private addEntry(message: string, payload: any, level: LogLevel): void {
    this._entries.push({
      message,
      level,
      payload,
      timestamp: new Date(Date.now())
    });
  }
  //#endregion
}
