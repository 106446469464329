import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoBr: CultureInfo = {
  name: 'br',
  displayName: 'Breton',
  iso3LanguageCode: 'bre',
  iso2LanguageCode: 'br',
  ietfLanguageTag: 'br',
  parent: null
};
