import { Inject, Injectable } from '@angular/core';
import { RxJsSubjectBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import {
  TOKENS,
  PermissionManager,
  PermissionService,
  Permission,
  IdentityService,
  Identity,
  PermissionRequest,
  GrantInfo
} from '@trustedshops/tswp-core-authorization';
import { ExportToken } from '@trustedshops/tswp-core-common';
import { Observable } from 'rxjs';
import { take, filter, map } from 'rxjs/operators';

const WILDCARD_RESOURCE = '*';
const WILDCARD_ACTION = '*';
const ACTION_LEVEL_SEPARATOR = ':';

/**
 * Service for reading and managing permissions
 *
 * @template TPermissionDefinition The type of a single permission that is handled with this `PermissionService`
 */
@Injectable()
@ExportToken(TOKENS.PermissionService)
export class PermissionServiceImpl<TPermissionDefinition extends Permission> implements PermissionService<TPermissionDefinition> {
  //#region Private Field
  private _permissionsProvider: PermissionManager<TPermissionDefinition>;
  private _permissionsCache: { [identityRef: string]: Promise<TPermissionDefinition[]> } = {};
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(TOKENS.IdentityService) private readonly _identityService: IdentityService) { }
  //#endregion

  //#region Public Methods
  /**
   * Gets an array of permission information for the user currently logged in.
   *
   * @returns The array of permissions.
   */
  public async getPersonalPermissions(): Promise<TPermissionDefinition[]> {
    const identityId = await this.getIdentityId();

    return this.getPermissions(identityId);
  }

  public async getPermissions(identityId: string): Promise<TPermissionDefinition[]> {
    if (!this._permissionsCache[identityId]) {
      this._permissionsCache[identityId] = this._permissionsProvider.getPermissionsForUser(identityId);
    }

    return await this._permissionsCache[identityId];
  }

  /**
   * Registers a permission manager to get and apply the permissions from/to.
   * Can only be called one time and is reserved by the platform
   *
   * @param permissionsProvider The type of permissions provider to use
   */
  public registerPermissionManager<TManager extends PermissionManager<TPermissionDefinition>>(
    permissionsProvider: TManager): void {

    this._permissionsProvider = this._permissionsProvider || permissionsProvider;
  }

  /**
   * Checks wether
   *
   * @param action The action to execute. May not contain wildcards
   * @param resource The resource to execute the action within
   * @param resourceType The type of resource given in `resource`.
   * @param identityRef The identity to check the permission for
   */
  public async requestPermissions(requests: PermissionRequest[]): Promise<GrantInfo<TPermissionDefinition>[]> {
    const hasPersonalRequests = requests.some(x => !x.identityId);
    const personalIdentityId: string = hasPersonalRequests
      ? await this.getIdentityId()
      : null;

    requests = requests.map(request => ({
      ...request,
      identityId: request.identityId || personalIdentityId
    }));

    const uniqueIdentityIds = Array.from(new Set(requests.map(x => x.identityId)));
    await Promise.all(uniqueIdentityIds.map(identityId => this.getPermissions(identityId)));

    return await Promise.all(requests.map(request => this.resolvePermissionRequest(request)));
  }
  //#endregion

  //#region Private Methods
  private async resolvePermissionRequest(request: PermissionRequest): Promise<GrantInfo<TPermissionDefinition>> {
    const permissions = await this.getPermissions(request.identityId);

    const resourceTypePermissions = permissions.filter(permission =>
      permission.resourceType === request.resourceType);


    const lookForAtLeastOne: (perm: Permission) => boolean =
      () => request.resource === WILDCARD_RESOURCE;
    const checkExact: (perm: Permission) => boolean =
      permission => permission.resource === request.resource;
    const checkIsWildcardPermission: (perm: Permission) => boolean =
      permission => permission.resource === WILDCARD_RESOURCE;

    const suitableResourcePermissions = resourceTypePermissions
      .filter(permission => [
        lookForAtLeastOne,
        checkExact,
        checkIsWildcardPermission
      ].some(isMatching => isMatching(permission)));

    const suitableActionPermission = suitableResourcePermissions.find(permission =>
      permission.actions.some(action => this.matchesAction(action, request.action)));

    if (suitableActionPermission) {
      return {
        granted: true,
        matchingDefinition: suitableActionPermission,
        request
      };
    }

    return {
      granted: false,
      matchingDefinition: null,
      request
    };
  }

  private matchesAction(permissionTemplate: string, fullyQualifiedAction: string): boolean {
    const templateSegments = permissionTemplate.split(ACTION_LEVEL_SEPARATOR);
    const actionSegments = fullyQualifiedAction.split(ACTION_LEVEL_SEPARATOR);

    for (let index = 0; index < Math.min(templateSegments.length, actionSegments.length); index++) {
      const currentTemplateSegment = templateSegments[index];

      if (actionSegments[index] === WILDCARD_ACTION) {
        continue;
      }

      if (currentTemplateSegment === WILDCARD_ACTION) {
        return true;
      }

      if (!actionSegments[index]) {
        return false;
      }

      if (currentTemplateSegment !== actionSegments[index]) {
        return false;
      }
    }

    return true;
  }

  private async getIdentityId(): Promise<string> {
    return await this._identityService.identity
      .convertWith<Observable<Identity<any>>>(RxJsSubjectBridge)
      .pipe(
        filter(x => !!x),
        take(1),
        map(x => (x as Identity<any>).id))
      .toPromise();
  }
  //#endregion
}
