import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoAk: CultureInfo = {
  name: 'ak',
  displayName: 'Akan',
  iso3LanguageCode: 'aka',
  iso2LanguageCode: 'ak',
  ietfLanguageTag: 'ak',
  parent: null
};
