import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKsh: CultureInfo = {
  name: 'ksh',
  displayName: 'Kölsch',
  iso3LanguageCode: 'ksh',
  iso2LanguageCode: 'ksh',
  ietfLanguageTag: 'ksh',
  parent: null
};
