import { CultureInfo } from '../culture-info.model';
import { CultureInfoEs } from './es';

/**
 * @ignore
 */
export const CultureInfoEsSX: CultureInfo = {
  name: 'es-SX',
  displayName: 'español (Sint Maarten)',
  iso3LanguageCode: 'spa',
  iso2LanguageCode: 'es',
  ietfLanguageTag: 'es-SX',
  parent: CultureInfoEs
};
