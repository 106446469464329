import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoSn: CultureInfo = {
  name: 'sn',
  displayName: 'chiShona',
  iso3LanguageCode: 'sna',
  iso2LanguageCode: 'sn',
  ietfLanguageTag: 'sn',
  parent: null
};
