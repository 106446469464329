import { HeliosValueControl } from './../helios-value-control';
import { ViewEncapsulation, Component, Input, HostBinding, ElementRef, ViewChild, Inject, ChangeDetectorRef } from '@angular/core';
import { WebComponent } from '../../decorators/web-component.decorator';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { InputType } from './input-type.enum';
import { ElementMethod } from '../../decorators/element-method.decorator';
import { WebComponentType } from '../../models/web-component-type';
import { DOCUMENT } from '@angular/common';
import { TswpUiLibraryConfigToken } from '../../models/tswp-ui-library-config.token';
import { TswpUiLibraryConfig } from '../../models/tswp-ui-library.config';

/**
 * Input Form Elements from the Helios Design system
 * @doctab "Examples" "input.component.examples.md"
 * @named_slot "suffix" "Text elements being inlined with the input at the end"
 * @named_slot "icon-suffix" "Icon elements being inlined with the input at the end"
 * @named_slot "button-suffix" "Button elements that are attached at the end of the text field"
 * @named_slot "prefix" "Text elements being inlined with the input at the beginning"
 * @named_slot "icon-prefix" "Icon elements being inlined with the input at the beginning"
 * @named_slot "button-prefix" "Button elements that are attached at the beginning of the text field"
 */
@WebComponent('hls-input')
@Component({
  selector: 'hls-input',
  templateUrl: 'input.component.html',
  styleUrls: ['input.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class InputComponent extends HeliosValueControl<string|number|boolean> {
  //#region Template Enums
  public readonly InputType: typeof InputType = InputType;
  //#endregion

  //#region Public Fields
  @ViewChild('prefixSlot')
  public prefixSlotRef: ElementRef;

  @ViewChild('iconPrefixSlot')
  public iconPrefixSlotRef: ElementRef;

  @ViewChild('buttonPrefixSlot')
  public buttonPrefixSlotRef: ElementRef;


  @ViewChild('suffixSlot')
  public suffixSlotRef: ElementRef;

  @ViewChild('iconSuffixSlot')
  public iconSuffixSlotRef: ElementRef;

  @ViewChild('buttonSuffixSlot')
  public buttonSuffixSlotRef: ElementRef;

  @ViewChild('input')
  public inputRef: ElementRef;
  //#endregion

  //#region Properties
  private _accept: string;
  /**
   * Gets or sets the expected file type in file upload controls.
   */
  @Input()
  @HostBinding('attr.accept')
  public get accept(): string {
    return this._accept;
  }
  public set accept(v: string) {
    this._accept = v;
  }

  private _autocomplete: string;
  /**
   * Gets or sets a value controlling the autocomplete mechanism of the browser.
   */
  @Input()
  @HostBinding('attr.autocomplete')
  public get autocomplete(): string {
    return this._autocomplete;
  }
  public set autocomplete(v: string) {
    this._autocomplete = v;
  }

  private _autofocus: boolean;
  /**
   * Gets or sets a value indicating wether this input is automatically focused when the page is loaded.
   */
  @Input()
  @HostBinding('attr.autofocus')
  public get autofocus(): boolean {
    return this._autofocus || null;
  }
  public set autofocus(v: boolean) {
    this._autofocus = coerceBooleanProperty(v);
  }

  @Input('checked')
  @HostBinding('attr.checked')
  public get checked(): boolean {
    return this._checked;
  }
  public set checked(v: boolean) {
    this._checked = coerceBooleanProperty(v);

    this._changeDetector.detectChanges();
  }

  private _checked: boolean;
  public get checkedInternal(): boolean {
    return this._checked;
  }
  public set checkedInternal(v: boolean) {
    this._checked = coerceBooleanProperty(v);
    this._updateValue(this.value, true);

    if (this.type === InputType.Radio) {
      this.updateSiblings();
    }
  }

  private _disabled: boolean;
  /**
   * Gets or sets the disabled state of the embedded HTML5 input field.
   */
  @Input()
  @HostBinding('attr.disabled')
  public get disabled(): any {
    return this._disabled || null;
  }
  public set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  private _max: number;
  /**
   * Gets or sets the maximum value.
   * Valid for numeric controls.
   */
  @Input()
  @HostBinding('attr.max')
  public get max(): number {
    return this._max;
  }
  public set max(v: number) {
    this._max = v;
  }

  private _maxlength: number;
  /**
   * Gets or sets the maximum length (number of characters) of the value.
   * Valid for:
   * - `InputType.Password`
   * - `InputType.Search`
   * - `InputType.Tel`
   * - `InputType.Text`
   * - `InputType.Url`
   */
  @Input()
  @HostBinding('attr.maxlength')
  public get maxlength(): number {
    return this._maxlength;
  }
  public set maxlength(v: number) {
    this._maxlength = v;
  }

  private _min: number;
  /**
   * Gets or sets the minimum value.
   * Valid for numeric controls.
   */
  @Input()
  @HostBinding('attr.min')
  public get min(): number {
    return this._min;
  }
  public set min(v: number) {
    this._min = v;
  }

  private _minlength: number;
  /**
   * Gets or sets the minimum length (number of characters) of the value.
   * Valid for:
   * - `InputType.Password`
   * - `InputType.Search`
   * - `InputType.Tel`
   * - `InputType.Text`
   * - `InputType.Url`
   */
  @Input()
  @HostBinding('attr.minlength')
  public get minlength(): number {
    return this._minlength;
  }
  public set minlength(v: number) {
    this._minlength = v;
  }

  private _multiple: boolean;
  /**
   * Gets or sets a value indicating whether to allow multiple values.
   * Valid for:
   * - `InputType.File`
   * - `InputType.Email`
   */
  @Input()
  @HostBinding('attr.multiple')
  public get multiple(): boolean {
    return this._multiple || null;
  }
  public set multiple(v: boolean) {
    this._multiple = coerceBooleanProperty(v);
  }

  private _name: string;
  /**
   * Gets or sets the name of this control.
   */
  @Input()
  @HostBinding('attr.name')
  public get name(): string {
    return this._name;
  }
  public set name(v: string) {
    this._name = v;
  }

  private _pattern: string;
  /**
   * Gets or sets a pattern the value must match to be valid.
   */
  @Input()
  @HostBinding('attr.pattern')
  public get pattern(): string {
    return this._pattern;
  }
  public set pattern(v: string) {
    this._pattern = v;
  }

  private _placeholder: string;
  /**
   * Gets or sets the placeholder of the embedded HTML5 input field.
   */
  @Input()
  @HostBinding('attr.placeholder')
  public get placeholder(): string {
    return this._placeholder;
  }
  public set placeholder(v: string) {
    this._placeholder = v;
  }

  private _readonly: boolean;
  /**
   * Gets or sets the readonly state of the embedded HTML5 input field.
   */
  @HostBinding('attr.readonly')
  @Input()
  public get readonly(): any {
    return this._readonly || null;
  }
  public set readonly(value: any) {
    this._readonly = coerceBooleanProperty(value);
  }

  private _required: boolean;
  /**
   * Gets or sets a value indicating wether this control is required.
   */
  @HostBinding('attr.required')
  @Input()
  public get required(): any {
    return this._required || null;
  }
  public set required(value: any) {
    this._required = coerceBooleanProperty(value);
  }

  private _showStepper = true;
  /**
   * Gets or sets a value indicating wether a numeric control should show the stepper buttons.
   * Default: `true`
   */
  @Input()
  @HostBinding('attr.show-stepper')
  public get showStepper(): boolean {
    return this._showStepper;
  }
  public set showStepper(v: boolean) {
    this._showStepper = coerceBooleanProperty(v);
  }


  private _indeterminate = false;
  /**
   * Gets or sets a value indicating if this checkbox should be shown as indeterminate
   */
  @Input()
  @HostBinding('attr.indeterminate')
  public get indeterminate(): boolean {
    return this._indeterminate;
  }
  public set indeterminate(v: boolean) {
    this._indeterminate = coerceBooleanProperty(v);
  }


  private _step: number;
  /**
   * Gets or sets the delta value that the controls value gets in-/decresed when stepping up/down
   */
  @Input()
  @HostBinding('attr.step')
  public get step(): number {
    return this._step;
  }
  public set step(v: number) {
    this._step = v;
  }

  private _type: InputType;
  /**
   * Gets or sets the type of the embedded HTML5 input field.
   */
  @Input()
  @HostBinding('attr.type')
  public get type(): InputType {
    return this._type;
  }
  public set type(v: InputType) {
    this._type = v;

    this.isSingleLine = [
      InputType.Date,
      InputType.DatetimeLocal,
      InputType.Email,
      InputType.File,
      InputType.Number,
      InputType.Password,
      InputType.Search,
      InputType.Tel,
      InputType.Text,
      InputType.Time,
      InputType.Url,
      InputType.Week
    ].includes(v);
  }

  private _isSingleLine: boolean;
  public get isSingleLine(): boolean {
    return this._isSingleLine;
  }
  public set isSingleLine(v: boolean) {
    this._isSingleLine = v;
  }
  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of a Helios Input Control
   *
   * @param document The document that this control is attached to
   * @param elementRef The control's element reference
   * @param config The configuration that this control was initialized with
   * @param changeDetector The change detector service that this control may use.
   */
    public constructor(
    @Inject(DOCUMENT) document: Document,
    elementRef: ElementRef,
    @Inject(TswpUiLibraryConfigToken) config: TswpUiLibraryConfig,
    changeDetector: ChangeDetectorRef) {
    super(document, elementRef, config, changeDetector);
  }
  //#endregion

  //#region Public Methods
  /**
   * When using input type number, increases the value by the specified `step` or `1`, if not set.
   */
  @ElementMethod('stepUp')
  public stepUp(): void {
    const input = this.inputRef.nativeElement as HTMLInputElement;
    input.stepUp();
    this.value = input.value;
  }

  /**
   * When using input type number, decreases the value by the specified `step` or `1`, if not set.
   */
  @ElementMethod('stepDown')
  public stepDown(): void {
    const input = this.inputRef.nativeElement as HTMLInputElement;
    input.stepDown();
    this.value = input.value;
  }

  public updateCheckedState($event: Event): void {
    this.checkedInternal = ($event.target as HTMLInputElement).checked;
  }
  //#endregion

  //#region Protected Methods
  protected updateSiblings(): void {
    if (!this.checkedInternal) {
      return;
    }

    const type = InputComponent as WebComponentType<InputComponent>;
    const siblings = Array.from(this.htmlElement.getRootNode().childNodes as NodeListOf<HTMLElement>)
      .filter(x => x.nodeType === 1)
      .map(x => Array.from(x.querySelectorAll(`${type.customElementSelector}[type="${this.type}"][name="${this.name}"]`)))
      .reduce((previous, current) => [...previous, ...current] as Partial<InputComponent>[], new Array<Partial<InputComponent>>());

    siblings
      .filter(x => x !== this.htmlElement)
      .map(x => x as InputComponent)
      .filter(x => x.checkedInternal)
      .forEach(x => x.checkedInternal = false);
  }

  protected parseValue(value: any): string|number|boolean {
    if (this.type === InputType.Number) {
      return Number(value);
    }

    return super.parseValue(value);
  }
  //#endregion
}
