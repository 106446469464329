import { BehaviorSubject } from 'rxjs';
import { KeycloakTokenParsed } from 'keycloak-js';
import { Event } from '@trustedshops/tswp-core-common';
import { Session } from '../models/session.interface';

/**
 * Base class for implementing a session provider
 */
export interface SessionProviderService<T extends Session, TOptions> {
    /**
     * Gets the current session that this provider manages actively
     */
    readonly session: Event<T>;
    /**
     * Gets the type of this session provider
     */
    readonly type: string;
    /**
     * Indicates if the session provider has an active session
     */
    readonly hasSession: boolean;
    /**
     * Gets the Keyckloak User Info form the token
     */
    readonly parsedToken: BehaviorSubject<KeycloakTokenParsed>
    /**
     * Establishes a session and verifies if the user has been logged in already.
     * @throws {SessionError} Thrown when an error occurs while establishing the session.
     */
    initialize(options: TOptions): any;
    /**
     * Gets the URI showing the login form.
     * @param redirectUri The URL to redirect to after being logged-in successfully
     * @param locale The locale to use in the login UI
     */
    getLoginUri(redirectUri?: string, locale?: string): any;
    /**
     * Gets the uri to logout.
     */
    getLogoutUri(): any;
}
