import { CultureInfo } from '../culture-info.model';
import { CultureInfoSt } from './st';

/**
 * @ignore
 */
export const CultureInfoStLS: CultureInfo = {
  name: 'st-LS',
  displayName: 'Sesotho (LS)',
  iso3LanguageCode: 'sot',
  iso2LanguageCode: 'st',
  ietfLanguageTag: 'st-LS',
  parent: CultureInfoSt
};
