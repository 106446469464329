import { CultureInfo } from '../culture-info.model';
import { CultureInfoSat } from './sat';

/**
 * @ignore
 */
export const CultureInfoSatOlck: CultureInfo = {
  name: 'sat-Olck',
  displayName: 'ᱥᱟᱱᱛᱟᱲᱤ',
  iso3LanguageCode: 'sat',
  iso2LanguageCode: 'sat',
  ietfLanguageTag: 'sat-Olck',
  parent: CultureInfoSat
};
