import { Injectable, Inject, APP_INITIALIZER } from '@angular/core';
import {
  ResourceType,
  BootstrapperBase,
  DomResourceService,
  bootstrapperFactory,
  TOKENS} from '@trustedshops/tswp-core-common';
import { JsDomResourceLoaderService } from '../services/resources/js-dom-resource-loader.service';

/**
 * Adds an instance of JsDomResourceLoaderService to the DomResourceService
 */
@Injectable()
export class JsResourceLoaderRegistration implements BootstrapperBase {
  //#region Ctor
  /**
   * Creates an instance of JsResourceLoaderRegistration
   * @param _domResourceService The DomResourceService to register to
   * @param _cssResourceLoader The JsDomResourceLoaderService to register
   */
  public constructor(
    @Inject(TOKENS.DomResourceService) private readonly _domResourceService: DomResourceService,
    private readonly _javascriptResourceLoader: JsDomResourceLoaderService) {
  }
  //#endregion

  //#region Public Methods
  /**
   * Runs the resource loader registration
   */
  public async initialize(): Promise<void> {
    this._domResourceService
      .registerLoader(ResourceType.JavaScript, this._javascriptResourceLoader);
  }
  //#endregion
}

/**
 * Adds an instance of JsDomResourceLoaderService to the DomResourceService
 */
export const JsResourceLoaderInitializer = [
  JsResourceLoaderRegistration,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: bootstrapperFactory,
    deps: [JsResourceLoaderRegistration]
  }
];
