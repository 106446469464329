import { CultureInfo } from '../culture-info.model';
import { CultureInfoHy } from './hy';

/**
 * @ignore
 */
export const CultureInfoHyAM: CultureInfo = {
  name: 'hy-AM',
  displayName: 'Armenian (Armenia)',
  iso3LanguageCode: 'hye',
  iso2LanguageCode: 'hy',
  ietfLanguageTag: 'hy-AM',
  parent: CultureInfoHy
};
