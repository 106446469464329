import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoFf: CultureInfo = {
  name: 'ff',
  displayName: 'Fulah',
  iso3LanguageCode: 'ful',
  iso2LanguageCode: 'ff',
  ietfLanguageTag: 'ff',
  parent: null
};
