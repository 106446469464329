import { CultureInfo } from '../culture-info.model';
import { CultureInfoSo } from './so';

/**
 * @ignore
 */
export const CultureInfoSoET: CultureInfo = {
  name: 'so-ET',
  displayName: 'Soomaali (Itoobiya)',
  iso3LanguageCode: 'som',
  iso2LanguageCode: 'so',
  ietfLanguageTag: 'so-ET',
  parent: CultureInfoSo
};
