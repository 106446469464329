import { CultureInfo } from '../culture-info.model';
import { CultureInfoChr } from './chr';

/**
 * @ignore
 */
export const CultureInfoChrUS: CultureInfo = {
  name: 'chr-US',
  displayName: 'ᏣᎳᎩ (ᏌᏊ ᎢᏳᎾᎵᏍᏔᏅ ᏍᎦᏚᎩ)',
  iso3LanguageCode: 'chr',
  iso2LanguageCode: 'chr',
  ietfLanguageTag: 'chr-US',
  parent: CultureInfoChr
};
