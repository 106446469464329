import { Component } from '@angular/core';
import { RxJsBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import { NavigateFirstUserInteractionHandler } from '@trustedshops/tswp-core-ui';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserInteractionComponentBase } from '../user-interaction-component-base';

/**
 * Component rendering a navigate interaction
 */
@Component({
  selector: 'navigate-first-interaction',
  templateUrl: 'navigate-first-interaction.component.html',
  standalone: false,
})
export class NavigateFirstInteractionComponent extends UserInteractionComponentBase<NavigateFirstUserInteractionHandler> {
  //#region Properties
  private _renderingOptions$: Observable<string>;
  public get renderingOptions$(): Observable<string> {
    if (!this.renderingOptions) {
      return undefined;
    }

    return this._renderingOptions$ = this._renderingOptions$
      || this.renderingOptions.convertWith(RxJsBridge(BehaviorSubject));
  }
  //#endregion
}
