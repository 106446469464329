import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { BootstrapCustomElements } from '../../decorators/bootstrap-custom-elements.decorator';
import { SharedModule } from '../../shared.module';
import { WebComponentControlModule } from '../../web-component-control.module';
import { NotificationComponent } from './notification.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [NotificationComponent],
  exports: [NotificationComponent],
  imports: [
    CommonModule,
    SharedModule,
  ]
})
@BootstrapCustomElements([
  NotificationComponent
])
export class HlsNotificationModule extends WebComponentControlModule { }
