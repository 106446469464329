import { CultureInfo } from '../culture-info.model';
import { CultureInfoEn } from './en';

/**
 * @ignore
 */
export const CultureInfoEnNR: CultureInfo = {
  name: 'en-NR',
  displayName: 'English (Nauru)',
  iso3LanguageCode: 'eng',
  iso2LanguageCode: 'en',
  ietfLanguageTag: 'en-NR',
  parent: CultureInfoEn
};
