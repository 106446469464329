import { PluginEntryPointType } from './plugin-entry-point-type.interface';
import { PluginEntryPoint } from './plugin-entry-point.interface';

export interface PluginArrivalInfo {
  /**
   * Gets an instance of the plugin entry point.
   */
  entryPoint?: PluginEntryPoint;

  /**
   * Gets the type of the plugin
   * entry point to create it using dependency injection.
   */
  entryPointType?: PluginEntryPointType;

  /**
   * Gets a list of extra ordinary services this plugin needs.
   */
  extraOrdinaryServices: string[];
}
