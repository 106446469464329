import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoAs: CultureInfo = {
  name: 'as',
  displayName: 'Assamese',
  iso3LanguageCode: 'asm',
  iso2LanguageCode: 'as',
  ietfLanguageTag: 'as',
  parent: null
};
