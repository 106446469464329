import { NavigationItem } from '../../models/navigation/navigation-item.interface';

/**
 * Declaration for an object that provides breadcrumbs for a given route
 */
export interface BreadcrumbProvider {
  /**
   * Gets or sets the order index at which this provider should be executed
   */
  readonly order: number;

  /**
   * Gets the breadcrumbs for a given route
   *
   * @param route The route to return the breadcrumbs for.
   * @returns The breadcrumbs for the given route. Empty array, if no breadcrumbs found
   */
  getBreadcrumbs(route: string): NavigationItem[];
}
