import { CultureInfo } from '../culture-info.model';
import { CultureInfoJv } from './jv';

/**
 * @ignore
 */
export const CultureInfoJvID: CultureInfo = {
  name: 'jv-ID',
  displayName: 'Jawa (Indonésia)',
  iso3LanguageCode: 'jav',
  iso2LanguageCode: 'jv',
  ietfLanguageTag: 'jv-ID',
  parent: CultureInfoJv
};
