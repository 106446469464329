import { CultureInfo } from '../culture-info.model';
import { CultureInfoHu } from './hu';

/**
 * @ignore
 */
export const CultureInfoHuHU: CultureInfo = {
  name: 'hu-HU',
  displayName: 'Hungarian (Hungary)',
  iso3LanguageCode: 'hun',
  iso2LanguageCode: 'hu',
  ietfLanguageTag: 'hu-HU',
  parent: CultureInfoHu
};
