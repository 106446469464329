import { APP_INITIALIZER, Inject, Injectable, Injector } from '@angular/core';
import { AbstractReplayEvent, BootstrapperBase, ExportToken, ExportTokenDefinition } from '@trustedshops/tswp-core-common';
import { DependencyContainer, TOKENS } from '@trustedshops/tswp-core-composition';

/**
 * Creates Mappings between services and tokens using ExportToken (tswp-core-common) decorator
 * Registers these mappings to a foreign dependency container
 */
@Injectable()
class ServiceMappingBootstrapper implements BootstrapperBase {
  //#region Ctor
  /**
   * Creates an instance of ServiceMappingBootstrapper
   * @param _dependencyContainer The dependency container the services are registered to
   * @param _injector The injector to get the services singletons from.
   */
  public constructor(
    @Inject(TOKENS.DependencyContainer) private readonly _dependencyContainer: DependencyContainer,
    private readonly _injector: Injector) {
  }
  //#endregion

  //#region Public Methods
  /**
   * Runs the service registrations
   */
  public async initialize(): Promise<void> {
    const tokenNotifications = (ExportToken as any).tokens as AbstractReplayEvent<ExportTokenDefinition<any>>;

    tokenNotifications.subscribe(exportToken => {
      let instance: any = null;
      try {
        instance = this._injector.get(exportToken.type);
      } catch {
        instance = this._injector.get<any>(exportToken.token as any);
      }

      this._dependencyContainer
        .registerTokenizedValue(
          exportToken.token,
          instance);
    });
  }
  //#endregion
}

/**
 * Creates Mappings between services and tokens using ExportToken (tswp-core-common) decorator
 * Registers these mappings to a foreign dependency container
 */
export const ServiceMappingInitializer = [
  ServiceMappingBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [ServiceMappingBootstrapper]
  }
];
