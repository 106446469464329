import { CultureInfo } from '../culture-info.model';
import { CultureInfoNds } from './nds';

/**
 * @ignore
 */
export const CultureInfoNdsDE: CultureInfo = {
  name: 'nds-DE',
  displayName: 'nds (DE)',
  iso3LanguageCode: 'nds',
  iso2LanguageCode: 'nds',
  ietfLanguageTag: 'nds-DE',
  parent: CultureInfoNds
};
