import { ErrorBase } from '@trustedshops/tswp-core-common';
import { PluginLoadError as IPluginLoadError } from '@trustedshops/tswp-core-plugins';

export class PluginLoadError extends ErrorBase implements IPluginLoadError {
  //#region Properties
  private _pluginName: string;
  public get pluginName(): string {
    return this._pluginName;
  }
  //#endregion

  //#region Ctor
  public constructor(message: string, pluginName: string, innerError?: Error) {
    super(message, innerError);
    this._pluginName = pluginName;
  }
  //#endregion
}
