import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoFy: CultureInfo = {
  name: 'fy',
  displayName: 'Western Frisian',
  iso3LanguageCode: 'fry',
  iso2LanguageCode: 'fy',
  ietfLanguageTag: 'fy',
  parent: null
};
