import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoFo: CultureInfo = {
  name: 'fo',
  displayName: 'Faroese',
  iso3LanguageCode: 'fao',
  iso2LanguageCode: 'fo',
  ietfLanguageTag: 'fo',
  parent: null
};
