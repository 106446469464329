import { CultureInfo } from '../culture-info.model';
import { CultureInfoAr } from './ar';

/**
 * @ignore
 */
export const CultureInfoArSA: CultureInfo = {
  name: 'ar-SA',
  displayName: 'Arabic (Saudi Arabia)',
  iso3LanguageCode: 'ara',
  iso2LanguageCode: 'ar',
  ietfLanguageTag: 'ar-SA',
  parent: CultureInfoAr
};
