import { Injectable } from '@angular/core';
import { ExportToken, RootTranslationService, TOKENS, TranslationService } from '@trustedshops/tswp-core-common';
import { ChildTranslationService } from '@trustedshops/tswp-core-common';
import { TranslationServiceImpl } from './translation.service';

@Injectable()
@ExportToken(TOKENS.TranslationService)
export class RootTranslationServiceImpl extends TranslationServiceImpl implements RootTranslationService {
  private readonly _children: Map<string, TranslationService> = new Map<string, TranslationService>();

  //#region Public Methods
  public createChild(scope: string): ChildTranslationService {
    const child = new TranslationServiceImpl(this._httpClient, this._logService, this._cultureInfoService, this._configuration);
    child.parent = this;
    this._children.set(scope, child);

    return child;
  }
  //#endregion
}
