import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoMn: CultureInfo = {
  name: 'mn',
  displayName: 'Mongolian',
  iso3LanguageCode: 'mon',
  iso2LanguageCode: 'mn',
  ietfLanguageTag: 'mn',
  parent: null
};
