export interface GoogleAnalyticsTrackerConfiguration {
  /**
   * The google analytics property id, e.g. UA-1234556-XY
   */
  property: string;

  /**
   * The URL to the google analytics scripts
   */
  gtagScriptUri: string;

  //#region Google Analytics Configuration
  /**
   * Indicates wether ip addresses should be anonymized
   */
  anonymize_ip?: boolean;

  /**
   * Indicates wether google analytics should automatically send page views for static site requests.
   */
  send_page_view?: boolean;

  /**
   * Used by the config command to assign a target to one or more groups.
   * See https://developers.google.com/tag-platform/gtagjs/reference/parameters
   */
  groups?: string | string[];


  /** Google does not provide an official TypeScript interface for GA4 configuration.
   * Since this interface may change multiple times with GA4 evolution,
   * we keep the type below flexible without enforcing strict values.
   *
   * As of February 2025, we rely on the following documentation:
   * https://developers.google.com/analytics/devguides/collection/ga4/reference/config
   */
  [key: string]: any;

  //#endregion
}
