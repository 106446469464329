import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BootstrapCustomElements } from '../../decorators/bootstrap-custom-elements.decorator';
import { SharedModule } from '../../shared.module';
import { WebComponentControlModule } from '../../web-component-control.module';
import { SelectComponent } from './select.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [SelectComponent],
  exports: [SelectComponent],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatInputModule,
    BrowserAnimationsModule
  ]
})
@BootstrapCustomElements([SelectComponent])
export class HlsSelectModule extends WebComponentControlModule { }
