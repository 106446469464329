import { CultureInfo } from '../culture-info.model';
import { CultureInfoSw } from './sw';

/**
 * @ignore
 */
export const CultureInfoSwKE: CultureInfo = {
  name: 'sw-KE',
  displayName: 'Swahili (Kenya)',
  iso3LanguageCode: 'swa',
  iso2LanguageCode: 'sw',
  ietfLanguageTag: 'sw-KE',
  parent: CultureInfoSw
};
