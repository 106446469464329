/**
 * Enum for defining the rating star size rendered
 */
export enum StarSize {
  /**
   * Small stars
   */
  Small = 'small',

  /**
   * Default sized stars
   */
  Default = 'default'
}
