import { CultureInfo } from '../culture-info.model';
import { CultureInfoWae } from './wae';

/**
 * @ignore
 */
export const CultureInfoWaeCH: CultureInfo = {
  name: 'wae-CH',
  displayName: 'Walser (Schwiz)',
  iso3LanguageCode: 'wae',
  iso2LanguageCode: 'wae',
  ietfLanguageTag: 'wae-CH',
  parent: CultureInfoWae
};
