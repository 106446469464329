import {
  BootstrapperBase,
  HttpClientService,
  HttpInterceptor,
  TOKENS,
} from '@trustedshops/tswp-core-common';
import { AngularKeycloakHttpInterceptor } from '@trustedshops/tswp-core-authorization-keycloak-implementation';
import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { CarrierConfig } from '../models/carrier-config.interface';
import { CarrierConfigToken } from '../models/carrier-config.token';

@Injectable()
export class ProtectedUrlsBootstrapper implements BootstrapperBase {
  private readonly interceptor: AngularKeycloakHttpInterceptor;
  private readonly carrierConfig: CarrierConfig;
  private readonly httpClient: HttpClientService;

  public constructor(
    interceptor: AngularKeycloakHttpInterceptor,
    @Inject(TOKENS.HttpClientService) httpClient: HttpClientService,
    @Inject(CarrierConfigToken) carrierConfig: CarrierConfig,
  ) {
    this.httpClient = httpClient;
    this.interceptor = interceptor;
    this.carrierConfig = carrierConfig;
  }

  public async initialize(): Promise<void> {
    this.httpClient.registerInterceptor(
      this.interceptor as unknown as HttpInterceptor,
    );

    this.carrierConfig.authorization.protectedEndpoints.forEach((endpoint) =>
      this.interceptor.registerProtectedUrl(
        this.createWildcardPattern(endpoint),
      ),
    );

    this.interceptor.activate();
  }

  private createWildcardPattern(url: string): RegExp {
    return new RegExp(`${url.replace(/\//g, '\\/')}.*`);
  }
}

export const ProtectedUrlsInitializer = [
  ProtectedUrlsBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [ProtectedUrlsBootstrapper]
  }
];
