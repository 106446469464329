import { Injectable, APP_INITIALIZER, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { BootstrapperBase, LogService, TOKENS } from '@trustedshops/tswp-core-common';
import { TOKENS as TOKENS_ANALYTICS, TrackerType, TrackingService } from '@trustedshops/tswp-core-analytics';
import { TOKENS as MASTERDATA_TOKENS, FeatureBookingService } from '@trustedshops/tswp-core-masterdata';
import { Environment, ENVIRONMENT } from '../models/environment.type';
import { UserCentricsConsent } from '../core/user-centrics-consent';

type AccountSubscriptionStatus = 'free' | 'paid' | 'unknown';

@Injectable()
export class GoogleAnalyticsTrackingBootstrapper extends UserCentricsConsent implements BootstrapperBase {
  public static readonly TYPE = 'GoogleAnalyticsTrackingBootstrapper';

  public constructor(
    @Inject(TOKENS_ANALYTICS.TrackingService) private readonly _trackingService: TrackingService,
    @Inject(ENVIRONMENT) private readonly _environment: Environment,
    @Inject(DOCUMENT) readonly _document: Document,
    @Inject(MASTERDATA_TOKENS.FeatureBookingService) private readonly featureBookingService: FeatureBookingService,
    @Inject(TOKENS.LogService)
    private readonly logService: LogService,
  ) {
    super(_document);
  }

  public async initialize(): Promise<void> {
    const hasGoogleAnalytics4Consent = await this.hasGoogleAnalytics4Consent();
    this.ensureGoogleAnalyticsTrackerRegistered(hasGoogleAnalytics4Consent);
  }

  private async hasGoogleAnalytics4Consent(): Promise<boolean> {
    return await this.userConsentObtained('Google Analytics 4');
  }

  private async ensureGoogleAnalyticsTrackerRegistered(hasGoogleAnalytics4Consent: boolean): Promise<void> {
    const googleAnalyticsTracker = this._environment.tracking.googleAnalytics;

    if (!hasGoogleAnalytics4Consent || this._trackingService.trackers.includes(googleAnalyticsTracker)) {
      return;
    }

    try {
      const subscriptionStatus: AccountSubscriptionStatus = await this.getAccountSubscription();

      this._trackingService.registerTracker(TrackerType.GoogleAnalytics, {
        property: this._environment.tracking.googleAnalytics4,
        anonymize_ip: true,
        send_page_view: true,
        account_type: subscriptionStatus,
        gtagScriptUri: 'https://www.googletagmanager.com/gtag/js',
      });
    } catch (error: unknown) {
      this.logService.error(
        GoogleAnalyticsTrackingBootstrapper.TYPE,
        new Error('Google Analytics init has failed'),
        error
      );
    }
  }

  private async getAccountSubscription(): Promise<AccountSubscriptionStatus> {
    try {
      const [isFreeAccount, isPaidAccount]: [boolean, boolean] = await Promise.all([
        this.featureBookingService.isFreeAccount(),
        this.featureBookingService.isPaidAccount()
      ]);

      if (isFreeAccount) {
        return 'free';
      }

      if (isPaidAccount) {
        return 'paid';
      }

      return 'unknown';
    } catch (error: unknown) {
      this.logService.error(
        GoogleAnalyticsTrackingBootstrapper.TYPE,
        new Error('Get Account Subscription has failed'),
        error
      );

      return 'unknown';
    }
  }
}

export const GoogleAnalyticsTrackingInitializer = [
  GoogleAnalyticsTrackingBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: GoogleAnalyticsTrackingBootstrapper) => {
      return () => initializer.initialize();
    },
    deps: [GoogleAnalyticsTrackingBootstrapper]
  }
];
