import { CultureInfo } from '../culture-info.model';
import { CultureInfoRn } from './rn';

/**
 * @ignore
 */
export const CultureInfoRnBI: CultureInfo = {
  name: 'rn-BI',
  displayName: 'Ikirundi (Uburundi)',
  iso3LanguageCode: 'run',
  iso2LanguageCode: 'rn',
  ietfLanguageTag: 'rn-BI',
  parent: CultureInfoRn
};
