import { ErrorHandler, NgModule } from '@angular/core';
import { InstanaErrorHandler } from './services/instana-error.handler';

@NgModule({
  providers: [
    {
      provide: ErrorHandler,
      useValue: InstanaErrorHandler,
    },
    InstanaErrorHandler,
  ],
})
export class TswpCoreCommonDiagInstanaModule { }
