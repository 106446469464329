import { Type } from '@angular/core';
import { WebComponentType } from '../models/web-component-type';

/**
 * Enables a WebComponent registration for the assigned component and declares a selector for it
 *
 * @param selector The selector that this component should be registered
 * @decorator
 */
export function WebComponent(selector: string): (constructor: Type<any>) => any {
  return (target: WebComponentType<any>) => {
    target.customElementSelector = selector;
    return target;
  };
}
