import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoHsb: CultureInfo = {
  name: 'hsb',
  displayName: 'Upper Sorbian',
  iso3LanguageCode: 'hsb',
  iso2LanguageCode: 'hsb',
  ietfLanguageTag: 'hsb',
  parent: null
};
