import { UserInteractionHandler } from './user-interaction-handler';

/**
 * Predefined user interaction for navigating to the first child of the navigation item.
 */
export interface NavigateFirstUserInteractionHandler extends UserInteractionHandler {
  readonly parentNavigationItemId: string;
  readonly childInteraction: UserInteractionHandler;
  readonly childNavigationItemRegion: string;
}
