import { Injectable, Inject } from '@angular/core';
import { PluginHost, PluginHostProvider } from '@trustedshops/tswp-core-plugins';
import { DOCUMENT } from '@angular/common';

/** @internal */
@Injectable()
export class BrowserPluginHostProvider implements PluginHostProvider {
  //#region Ctor
  public constructor(@Inject(DOCUMENT) private readonly _document: Document) { }
  //#endregion

  //#region Public Methods
  public provideHost(): PluginHost {
    return this._document.defaultView as any as PluginHost;
  }
  //#endregion
}
