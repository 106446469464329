import { CultureInfo } from '../culture-info.model';
import { CultureInfoBo } from './bo';

/**
 * @ignore
 */
export const CultureInfoBoIN: CultureInfo = {
  name: 'bo-IN',
  displayName: 'བོད་སྐད་ (རྒྱ་གར་)',
  iso3LanguageCode: 'bod',
  iso2LanguageCode: 'bo',
  ietfLanguageTag: 'bo-IN',
  parent: CultureInfoBo
};
