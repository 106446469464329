import { CultureInfo } from '../culture-info.model';
import { CultureInfoBem } from './bem';

/**
 * @ignore
 */
export const CultureInfoBemZM: CultureInfo = {
  name: 'bem-ZM',
  displayName: 'Ichibemba (Zambia)',
  iso3LanguageCode: 'bem',
  iso2LanguageCode: 'bem',
  ietfLanguageTag: 'bem-ZM',
  parent: CultureInfoBem
};
