import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoCa: CultureInfo = {
  name: 'ca',
  displayName: 'Catalan',
  iso3LanguageCode: 'cat',
  iso2LanguageCode: 'ca',
  ietfLanguageTag: 'ca',
  parent: null
};
