import { Event } from '@trustedshops/tswp-core-common';

/**
 * Describes the configuration of one navigation region
 */
export interface NavigationRegionConfiguration {
  /**
   * Gets or sets the name of the navigation region
   */
  name: string;
  /**
   * Gets or sets the initial state of the navigation area
   */
  initialState: {
    /**
     * Gets or sets a value indicating wether the region is collapsed
     */
    collapsed: Event<boolean>;
  };

  /**
   * Gets or sets information about the animation durations for collapsing and expanding
   */
  animationDurations: {
    /**
     * Gets or sets the number of milliseconds it takes to collapse the region
     */
    collapse: number;

    /**
     * Gets or sets the number of milliseconds it takes to expand the region
     */
    expand: number;
  };
}
