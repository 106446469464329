import { CultureInfo } from '../culture-info.model';
import { CultureInfoCv } from './cv';

/**
 * @ignore
 */
export const CultureInfoCvRU: CultureInfo = {
  name: 'cv-RU',
  displayName: 'Чӑвашла (RU)',
  iso3LanguageCode: 'chv',
  iso2LanguageCode: 'cv',
  ietfLanguageTag: 'cv-RU',
  parent: CultureInfoCv
};
