import { CultureInfo } from '../culture-info.model';
import { CultureInfoMgo } from './mgo';

/**
 * @ignore
 */
export const CultureInfoMgoCM: CultureInfo = {
  name: 'mgo-CM',
  displayName: 'metaʼ (Kamalun)',
  iso3LanguageCode: 'mgo',
  iso2LanguageCode: 'mgo',
  ietfLanguageTag: 'mgo-CM',
  parent: CultureInfoMgo
};
