import { CultureInfo } from '../culture-info.model';
import { CultureInfoZu } from './zu';

/**
 * @ignore
 */
export const CultureInfoZuZA: CultureInfo = {
  name: 'zu-ZA',
  displayName: 'Zulu (South Africa)',
  iso3LanguageCode: 'zul',
  iso2LanguageCode: 'zu',
  ietfLanguageTag: 'zu-ZA',
  parent: CultureInfoZu
};
