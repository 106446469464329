import { EventSubscription } from './event-subscription.interface';
import { EventListenerBridge } from './event-bridge.interface';
import { SubscriptionHandler } from './subscription-handler-delegate.type';
import { Type } from '../system/type';

/**
 * Generic description of an event listener.
 */
export interface EventListener<TEmission> {
  /**
   * Subscribes to the event stream
   * @param subscriptionHandler The subscription handler that processes the event occured.
   * @param errorHandler The subscription handler that processes an error occured.
   */
  subscribe(
    subscriptionHandler: SubscriptionHandler<TEmission>,
    errorHandler?: SubscriptionHandler<Error>): EventSubscription<TEmission>;

  /**
   * Converts this abstract event stream to a specific event, e.g. RxJs Observables using a bridge
   *
   * @param bridgeType The bridge to use for converting
   * @param args The arguments to pass to the bridge
   */
  convertWith<TOut>(type: Type<EventListenerBridge<TOut, TEmission>>, ...args: any[]): TOut;

  /**
   * Removes the given subscription from the event stream for listening
   * @param subscription The subscription to remove
   */
  remove(subscription: EventSubscription<TEmission>): void;
}
