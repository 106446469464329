import { Injectable } from '@angular/core';
import { TOKENS, BusyState } from '@trustedshops/tswp-core-ui';
import { InternalBusyState } from '../models/internal-busy-state';
import { AbstractPersistentEvent, Event, ExportToken } from '@trustedshops/tswp-core-common';

/**
 * Service for managing application busy states in different areas of an application
 */
@Injectable()
@ExportToken(TOKENS.BusyService)
export class BusyServiceImpl {
  //#region Private Fields
  private readonly _busyRegions: Map<string, InternalBusyState> = new Map();
  //#endregion

  //#region Public Methods
  /**
   * Queues a new operation to the busy region, forcing its progression to be recalculated on progress emission.
   * As the given observable completes, its progress is stated as 100% (finished).
   * If the observable stays open, its maximum emitted value is overriden by 99% if exceeding.
   *
   * @param region The busy region to register the operation for
   * @param operationProgressStream The observable progress value to register
   */
  public queueOperation(region: string, operationProgressStream: Event<number>): void {
    const regionState = this.getBusyRegion(region);
    if (regionState.watcher) {
      regionState.watcher?.unsubscribe();
      regionState.watcher = null;

      regionState.subscriptions
        .forEach(x => x?.unsubscribe());
      regionState.subscriptions.splice(0, regionState.subscriptions.length);
    }

    const streams = [ ...regionState.operations, operationProgressStream];
    regionState.results = Array.from(Array(streams.length)).map(() => null);
    const progressStream = new AbstractPersistentEvent(0);
    const onStreamUpdate = (progresses: number[]) => {
      if (progresses.length && progresses.every(x => x === 100)) {
        progressStream.emit(100);
        return;
      }

      const remainingProgresses = progresses
        .filter(x => x !== 100);
      progressStream.emit(
        remainingProgresses.reduce(
          (prev, cur) => prev + cur,
          0) / remainingProgresses.length);
    };

    regionState.subscriptions = streams.map((stream, i) =>
      stream.subscribe(value => {
        regionState.results[i] = value;
        onStreamUpdate(regionState.results.filter(x => x !== null));
      }));

    regionState.watcher = progressStream.subscribe(progress => {
      regionState.stream.emit({
        isBusy: Math.min(progress, 100) !== 100,
        progress: Math.min(progress, 100)
      });
    });

    regionState.operations.push(operationProgressStream);
  }

  /**
   * Gets the progression of the busy region.
   *
   * @param region The region to get the state for
   */
  public getBusyState(region: string): Event<BusyState> {
    return this.getBusyRegion(region).stream;
  }
  //#endregion

  //#region Private Methods
  private createBusyState(): InternalBusyState {
    return {
      isBusy: false,
      progress: null,
      stream: new AbstractPersistentEvent<BusyState>({
        isBusy: false,
        progress: null
      }),
      watcher: null,
      operations: [],
      results: [],
      subscriptions: null
    };
  }

  private getBusyRegion(region: string): InternalBusyState {
    return this._busyRegions.get(region) || this._busyRegions.set(region, this.createBusyState()).get(region);
  }
  //#endregion
}
