import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoCcp: CultureInfo = {
  name: 'ccp',
  displayName: '𑄌𑄋𑄴𑄟𑄳𑄦',
  iso3LanguageCode: 'ccp',
  iso2LanguageCode: 'ccp',
  ietfLanguageTag: 'ccp',
  parent: null
};
