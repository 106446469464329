import { CultureInfo } from '../culture-info.model';
import { CultureInfoTa } from './ta';

/**
 * @ignore
 */
export const CultureInfoTaMY: CultureInfo = {
  name: 'ta-MY',
  displayName: 'தமிழ் (மலேசியா)',
  iso3LanguageCode: 'tam',
  iso2LanguageCode: 'ta',
  ietfLanguageTag: 'ta-MY',
  parent: CultureInfoTa
};
