import { AbstractEvent, Event } from '@trustedshops/tswp-core-common';

export function combineLatest(...events: Event<any>[]): Event<any[]> {
  const data = Array.from(Array(events.length));
  const newEvent = new AbstractEvent<any[]>();
  for (let i = 0; i < events.length; i++) {
    events[i].subscribe(x => {
      data[i] = x;

      if (!data.every(y => y)) {
        return;
      }

      newEvent.emit(data);
    });
  }
  return newEvent;
}
