import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoLb: CultureInfo = {
  name: 'lb',
  displayName: 'Luxembourgish',
  iso3LanguageCode: 'ltz',
  iso2LanguageCode: 'lb',
  ietfLanguageTag: 'lb',
  parent: null
};
