import { CultureInfo } from '../culture-info.model';
import { CultureInfoSrLatn } from './sr-Latn';

/**
 * @ignore
 */
export const CultureInfoSrLatnXK: CultureInfo = {
  name: 'sr-Latn-XK',
  displayName: 'srpski (latinica, Kosovo)',
  iso3LanguageCode: 'srp',
  iso2LanguageCode: 'sr',
  ietfLanguageTag: 'sr-Latn-XK',
  parent: CultureInfoSrLatn
};
