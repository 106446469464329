import { CultureInfo } from '../culture-info.model';
import { CultureInfoCgg } from './cgg';

/**
 * @ignore
 */
export const CultureInfoCggUG: CultureInfo = {
  name: 'cgg-UG',
  displayName: 'Rukiga (Uganda)',
  iso3LanguageCode: 'cgg',
  iso2LanguageCode: 'cgg',
  ietfLanguageTag: 'cgg-UG',
  parent: CultureInfoCgg
};
