import { CultureInfo } from '../culture-info.model';
import { CultureInfoTk } from './tk';

/**
 * @ignore
 */
export const CultureInfoTkTM: CultureInfo = {
  name: 'tk-TM',
  displayName: 'Turkmen (Turkmenistan)',
  iso3LanguageCode: 'tuk',
  iso2LanguageCode: 'tk',
  ietfLanguageTag: 'tk-TM',
  parent: CultureInfoTk
};
