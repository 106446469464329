import { CultureInfo } from '../culture-info.model';
import { CultureInfoMr } from './mr';

/**
 * @ignore
 */
export const CultureInfoMrIN: CultureInfo = {
  name: 'mr-IN',
  displayName: 'Marathi (India)',
  iso3LanguageCode: 'mar',
  iso2LanguageCode: 'mr',
  ietfLanguageTag: 'mr-IN',
  parent: CultureInfoMr
};
