import { CultureInfo } from '../culture-info.model';
import { CultureInfoVai } from './vai';

/**
 * @ignore
 */
export const CultureInfoVaiVaii: CultureInfo = {
  name: 'vai-Vaii',
  displayName: 'ꕙꔤ',
  iso3LanguageCode: 'vai',
  iso2LanguageCode: 'vai',
  ietfLanguageTag: 'vai-Vaii',
  parent: CultureInfoVai
};
