import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoMs: CultureInfo = {
  name: 'ms',
  displayName: 'Malay',
  iso3LanguageCode: 'msa',
  iso2LanguageCode: 'ms',
  ietfLanguageTag: 'ms',
  parent: null
};
