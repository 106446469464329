import { CultureInfo } from '../culture-info.model';
import { CultureInfoNb } from './nb';

/**
 * @ignore
 */
export const CultureInfoNbSJ: CultureInfo = {
  name: 'nb-SJ',
  displayName: 'norsk bokmål (Svalbard og Jan Mayen)',
  iso3LanguageCode: 'nob',
  iso2LanguageCode: 'nb',
  ietfLanguageTag: 'nb-SJ',
  parent: CultureInfoNb
};
