/**
 * Data Transfer Object Wrapper for an oganizational container
 */
export interface OrganizationalContainer<T> {

  /**
   * Gets the id of the container
   */
  readonly id: string;

  /**
   * Gets the display name of the container
   */
  readonly displayName: string;

  /**
   * Gets the type of the container
   */
  readonly type: string;

  /**
   * Gets the info data of the container, can be anything related and dynamic to pass a payload
   */
  readonly info: T;

  /**
   * Gets the resource type mapping of the container that is located inside `info`
   */
  readonly resourceType: string;
}
