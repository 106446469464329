import { AbstractPersistentEvent } from '../eventing/abstract-persistent-event';

/**
 * Service responsible for translations.
 */
export interface TranslationService {
  /**
   * Gets translations for the given `key`, from the currently loaded locale file.
   *
   * @param key The key to translate.
   * @returns The event stream with the translated value.
   */
  getTranslationForKey(key: string): AbstractPersistentEvent<string>;
}
