import { Session } from '../models/session.interface';
import { Identity } from '../models/identity.interface';
import { Event, NextInterceptorHandler } from '@trustedshops/tswp-core-common';

/**
 * Base class for a session interceptor
 */
export interface SessionInterceptor {
    /**
     * Gets the order index of this Interceptor
     */
    readonly order: number;
    /**
     * Called upon load of a session of which the user is considered as authenticated.
     *
     * @param session The session that has been loaded
     * @param next The next interceptor that is to be called. If not called, the interception chain is broken.
     */
    onSessionStart?(session: Session, next: NextInterceptorHandler<SessionInterceptor, Session>): Event<any>;
    /**
     * Called when the session ended or the initial load of the session results in an unauthenticated state.
     * @param next the next interceptor to call. If not called, the interception chain will be broken.
     */
    onSessionEnd?(next: NextInterceptorHandler<SessionInterceptor, void>): Event<void>;
    /**
     * Called upon load of an identity. This method may update or change the identity during the interception chain
     *
     * @param identity The identity that has been loaded
     * @param next The next interceptor that is to be called. If not called, the interception chain is broken.
     */
    onIdentityReceived?(identity: Identity<any>, next: NextInterceptorHandler<SessionInterceptor, Identity<any>>): Event<any>;
}
