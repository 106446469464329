import { CultureInfo } from '../culture-info.model';
import { CultureInfoArn } from './arn';

/**
 * @ignore
 */
export const CultureInfoArnCL: CultureInfo = {
  name: 'arn-CL',
  displayName: 'Mapuche (Chile)',
  iso3LanguageCode: 'arn',
  iso2LanguageCode: 'arn',
  ietfLanguageTag: 'arn-CL',
  parent: CultureInfoArn
};
