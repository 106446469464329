import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKcg: CultureInfo = {
  name: 'kcg',
  displayName: 'Katab',
  iso3LanguageCode: 'kcg',
  iso2LanguageCode: 'kcg',
  ietfLanguageTag: 'kcg',
  parent: null
};
