import { CultureInfo } from '../culture-info.model';
import { CultureInfoSd } from './sd';

/**
 * @ignore
 */
export const CultureInfoSdDeva: CultureInfo = {
  name: 'sd-Deva',
  displayName: 'सिन्धी',
  iso3LanguageCode: 'snd',
  iso2LanguageCode: 'sd',
  ietfLanguageTag: 'sd-Deva',
  parent: CultureInfoSd
};
