import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoWuu: CultureInfo = {
  name: 'wuu',
  displayName: '上海话',
  iso3LanguageCode: 'wuu',
  iso2LanguageCode: 'wuu',
  ietfLanguageTag: 'wuu',
  parent: null
};
