import { UserInteractionHandler } from '../handlers/user-interaction-handler';

/**
 * Base class for a user interaction handlerd
 */
export abstract class UserInteractionHandlerBase
  implements UserInteractionHandler {

  //#region Properties
  private _type: string;
  /**
   * Gets or sets the type of interaction
   */
  public get type(): string {
    return this._type;
  }
  public set type(v: string) {
    this._type = v;
  }
  //#endregion

  //#region Public Methods
  public async onInitialize?(): Promise<void>;
  public async onDispose?(): Promise<void>;
  //#endregion
}
