import { CultureInfo } from '../culture-info.model';
import { CultureInfoRu } from './ru';

/**
 * @ignore
 */
export const CultureInfoRuKZ: CultureInfo = {
  name: 'ru-KZ',
  displayName: 'русский (Казахстан)',
  iso3LanguageCode: 'rus',
  iso2LanguageCode: 'ru',
  ietfLanguageTag: 'ru-KZ',
  parent: CultureInfoRu
};
