import { ErrorBase } from './error-base';

/**
 * The exception that is thrown when a method call is invalid for the object's current state.
 */
export class InvalidOperationError extends ErrorBase {
  //#region Ctor
  /**
   * Initializes a new instance of the InvalidOperationException class with a specified error message.
   * @param msg The message that describes the error.
   */
  public constructor(msg: string, innerError?: Error) {
    super(`InvalidOperationError: ` + msg, innerError);
  }
  //#endregion
}
