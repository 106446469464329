export enum LinkTarget {
  /**
   * Indicates that a Uri should be opened in the same tab/window
   */
  SameWindow = '_self',

  /**
   * Indicates that a Uri should be opened in a new tab/window
   */
  NewWindow = '_blank'
}
