import { CultureInfo } from '../culture-info.model';
import { CultureInfoHr } from './hr';

/**
 * @ignore
 */
export const CultureInfoHrHR: CultureInfo = {
  name: 'hr-HR',
  displayName: 'Croatian (Croatia)',
  iso3LanguageCode: 'hrv',
  iso2LanguageCode: 'hr',
  ietfLanguageTag: 'hr-HR',
  parent: CultureInfoHr
};
