import { Injectable, Inject } from '@angular/core';
import { IdentityService, Identity, TOKENS } from '@trustedshops/tswp-core-authorization';
import { KeycloakProfileData } from '@trustedshops/tswp-core-authorization-keycloak';
import {
  AbstractEvent,
  AbstractPersistentEvent,
  Event,
  EventSubscription,
  ExportToken,
  HttpInterceptor,
  HttpRequest,
  HttpEvent,
  NextInterceptorHandler } from '@trustedshops/tswp-core-common';

@Injectable()
@ExportToken(TOKENS.NgHttpInterceptor)
export class AngularKeycloakHttpInterceptor implements HttpInterceptor {
  //#region Private Fields
  private readonly _protectedUrls: RegExp[];
  private readonly _identityService: IdentityService;
  private readonly _active: AbstractPersistentEvent<boolean> = new AbstractPersistentEvent(false);
  //#endregion

  //#region Ctor
  public constructor(@Inject(TOKENS.IdentityService) identityService: IdentityService) {
    this._identityService = identityService;
    this._protectedUrls = [];
  }
  //#endregion

  //#region Public Methods
  public registerProtectedUrl(protectedUrlPattern: RegExp): void {
    this._protectedUrls.push(protectedUrlPattern);
  }

  public beforeRequestSent(
    req: HttpRequest<any>,
    next: NextInterceptorHandler<HttpInterceptor, HttpRequest<any>, HttpEvent<any>>): Event<HttpEvent<any>> {
    const requestStream = new AbstractEvent<HttpEvent<any>>();

    if (!this._protectedUrls
      .map(x => x.test(req.url))
      .some(x => x)) {

      next.handle(req)
        .subscribe(
          response => requestStream.emit(response),
          error => requestStream.emitError(error));

      return requestStream;
    }

    this.getToken().then(token => {
      req = Object.assign({}, req, {
        headers: Object.assign({}, req.headers, {
          Authorization: `Bearer ${token}`
        })
      });

      next.handle(req)
        .subscribe(
          response => requestStream.emit(response),
          error => requestStream.emitError(error));
    });

    return requestStream;
  }

  /**
   * Activates this interceptor and unpauses holding all requests
   */
  public activate(): void {
    this._active.emit(true);
  }
  //#endregion

  //#region Private Methods
  private async getToken(): Promise<string> {
    const identity = await new Promise<Identity<KeycloakProfileData>>(resolve => this.getIdentity().subscribe(resolve));
    return await new Promise(resolve =>
      identity.profile.keycloak.token.encoded.subscribe(resolve));
  }

  private getIdentity(): Event<Identity<KeycloakProfileData>> {
    const identityEventStream = new AbstractEvent<Identity<KeycloakProfileData>>();
    this._active.subscribe(async (active) => {
      if (!active) {
        return;
      }

      let subscription: EventSubscription<any>;
      const identity = await new Promise<Identity<KeycloakProfileData>>(resolve =>
        subscription = this._identityService.identity.subscribe(emittedIdentity => {
          if (!emittedIdentity) {
            return;
          }

          resolve(emittedIdentity);
        }));

      subscription.unsubscribe();
      identityEventStream.emit(identity);
    });

    return identityEventStream;
  }
  //#endregion
}
