import { TrackerType } from './tracker-type.enum';
import { TrackingEventInfo } from './tracking-event-info.model';

/**
 * Abstract interface of a tracker
 */
export interface Tracker {
  /**
   * Gets the type of this tracker
   */
  readonly type: TrackerType;

  /**
   * Gets the unique id of this tracker.
   */
  readonly id: string;

  /**
   * Tracks an event in the given tracker
   *
   * @param event The event that is to be sent
   */
  trackEvent(event: TrackingEventInfo): Promise<void>;

  /**
   * Initializes the tracker to start tracking
   */
  initialize(): void;
}