import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKa: CultureInfo = {
  name: 'ka',
  displayName: 'Georgian',
  iso3LanguageCode: 'kat',
  iso2LanguageCode: 'ka',
  ietfLanguageTag: 'ka',
  parent: null
};
