import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoTk: CultureInfo = {
  name: 'tk',
  displayName: 'Turkmen',
  iso3LanguageCode: 'tuk',
  iso2LanguageCode: 'tk',
  ietfLanguageTag: 'tk',
  parent: null
};
