import { Event, AbstractPersistentEvent, InvalidOperationError, ExportToken } from '@trustedshops/tswp-core-common';
import { Inject, Injectable } from '@angular/core';
import { OrganizationalContainerSource, TOKENS, OrganizationalContainerProvider } from '@trustedshops/tswp-core-masterdata';

/**
 * Composition provider for organizational containers.
 * Responsible for collecting all container sources and distributing information about the current active container source
 */
@Injectable()
@ExportToken(TOKENS.OrganizationalContainerProvider)
export class OrganizationalContainerProviderImpl implements OrganizationalContainerProvider {
  //#region Private Fields
  private readonly _sources: Map<string, OrganizationalContainerSource<unknown>> = new Map();
  private _currentSource: AbstractPersistentEvent<OrganizationalContainerSource<any>>;
  //#endregion

  //#region Properties
  /**
   * Gets the default organizational container renderer
   */
  public get defaultContainerSource(): string {
    return this._defaultSource.type;
  }
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(TOKENS.DefaultOrganizationalContainerSource) private readonly _defaultSource: OrganizationalContainerSource<unknown>) {

    this._sources.set(this._defaultSource.type, this._defaultSource);
    this._currentSource = new AbstractPersistentEvent(this._defaultSource);
  }
  //#endregion

  //#region Public Methods
  /**
   * Registers an organization container source that's able to pull containers using a specific implementation
   *
   * @param source A organizational container source to register
   */
   public registerSource<T>(source: OrganizationalContainerSource<T>): void {
    if (!source.type || this._sources.has(source.type)) {
      throw new InvalidOperationError(`Cannot register organizational unit container. Reason: Given container has no unique key.`);
    }

    this._sources.set(source.type, source);
  }

  /**
   * Activates the container source with the specified key
   *
   * @param key The key of the container source to activate
   */
   public activateSource(key: string): void {
    if (!key || !this._sources.has(key)) {
      throw new InvalidOperationError(`Cannot activate organizational unit container "${key}". Reason: Not found.`);
    }

    this._currentSource.emit(this._sources.get(key));
  }

  /**
   * Gets the currently active container source
   */
  public getCurrentSource<T>(): Event<OrganizationalContainerSource<T>> {
    return this._currentSource;
  }
  //#endregion
}
