import { Inject, Injectable } from '@angular/core';
import {
  AbstractEvent,
  AbstractPersistentEvent,
  Event,
  ExportToken,
} from '@trustedshops/tswp-core-common';
import { ProvidedPageTitleResult } from '@trustedshops/tswp-core-ui';
import { RouteSourceService, TOKENS } from '@trustedshops/tswp-core-ui';
import {
  PageTitleCompositionService,
  PageTitleProvider,
} from '@trustedshops/tswp-core-ui';

@Injectable()
@ExportToken(TOKENS.PageTitleCompositionService)
export class PageTitleCompositionServiceImpl implements PageTitleCompositionService {
  //#region Private Fields
  private readonly _pageTitleProviders: PageTitleProvider[] = [];
  private _defaultPageTitleProvider?: PageTitleProvider;
  //#endregion

  //#region Properties
  private _currentPageTitle: AbstractEvent<AbstractPersistentEvent<string>> = new AbstractEvent();
  /**
   * Gets the current trail of breadcrumbs
   */
  public get currentPageTitle(): Event<AbstractPersistentEvent<string>> {
    return this._currentPageTitle;
  }
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(TOKENS.RouteSourceService)
    private readonly _routeSourceService: RouteSourceService
  ) {}
  //#endregion

  //#region Public Methods
  public getPageTitleForRoute(route: string): AbstractPersistentEvent<string> {
    const pageTitlesByProviders = [...this._pageTitleProviders]
      .sort((a, b) => b.order - a.order)
      .map((provider) => provider.getPageTitle(route))
      .filter(
        (titleResult): titleResult is ProvidedPageTitleResult =>
          !!titleResult.canProvide
      )
      .map((titleResult) => titleResult.pageTitle);

    if (pageTitlesByProviders.length > 0) {
      return pageTitlesByProviders[0];
    }

    if (!this._defaultPageTitleProvider) {
      throw new Error(
        'DefaultPageTitleProvider is not configured. Please set the default page title provider.'
      );
    }

    const defaultPageTitle = this._defaultPageTitleProvider.getPageTitle(route);

    if (!defaultPageTitle.canProvide) {
      throw new Error(
        'The DefaultPageTitleProvider must always be able to provide a default title. Please configure a valid DefaultPageTitleProvider.'
      );
    }

    return defaultPageTitle.pageTitle;
  }

  public update(): void {
    this._currentPageTitle.emit(
      this.getPageTitleForRoute(this._routeSourceService.lastKnownRoute)
    );
  }

  public registerProvider(
    pageTitleProviders: PageTitleProvider[]
  ): PageTitleCompositionService {
    this._pageTitleProviders.push(...pageTitleProviders);
    return this;
  }

  public configureDefaultPageTitleProvider(provider: PageTitleProvider): void {
    if (!this._defaultPageTitleProvider) {
      this._defaultPageTitleProvider = provider;
    }
  }
  //#endregion
}
