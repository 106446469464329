import { CultureInfo } from '../culture-info.model';
import { CultureInfoSn } from './sn';

/**
 * @ignore
 */
export const CultureInfoSnZW: CultureInfo = {
  name: 'sn-ZW',
  displayName: 'chiShona (Zimbabwe)',
  iso3LanguageCode: 'sna',
  iso2LanguageCode: 'sn',
  ietfLanguageTag: 'sn-ZW',
  parent: CultureInfoSn
};
