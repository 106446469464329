import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoMl: CultureInfo = {
  name: 'ml',
  displayName: 'Malayalam',
  iso3LanguageCode: 'mal',
  iso2LanguageCode: 'ml',
  ietfLanguageTag: 'ml',
  parent: null
};
