import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoShi: CultureInfo = {
  name: 'shi',
  displayName: 'ⵜⴰⵛⵍⵃⵉⵜ',
  iso3LanguageCode: 'shi',
  iso2LanguageCode: 'shi',
  ietfLanguageTag: 'shi',
  parent: null
};
