import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoEl: CultureInfo = {
  name: 'el',
  displayName: 'Greek',
  iso3LanguageCode: 'ell',
  iso2LanguageCode: 'el',
  ietfLanguageTag: 'el',
  parent: null
};
