import { CultureInfo } from '../culture-info.model';
import { CultureInfoNe } from './ne';

/**
 * @ignore
 */
export const CultureInfoNeNP: CultureInfo = {
  name: 'ne-NP',
  displayName: 'Nepali (Nepal)',
  iso3LanguageCode: 'nep',
  iso2LanguageCode: 'ne',
  ietfLanguageTag: 'ne-NP',
  parent: CultureInfoNe
};
