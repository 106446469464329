import { CultureInfo } from '../culture-info.model';
import { CultureInfoTi } from './ti';

/**
 * @ignore
 */
export const CultureInfoTiER: CultureInfo = {
  name: 'ti-ER',
  displayName: 'Tigrinya (Eritrea)',
  iso3LanguageCode: 'tir',
  iso2LanguageCode: 'ti',
  ietfLanguageTag: 'ti-ER',
  parent: CultureInfoTi
};
