import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoPt: CultureInfo = {
  name: 'pt',
  displayName: 'Portuguese',
  iso3LanguageCode: 'por',
  iso2LanguageCode: 'pt',
  ietfLanguageTag: 'pt',
  parent: null
};
