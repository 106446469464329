import { bootstrapperFactory, BootstrapperBase, DomResourceService, ResourceType, TOKENS, } from '@trustedshops/tswp-core-common';
import { Inject, Injectable, APP_INITIALIZER } from '@angular/core';
import { CssDomResourceLoaderService } from '../services/resources/css-dom-resource-loader.service';

/**
 * Adds an instance of CssDomResourceLoaderService to the DomResourceService
 */
@Injectable()
export class CssResourceLoaderRegistration implements BootstrapperBase {
  //#region Ctor
  /**
   * Creates an instance of CssResourceLoaderRegistration
   * @param _domResourceService The DomResourceService to register to
   * @param _cssResourceLoader The CssDomResourceLoaderService to register
   */
  public constructor(
    @Inject(TOKENS.DomResourceService) private readonly _domResourceService: DomResourceService,
    private readonly _cssResourceLoader: CssDomResourceLoaderService) {
  }
  //#endregion

  //#region Public Methods
  /**
   * Runs the resource loader registration
   */
  public async initialize(): Promise<void> {
    this._domResourceService
      .registerLoader(ResourceType.CascadingStyleSheet, this._cssResourceLoader);
  }
  //#endregion
}

/**
 * Adds an instance of CssDomResourceLoaderService to the DomResourceService
 */
export const CssResourceLoaderInitializer = [
  CssResourceLoaderRegistration,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: bootstrapperFactory,
    deps: [CssResourceLoaderRegistration]
  }
];
