import { CultureInfo } from '../culture-info.model';
import { CultureInfoCo } from './co';

/**
 * @ignore
 */
export const CultureInfoCoFR: CultureInfo = {
  name: 'co-FR',
  displayName: 'Corsican (France)',
  iso3LanguageCode: 'cos',
  iso2LanguageCode: 'co',
  ietfLanguageTag: 'co-FR',
  parent: CultureInfoCo
};
