import { CultureInfo } from '../culture-info.model';
import { CultureInfoEs } from './es';

/**
 * @ignore
 */
export const CultureInfoEsMF: CultureInfo = {
  name: 'es-MF',
  displayName: 'español (San Martín)',
  iso3LanguageCode: 'spa',
  iso2LanguageCode: 'es',
  ietfLanguageTag: 'es-MF',
  parent: CultureInfoEs
};
