import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoEt: CultureInfo = {
  name: 'et',
  displayName: 'Estonian',
  iso3LanguageCode: 'est',
  iso2LanguageCode: 'et',
  ietfLanguageTag: 'et',
  parent: null
};
