import { AbstractPersistentEvent } from '@trustedshops/tswp-core-common';
import { VisibilityGuardFactory } from './visibility-guard-factory.interface';

/**
 * Registry for pre-defined visibility guards
 */
export interface VisibilityGuardFactoryRegistry {

  /**
   * Registers a visibility guard factory for foreign usage
   *
   * @param type The unique key/type of the visibility guard
   * @param visibilityGuardFactory The factory of the visibility guard
   */
  registerVisibilityGuardFactory(type: string, visibilityGuardFactory: VisibilityGuardFactory): VisibilityGuardFactoryRegistry;

  /**
   * Gets a visibility guard factory for instantiation of a new visibility guard
   *
   * @param type The unique key/type of the visibility guard factory registration
   * @param args The factory args
   */
   createVisibilityGuard(type: string, ...args: any[]): AbstractPersistentEvent<boolean>;
}
