import { AbstractPersistentEvent } from '../../eventing/abstract-persistent-event';
import { CultureInfo } from '../../globalization/culture-info.model';

/**
 * Provider for managing and forwarding the currently active culture to the CultureInfoService
 */
export interface CurrentCultureInfoProvider {
  /**
   * Gets an event stream emitting the currently active culture
   */
  readonly currentCulture: AbstractPersistentEvent<CultureInfo>;

  /**
   * Updates the current culture in the system
   *
   * @param cultureInfo New culture info
   */
  updateCurrentCulture(cultureInfo: CultureInfo): void;

  /**
   * Resolves the locales basic culture info defined for the platform.
   * Example: nl-BE will get nl-NL when configured this way.
   *
   * @param locale The locale to get the base culture for. Can be passed as localeIso2 or ietfLanguageTag
   */
   resolveBaseCulture(locale: string): CultureInfo;
}
