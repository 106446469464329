import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoEwo: CultureInfo = {
  name: 'ewo',
  displayName: 'ewondo',
  iso3LanguageCode: 'ewo',
  iso2LanguageCode: 'ewo',
  ietfLanguageTag: 'ewo',
  parent: null
};
