<div class="gallery-container">
    <div class="block-wrapper">
        <div (click)="i === 0 ? nextBlock() : i === 2 ? prevBlock() : undefined"
             *ngFor="let block of blocks; let i = index"
             [class.active]="i === 1"
             [ngClass]="fadeOutAnimationClass"
             class="block">
            <div>
                <div class="icon-trend"></div>
                <div class="percentage">{{ translationService.getTranslationForKey(block.percentage) | subscribe }}</div>
                <div class="label">{{ translationService.getTranslationForKey(block.label) | subscribe }}</div>
            </div>
            <div class="description">{{ translationService.getTranslationForKey(block.description) | subscribe }}</div>
        </div>
    </div>
    <div class="gallery-controls">
        <button (click)="prevBlock()">
            <span class="icon-prev"></span>
        </button>
        <div [ngClass]="fadeOutAnimationClass" class="gallery-text">
            <ng-container *ngFor="let block of blocks; let i = index">
                <ng-container *ngIf="i === 1">
                    <span class="heading-md">{{ translationService.getTranslationForKey(block.heading) | subscribe }}</span>
                    <span class="text-tertiary">{{ translationService.getTranslationForKey(block.text) | subscribe }}</span>
                </ng-container>
            </ng-container>
        </div>
        <button (click)="nextBlock()">
            <span class="icon-next"></span>
        </button>
    </div>
</div>
