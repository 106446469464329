import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKde: CultureInfo = {
  name: 'kde',
  displayName: 'Chimakonde',
  iso3LanguageCode: 'kde',
  iso2LanguageCode: 'kde',
  ietfLanguageTag: 'kde',
  parent: null
};
