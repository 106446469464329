import { CultureInfo } from '../culture-info.model';
import { CultureInfoId } from './id';

/**
 * @ignore
 */
export const CultureInfoIdID: CultureInfo = {
  name: 'id-ID',
  displayName: 'Indonesian (Indonesia)',
  iso3LanguageCode: 'ind',
  iso2LanguageCode: 'id',
  ietfLanguageTag: 'id-ID',
  parent: CultureInfoId
};
