import { CultureInfo } from '../culture-info.model';
import { CultureInfoBr } from './br';

/**
 * @ignore
 */
export const CultureInfoBrFR: CultureInfo = {
  name: 'br-FR',
  displayName: 'Breton (France)',
  iso3LanguageCode: 'bre',
  iso2LanguageCode: 'br',
  ietfLanguageTag: 'br-FR',
  parent: CultureInfoBr
};
