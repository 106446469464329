import { Injectable, Inject } from '@angular/core';
import {
  InvalidOperationError,
  ApplicationService,
  DomResourceService,
  ResourceInfo,
  TimeoutError,
  StopwatchFactory,
  ExportToken,
  TOKENS as TOKENS_COMMON,
  DuplicateKeyError,
  LogService,
  TimerService,
  ErrorBase,
  AbstractReplayEvent,
  Event
} from '@trustedshops/tswp-core-common';
import { DependencyContainer, TOKENS as TOKENS_COMPOSITION } from '@trustedshops/tswp-core-composition';
import {
  PluginRegistration,
  PluginEntryPoint,
  LoadPluginRequest,
  PluginContext,
  PluginStatus,
  TOKENS as TOKENS_PLUGINS,
  PluginArrival,
  PluginArrivalInfo,
  PluginService
} from '@trustedshops/tswp-core-plugins';
import { PluginLoadError } from '../error/plugin-load.error';

/**
 * Handles plugin registrations and loads plugins
 */
@Injectable()
@ExportToken(TOKENS_PLUGINS.PluginService)
export class PluginServiceImpl implements PluginService {
  private readonly DEFAULT_PLUGIN_LOAD_TIMEOUT: number = 5000;

  private readonly _bootQueue: Map<PluginRegistration, Promise<PluginRegistration>> = new Map();
  private readonly _queuedPluginEntryPoints: PluginArrival[] = [];
  private _pluginQueueSubscription: any;
  private _pluginPipeline: AbstractReplayEvent<PluginArrival> = new AbstractReplayEvent();
  private readonly _extraOrdinaryServices: Map<string, any> = new Map<string, any>();

  private _pluginContextBus: Event<PluginContext> = new AbstractReplayEvent();
  /**
   * Gets a stream that emits newly created `PluginContext` objects.
   * Useful for attaching isolated services for plugins
   */
  public get pluginContextBus(): Event<PluginContext> {
    return this._pluginContextBus;
  }

  private _pluginLoadTimeout: number = this.DEFAULT_PLUGIN_LOAD_TIMEOUT;
  /**
   * Gets or sets the default time the plugin must be loaded within.
   * May be overriden by the loadPlugin or loadPluginByName call
   */
  public get pluginLoadTimeout(): number {
    return this._pluginLoadTimeout;
  }
  public set pluginLoadTimeout(v: number) {
    this._pluginLoadTimeout = v;
  }

  private _registeredPlugins: PluginRegistration[] = [];
  /**
   * Gets the plugins registered.
   */
  public get registeredPlugins(): PluginRegistration[] {
    return this._registeredPlugins;
  }

  /**
   * Creates an instance of PluginService
   * @param _domResourceService The DomResourceService to use when loading plugins
   * @param _stopWatchFactory The Stopwatch factory to use when measuring load timeouts
   * @param _dependencyContainer The dependency container to wire the plugins with
   * @param _applicationService The application service containing this applications metadata.
   */
  public constructor(
    @Inject(TOKENS_COMMON.LogService) private readonly _logService: LogService,
    @Inject(TOKENS_COMMON.DomResourceService) private readonly _domResourceService: DomResourceService,
    @Inject(TOKENS_COMMON.StopwatchFactory) private readonly _stopWatchFactory: StopwatchFactory,
    @Inject(TOKENS_COMPOSITION.DependencyContainer) private readonly _dependencyContainer: DependencyContainer,
    @Inject(TOKENS_COMMON.ApplicationService) private readonly _applicationService: ApplicationService,
    @Inject(TOKENS_COMMON.TimerService) private readonly _timerService: TimerService) {
  }

  /**
   * Declares a new extra-ordinary service which is available on demand only.
   * @param token The token to request the service with
   * @param value The service instance.
   */
  public enableExtraOrdinaryService<T>(token: string, value: T): void {
    this._extraOrdinaryServices.set(token, value);
  }

  /**
   * Loads an already registered plugin. If already loaded, returns without action.
   * @param request Name of the plugin
   * @param timeout Time that the plugin has to respond back to the platform after is has been loaded
   */
  public async loadPluginByName(name: string, timeout?: number, dependentPluginName?: string): Promise<PluginRegistration> {
    this._logService.debug(TOKENS_PLUGINS.PluginService, `Requested to load plugin '${name}'`);

    const registration = this.tryFindRegistration(name);
    if (!registration || (registration.status === PluginStatus.ResourceLoadingFailed || registration.status === PluginStatus.BootFailed)) {
      throw this._logService.error(TOKENS_PLUGINS.PluginService,
        new InvalidOperationError(`Unknown plugin "${name}"${dependentPluginName
          ? ` requested by "${dependentPluginName}"`
          : ''}. Please make sure to register it beforehand.`));
    }

    if (registration.status === PluginStatus.Booted) {
      return registration;
    }

    if (registration.status === PluginStatus.Booting) {
      return this._bootQueue.get(registration);
    }

    registration.status = PluginStatus.Booting;

    return this._bootQueue
      .set(registration, this.loadAndSetupPlugin(registration, timeout))
      .get(registration);
  }

  /**
   * Registers a plugin without loading.
   * @param request Request that defines what the plugin consists of
   */
  public registerPlugin(request: LoadPluginRequest): PluginRegistration {
    if (this._registeredPlugins.some(registeredPlugin => registeredPlugin.name === request.name)) {
      throw new DuplicateKeyError('A plugin with the same name was already registered.', request);
    }

    const resourcesToLoad: ResourceInfo[] = []
      .concat(this.mapResource(request.scripts, script => `${script}`))
      .concat(this.mapResource(request.styles, style => `${style}`));

    const registration: PluginRegistration = {
      context: null,
      entryPoint: null,
      name: request.name,
      initialRequest: request,
      resources: resourcesToLoad,
      status: PluginStatus.Waiting,
      extraOrdinaryDependencies: []
    };

    this._registeredPlugins.push(registration);

    return registration;
  }

  /**
   * Registers and loads a plugin in one turn. If already loaded, returns without action.
   * @param request Request that defines what the plugin consists of
   * @param timeout Time that the plugin has to respond back to the platform after is has been loaded
   */
  public async loadPlugin(request: LoadPluginRequest, timeout?: number): Promise<PluginRegistration> {
    let registration = this.tryFindRegistration(request.name);
    if (registration && registration.status === PluginStatus.Booted) {
      return registration;
    }

    if (!registration) {
      registration = this.registerPlugin(request);
    }

    return await this.loadPluginByName(registration.name, timeout);
  }

  /**
   * Notify PluginService that a specific plugin has loaded.
   * Usually called through PluginHosts registerPlugin Method
   * @param entryPoint The entrypoint loaded.
   */
  public notifyPluginArrived(pluginArrivalInfo: PluginArrivalInfo): void {
    const {
      extraOrdinaryServices,
      entryPoint,
      entryPointType
    }: PluginArrivalInfo = pluginArrivalInfo;

    let entryPointResolver: (dependencyContainer: DependencyContainer) => PluginEntryPoint
      = () => entryPoint;

    if (entryPointType) {
      entryPointResolver = (dependencyContainer: DependencyContainer) => {
        dependencyContainer.registerType(entryPointType);
        return dependencyContainer.get(entryPointType);
      };
    }

    const entryPointName = entryPointType?.pluginName || entryPoint.name;

    this._logService.trace(TOKENS_PLUGINS.PluginService, `Plugin "${entryPointName}"; arrival noticed, pushing it to the pipeline `);
    this._pluginPipeline.emit({
      entryPointName,
      entryPointResolver,
      extraOrdinaryDependencies: extraOrdinaryServices
    });
  }

  public getPluginFilePath(plugin: string, path: string): string {
    const pluginRegistration = this.registeredPlugins.find(x => x.name === plugin);
    if (!pluginRegistration) {
      throw new InvalidOperationError(
        `Could not resolve file path '${path}' for plugin '${plugin}':` +
        `The plugin was not found in the system.`);
    }

    const { basePath, pluginStorageUrl }: LoadPluginRequest = pluginRegistration.initialRequest;
    return `${pluginStorageUrl}/${basePath}/${path}`;
  }

  /**
   * Tries to find a registration for a plugin with the provided name
   *
   * @param name The name of the plugin to find the registration for
   * @returns undefined or null if not found. Instance of a `PluginRegistration` if found.
   */
  public tryFindRegistration(name: string): PluginRegistration {
    return this.registeredPlugins
      .find(plugin => plugin.name === name);
  }

  private async getPluginEntryPoint(request: LoadPluginRequest, timeout?: number): Promise<PluginArrival> {
    this.ensureListenerExists();

    const stopwatch = this._stopWatchFactory
      .createStopwatch()
      .start();

    let pluginEntryPoint: PluginArrival;
    this._logService.trace(TOKENS_PLUGINS.PluginService, `Checking availability of entry point of plugin ${request.name}`);

    if (timeout === undefined) {
      timeout = this.pluginLoadTimeout;
    }

    while (!pluginEntryPoint && stopwatch.elapsed < timeout) {
      this._logService.trace(TOKENS_PLUGINS.PluginService, `Trying to find ${request.name}`);

      pluginEntryPoint = this.tryResolvePlugin(request);
      const registration = this.tryFindRegistration(request.name);
      if (registration && (registration.status === PluginStatus.ResourceLoadingFailed || registration.status === PluginStatus.BootFailed)) {
        throw new PluginLoadError(`An error occurred during loading or bootstrapping resources of ${request.name}`,
          request.name,
          new PluginLoadError('Plugin is in an invalid state: ' + PluginStatus[registration.status], registration.name));
      }

      this._logService.trace(TOKENS_PLUGINS.PluginService, `Possibly have found  ${request.name}....`);

      if (pluginEntryPoint) {
        this._logService.trace(TOKENS_PLUGINS.PluginService, `Found entry point of plugin "${request.name}"`);
        this._logService.debug(TOKENS_PLUGINS.PluginService, `pluginEntryPoint`, pluginEntryPoint);
        break;
      }

      this._logService.trace(TOKENS_PLUGINS.PluginService, `Negative, next trying to find ${request.name}`);

      try {
        this._logService.trace(TOKENS_PLUGINS.PluginService, `Pausing 100ms for the next try to find ${request.name}`, this._timerService);
        await this._timerService.sleep(100);
      } catch (err: any) {
        this._logService.error(
          TOKENS_PLUGINS.PluginService,
          new ErrorBase('Error when trying to sleep', err));
      }
    }


    if (stopwatch.elapsed > timeout) {
      throw this._logService.error(
        TOKENS_PLUGINS.PluginService,
        new TimeoutError(`The plugin '${request.name}' did not respond within the given amount of time. Continuing without it.`, timeout));
    }

    return pluginEntryPoint;
  }

  private tryResolvePlugin(request: LoadPluginRequest): PluginArrival {
    this._logService.trace(TOKENS_PLUGINS.PluginService, `tryResolvePlugin`, request);
    return this._queuedPluginEntryPoints
      .find(arrival => arrival.entryPointName === request.name);
  }

  private ensureListenerExists(): void {
    if (this._pluginQueueSubscription) {
      return;
    }

    this._logService.trace(TOKENS_PLUGINS.PluginService, `Starting plugin queue!`);

    this._pluginQueueSubscription = this._pluginPipeline
      .subscribe((arrival: PluginArrival) => {
        this._logService.trace(TOKENS_PLUGINS.PluginService, `Plugin "${arrival.entryPointName}"; went through plugin arrival pipeline`);
        this._queuedPluginEntryPoints.push(arrival);
      });

  }

  private async wirePlugin(registrationRequest: PluginRegistration, arrival: PluginArrival): Promise<PluginRegistration> {

    const pluginContext = this.createPluginContext(registrationRequest);

    registrationRequest.entryPoint = arrival.entryPointResolver(pluginContext.serviceProvider);
    registrationRequest.extraOrdinaryDependencies = arrival.extraOrdinaryDependencies;
    const nonExistentServices = (registrationRequest.extraOrdinaryDependencies || [])
      .filter(token => !this._extraOrdinaryServices.has(token))
      .join('\n');

    if (nonExistentServices.length > 0) {
      throw new InvalidOperationError(
        `The services requested by "${registrationRequest.name}" are not existent.`
        + `Please check the plugins configuration for extraordinary services. Non-Existent services are:\n\n`);
    }

    (registrationRequest.extraOrdinaryDependencies || [])
      .forEach(token => pluginContext.serviceProvider.registerTokenizedValue(token, this._extraOrdinaryServices.get(token)));

    try {
      this._logService.trace(TOKENS_PLUGINS.PluginService, `Start plugin ${registrationRequest.name}`, registrationRequest);
      registrationRequest.context = pluginContext;
      await registrationRequest.entryPoint.main(pluginContext);
      registrationRequest.status = PluginStatus.Booted;
    } catch (err: any) {
      registrationRequest.status = PluginStatus.BootFailed;
      this._logService.error(
        TOKENS_PLUGINS.PluginService,
        new PluginLoadError(
          `Unable to load plugin ${registrationRequest.name} because of an error.`,
          registrationRequest.name,
          err));
      return null;
    }

    return registrationRequest;
  }

  private createPluginContext(registrationRequest: PluginRegistration): PluginContext {
    const pluginContext: PluginContext = {
      applicationEnvironment: JSON.parse(JSON.stringify(this._applicationService.environment)),
      serviceProvider: this._dependencyContainer.createChild(registrationRequest.name),
      registration: registrationRequest
    };

    this.pluginContextBus.emit(pluginContext);

    return pluginContext;
  }

  private mapResource(scripts: ResourceInfo[], keyResolver: (input: string) => string): ResourceInfo[] {
    return (scripts || []).map(script => {
      return {
        ...script,
        key: keyResolver(script.key)
      };
    });
  }

  private async loadAndSetupPlugin(registration: PluginRegistration, timeout: number): Promise<PluginRegistration> {
    for (const dependency of registration.initialRequest.dependencies) {
      const result = await this.loadPluginByName(dependency, undefined, registration.name);
      if (!result) {
        this._logService.error(TOKENS_PLUGINS.PluginService, new PluginLoadError(
          `Unable to load dependency plugin ${dependency} requested by ${registration.name}.`,
          dependency));
        return null;
      }
    }

    try {
      await Promise.all(registration.resources.map(resource =>
        this._domResourceService.loadResource(resource)));
    } catch (err: any) {
      this._logService.error(TOKENS_PLUGINS.PluginService, new PluginLoadError(
        `Failed loading resources for plugin "${registration.name}"`,
        registration.name,
        err));
      registration.status = PluginStatus.ResourceLoadingFailed;
      return null;
    }

    const arrival = await this.getPluginEntryPoint(registration.initialRequest, timeout);

    return this.wirePlugin(registration, arrival);
  }
}
