import { CultureInfo } from '../culture-info.model';
import { CultureInfoKsf } from './ksf';

/**
 * @ignore
 */
export const CultureInfoKsfCM: CultureInfo = {
  name: 'ksf-CM',
  displayName: 'rikpa (kamɛrún)',
  iso3LanguageCode: 'ksf',
  iso2LanguageCode: 'ksf',
  ietfLanguageTag: 'ksf-CM',
  parent: CultureInfoKsf
};
