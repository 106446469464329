import { CultureInfo } from '../culture-info.model';
import { CultureInfoBg } from './bg';

/**
 * @ignore
 */
export const CultureInfoBgBG: CultureInfo = {
  name: 'bg-BG',
  displayName: 'Bulgarian (Bulgaria)',
  iso3LanguageCode: 'bul',
  iso2LanguageCode: 'bg',
  ietfLanguageTag: 'bg-BG',
  parent: CultureInfoBg
};
