import { DOCUMENT } from '@angular/common';
import { APP_INITIALIZER, Inject, Injectable, Injector } from '@angular/core';
import { createCustomElement } from '@angular/elements';
import { BootstrapperBase } from '@trustedshops/tswp-core-common';
import { UpgradePageComponent } from './upgrade-page.component';

@Injectable()
export class UpgradePageBootstrapper implements BootstrapperBase {
  public constructor(
    private readonly _injector: Injector,
    @Inject(DOCUMENT) private readonly _document: Document) {
  }

  public async initialize(): Promise<void> {
    const customElement = createCustomElement(UpgradePageComponent, {
      injector: this._injector
    });

    this._document.defaultView.customElements
      .define(UpgradePageComponent.selector, customElement);
  }
}

export const UpgradePageInitializer = [
  UpgradePageBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [UpgradePageBootstrapper]
  }];
