import { Component, Inject } from '@angular/core';
import { ChildTranslationService, TOKENS } from '@trustedshops/tswp-core-common';

@Component({
  selector: 'tswp-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  standalone: false,
})
export class GalleryComponent {
  public fadeOutAnimationClass = '';

  public blocks: { percentage: string; description: string; label: string; heading: string; text: string; }[] = [
    {
      percentage: 'page.upgrade.gallery.0.percentage',
      label: 'page.upgrade.gallery.0.label',
      description:  'page.upgrade.gallery.0.description',
      heading:  'page.upgrade.gallery.0.heading',
      text:   'page.upgrade.gallery.0.text',
    },
    {
      percentage: 'page.upgrade.gallery.1.percentage',
      label: 'page.upgrade.gallery.1.label',
      description: 'page.upgrade.gallery.1.description',
      heading: 'page.upgrade.gallery.1.heading',
      text: 'page.upgrade.gallery.1.text',
    },
    {
      percentage: 'page.upgrade.gallery.2.percentage',
      label: 'page.upgrade.gallery.2.label',
      description: 'page.upgrade.gallery.2.description',
      heading: 'page.upgrade.gallery.2.heading',
      text: 'page.upgrade.gallery.2.text',
    }
  ];

  public constructor(
      @Inject(TOKENS.TranslationService)
      public readonly translationService: ChildTranslationService) {
  }

  public nextBlock(): void {
    this.fadeOutAnimationClass = 'fade-out';

    setTimeout(() => {
      const lastElement = this.blocks.pop();
      this.blocks = [lastElement, ...this.blocks];

      this.fadeOutAnimationClass = '';
    }, 300);
  }

  public prevBlock(): void {
    this.fadeOutAnimationClass = 'fade-out';
    setTimeout(() => {
      const firstElement = this.blocks.shift();
      this.blocks = [...this.blocks, firstElement];

      this.fadeOutAnimationClass = '';
    }, 300);
  }
}
