import { CultureInfo } from '../culture-info.model';
import { CultureInfoXh } from './xh';

/**
 * @ignore
 */
export const CultureInfoXhZA: CultureInfo = {
  name: 'xh-ZA',
  displayName: 'Xhosa (South Africa)',
  iso3LanguageCode: 'xho',
  iso2LanguageCode: 'xh',
  ietfLanguageTag: 'xh-ZA',
  parent: CultureInfoXh
};
