import { CultureInfo } from '../culture-info.model';
import { CultureInfoBo } from './bo';

/**
 * @ignore
 */
export const CultureInfoBoCN: CultureInfo = {
  name: 'bo-CN',
  displayName: 'Tibetan (China mainland)',
  iso3LanguageCode: 'bod',
  iso2LanguageCode: 'bo',
  ietfLanguageTag: 'bo-CN',
  parent: CultureInfoBo
};
