import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoSl: CultureInfo = {
  name: 'sl',
  displayName: 'Slovenian',
  iso3LanguageCode: 'slv',
  iso2LanguageCode: 'sl',
  ietfLanguageTag: 'sl',
  parent: null
};
