import { CultureInfo } from '../culture-info.model';
import { CultureInfoGv } from './gv';

/**
 * @ignore
 */
export const CultureInfoGvIM: CultureInfo = {
  name: 'gv-IM',
  displayName: 'Gaelg (Ellan Vannin)',
  iso3LanguageCode: 'glv',
  iso2LanguageCode: 'gv',
  ietfLanguageTag: 'gv-IM',
  parent: CultureInfoGv
};
