import { PluginEntryPoint } from './plugin-entry-point.interface';
import { ResourceInfo } from '@trustedshops/tswp-core-common';
import { PluginContext } from './plugin-context.interface';
import { PluginStatus } from './plugin-status.enum';
import { LoadPluginRequest } from './load-plugin-request.interface';

/**
 * Represents one plugin registration
 */
export interface PluginRegistration {
  /**
   * Unique name of the plugin
   */
  name: string;

  /**
   * Metadata about the plugin
   */
  entryPoint: PluginEntryPoint;

  /**
   * Resources loaded and requested by the plugin
   */
  resources: ResourceInfo[];

  /**
   * The plugin context passed to the plugin's entry point
   */
  context: PluginContext;

  /**
   * The request that arrived at the platform to load this plugin.
   */
  initialRequest: LoadPluginRequest;

  /**
   * Status of the plugin
   */
  status: PluginStatus;

  /**
   * The extra-ordinary services the plugin requires
   */
  extraOrdinaryDependencies: string[];
}
