import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKea: CultureInfo = {
  name: 'kea',
  displayName: 'kabuverdianu',
  iso3LanguageCode: 'kea',
  iso2LanguageCode: 'kea',
  ietfLanguageTag: 'kea',
  parent: null
};
