import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKam: CultureInfo = {
  name: 'kam',
  displayName: 'Kikamba',
  iso3LanguageCode: 'kam',
  iso2LanguageCode: 'kam',
  ietfLanguageTag: 'kam',
  parent: null
};
