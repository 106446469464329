import { CultureInfo } from '../culture-info.model';
import { CultureInfoAk } from './ak';

/**
 * @ignore
 */
export const CultureInfoAkGH: CultureInfo = {
  name: 'ak-GH',
  displayName: 'Akan (Gaana)',
  iso3LanguageCode: 'aka',
  iso2LanguageCode: 'ak',
  ietfLanguageTag: 'ak-GH',
  parent: CultureInfoAk
};
