import { AbstractPersistentEvent, Event } from '@trustedshops/tswp-core-common';
import { PageTitleProvider } from '../../models/page-title/page-title-provider.interface';

/**
 * Service used to compose the page title.
 */
export interface PageTitleCompositionService {
  readonly currentPageTitle: Event<AbstractPersistentEvent<string>>;

  /**
   * Returns an AbstractPersistentEvent containting the latest title for the respective
   *
   * @param route The route to get the page title for
   */
  getPageTitleForRoute(route: string): AbstractPersistentEvent<string>;

  /**
   * Requests for latest information of all registered providers and updates the current page title.
   *
   * @param delegate The delegate to update the page title
   */
  update(): void;

  /**
   * Registers an array of providers to be used to compose the page title.
   *
   * @param pageTitleProviders The providers to register
   * @returns The service instance
   */
  registerProvider(
    pageTitleProviders: PageTitleProvider[]
  ): PageTitleCompositionService;

  /**
   * Configures the default provider for the page title for the case when no provider is registered.
   *
   * @param provider The provider to configure as the default page title provider
   */
  configureDefaultPageTitleProvider(provider: PageTitleProvider): void;
}
