import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoWae: CultureInfo = {
  name: 'wae',
  displayName: 'Walser',
  iso3LanguageCode: 'wae',
  iso2LanguageCode: 'wae',
  ietfLanguageTag: 'wae',
  parent: null
};
