import { CultureInfo } from '../culture-info.model';
import { CultureInfoUr } from './ur';

/**
 * @ignore
 */
export const CultureInfoUrPK: CultureInfo = {
  name: 'ur-PK',
  displayName: 'Urdu (Pakistan)',
  iso3LanguageCode: 'urd',
  iso2LanguageCode: 'ur',
  ietfLanguageTag: 'ur-PK',
  parent: CultureInfoUr
};
