import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoArn: CultureInfo = {
  name: 'arn',
  displayName: 'Mapuche',
  iso3LanguageCode: 'arn',
  iso2LanguageCode: 'arn',
  ietfLanguageTag: 'arn',
  parent: null
};
