import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoTwq: CultureInfo = {
  name: 'twq',
  displayName: 'Tasawaq senni',
  iso3LanguageCode: 'twq',
  iso2LanguageCode: 'twq',
  ietfLanguageTag: 'twq',
  parent: null
};
