import {Component, Input, ViewEncapsulation, HostBinding } from '@angular/core';
import { WebComponent } from '../../decorators/web-component.decorator';

import { BadgeType } from './badge-type.enum';

/**
 * Helios status badges
 * @doctab "Examples" "status-badge.component.examples.md"
 */
@WebComponent('hls-status-badge')
@Component({
  selector: 'hls-status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.scss'],
  preserveWhitespaces: true,
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class StatusBadgeComponent {
  //#region Properties
  private _label = '';
  /**
   * Gets or sets the label of the badge
   */
  @Input()
  @HostBinding('attr.label')
  public get label(): string {
    return this._label;
  }
  public set label(v: string) {
    this._label = v;
  }

  private _type: BadgeType = BadgeType.Mute;
  /**
   * Gets or sets the badge color.
   * Default: `BadgeColor.Mute`
   */
  @Input()
  @HostBinding('attr.type')
  public get type(): BadgeType {
    return this._type;
  }
  public set type(v: BadgeType) {
    this._type = v;
  }

  private _loaded = true;
  /**
   * Gets or sets the state of this badge, indicating wether it is loaded.
   * Default: `true`
   */
  @Input()
  @HostBinding('attr.loaded')
  public get loaded(): boolean {
    return this._loaded;
  }
  public set loaded(v: boolean) {
    this._loaded = v;
  }

  //#endregion
}
