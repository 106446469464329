import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { TswpUiLibraryConfig } from '../models/tswp-ui-library.config';
import { TswpUiLibraryConfigToken } from '../models/tswp-ui-library-config.token';

@Injectable()
export class IconCacheService {
  //#region Private Fields
  private readonly _iconCache: Map<string, string> = new Map();
  //#endregion

  //#region Ctor
  public constructor(
    @Inject(TswpUiLibraryConfigToken) private readonly _config: TswpUiLibraryConfig,
    private readonly _httpClient: HttpClient) { }
  //#endregion

  //#region Public Methods
  /**
   * Retrieves an icon svg source from the cache. If not present, downloads the icon and caches it afterwards.
   *
   * @param iconName The icon name to retrieve the source for.
   * @returns The icon SVG source code.
   */
  public async getIcon(iconName: string): Promise<string> {
    let cache = this._iconCache;
    if (!this._iconCache.has(iconName)) {
      cache = this._iconCache.set(iconName, await this.downloadIcon(iconName));
    }

    return this._iconCache.get(iconName);
  }

  public async downloadIcon(iconName: string): Promise<string> {
    const iconUri = [
      this._config.heliosIconFontUri,
      `icon-${iconName}`
    ]
    .map(x => x.replace(/\/$/g, ''))
    .join('/');

    const iconUrl = `${iconUri}.svg`;
    return new Promise<string>(resolve =>
      this._httpClient.get(iconUrl, {
        responseType: 'text'
      }).subscribe(resolve));
  }
  //#endregion
}
