import { CultureInfo } from '../culture-info.model';
import { CultureInfoSq } from './sq';

/**
 * @ignore
 */
export const CultureInfoSqMK: CultureInfo = {
  name: 'sq-MK',
  displayName: 'shqip (Maqedonia e Veriut)',
  iso3LanguageCode: 'sqi',
  iso2LanguageCode: 'sq',
  ietfLanguageTag: 'sq-MK',
  parent: CultureInfoSq
};
