import { ErrorHandler, Injectable } from '@angular/core';

@Injectable()
export class InstanaErrorHandler implements ErrorHandler {
  public handleError(error: any): void {
    if (ineum === undefined || typeof ineum !== 'function') {
      return;
    }

    this.reportError(error);
  }

  private reportError(error: any): void {
    ineum('reportError', `[GLOBAL-ERROR] ${error.message}`);
  }
}

