import { Type, DateProvider } from '@trustedshops/tswp-core-common';

/**
 * Creates a BrowserDateProvider.
 * @param document The window document to take the Date object from.
 */
export function BrowserDateProviderInitializer(document: Document): DateProvider {
  return (...args: any[]): Date => {
    const window = document.defaultView as Window;
    const dateType = (window as any).Date as Type<Date>;
    return new dateType(...args);
  };
}

/**
 * Creates a BrowserDateProvider.
 * @param document The window document to take the Date object from.
 */
export function BrowserDateProviderFactory(document: Document): DateProvider {
  return (...args: any[]) => BrowserDateProviderInitializer(document)(...args);
}
