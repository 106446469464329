import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoMua: CultureInfo = {
  name: 'mua',
  displayName: 'MUNDAŊ',
  iso3LanguageCode: 'mua',
  iso2LanguageCode: 'mua',
  ietfLanguageTag: 'mua',
  parent: null
};
