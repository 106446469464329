import { CultureInfo } from '../culture-info.model';
import { CultureInfoOs } from './os';

/**
 * @ignore
 */
export const CultureInfoOsRU: CultureInfo = {
  name: 'os-RU',
  displayName: 'ирон (Уӕрӕсе)',
  iso3LanguageCode: 'oss',
  iso2LanguageCode: 'os',
  ietfLanguageTag: 'os-RU',
  parent: CultureInfoOs
};
