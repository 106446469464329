import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoRwk: CultureInfo = {
  name: 'rwk',
  displayName: 'Kiruwa',
  iso3LanguageCode: 'rwk',
  iso2LanguageCode: 'rwk',
  ietfLanguageTag: 'rwk',
  parent: null
};
