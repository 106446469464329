import { CultureInfo } from '../culture-info.model';
import { CultureInfoTn } from './tn';

/**
 * @ignore
 */
export const CultureInfoTnZA: CultureInfo = {
  name: 'tn-ZA',
  displayName: 'Tswana (South Africa)',
  iso3LanguageCode: 'tsn',
  iso2LanguageCode: 'tn',
  ietfLanguageTag: 'tn-ZA',
  parent: CultureInfoTn
};
