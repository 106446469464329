import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoRw: CultureInfo = {
  name: 'rw',
  displayName: 'Kinyarwanda',
  iso3LanguageCode: 'kin',
  iso2LanguageCode: 'rw',
  ietfLanguageTag: 'rw',
  parent: null
};
