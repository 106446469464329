import { UserInteractionHandler, UserInteractionRenderer } from '@trustedshops/tswp-core-ui';
import { Input, OnDestroy, Component } from '@angular/core';
import { AbstractPersistentEvent, Event } from '@trustedshops/tswp-core-common';

/**
 * Base class for implementing a user interaction component.
 */
@Component({
  selector: 'app-user-interaction-component-base',
  template: ''
})
export class UserInteractionComponentBase<T extends UserInteractionHandler>
  implements UserInteractionRenderer<T>, OnDestroy {

  //#region Properties
  protected _interaction: AbstractPersistentEvent<T> = new AbstractPersistentEvent(null);

  /**
   * Gets or sets an interaction that should be executed
   */
  @Input()
  public get interaction(): T {
    return this._interaction.value;
  }
  public set interaction(v: T) {
    if (this._interaction.value?.onDispose) {
      this._interaction.value.onDispose();
    }

    if (v?.onInitialize) {
      v.onInitialize();
    }

    this._interaction.emit(v);
  }

  private _renderingOptions: Event<any>;
  /**
   * Gets or sets the rendering options that the renderer should consider
   */
  public get renderingOptions(): Event<any> {
    return this._renderingOptions;
  }
  public set renderingOptions(v: Event<any>) {
    this._renderingOptions = v;
  }

  //#endregion

  //#region Public Methods
  public async ngOnDestroy(): Promise<void> {
    if (this._interaction.value?.onDispose) {
      await this._interaction.value.onDispose();
    }
  }
  //#endregion
}
