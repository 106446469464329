import { CultureInfo } from '../culture-info.model';
import { CultureInfoVaiLatn } from './vai-Latn';

/**
 * @ignore
 */
export const CultureInfoVaiLatnLR: CultureInfo = {
  name: 'vai-Latn-LR',
  displayName: 'Vai (Latn, Laibhiya)',
  iso3LanguageCode: 'vai',
  iso2LanguageCode: 'vai',
  ietfLanguageTag: 'vai-Latn-LR',
  parent: CultureInfoVaiLatn
};
