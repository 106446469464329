/**
 * Enumeration of commonly available stages
 */
export enum DeploymentStageName {
  /**
   * Represents the INTEGR Stage (formerly DEV)
   */
  Integr = 'integr',

  /**
   * Represents the QA Stage (formerly TEST)
   */
  QA = 'qa',

  /**
   * Represents the PROD Stage
   */
  Production = 'prod'
}
