import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoWo: CultureInfo = {
  name: 'wo',
  displayName: 'Wolof',
  iso3LanguageCode: 'wol',
  iso2LanguageCode: 'wo',
  ietfLanguageTag: 'wo',
  parent: null
};
