import { CultureInfo } from '../culture-info.model';
import { CultureInfoFr } from './fr';

/**
 * @ignore
 */
export const CultureInfoFrMA: CultureInfo = {
  name: 'fr-MA',
  displayName: 'French (Morocco)',
  iso3LanguageCode: 'fra',
  iso2LanguageCode: 'fr',
  ietfLanguageTag: 'fr-MA',
  parent: CultureInfoFr
};
