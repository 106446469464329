import { CultureInfo } from '../culture-info.model';
import { CultureInfoMyv } from './myv';

/**
 * @ignore
 */
export const CultureInfoMyvRU: CultureInfo = {
  name: 'myv-RU',
  displayName: 'эрзянь кель (RU)',
  iso3LanguageCode: 'myv',
  iso2LanguageCode: 'myv',
  ietfLanguageTag: 'myv-RU',
  parent: CultureInfoMyv
};
