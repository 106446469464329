import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BootstrapCustomElements } from '../../decorators/bootstrap-custom-elements.decorator';
import { SharedModule } from '../../shared.module';
import { WebComponentControlModule } from '../../web-component-control.module';
import { CardComponent } from './card.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [CardComponent],
  exports: [CardComponent],
  imports: [CommonModule, SharedModule]
})
@BootstrapCustomElements([CardComponent])
export class HlsCardModule extends WebComponentControlModule { }
