import { CultureInfo } from '../culture-info.model';
import { CultureInfoNus } from './nus';

/**
 * @ignore
 */
export const CultureInfoNusSS: CultureInfo = {
  name: 'nus-SS',
  displayName: 'Thok Nath (SS)',
  iso3LanguageCode: 'nus',
  iso2LanguageCode: 'nus',
  ietfLanguageTag: 'nus-SS',
  parent: CultureInfoNus
};
