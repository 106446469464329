import { CultureInfo } from '../culture-info.model';
import { CultureInfoBez } from './bez';

/**
 * @ignore
 */
export const CultureInfoBezTZ: CultureInfo = {
  name: 'bez-TZ',
  displayName: 'Hibena (Hutanzania)',
  iso3LanguageCode: 'bez',
  iso2LanguageCode: 'bez',
  ietfLanguageTag: 'bez-TZ',
  parent: CultureInfoBez
};
