/**
 * Enum describing the type of interaction
 */
export enum UserInteractionType {
  /**
   * Navigation events like clicking an <a> tag,
   * while the click event keeps handled by the browser/framework
   */
  Navigate = 'NAVIGATE',

  /**
   * Navigation events like clicking an anchor element,
   * while the click event keeps handled by the browser/framework
   */
  NavigateToFirstChild = 'NAVIGATE_TO_FIRST_CHILD',

  /**
   * Mouse click events / Taps
   */
  Click = 'CLICK'
}
