import {
  AbstractEvent,
  Action,
  BootstrapperBase,
  LogService,
  TimerService,
  TOKENS,
} from '@trustedshops/tswp-core-common';
import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { BusyService, TOKENS as TOKENS_UI } from '@trustedshops/tswp-core-ui';
import { DOCUMENT } from '@angular/common';
import { AnimationItem } from 'lottie-web';

@Injectable()
export class AnimationConnectionBootstrapper implements BootstrapperBase {
  private static readonly TYPE: string =
    '@trustedshops/tswp-carrier-core:AnimationConnectionBootstrapper';

  private readonly LOADING_ANIMATION_START: number = 0;
  private readonly LOADING_ANIMATION_END: number = 142;
  private readonly FINISH_ANIMATION_START: number = 143;
  private readonly FINISH_ANIMATION_END: number = 169;
  private readonly completedEvent: AbstractEvent<void> =
    new AbstractEvent<void>();

  private cancelSchedule: Action;
  private loadingAnimationActive = true;
  private eventListenersSwitched = false;

  public constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    @Inject(TOKENS.LogService) private readonly logService: LogService,
    @Inject(TOKENS_UI.BusyService) private readonly busyService: BusyService,
    @Inject(TOKENS.TimerService) private readonly timerService: TimerService,
  ) { }

  public async initialize(): Promise<void> {
    this.cancelSchedule = this.timerService.scheduleActionRepetition(100, () =>
      this.findAndWireAnimation(),
    );
  }

  private findAndWireAnimation(): void {
    const window = this.document.defaultView;
    if (!(window as any).anim) {
      return;
    }

    this.logService.trace(
      AnimationConnectionBootstrapper.TYPE,
      'Found animation',
    );
    this.cancelSchedule();

    const animation: AnimationItem = (window as any).anim;
    this.switchEventListeners(animation);

    const fullPageAnimationRegionState =
      this.busyService.getBusyState('FULL_PAGE');
    fullPageAnimationRegionState.subscribe(async (state) => {
      if (!state.isBusy && this.loadingAnimationActive) {
        this.logService.trace(
          AnimationConnectionBootstrapper.TYPE,
          'Playing finish',
        );
        await this.stopInitialLoadingAnimation();
        this.playFinishAnimation(animation);
      } else if (state.isBusy && !this.loadingAnimationActive) {
        this.logService.trace(
          AnimationConnectionBootstrapper.TYPE,
          'Replaying loading animation',
        );
        this.playLoadingAnimation(animation);
      }
    });
  }

  private async playFinishAnimation(animation: AnimationItem): Promise<void> {
    this.logService.trace(AnimationConnectionBootstrapper.TYPE, 'playFinish');

    animation.playSegments(
      [this.FINISH_ANIMATION_START, this.FINISH_ANIMATION_END],
      true,
    );

    const subscription = this.completedEvent.subscribe(() => {
      this.logService.trace(
        AnimationConnectionBootstrapper.TYPE,
        'playFinish - hide container',
      );
      this.document.getElementById('loading-container').classList.add('hidden');
      subscription?.unsubscribe();
    });
  }

  private playLoadingAnimation(animation: AnimationItem): void {
    this.logService.trace(
      AnimationConnectionBootstrapper.TYPE,
      'playLoadingAnimation',
    );
    this.loadingAnimationActive = true;

    this.playLoadingAnimationSegments(animation);
  }

  private async stopInitialLoadingAnimation(): Promise<void> {
    this.loadingAnimationActive = false;
  }

  private switchEventListeners(animation: AnimationItem): void {
    this.logService.trace(
      AnimationConnectionBootstrapper.TYPE,
      'Switching event listeners',
    );
    if (this.eventListenersSwitched) {
      return;
    }

    (window as any).animationFinished = () => this.completedEvent.emit();

    this.eventListenersSwitched = true;

    this.completedEvent.subscribe(() => {
      if (!this.loadingAnimationActive) {
        return;
      }

      this.playLoadingAnimationSegments(animation);
    });
  }

  private playLoadingAnimationSegments(animation: AnimationItem): void {
    this.logService.trace(
      AnimationConnectionBootstrapper.TYPE,
      'playLoadingAnimationSegments',
    );
    animation.playSegments(
      [this.LOADING_ANIMATION_START, this.LOADING_ANIMATION_END],
      true,
    );
  }
}

export const AnimationConnectionInitializer = [
  AnimationConnectionBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [AnimationConnectionBootstrapper],
  },
];
