import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKln: CultureInfo = {
  name: 'kln',
  displayName: 'Kalenjin',
  iso3LanguageCode: 'kln',
  iso2LanguageCode: 'kln',
  ietfLanguageTag: 'kln',
  parent: null
};
