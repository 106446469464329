import { ArgumentError } from '../errors/argument.error';

export class ArgumentChecks {
  //#region Public Static Methods
  /**
   * Throws an error if the given value is null or undefined.
   * In case of a string another check is made if it is whitespace or empty
   *
   * @param arg The argument to check
   * @param name The argument's name
   */
  public static isNotNullOrUndefinedOrWhitespace(arg: string, name?: string): void {
    ArgumentChecks.isNotNullOrUndefined(arg, name);

    if (arg.trim() === '') {
      throw new ArgumentError(`${name} is whitespace or empty`);
    }
  }

  /**
   * Throws an error if the given value is null or undefined.
   *
   * @param arg The argument to check
   * @param name The argument's name
   */
  public static isNotNullOrUndefined(arg: any, name?: string): void {
    if (arg === undefined || arg === null) {
      throw new ArgumentError(`${name} is null or undefined`);
    }
  }
  //#endregion
}
