import { CultureInfo } from '../culture-info.model';
import { CultureInfoUg } from './ug';

/**
 * @ignore
 */
export const CultureInfoUgCN: CultureInfo = {
  name: 'ug-CN',
  displayName: 'Uyghur (China mainland)',
  iso3LanguageCode: 'uig',
  iso2LanguageCode: 'ug',
  ietfLanguageTag: 'ug-CN',
  parent: CultureInfoUg
};
