import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoTeo: CultureInfo = {
  name: 'teo',
  displayName: 'Kiteso',
  iso3LanguageCode: 'teo',
  iso2LanguageCode: 'teo',
  ietfLanguageTag: 'teo',
  parent: null
};
