import { CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable, Inject } from '@angular/core';
import { TOKENS, PluginService, PluginRouteData } from '@trustedshops/tswp-core-plugins';

/**
 * Pauses the route resolution for the loading time of the required plugin.
 * Stops navigating if the plugin fails to load.
 */
@Injectable()
export class PluginLoadedRouteGuard implements CanActivate {
  //#region Ctor
  /** ignore */
  public constructor(@Inject(TOKENS.PluginService) private readonly _pluginService: PluginService) { }
  //#endregion

  //#region Public Methods
  /**
   * Checks if the plugin defined in the route.data property on if it can be loaded.
   * @param route The route to check the activation for
   */
  public async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      const pluginRouteData: PluginRouteData = route.data as unknown as PluginRouteData;

      const registration = await this._pluginService.loadPluginByName(pluginRouteData.pluginName);
      if (!registration) {
        return false;
      }
      return true;
    } catch {
      return false;
    }
  }
  //#endregion
}
