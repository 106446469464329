import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BootstrapCustomElements } from '../../decorators/bootstrap-custom-elements.decorator';
import { SharedModule } from '../../shared.module';
import { WebComponentControlModule } from '../../web-component-control.module';
import { TooltipComponent } from './tooltip.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [TooltipComponent],
  exports: [TooltipComponent],
  imports: [CommonModule, SharedModule, BrowserAnimationsModule],
  providers: [],
})
@BootstrapCustomElements(
  [TooltipComponent],
  [
    /*{
  provide: OverlayContainer,
  useFactory: (document: Document, platform: Platform, hostElement: HTMLElement) =>
    new TooltipOverlayContainer(document, platform, hostElement),
  deps: [DOCUMENT, Platform, WebComponentElement],
}*/
  ]
)
export class HlsTooltipModule extends WebComponentControlModule {}
