<button (click)="toggleChecklist()" type="button" class="userflow-trigger">
  <span class="rocket-icon"></span>
  <span class="trigger-content">
    <span
      class="trigger-title"
      [translate]="'navigation.userflow.button.title'"
    ></span>
    <span *ngIf="(progress$ | async) > -1" class="progress-container">
      <span [style.width.%]="progress$ | async" class="progress-bar"></span>
    </span>
  </span>
  <hls-icon icon="arrow-chevron-down" class="chevron-down-icon" [class.opened]="isOpened"></hls-icon>
</button>

<div
  class="overlay"
  [class.visible]="isOpened"
  (click)="closeChecklist()"
  aria-hidden="true"
></div>
