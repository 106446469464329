<div class="error-wrapper">
  <div class="logo">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="72"
      height="72"
      viewBox="0 0 28 28"
    >
      <title>TrustedShops</title>
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g fill="#000000">
          <path
            d="M14.0002835,3 C20.0750754,3 25,7.92435762 25,13.9997165 C25,20.0750754 20.0750754,25 14.0002835,25 C7.92492462,25 3,20.0750754 3,13.9997165 C3,7.92435762 7.92492462,3 14.0002835,3 Z M13.9089972,7.03304039 C10.0806423,7.03304039 6.97747481,10.1362078 6.97747481,13.9645628 C6.97747481,17.7929177 10.0806423,20.8955182 13.9089972,20.8955182 C17.7367851,20.8955182 20.8405196,17.7929177 20.8405196,13.9645628 C20.8405196,10.1362078 17.7367851,7.03304039 13.9089972,7.03304039 Z M15.2777248,9.26019948 C16.4173861,9.26019948 17.9511095,9.72059998 17.9511095,11.1012345 C17.9511095,13.5773047 14.0728589,14.4102214 12.1660524,14.8921677 C12.1002809,15.3083426 12.0345094,15.7245174 12.0345094,16.1412592 C12.0345094,16.9951548 12.4943429,17.7844128 13.4366898,17.7844128 C14.6642355,17.7844128 15.650241,16.6010928 16.3731605,15.7245174 L16.9214453,16.1412592 C15.913327,17.6307569 14.5105796,18.9898456 12.6264529,18.9898456 C10.6317621,18.9898456 9.47055488,17.7407541 9.47055488,15.7245174 C9.47055488,12.4597562 11.8808536,9.26019948 15.2777248,9.26019948 Z M15.0582975,9.91734749 C13.4809154,9.91734749 12.6701116,12.9853612 12.3633669,14.1907941 C13.8313188,13.7525064 15.8696683,12.5476405 15.8696683,10.8160357 C15.8696683,10.3119765 15.5844695,9.91734749 15.0582975,9.91734749 Z"
          />
        </g>
      </g>
    </svg>
  </div>

  <div class="content">
    <ng-content></ng-content>
  </div>

  <div class="actions">
    <ng-content select="[actions]"></ng-content>
  </div>
</div>
