import { serializeTypedObject } from '../utils/serialize-typed-object';
import { CommonError } from './common.error';

/**
 * Basic Error class
 */
export class ErrorBase extends Error implements CommonError {
  //#region Properties
  private _innerError: Error;
  /**
   * Gets the underlying error that triggered an instance of this one.
   */
  public get innerError(): Error {
    return this._innerError;
  }
  //#endregion

  //#region Ctor
  /**
   * Initializes a new instance of the ErrorBase class.
   * @param msg The error message that explains the reason for the exception.
   */
  public constructor(msg: string, innerError?: Error) {
    super(msg);

    this._innerError = innerError;
  }
  //#endregion

  //#region Public Methods
  public override toString(): string {
    const baseMessage = super.toString();
    if (this.innerError) {
      const innerErrorMessage = this.innerError
        .toString()
        .split('\n')
        .join('\n  ');

      return `${baseMessage}\n  InnerError: ${innerErrorMessage}`;
    }

    return baseMessage;
  }

  public toJSON(): string {
    return serializeTypedObject(this);
  }
  //#endregion
}
