import { CultureInfo } from '../culture-info.model';
import { CultureInfoEs } from './es';

/**
 * @ignore
 */
export const CultureInfoEsGT: CultureInfo = {
  name: 'es-GT',
  displayName: 'Spanish (Guatemala)',
  iso3LanguageCode: 'spa',
  iso2LanguageCode: 'es',
  ietfLanguageTag: 'es-GT',
  parent: CultureInfoEs
};
