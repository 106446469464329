import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoMni: CultureInfo = {
  name: 'mni',
  displayName: 'Manipuri',
  iso3LanguageCode: 'mni',
  iso2LanguageCode: 'mni',
  ietfLanguageTag: 'mni',
  parent: null
};
