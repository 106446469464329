import { CultureInfo } from '../culture-info.model';
import { CultureInfoDv } from './dv';

/**
 * @ignore
 */
export const CultureInfoDvMV: CultureInfo = {
  name: 'dv-MV',
  displayName: 'Dhivehi (Maldives)',
  iso3LanguageCode: 'div',
  iso2LanguageCode: 'dv',
  ietfLanguageTag: 'dv-MV',
  parent: CultureInfoDv
};
