import { CultureInfo } from '../culture-info.model';
import { CultureInfoKok } from './kok';

/**
 * @ignore
 */
export const CultureInfoKokIN: CultureInfo = {
  name: 'kok-IN',
  displayName: 'Konkani (India)',
  iso3LanguageCode: 'kok',
  iso2LanguageCode: 'kok',
  ietfLanguageTag: 'kok-IN',
  parent: CultureInfoKok
};
