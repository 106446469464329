import { NgModule } from '@angular/core';
import { BrowserPluginHostProvider } from './services/plugin-hosts/browser-plugin-host-provider.service';
import { TOKENS } from '@trustedshops/tswp-core-plugins';

@NgModule({
  imports: [
  ],
  providers: [
    { provide: TOKENS.PluginHostProvider, useClass: BrowserPluginHostProvider }
  ]
})
export class BrowserHostedPluginSystemModule { }
