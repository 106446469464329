import { CultureInfo } from '../culture-info.model';
import { CultureInfoFil } from './fil';

/**
 * @ignore
 */
export const CultureInfoFilPH: CultureInfo = {
  name: 'fil-PH',
  displayName: 'Filipino (Philippines)',
  iso3LanguageCode: 'fil',
  iso2LanguageCode: 'fil',
  ietfLanguageTag: 'fil-PH',
  parent: CultureInfoFil
};
