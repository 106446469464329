import { CultureInfo } from '../culture-info.model';
import { CultureInfoAm } from './am';

/**
 * @ignore
 */
export const CultureInfoAmET: CultureInfo = {
  name: 'am-ET',
  displayName: 'Amharic (Ethiopia)',
  iso3LanguageCode: 'amh',
  iso2LanguageCode: 'am',
  ietfLanguageTag: 'am-ET',
  parent: CultureInfoAm
};
