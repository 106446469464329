import { CultureInfo } from '../culture-info.model';
import { CultureInfoGn } from './gn';

/**
 * @ignore
 */
export const CultureInfoGnPY: CultureInfo = {
  name: 'gn-PY',
  displayName: 'Guarani (Paraguay)',
  iso3LanguageCode: 'grn',
  iso2LanguageCode: 'gn',
  ietfLanguageTag: 'gn-PY',
  parent: CultureInfoGn
};
