import { CultureInfo } from '../culture-info.model';
import { CultureInfoKi } from './ki';

/**
 * @ignore
 */
export const CultureInfoKiKE: CultureInfo = {
  name: 'ki-KE',
  displayName: 'Gikuyu (Kenya)',
  iso3LanguageCode: 'kik',
  iso2LanguageCode: 'ki',
  ietfLanguageTag: 'ki-KE',
  parent: CultureInfoKi
};
