import { CultureInfo } from '../culture-info.model';
import { CultureInfoCcp } from './ccp';

/**
 * @ignore
 */
export const CultureInfoCcpIN: CultureInfo = {
  name: 'ccp-IN',
  displayName: '𑄌𑄋𑄴𑄟𑄳𑄦 (𑄞𑄢𑄧𑄖𑄴)',
  iso3LanguageCode: 'ccp',
  iso2LanguageCode: 'ccp',
  ietfLanguageTag: 'ccp-IN',
  parent: CultureInfoCcp
};
