import { CultureInfo } from '../culture-info.model';
import { CultureInfoAf } from './af';

/**
 * @ignore
 */
export const CultureInfoAfZA: CultureInfo = {
  name: 'af-ZA',
  displayName: 'Afrikaans (South Africa)',
  iso3LanguageCode: 'afr',
  iso2LanguageCode: 'af',
  ietfLanguageTag: 'af-ZA',
  parent: CultureInfoAf
};
