import { UserInteractionType } from '../../models/user-interaction/models/user-interaction-type.enum';
import { Type } from '@trustedshops/tswp-core-common';

/**
 * Service for requesting and registering user interaction renderers
 */
export interface UserInteractionRendererProvider {
  /**
   * Gets the type of the renderer for a given user interaction type
   *
   * @param type The type to get the renderer for
   */
  getRenderer(type: UserInteractionType | string): Type<any>;

  /**
   * Registers a renderer for the given user interaction type
   *
   * @param type The user interaction type to register the handler for
   * @param handler The handler that renders visual components for the given user interaction type
   */
  registerRenderer(type: UserInteractionType | string, handler: Type<any>): UserInteractionRendererProvider;
}
