import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoDje: CultureInfo = {
  name: 'dje',
  displayName: 'Zarmaciine',
  iso3LanguageCode: 'dje',
  iso2LanguageCode: 'dje',
  ietfLanguageTag: 'dje',
  parent: null
};
