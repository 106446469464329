import { Injectable, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import {
  InvalidOperationError,
  Guid,
  ExportToken,
  Event,
  AbstractPersistentEvent,
  ObservableArray,
  EventSubscription
} from '@trustedshops/tswp-core-common';
import {
  NavigationItem,
  TOKENS,
  NavigationService,
  NavigateUserInteractionHandler,
  UriLocationType,
  ClickUserInteractionHandler,
  UserInteractionType,
  UserInteractionHandlerFactory,
  UserInteractionHandler
} from '@trustedshops/tswp-core-ui';
import { UserInteractionHandlerTypes, ChildNavigationItem } from '@trustedshops/tswp-core-ui';
import { BehaviorSubject, concat } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';
import { fromObservable } from '@trustedshops/tswp-core-common-eventing-rxjs';
import {
  NavigateFirstUserInteractionHandlerImpl
} from '../user-interaction/navigate-first-user-interaction/navigate-first-user-interaction-handler';
import { UserInteractionHandlerTypesMap } from '../models/user-interaction-handler-types-map';
import { Writable } from '../models/writable';

/**
 * A service responsible for registering and distributing navigation item information
 */
@ExportToken(TOKENS.NavigationService)
@Injectable()
export class NavigationServiceImpl implements NavigationService {

  //#region Private Fields
  private readonly _factories: UserInteractionHandlerTypesMap =
    new UserInteractionHandlerTypesMap()
      .set(UserInteractionType.Click, ({ command }) =>
        new ClickUserInteractionHandler(command))
      .set(UserInteractionType.NavigateToFirstChild, ({ parentNavigationItemId, childrenRegion }) =>
        new NavigateFirstUserInteractionHandlerImpl(this, parentNavigationItemId, childrenRegion))
      .set(UserInteractionType.Navigate, ({ uri, locationType, queryParams }) =>
        new NavigateUserInteractionHandler(uri, locationType, queryParams));


  private readonly _regions: Map<string, ObservableArray<NavigationItem>> = new Map();
  private _initialized = false;
  //#endregion

  //#region Properties
  private readonly _registeredItems: ObservableArray<NavigationItem> = new ObservableArray();
  public get registeredItems(): ObservableArray<NavigationItem> {
    return this._registeredItems;
  }

  private readonly _registeredRegions: ObservableArray<string> = new ObservableArray();
  public get registeredRegions(): ObservableArray<string> {
    return this._registeredRegions;
  }

  private _activeNavigationItems: BehaviorSubject<NavigationItem[]> = new BehaviorSubject([]);
  public get activeItems(): Event<NavigationItem[]> {
    return fromObservable(this._activeNavigationItems, AbstractPersistentEvent);
  }
  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of NavigationItemServiceImpl
   */
  public constructor(
    private readonly _ngZone: NgZone,
    private readonly _router: Router) { }
  //#endregion

  //#region Public Methods
  /**
   * Gets an observable navigation items collection for a given region
   * @param region The region to get the navigation item stream for
   */
  public getItemsForRegion(region: string): ObservableArray<NavigationItem> {
    this.ensureActivationListenerRunning();

    if (!this._regions.has(region)) {
      this._regions.set(region, new ObservableArray());
      this._registeredRegions.push(region);
    }

    return this._regions.get(region);
  }

  /**
   * Gets an item with the specified id.
   *
   * @param itemId The id of the item to resolve.
   * @returns A promise resolving as soon as the item has been registered.
   */
  public async getItemById(itemId: string): Promise<NavigationItem> {
    const currentlyRegistered = this._registeredItems.find(x => x.id === itemId);
    if (currentlyRegistered) {
      return currentlyRegistered;
    }

    return new Promise(resolve => {
      const subscription = this._registeredItems.onChanged
        .subscribe(event => {
          const requestedItemInsertion = event.inserted.find(x => x.item.id === itemId);
          if (requestedItemInsertion) {
            resolve(requestedItemInsertion.item);
            subscription?.unsubscribe();
          }
        });
    });
  }

  /**
   * Registers a set of navigation items in the specified regions
   * @param definitions The navigation item definition to register
   * @param regions The regions to register navigation items in
   */
  public registerNavigationItems(definitions: NavigationItem[], ...regions: string[]): NavigationService {
    this.ensureActivationListenerRunning();

    if (!regions || !regions.length) {
      throw new InvalidOperationError(
        'You must not register navigation items without any region. ' +
        'Please refer to the platform documentation for more information.');
    }

    for (const region of regions) {
      const regionItems = this.getItemsForRegion(region);

      const itemRegistrations = [...definitions
        .map(item => {
          if (!item.userInteraction) {
            throw new InvalidOperationError(`Navigation item must have userInteraction property set.`);
          }

          const children = item.children;

          item.children = new ObservableArray();

          this
            .assignItemId(item)
            .setupVisibilityGuards(item)
            .registerChildren(item, children);

          return item;
        })];

      this._ngZone.run(() => {
        this._registeredItems.push(...itemRegistrations);
        regionItems.push(...itemRegistrations);
      });
    }

    return this;
  }

  public getUserInteractionFactory<T extends keyof UserInteractionHandlerTypes>(type: T): UserInteractionHandlerFactory<T> {
    return this._factories.get(type);
  }

  public async registerChildNavigationItem(

    parent: NavigationItem | string,

    definitions: ChildNavigationItem[]): Promise<void> {

    const parentId = typeof parent === 'string'
      ? parent
      : parent.id;

    const parentItem = await this.getItemById(parentId);

    this.registerChildren(parentItem, definitions);
  }
  //#endregion

  //#region Private Methods
  private setupVisibilityGuards(item: NavigationItem<any, UserInteractionHandler>): NavigationServiceImpl {
    const writableItem = item as Writable<NavigationItem>;
    writableItem.isVisible = new AbstractPersistentEvent(false);

    const visibilityGuards = this.convertVisibilityGuardsToObservable(item);

    visibilityGuards.push(this.getChildrenCheckGuard(item));

    const initiallyVisible = this.getIsVisible(visibilityGuards);
    if (initiallyVisible) {
      writableItem.isVisible.emit(initiallyVisible);
    }

    let subscriptions = this.subscribeToGuards(item);
    visibilityGuards.onChanged.subscribe(() =>
      subscriptions = this
        .cleanVisibilityGuardSubscriptions(subscriptions)
        .subscribeToGuards(item));

    return this;
  }

  public getChildrenCheckGuard(item: NavigationItem): AbstractPersistentEvent<boolean> {
    const directInteractionTypes: string[] = [
      UserInteractionType.Navigate,
      UserInteractionType.Click
    ];

    const observableChildren = item.children as ObservableArray<ChildNavigationItem>;

    const hasNoChildren = (checkedItem: NavigationItem) =>
      checkedItem.children.length === 0;

    const event = new AbstractPersistentEvent(
      hasNoChildren(item)
      && directInteractionTypes.includes(item.userInteraction.type));

    let subscriptions = [] as EventSubscription<boolean>[];
    observableChildren.onChanged.subscribe(() => {
      subscriptions.forEach(subscription =>
        subscription?.unsubscribe());
      subscriptions = [];

      if (hasNoChildren(item) && directInteractionTypes.includes(item.userInteraction.type)) {
        event.emit(true);
        return;
      }

      const childrenVisibilities = item.children.map(x => x.isVisible.value);
      event.emit(
        item.children.length > 0
        && childrenVisibilities.some(isVisible => isVisible));

      subscriptions = item.children.map((child, index) =>
        child.isVisible.subscribe((isVisible?: boolean) => {
          childrenVisibilities[index] = isVisible;
          event.emit(
            item.children.length > 0 &&
            childrenVisibilities.some(isChildVisible => isChildVisible));
        }));
    });

    return event;
  }

  private subscribeToGuards(item: NavigationItem): EventSubscription<boolean>[] {
    return item.visibilityGuards.map(guard =>
      guard.subscribe(() =>
        item.isVisible.emit(
          this.getIsVisible(
            item.visibilityGuards as ObservableArray<AbstractPersistentEvent<boolean>>))));
  }

  private cleanVisibilityGuardSubscriptions(subscriptions: EventSubscription<boolean>[]): NavigationServiceImpl {
    for (const subscription of subscriptions) {
      subscription?.unsubscribe();
    }

    subscriptions.splice(0, subscriptions.length);

    return this;
  }

  private getIsVisible(visibilityGuards: ObservableArray<AbstractPersistentEvent<boolean>>): boolean {
    return visibilityGuards
      .every(({ value: isVisible }) => isVisible);
  }

  private convertVisibilityGuardsToObservable(
    item: NavigationItem<any, UserInteractionHandler>): ObservableArray<AbstractPersistentEvent<boolean>> {

    const writableItem = item as Writable<NavigationItem>;
    const visibilityGuards = item.visibilityGuards ?? [];
    writableItem.visibilityGuards = new ObservableArray();
    writableItem.visibilityGuards.push(...visibilityGuards);
    return item.visibilityGuards as ObservableArray<AbstractPersistentEvent<boolean>>;
  }

  private assignItemId(item: NavigationItem<any, UserInteractionHandler>): NavigationServiceImpl {
    (item as any).runtimeId = Guid.newGuid();
    // TODO: Verify and warn about duplicate identifier given.
    item.id = item.id || item.runtimeId;
    return this;
  }

  private ensureActivationListenerRunning(): void {
    if (this._initialized) {
      return;
    }
    this._initialized = true;

    concat(
      new BehaviorSubject(new NavigationEnd(0, '', '')).pipe(first()),
      this._router.events)
      .pipe(filter(event => event instanceof NavigationEnd))
      .pipe(map(() => {
        const activeItems: NavigationItem[] = [];
        const itemsInRegions = Array.from(this._regions.values());
        const allItems = (itemsInRegions
          .reduce((prev, cur) => prev.concat(cur), []) as NavigationItem[])
          .filter(x => (x.userInteraction as NavigateUserInteractionHandler).uriLocationType === UriLocationType.Internal);

        for (const item of allItems) {
          const { userInteraction }: NavigationItem = item;
          const navigateUserInteraction = userInteraction as NavigateUserInteractionHandler;
          if (!this._router.isActive(navigateUserInteraction.uri, false)) {
            continue;
          }

          let current = item;
          while (current) {
            if (!activeItems.includes(current)) {
              activeItems.push(current);
            }
            current = (current as NavigationItem & { parent: NavigationItem }).parent;
          }
        }
        return activeItems;
      })).subscribe(x => this._activeNavigationItems.next(x));
  }

  private registerChildren(parent: NavigationItem, children: ChildNavigationItem[]): void {
    if (!children) {
      return;
    }

    children.forEach(child => {
      const region = child.region;

      this.registerNavigationItems([
        Object.assign(child, { parent })
      ] as Array<NavigationItem & { parent: NavigationItem }>, region);

      if (!parent.children.includes(child)) {
        parent.children.push(child);
      }
    });
  }
  //#endregion
}
