import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import {
  AbstractAsyncPersistentEvent,
  CultureInfo,
  CurrentCultureInfoProvider,
  LocalStorageService,
  TOKENS,
} from '@trustedshops/tswp-core-common';
import {
  Identity,
  IdentityService,
  TOKENS as TOKENS_AUTH,
} from '@trustedshops/tswp-core-authorization';
import { CarrierConfigToken } from '../models/carrier-config.token';
import { CarrierConfig } from '../models/carrier-config.interface';
import { KeycloakProfileData } from '@trustedshops/tswp-core-authorization-keycloak';
import { toPromise } from '@trustedshops/tswp-core-common-eventing-rxjs';

@Injectable()
export class CurrentCultureInfoProviderImpl
  implements CurrentCultureInfoProvider
{
  readonly _currentCulture: AbstractAsyncPersistentEvent<CultureInfo>;
  private _initialized: boolean;

  public constructor(
    @Inject(DOCUMENT) private readonly _document: Document,
    @Inject(TOKENS.LocalStorageService)
    private readonly _localStorageService: LocalStorageService,
    @Inject(TOKENS_AUTH.IdentityService)
    private readonly _identityService: IdentityService,
    @Inject(CarrierConfigToken) private readonly _carrierConfig: CarrierConfig,
  ) {
    this._currentCulture = new AbstractAsyncPersistentEvent();
  }

  public get currentCulture(): AbstractAsyncPersistentEvent<CultureInfo> {
    return this._currentCulture;
  }

  public async initialize(): Promise<void> {
    if (this._initialized) {
      return;
    }

    let urlLanguage: CultureInfo = null;
    const queryParams = this.getQueryParams();
    if (queryParams?.['locale']) {
      urlLanguage = this.findCultureByString(queryParams['locale']);
    }

    if (queryParams?.['lang']) {
      urlLanguage = this.findCultureByString(queryParams['lang']);
    }

    // TODO: Remove localStorage usage, keep localStorageService,
    // after migration (https://tracker.trustedshops.com/browse/PL-1547)
    const storedLanguage: string =
      this._localStorageService
        .createScoped('tswp-carrier')
        .select('culture') ||
      document.defaultView.localStorage.getItem('preferredLanguage');

    const storageLanguage = this.findCultureByString(storedLanguage);
    const fixedDefaultLanguage =
      this._carrierConfig.globalization.defaultCulture;
    const browserLanguage = this.findCultureByString(
      document.defaultView.navigator.language,
    );

    let tokenLanguage = null;
    if (this._identityService?.sessionProvider?.hasSession) {
      const identity = await toPromise<Identity<KeycloakProfileData>>(
        this._identityService.identity,
      );
      const profile: KeycloakProfileData = identity.profile;
      const token = await toPromise(profile.keycloak.token.payload);

      tokenLanguage = this.findCultureByString(token?.['locale']);
    }

    const extendedLanguageSettings = [
      urlLanguage,
      storageLanguage,
      tokenLanguage,
      browserLanguage,
      fixedDefaultLanguage,
    ];

    const cultureLookup = extendedLanguageSettings.find((culture) => !!culture);
    this.updateCurrentCulture(cultureLookup);
  }

  public resolveBaseCulture(locale: string): CultureInfo {
    const [_, baseCulture]: [CultureInfo, CultureInfo] = (
      this._carrierConfig.globalization.baseCultureMappings || []
    ).find(
      ([resolved]) =>
        resolved.ietfLanguageTag === locale ||
        resolved.iso2LanguageCode === locale,
    );

    return baseCulture || null;
  }

  public updateCurrentCulture(cultureInfo: CultureInfo): void {
    this._currentCulture.emit(cultureInfo);

    this.saveCultureInfo(cultureInfo);
  }

  private saveCultureInfo(cultureInfo: CultureInfo): void {
    this._localStorageService
      .createScoped('tswp-carrier')
      .insertOrUpdate('culture', cultureInfo.ietfLanguageTag);

    // TODO: Remove localStorage usage, keep localStorageService,
    // after migration (https://tracker.trustedshops.com/browse/PL-1547)
    this._document.defaultView.localStorage.removeItem('preferredLanguage');
  }

  private findCultureByString(str: string): CultureInfo {
    if (!str) {
      return null;
    }

    let cultureFound = this._carrierConfig.globalization.cultures.find(
      (x) => x.ietfLanguageTag === str,
    );
    if (cultureFound && str.length > 2) {
      return cultureFound;
    }

    if (str.includes('-')) {
      str = str.substring(0, str.indexOf('-'));
    }

    const alternativeCultureTag = str;
    cultureFound = this._carrierConfig.globalization.cultures.find(
      (x) =>
        x.iso2LanguageCode === alternativeCultureTag &&
        x.ietfLanguageTag !== alternativeCultureTag,
    );
    return cultureFound || null;
  }

  private getQueryParams(): Record<string, string> {
    const currentQueryParams: Record<string, string> = {};
    const urlSearchParams = new URLSearchParams(document.location.search);
    urlSearchParams.forEach((value, key) => (currentQueryParams[key] = value));
    return currentQueryParams;
  }
}
