import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoBo: CultureInfo = {
  name: 'bo',
  displayName: 'Tibetan',
  iso3LanguageCode: 'bod',
  iso2LanguageCode: 'bo',
  ietfLanguageTag: 'bo',
  parent: null
};
