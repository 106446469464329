import { CultureInfo } from '../culture-info.model';
import { CultureInfoKln } from './kln';

/**
 * @ignore
 */
export const CultureInfoKlnKE: CultureInfo = {
  name: 'kln-KE',
  displayName: 'Kalenjin (Emetab Kenya)',
  iso3LanguageCode: 'kln',
  iso2LanguageCode: 'kln',
  ietfLanguageTag: 'kln-KE',
  parent: CultureInfoKln
};
