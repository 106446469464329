/**
 * Declares a LoggingTarget which is a proxy target of the `LogService`
 */
export interface LoggingTarget {
  /**
   * Adds a log entry with `LogLevel.Trace`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  trace(message: string, payload?: any): void;

  /**
   * Adds a log entry with `LogLevel.Debug`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  debug(message: string, payload?: any): void;

  /**
   * Adds a log entry with `LogLevel.Information`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  information(message: string, payload?: any): void;

  /**
   * Adds a log entry with `LogLevel.Warning`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  warning(message: string, payload?: any): void;

  /**
   * Adds a log entry with `LogLevel.Error`.
   *
   * @param logSource The source that this event is coming from
   * @param message The error that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  error(message: string, payload?: any): void;

  /**
   * Adds a log entry with `LogLevel.Fatal`.
   *
   * @param logSource The source that this event is coming from
   * @param message The error that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  fatal(message: string, payload?: any): void;
}

