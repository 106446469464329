import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  Identity,
  IdentityService,
  TOKENS,
} from '@trustedshops/tswp-core-authorization';
import { BehaviorSubject, from, of, Subject } from 'rxjs';
import {
  first,
  filter,
  switchMap,
  map,
  catchError,
  take,
  distinctUntilChanged,
  tap,
} from 'rxjs/operators';
import { KeycloakProfileData } from '@trustedshops/tswp-core-authorization-keycloak';
import { RxJsSubjectBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import {
  TOKENS as COMMON_TOKENS,
  LogService,
  AbstractPersistentEvent,
  CultureInfoService,
  CultureInfo,
  HttpClientService,
} from '@trustedshops/tswp-core-common';
import {
  TOKENS as MASTERDATA_TOKENS,
  FeatureBookingService,
} from '@trustedshops/tswp-core-masterdata';
import userFlow from 'userflow.js';
import { environment } from '../configuration/get-environment.util';
import { ReviewsService } from './reviews.service';
import { UserService } from './user.service';
import { Observable } from 'rxjs/internal/Observable';
import { HttpParams } from '@angular/common/http';

// TODO: Move to other place where we store interfaces
interface UserFlowIdentity {
  identityRef: string;
  email: string;
  firstName: string;
  lastName: string;
  hasReviews: boolean;
  createdAt: string;
  isFreeAccount: boolean;
  isUnverifiedFreeAccount: boolean;
  metadata: any[];
  locale_code: string;
}

export interface ProgressResponse {
  userId: string;
  progress: number;
}

@Injectable()
export class UserFlowService {
  private static readonly TYPE: string = 'UserFlowService';

  private readonly baseUrl: string = environment.userFlow.proxyApi;

  private readonly progressSubject$: BehaviorSubject<number> = new BehaviorSubject<number>(-1);
  public readonly progress$: Observable<number> = this.progressSubject$.asObservable();

  public constructor(
    @Inject(TOKENS.IdentityService)
    private readonly identityService: IdentityService,
    @Inject(COMMON_TOKENS.LogService) private readonly logService: LogService,
    @Inject(MASTERDATA_TOKENS.FeatureBookingService)
    private readonly featureBookingService: FeatureBookingService,
    @Inject(COMMON_TOKENS.CultureInfoService)
    private readonly cultureInfoService: CultureInfoService,
    private readonly reviewsService: ReviewsService,
    private readonly userService: UserService,
    private readonly router: Router,
    @Inject(COMMON_TOKENS.HttpClientService)
    private readonly httpClient: HttpClientService,
  ) { }

  /*
   This method is called once the application has been loaded.
   It inits the UserFlow library and update User profile in the UserFlow
   */
  public init(): void {
    const getIdentityProfile = this.identityService.identity
      .convertWith<Subject<Identity<KeycloakProfileData>>>(RxJsSubjectBridge)
      .pipe(
        filter((identity: Identity<KeycloakProfileData>) => !!identity),
        first(),
        map(
          (identity: Identity<KeycloakProfileData>) =>
            identity.profile.keycloak.token
              .payload as AbstractPersistentEvent<any>,
        ),
        // Ensure that this subscription evaluates once
        take(1),
      );

    // TODO: Move inline method outside of the init method
    /* Get User logged in data */
    const getKeycloakProfile = (payload: AbstractPersistentEvent<any>) =>
      payload.convertWith<Subject<any>>(RxJsSubjectBridge);

    /* Get Locale Code */
    const getLocaleCode = () =>
      this.cultureInfoService.currentCulture
        .convertWith<Observable<CultureInfo>>(RxJsSubjectBridge)
        .pipe(
          map((cultureInfo) => cultureInfo.ietfLanguageTag),
          distinctUntilChanged(),
        );

    /* Get Account reviews count */
    const getAccountReviews = () => {
      let params = new HttpParams();
      params = params.set('count', '1');

      return this.reviewsService.getReviewsCount(params);
    };

    /* Check if Account is in the Free state */
    const getIsFreeAccount = () =>
      from(this.featureBookingService.hasAccountFeatureBooked('FREE_ACCOUNT'));

    /* Check if Account is in the Unverified state */
    const getIsUnverifiedAccount = () =>
      from(this.featureBookingService.isUnverifiedAccount());

    /* Get logged in User Details to extract createdAt and updatedAt dates */
    const getUserDetails = (profile: any) => {
      const accountRef = profile['https://etrusted.com'].accountRef;
      const identityRef = profile.identityRef;

      return this.userService.getUser(identityRef, accountRef);
    };

    /* Define UserFlowIdentity object to send it to the UserFlow */
    const mapToUserFlowIdentity = ({
      hasReviews,
      profile,
      isFreeAccount,
      isUnverifiedFreeAccount,
      locale_code,
      createdAt
    }: any): UserFlowIdentity => ({
      identityRef: profile.identityRef,
      email: profile.email,
      firstName: profile.given_name,
      lastName: profile.family_name,
      hasReviews,
      createdAt,
      isFreeAccount,
      isUnverifiedFreeAccount,
      metadata: [],
      locale_code,
    });

    /* Init UseFlow library */
    const initializeUserFlow = (user: UserFlowIdentity) => {
      userFlow.init(environment.userFlow.apiKey);

      /* By default, Go to page actions in Userflow trigger full page reloads using window.location.href = url.
       This code overrides that behavior to use a single page approach without reloading.
       For more details, visit Userflow JS documentation: https://docs.userflow.com/docs/userflow-js#setcustomnavigate.
      */
      userFlow.setCustomNavigate(url => {
        this.router.navigate([url]);
      });

      this.logService.debug(
        UserFlowService.TYPE,
        'UserFlowService initialized',
        user,
      );

      return from(userFlow.identify(user.identityRef, { ...user }));
    };

    getIdentityProfile
      .pipe(
        switchMap(getKeycloakProfile),
        switchMap((profile) =>
          getIsFreeAccount().pipe(
            map((isFreeAccount) => ({
              profile,
              isFreeAccount,
            })),
            catchError((err) => {
              this.logService.debug(
                UserFlowService.TYPE,
                `Check if Free Account failed: ${err}`,
              );

              return of({
                profile,
                isFreeAccount: undefined,
              });
            }),
          ),
        ),
        switchMap((props) =>
          getIsUnverifiedAccount().pipe(
            map((isUnverified) => ({
              ...props,
              isUnverifiedFreeAccount: isUnverified,
            })),
            catchError((err) => {
              this.logService.debug(
                UserFlowService.TYPE,
                `Check if Free Account failed: ${err}`,
              );

              return of({
                ...props,
                isUnverifiedFreeAccount: undefined,
              });
            }),
          ),
        ),
        filter(props => !props.isUnverifiedFreeAccount),
        // TODO: Replace the following switchMap methods with forkJoin.
        switchMap((props) =>
          getAccountReviews().pipe(
            map((response) => ({
              ...props,
              hasReviews: response?.items?.length > 0,
              profile: props.profile,
            })),
            catchError((err) => {
              this.logService.debug(
                UserFlowService.TYPE,
                `Get Reviews failed: ${err}`,
              );

              return of({ hasReviews: undefined, profile: props.profile, ...props });
            }),
          ),
        ),
        switchMap((props) =>
          getLocaleCode().pipe(
            map((locale_code) => ({
              ...props,
              locale_code,
            })),
            catchError((err) => {
              this.logService.debug(
                UserFlowService.TYPE,
                `Get locale_code failed: ${err}`,
              );

              return of({
                ...props,
                locale_code: undefined,
              });
            }),
          ),
        ),
        switchMap(props => this.identityService.sessionProvider.parsedToken.pipe(
          filter(payload => !!payload),
          map(
          ({ kcUserCreated }) => ({
            ...props,
            createdAt: new Date(kcUserCreated).toISOString(),
          })
        ))),
        map(mapToUserFlowIdentity),
        switchMap(initializeUserFlow),
        tap(() => this.loadProgress()),
        catchError(this.handleError.bind(this)),
      )
      .subscribe();
  }

  /**
   * Returns the progress of the UserFlow
   * @returns progress
   */
  public loadProgress(): void {
    const params = {
      content_id: environment.userFlow.checklistId,
      user_id: '',
    };
    const progressRequest$ = (p: Record<string, string>) => this.httpClient.get<ProgressResponse>(
      `${this.baseUrl}/progress?${new URLSearchParams(p).toString()}`,
      {
        // params, // TODO: params are not working, need to fix it
      }
    )
      .convertWith<Subject<ProgressResponse>>(RxJsSubjectBridge)
      .pipe(
        catchError(err => {
          this.logService.error(UserFlowService.TYPE, err);
          return of({ userId: '', progress: -.01 });
        }
        ),
        map((response) => this.toPercentage(response.progress)),
      );
    this.identityService.identity
      .convertWith<Subject<Identity<KeycloakProfileData>>>(RxJsSubjectBridge)
      .pipe(
        filter(x => !!x),
        take(1),
        switchMap((identity) => {
          params.user_id = identity.id;
          return progressRequest$(params);
        })
      )
      .subscribe((progress) => this.progressSubject$.next(progress));
  }

  private handleError(error: any): Observable<any> {
    this.logService.debug(
      UserFlowService.TYPE,
      `UserFlowService init failed: ${error}`,
    );
    return of(error);
  }

  private toPercentage(value: number): number {
    return Math.round(value * 100);
  }
}
