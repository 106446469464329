import { Event } from './event.interface';
import { AbstractEvent } from './abstract-event';
import { EventSubscription } from './event-subscription.interface';
import { SubscriptionHandler } from './subscription-handler-delegate.type';

export class AbstractCombinedEvent<T, TOut = T[]> extends AbstractEvent<TOut> {
  //#region Private Fields
  private readonly _streams: Event<T>[];
  private readonly _handler: (input: T[]) => TOut;

  private readonly _firstEmission: boolean[] = null;
  private readonly _emittedValues: T[] = null;

  private _innerSubscriptions: EventSubscription<T>[] = null;

  //#endregion

  //#region Ctor
  public constructor(streams: Event<T>[], handler: (input: T[]) => TOut) {
    super();

    this._firstEmission = Array
      .from(Array(streams.length))
      .map(() => false);

    this._emittedValues = Array
      .from(Array(streams.length))
      .map(() => undefined);

    this._streams = streams;
    this._handler = handler;
  }
  //#endregion

  private saveValueAndVerifyEmissionNeeded(value: T, index: number): void {
    this._firstEmission[index] = true;
    this._emittedValues[index] = value;

    if (this._firstEmission.every(x => x)) {
      this.emit(this._handler(this._emittedValues));
    }
  }

  public override subscribe(subscriptionHandler: SubscriptionHandler<TOut>): EventSubscription<TOut> {
    const result = super.subscribe(subscriptionHandler);

    if (this._innerSubscriptions === null) {
      this._innerSubscriptions = this._streams.map((stream, index) =>
        stream.subscribe(value =>
          this.saveValueAndVerifyEmissionNeeded(value, index)));
    }

    return result;
  }
  //#endregion

  //#region Private Methods
  protected override _remove(subscription: EventSubscription<TOut>): void {
    super._remove(subscription);

    if (!this._subscriptions.length) {
      this._innerSubscriptions.forEach(x => x?.unsubscribe());
      this._innerSubscriptions = [];
    }
  }
  //#endregion
}
