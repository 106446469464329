import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoYi: CultureInfo = {
  name: 'yi',
  displayName: 'Yiddish',
  iso3LanguageCode: 'yid',
  iso2LanguageCode: 'yi',
  ietfLanguageTag: 'yi',
  parent: null
};
