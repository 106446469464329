import { ErrorBase } from './error-base';

/**
 * Thrown when an attempt is made to add an object to the identity cache by using a key that is already being used.
 */
export class DuplicateKeyError extends ErrorBase {
  //#region Properties
  private _duplicate: any;
  /**
   * Gets the duplicate object
   */
  public get duplicate(): any {
    return this._duplicate;
  }
  //#endregion

  //#region Ctor
  /**
   * Initializes a new instance of the DuplicateKeyException class by referencing the duplicate key, providing an error message,
   * and specifying the exception that caused this exception to be thrown.
   * @param object The duplicate that caused the exception to be thrown.
   * @param msg The message to appear when the exception is thrown.
   */
  public constructor(msg: string, duplicate: any, innerError?: Error) {
    super(`DuplicateKeyError: ` + msg, innerError);
    this._duplicate = duplicate;
  }
  //#endregion
}
