import { CultureInfo } from '../culture-info.model';
import { CultureInfoDz } from './dz';

/**
 * @ignore
 */
export const CultureInfoDzBT: CultureInfo = {
  name: 'dz-BT',
  displayName: 'Dzongkha (Bhutan)',
  iso3LanguageCode: 'dzo',
  iso2LanguageCode: 'dz',
  ietfLanguageTag: 'dz-BT',
  parent: CultureInfoDz
};
