import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoIa: CultureInfo = {
  name: 'ia',
  displayName: 'interlingua',
  iso3LanguageCode: 'ina',
  iso2LanguageCode: 'ia',
  ietfLanguageTag: 'ia',
  parent: null
};
