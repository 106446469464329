import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostBinding,
  Input,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  MatButtonToggleChange,
  MatButtonToggleGroup,
} from '@angular/material/button-toggle';
import { ReplaySubject } from 'rxjs';
import { ElementMethod } from '../../decorators/element-method.decorator';
import { WebComponent } from '../../decorators/web-component.decorator';
import { HeliosValueControl } from '../helios-value-control';
import { ToggleButtonComponent } from './toggle-button.component';

/**
 * Helios toogle button groups
 * @doctab "Examples" "toggle-button-group.component.examples.md"
 * @default_slot Buttons belonging to the toggle button group
 */
@WebComponent('hls-toggle-button-group')
@Component({
  selector: 'ng-hls-toggle-button-group',
  templateUrl: 'toggle-button-group.component.html',
  styleUrls: ['toggle-button-group.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class ToggleButtonGroupComponent
  extends HeliosValueControl<any>
  implements AfterViewInit
{
  //#region Private Fields
  private _toggleButtons: ReplaySubject<ToggleButtonComponent> =
    new ReplaySubject();
  //#endregion

  //#region Public Fields
  @ViewChild('slot')
  public slotRef: ElementRef;

  @ViewChild('toggleButtonGroup', { read: MatButtonToggleGroup })
  public toggleButtonGroup: MatButtonToggleGroup;
  //#endregion

  //#region Properties
  private _multiple = false;
  /**
   * Gets or sets a value indicating wether multiple options can be checked
   */
  @Input()
  @HostBinding('attr.multiple')
  public get multiple(): boolean {
    return this._multiple || null;
  }
  public set multiple(v: boolean) {
    this._multiple = coerceBooleanProperty(v);
  }

  private _disabled = false;
  /**
   * Gets or sets a value indicating wether all options are disabled
   */
  @HostBinding('attr.disabled')
  @Input()
  public get disabled(): any {
    return this._disabled || null;
  }
  public set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  //#endregion

  //#region Public Methods
  /**
   * Registers a toggle button with this group
   *
   * @param toggleButton The toggle button to register
   */
  @ElementMethod('registerToggleButton')
  public registerToggleButton(toggleButton: ToggleButtonComponent): void {
    this._toggleButtons.next(toggleButton);
  }

  /**
   * @ignore
   */
  public async ngAfterViewInit(): Promise<void> {
    const assignToggleButtonGroup = (toggleButton: ToggleButtonComponent) =>
      (toggleButton.$buttonToggleGroup = this.toggleButtonGroup);

    const slotElement: HTMLSlotElement = this.slotRef.nativeElement;
    const prerenderedElements = slotElement
      .assignedNodes()
      .map((element) => element as any as ToggleButtonComponent);

    prerenderedElements.forEach(assignToggleButtonGroup);
    this._toggleButtons.subscribe(assignToggleButtonGroup);
  }

  /**
   * @ignore
   */
  public _updateValue(
    event: MatButtonToggleChange,
    innerComponentUpdate?: boolean
  ): void {
    super._updateValue(event, innerComponentUpdate);

    if (this.toggleButtonGroup) {
      this.toggleButtonGroup._emitChangeEvent(event?.source);
    }
  }
  //#endregion
}
