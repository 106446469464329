import { CultureInfo } from '../culture-info.model';
import { CultureInfoGd } from './gd';

/**
 * @ignore
 */
export const CultureInfoGdGB: CultureInfo = {
  name: 'gd-GB',
  displayName: 'Scottish Gaelic (United Kingdom)',
  iso3LanguageCode: 'gla',
  iso2LanguageCode: 'gd',
  ietfLanguageTag: 'gd-GB',
  parent: CultureInfoGd
};
