import {
  AbstractPersistentEvent,
  BootstrapperBase,
  LogService,
  ResourceInfo,
  ResourceType,
  TOKENS as TOKENS_COMMON,
} from '@trustedshops/tswp-core-common';
import { APP_INITIALIZER, Inject, Injectable, Injector } from '@angular/core';
import {
  LoadPluginRequest,
  PluginService,
  TOKENS as TOKENS_PLUGINS,
} from '@trustedshops/tswp-core-plugins';
import { BusyService, TOKENS as TOKENS_UI } from '@trustedshops/tswp-core-ui';
import { CarrierConfig } from '../models/carrier-config.interface';
import { CarrierConfigToken } from '../models/carrier-config.token';
import { PluginResolverService } from '../services/plugin-resolver.service';
import { PluginRegistrationInfo } from '../models/plugin-registration-info.interface';
import {
  FeatureBookingService,
  TOKENS as MASTERDATA_TOKENS,
} from '@trustedshops/tswp-core-masterdata';

@Injectable()
export class StartPluginBootstrapper implements BootstrapperBase {
  public static readonly TYPE: string =
    '@trustedshops/tswp-carrier-core:StartPluginBootstrapper';

  private _onComplete: () => void;

  public constructor(
    @Inject(TOKENS_PLUGINS.PluginService)
    private readonly pluginService: PluginService,
    private readonly injector: Injector,
    @Inject(TOKENS_UI.BusyService) private readonly busyService: BusyService,
    @Inject(CarrierConfigToken) private readonly carrierConfig: CarrierConfig,
    @Inject(TOKENS_COMMON.LogService) private readonly logService: LogService,
    @Inject(MASTERDATA_TOKENS.FeatureBookingService)
    private readonly featureBookingService: FeatureBookingService,
  ) {}

  private _completed: Promise<void> = new Promise(
    (resolve) => (this._onComplete = resolve),
  );

  public get completed(): Promise<void> {
    return this._completed;
  }

  public async initialize(): Promise<void> {
    const operationProgress = new AbstractPersistentEvent(0);
    this.busyService.queueOperation('FULL_PAGE', operationProgress);
    const pluginResolver: PluginResolverService =
      this.injector.get<PluginResolverService>(
        this.carrierConfig.plugins.resolver,
      );
    const plugins = await pluginResolver.resolve(
      this.carrierConfig.environment.targetUserGroup,
    );
    const mappedPluginLoadRequests = this.mapPluginLoadRequests(plugins);

    mappedPluginLoadRequests.forEach((plugin) =>
      this.pluginService.registerPlugin(plugin),
    );

    this.logService.trace(
      StartPluginBootstrapper.TYPE,
      'All plugins requested',
      mappedPluginLoadRequests,
    );

    let filterPlugins: LoadPluginRequest[] = [];
    const isUnverifiedAccount =
      await this.featureBookingService.isUnverifiedAccount();
    this.logService.trace(
      StartPluginBootstrapper.TYPE,
      'Account is unverified',
      isUnverifiedAccount,
    );
    if (isUnverifiedAccount) {
      filterPlugins = mappedPluginLoadRequests.filter(
        (plugin) => plugin.autostartUnverifiedAccount,
      );
    } else {
      filterPlugins = mappedPluginLoadRequests.filter(
        (plugin) => plugin.autostart,
      );
    }

    this.logService.trace(
      StartPluginBootstrapper.TYPE,
      'Start loading plugins',
      filterPlugins,
    );

    await Promise.all(
      filterPlugins.map(async (loadRequest, index) => {
        try {
          await this.pluginService.loadPluginByName(loadRequest.name);
          operationProgress.emit(((index + 1) / filterPlugins.length) * 100);
        } catch (err: any) {
          // Ignore single error for continuing the platform boot
          // but log it to see it in, e.g. sentry
          this.logService.error(StartPluginBootstrapper.TYPE, err, loadRequest);
        }
      }),
    );

    operationProgress.emit(100);

    this._onComplete();
  }

  private mapPluginLoadRequests(
    plugins: PluginRegistrationInfo[],
  ): LoadPluginRequest[] {
    return plugins.map((pluginRegistrationInfo) => {
      const scripts = pluginRegistrationInfo.resources
        .filter((resource) => resource.type === ResourceType.JavaScript)
        .map((resource) => ({
          ...resource,
          key: `${this.carrierConfig.plugins.storageRootPath}/${pluginRegistrationInfo.basePath}/${resource.key}`,
          options: resource.options || {},
        })) as ResourceInfo[];

      const styles = pluginRegistrationInfo.resources
        .filter(
          (resource) => resource.type === ResourceType.CascadingStyleSheet,
        )
        .map((resource) => ({
          ...resource,
          key: `${this.carrierConfig.plugins.storageRootPath}/${pluginRegistrationInfo.basePath}/${resource.key}`,
        })) as ResourceInfo[];

      return {
        name: pluginRegistrationInfo.name,
        autostart: pluginRegistrationInfo.autostart,
        autostartUnverifiedAccount:
          pluginRegistrationInfo.autostartUnverifiedAccount,
        basePath: pluginRegistrationInfo.basePath,
        dependencies: pluginRegistrationInfo.dependencies,
        pluginStorageUrl: this.carrierConfig.plugins.storageRootPath,
        scripts,
        styles,
      };
    });
  }
}

export const StartPluginInitializer = [
  StartPluginBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [StartPluginBootstrapper],
  },
];
