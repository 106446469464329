import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoNus: CultureInfo = {
  name: 'nus',
  displayName: 'Thok Nath',
  iso3LanguageCode: 'nus',
  iso2LanguageCode: 'nus',
  ietfLanguageTag: 'nus',
  parent: null
};
