import { CultureInfo } from '../culture-info.model';
import { CultureInfoKsb } from './ksb';

/**
 * @ignore
 */
export const CultureInfoKsbTZ: CultureInfo = {
  name: 'ksb-TZ',
  displayName: 'Kishambaa (Tanzania)',
  iso3LanguageCode: 'ksb',
  iso2LanguageCode: 'ksb',
  ietfLanguageTag: 'ksb-TZ',
  parent: CultureInfoKsb
};
