import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscribePipe } from '../pipes/subscribe.pipe';
import { GalleryComponent } from '../components/gallery/gallery.component';

@NgModule({
  imports: [CommonModule],
  exports: [SubscribePipe, GalleryComponent],
  declarations: [SubscribePipe, GalleryComponent],
})
export class TswpCoreUiSharedModule {}
