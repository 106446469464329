import { CultureInfo } from '../culture-info.model';
import { CultureInfoBs } from './bs';

/**
 * @ignore
 */
export const CultureInfoBsCyrl: CultureInfo = {
  name: 'bs-Cyrl',
  displayName: 'Bosnian (Cyrillic)',
  iso3LanguageCode: 'bos',
  iso2LanguageCode: 'bs',
  ietfLanguageTag: 'bs-Cyrl',
  parent: CultureInfoBs
};
