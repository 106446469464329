import { TranslationFile } from './translation-file.model';

export type TranslationKeyResolverDelegate<T = TranslationFile> =
  /**
   * Resolves a translated value for a given `key`
   *
   * @param key The key to translate.
   * @returns The translated value.
   */
  (translations: T, key: string) => string;
