import { CultureInfo } from '../culture-info.model';
import { CultureInfoHsb } from './hsb';

/**
 * @ignore
 */
export const CultureInfoHsbDE: CultureInfo = {
  name: 'hsb-DE',
  displayName: 'Upper Sorbian (Germany)',
  iso3LanguageCode: 'hsb',
  iso2LanguageCode: 'hsb',
  ietfLanguageTag: 'hsb-DE',
  parent: CultureInfoHsb
};
