import { CultureInfo } from '../culture-info.model';
import { CultureInfoAf } from './af';

/**
 * @ignore
 */
export const CultureInfoAfNA: CultureInfo = {
  name: 'af-NA',
  displayName: 'Afrikaans (Namibië)',
  iso3LanguageCode: 'afr',
  iso2LanguageCode: 'af',
  ietfLanguageTag: 'af-NA',
  parent: CultureInfoAf
};
