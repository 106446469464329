import { Permission } from './permission.interface';

/**
 * Interface for implementing a permission manager.
 * Responsible for creating, deleting reading or updating permissions
 *
 * @template TPermissionDefinition The type of a single permission that is handled with this `PermissionManager`
 */
export interface PermissionManager<TPermissionDefinition extends Permission> {
  getPermissionsForUser(identityId: string): Promise<TPermissionDefinition[]>;
}
