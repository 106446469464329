import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoTa: CultureInfo = {
  name: 'ta',
  displayName: 'Tamil',
  iso3LanguageCode: 'tam',
  iso2LanguageCode: 'ta',
  ietfLanguageTag: 'ta',
  parent: null
};
