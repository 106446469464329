import { StaticProvider, Type } from '@angular/core';

/**
 * Defines the list of custom elements that should be registered with the assigned NgModule.
 *
 * @param componentTypes Types of components to be registered
 * @param isolatedProviders Set of providers that should be instantiated per component.
 * @decorator
 */
export function BootstrapCustomElements(
  componentTypes: Type<any>[],
  isolatedProviders: StaticProvider[] = []): (constructor: Type<any>) => void {

  return (constructor: Type<any>) => {
    (constructor as any).customElements = componentTypes;
    (constructor as any).isolatedProviders = isolatedProviders;
  };
}
