import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoUk: CultureInfo = {
  name: 'uk',
  displayName: 'Ukrainian',
  iso3LanguageCode: 'ukr',
  iso2LanguageCode: 'uk',
  ietfLanguageTag: 'uk',
  parent: null
};
