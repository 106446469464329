import { SortUtil, ExportToken, AbstractPersistentEvent, Event } from '@trustedshops/tswp-core-common';
import { NextInterceptorHandler, NextInterceptorHandlerFunction } from '@trustedshops/tswp-core-common';
import { Injectable, Inject, Optional } from '@angular/core';
import {
  TOKENS,
  Identity,
  SessionProviderService,
  SessionInterceptor,
  Session,
  IdentityService
} from '@trustedshops/tswp-core-authorization';

/**
 * Service to handle the user identity based on session events
 */
@Injectable()
@ExportToken(TOKENS.IdentityService)
export class IdentityServiceImpl implements IdentityService {
  //#region Properties
  private _interceptors: Array<SessionInterceptor> = [];
  /**
   * List of interceptors running on session or identity changes
   */
  public get interceptors(): Array<SessionInterceptor> {
    return this._interceptors;
  }

  private _sessionProvider: SessionProviderService<Session, any> = null;
  /**
   * The instance of the session provider that the identity service utilizes
   */
  public get sessionProvider(): SessionProviderService<Session, any> {
    return this._sessionProvider;
  }

  private _identity: AbstractPersistentEvent<Identity<any>> = new AbstractPersistentEvent(null);
  /**
   * The current identity that is active within the session.
   */
  public get identity(): AbstractPersistentEvent<Identity<any>> {
    return this._identity;
  }
  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of IdentityService.
   * @param interceptors The session interceptors to initially use.
   */
  public constructor(@Inject(TOKENS.SessionInterceptor) @Optional() interceptors: SessionInterceptor[]) {
    interceptors.forEach(interceptor => this.registerInterceptor(interceptor));
  }
  //#endregion

  //#region Public Methods
  /**
   * Loads an identity from a specific session provider
   * @param source The session provider to load the session provider
   */
  public async loadIdentityFrom<TOptions>(source: SessionProviderService<Session, TOptions>, options: TOptions): Promise<void> {
    this._sessionProvider = source;
    await this._sessionProvider.initialize(options);

    if (!this._sessionProvider.hasSession) {
      this.onSessionEnd();
      return;
    }


    return new Promise(resolve => {
      this._sessionProvider.session.subscribe(session => {
        if (!session) {
          resolve();
          return;
        }

        this.onSessionStart(session);

        if (this._identity && this._identity.value && this._identity.value.id === session.identity) {
          resolve();
          return;
        }

        const startIdentity: Identity<any> = {
          id: session.identity,
          profile: {}
        };

        const newIdentity = this.onIdentityReceived(startIdentity);

        this
          .emitNewIdentityReceived(newIdentity)
          .then(() => resolve());
      });
    });
  }

  /**
   * Registers an interceptor and resorts all interceptors by order.
   * @param interceptor The interceptor to register
   */
  public registerInterceptor(interceptor: SessionInterceptor): void {
    this._interceptors.push(interceptor);
    this._interceptors = this._interceptors
      .sort(SortUtil.byProperty(x => x.order, false));
  }
  //#endregion

  //#region Private Methods
  /**
   * Invokes the interception chain for "onSessionStart" with the registered interceptors
   * @param session The session that has been loaded
   */
  private onSessionStart(session: Session): void {
    if (!this._interceptors.length) {
      return;
    }

    const interceptorHandlerFunction = (interceptor, interceptedObj, next) =>
      interceptor.onSessionStart(interceptedObj, next);

    const nextInterceptorHandler = new NextInterceptorHandler<SessionInterceptor, Session>(
      0,
      interceptorHandlerFunction,
      this._interceptors,
      res => new AbstractPersistentEvent(res));

    nextInterceptorHandler.handle(session);
  }

  /**
   * Invokes the interception chain for "onSessionEnd" with the registered interceptors
   */
  private onSessionEnd(): void {
    const interceptorHandlerFunction: NextInterceptorHandlerFunction<SessionInterceptor, void> =
      (interceptor, _, next) => interceptor.onSessionEnd(next);

    this.runInterception(interceptorHandlerFunction);
  }

  /**
   * Invokes the interception chain for "onIdentityReceived" with the registered interceptors
   * @param identity The identity that has been loaded
   */
  private onIdentityReceived(identity: Identity<any>): Event<Identity<any>> {
    const interceptorHandlerFunction: NextInterceptorHandlerFunction<SessionInterceptor, Identity<any>> =
      (interceptor, interceptedObj, next) => interceptor.onIdentityReceived(interceptedObj, next);

    return this.runInterception(interceptorHandlerFunction, identity);
  }

  /**
   * Invokes the interception chain for a specific callback with the registered interceptors
   * @param interceptionFunction The function that calls the interceptor function
   * @param startObject The object is to be intercepted.
   */
  private runInterception<TInterceptor, TInterceptedObject>(
    interceptionFunction: NextInterceptorHandlerFunction<TInterceptor, TInterceptedObject>,
    startObject?: TInterceptedObject): Event<TInterceptedObject> {
    const nextInterceptorHandler = new NextInterceptorHandler(
      0,
      interceptionFunction,
      this._interceptors as any,
      res => new AbstractPersistentEvent(res));
    return nextInterceptorHandler.handle(startObject);
  }

  private emitNewIdentityReceived(newIdentityStream: Event<Identity<any>>): Promise<void> {
    return new Promise<void>(resolve =>
      newIdentityStream.subscribe(newIdentity => {
        if (newIdentity === this._identity.value) {
          resolve();
          return;
        }

        this._identity.emit(newIdentity);
        this._identity
          .subscribe(() => resolve())
          .unsubscribe();
      }));
  }
  //#endregion
}
