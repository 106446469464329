import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoYue: CultureInfo = {
  name: 'yue',
  displayName: '廣東話',
  iso3LanguageCode: 'yue',
  iso2LanguageCode: 'yue',
  ietfLanguageTag: 'yue',
  parent: null
};
