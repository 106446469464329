import { CultureInfo } from '../culture-info.model';
import { CultureInfoKs } from './ks';

/**
 * @ignore
 */
export const CultureInfoKsDeva: CultureInfo = {
  name: 'ks-Deva',
  displayName: 'कॉशुर',
  iso3LanguageCode: 'kas',
  iso2LanguageCode: 'ks',
  ietfLanguageTag: 'ks-Deva',
  parent: CultureInfoKs
};
