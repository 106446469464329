import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoCkb: CultureInfo = {
  name: 'ckb',
  displayName: 'کوردیی ناوەندی',
  iso3LanguageCode: 'ckb',
  iso2LanguageCode: 'ckb',
  ietfLanguageTag: 'ckb',
  parent: null
};
