import { CultureInfo } from '../culture-info.model';
import { CultureInfoShi } from './shi';

/**
 * @ignore
 */
export const CultureInfoShiTfng: CultureInfo = {
  name: 'shi-Tfng',
  displayName: 'ⵜⴰⵛⵍⵃⵉⵜ',
  iso3LanguageCode: 'shi',
  iso2LanguageCode: 'shi',
  ietfLanguageTag: 'shi-Tfng',
  parent: CultureInfoShi
};
