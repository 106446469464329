import { CultureInfo } from '../culture-info.model';
import { CultureInfoZhHant } from './zh-Hant';

/**
 * @ignore
 */
export const CultureInfoZhHantMO: CultureInfo = {
  name: 'zh-Hant-MO',
  displayName: '繁體中文（澳門）',
  iso3LanguageCode: 'zho',
  iso2LanguageCode: 'zh',
  ietfLanguageTag: 'zh-Hant-MO',
  parent: CultureInfoZhHant
};
