import { CultureInfo } from '../culture-info.model';
import { CultureInfoMzn } from './mzn';

/**
 * @ignore
 */
export const CultureInfoMznIR: CultureInfo = {
  name: 'mzn-IR',
  displayName: 'مازرونی (ایران)',
  iso3LanguageCode: 'mzn',
  iso2LanguageCode: 'mzn',
  ietfLanguageTag: 'mzn-IR',
  parent: CultureInfoMzn
};
