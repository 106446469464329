import { CultureInfo } from '../culture-info.model';
import { CultureInfoIi } from './ii';

/**
 * @ignore
 */
export const CultureInfoIiCN: CultureInfo = {
  name: 'ii-CN',
  displayName: 'Sichuan Yi (China mainland)',
  iso3LanguageCode: 'iii',
  iso2LanguageCode: 'ii',
  ietfLanguageTag: 'ii-CN',
  parent: CultureInfoIi
};
