import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Injector,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation
} from '@angular/core';
import { ChildrenOutletContexts } from '@angular/router';
import { ChildRouteInjector } from './child-route-injector';
import { RouterOutletHostComponent } from './router-outlet-host.component';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'route-content',
  templateUrl: './route-content.component.html',
  encapsulation: ViewEncapsulation.ShadowDom,
  styleUrls: ['./route-content.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-standalone
  standalone: false,
})
export class RouteContentComponent implements AfterViewInit {
  //#region Public Static Constants
  public static readonly selector: string = 'tswp-route-content';
  //#endregion

  //#region Properties
  private _outletLocation?: ViewContainerRef;
  @ViewChild('outletLocation', { read: ViewContainerRef, static: true })
  public set outletLocation(v: ViewContainerRef) {
    this._outletLocation = v;
  }

  //#endregion

  //#region Ctor
  public constructor(
    private readonly _element: ElementRef,
    private readonly _injector: Injector,
    private readonly _componentFactoryResolver: ComponentFactoryResolver) { }
  //#endregion

  //#region Public Methods
  public ngAfterViewInit(): void {
    let currentElement: any = this._element.nativeElement;
    while (currentElement && !currentElement.childrenOutletContexts) {
      currentElement = currentElement.parentElement
        ? currentElement.parentElement
        : currentElement.getRootNode().host;

      if (currentElement && currentElement.tagName.toLowerCase() === 'plugin-control') {
        break;
      }
    }

    if (!currentElement) {
      return;
    }

    const childrenOutletContexts: ChildrenOutletContexts = (currentElement as any).childrenOutletContexts;
    const outletInjector = new ChildRouteInjector(childrenOutletContexts, this._injector);
    const factory = this._componentFactoryResolver.resolveComponentFactory(RouterOutletHostComponent);
    this._outletLocation.createComponent(factory, 0, outletInjector);
  }
  //#endregion
}
