import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoLo: CultureInfo = {
  name: 'lo',
  displayName: 'Lao',
  iso3LanguageCode: 'lao',
  iso2LanguageCode: 'lo',
  ietfLanguageTag: 'lo',
  parent: null
};
