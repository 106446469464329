import { PluginEntryPointType } from '../models/plugin-entry-point-type.interface';
import { PluginEntryPoint } from '../models/plugin-entry-point.interface';

/**
 * Describes a PluginHost.
 * A plugin host is the facade to which all plugins talk to,
 */
export interface PluginHost {
  /**
   * Main Variable holding all methods of the plugin host
   */
  tswp: {
    /**
     * Registers a plugin to the plugin host
     */
    registerPlugin: (metadata: PluginEntryPoint, extraOrdinaryDependencies?: string[]) => void;

    /**
     * Registers a plugin to the plugin host.
     * Lets the plugin service instantiate the entry point itself
     */
    registerPluginType: (metadata: PluginEntryPointType, extraOrdinaryDependencies?: string[]) => void;

    /**
     * Gets the full path to a plugin's static file being hosted within the plugin bundle.
     */
    getPluginFilePath(plugin: string, path: string): string;
  };
}
