import { HttpInterceptor } from './http-interceptor.interface';

import { Event } from '../../../eventing/event.interface';
import { HttpRequest } from './http-request.interface';
import { HttpRequestOptions } from './http-request-options.interface';
import { HttpEvent } from './http-event.interface';

export interface HttpClientService {
  readonly interceptors: HttpInterceptor[];

  /**
   * Registers an interceptor for HTTP calls
   *
   * @param interceptorInstance The instance of the interceptor
   */
  registerInterceptor<T extends HttpInterceptor>(interceptorInstance: T): HttpClientService;

  request<T>(request: HttpRequest<any>): Event<HttpEvent<T>>;
  delete<T>(url: string, options: HttpRequestOptions): Event<T>;
  get<T>(url: string, options: HttpRequestOptions): Event<T>;
  head<T>(url: string, options: HttpRequestOptions): Event<T>;
  jsonp<T>(url: string, callbackParam: string): Event<T>;
  options<T>(url: string, options: HttpRequestOptions): Event<T>;
  patch<T>(url: string, body: any, options: HttpRequestOptions): Event<T>;
  put<T>(url: string, body: any, options: HttpRequestOptions): Event<T>;
  post<T>(url: string, body: any, options: HttpRequestOptions): Event<T>;
}
