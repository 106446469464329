import { APP_INITIALIZER, Inject, Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { RouteSourceService, TOKENS } from '@trustedshops/tswp-core-ui';
import { BootstrapperBase } from '@trustedshops/tswp-core-common';
import { fromObservable } from '@trustedshops/tswp-core-common-eventing-rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable()
export class NavigationBootstrapper implements BootstrapperBase {
  public constructor(
    @Inject(TOKENS.RouteSourceService)
    private readonly routeSourceService: RouteSourceService,
    private readonly router: Router,
  ) {}

  public async initialize(): Promise<void> {
    this.initializeBreadcrumbs();
  }

  private initializeBreadcrumbs(): void {
    this.routeSourceService.registerRouteSource(
      fromObservable(
        this.router.events.pipe(
          filter((event) => event instanceof NavigationEnd),
          map((event) => event.urlAfterRedirects),
        ),
      ),
    );
  }
}

export const NavigationInitializer = [
  NavigationBootstrapper,
  {
    provide: APP_INITIALIZER,
    multi: true,
    useFactory: (initializer: BootstrapperBase) => {
      return () => initializer.initialize();
    },
    deps: [NavigationBootstrapper],
  },
];
