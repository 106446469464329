import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoHr: CultureInfo = {
  name: 'hr',
  displayName: 'Croatian',
  iso3LanguageCode: 'hrv',
  iso2LanguageCode: 'hr',
  ietfLanguageTag: 'hr',
  parent: null
};
