import { CultureInfo } from '../culture-info.model';
import { CultureInfoSatDeva } from './sat-Deva';

/**
 * @ignore
 */
export const CultureInfoSatDevaIN: CultureInfo = {
  name: 'sat-Deva-IN',
  displayName: 'सानताड़ी (देवानागारी, IN)',
  iso3LanguageCode: 'sat',
  iso2LanguageCode: 'sat',
  ietfLanguageTag: 'sat-Deva-IN',
  parent: CultureInfoSatDeva
};
