import { CultureInfo } from '../culture-info.model';
import { CultureInfoMni } from './mni';

/**
 * @ignore
 */
export const CultureInfoMniBeng: CultureInfo = {
  name: 'mni-Beng',
  displayName: 'মৈতৈলোন্',
  iso3LanguageCode: 'mni',
  iso2LanguageCode: 'mni',
  ietfLanguageTag: 'mni-Beng',
  parent: CultureInfoMni
};
