import { CultureInfo } from '../culture-info.model';
import { CultureInfoBas } from './bas';

/**
 * @ignore
 */
export const CultureInfoBasCM: CultureInfo = {
  name: 'bas-CM',
  displayName: 'Ɓàsàa (Kàmɛ̀rûn)',
  iso3LanguageCode: 'bas',
  iso2LanguageCode: 'bas',
  ietfLanguageTag: 'bas-CM',
  parent: CultureInfoBas
};
