import { CommandUserInteractionHandler } from './command-user-interaction-handler';
import { UserInteractionType } from '../models/user-interaction-type.enum';
import { Command } from '@trustedshops/tswp-core-common';

/**
 * A user interaction  handler for a click eveent
 */
export class ClickUserInteractionHandler
  extends CommandUserInteractionHandler {
  //#region Ctor
  /**
   * Creates a new instance of ClickUserInteractionHandler
   *
   * @param command The command to execute when the user clicks the rendered user interaction
   * @deprecated
   * Calling `ClickUserInteractionHandler` is deprecated and will be removed in version 2.0.0.
   * Please migrate to `NavigationService.getUserInteractionHandler`.
   * As of the next version, this export will be removed.
   */
  public constructor(command: Command<any>) {
    super(command);

    this.type = UserInteractionType.Click;
  }
  //#endregion
}
