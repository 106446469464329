import { CultureInfo } from '../culture-info.model';
import { CultureInfoUrArab } from './ur-Arab';

/**
 * @ignore
 */
export const CultureInfoUrArabIN: CultureInfo = {
  name: 'ur-Arab-IN',
  displayName: 'اردو (نسخ،بھارت)',
  iso3LanguageCode: 'urd',
  iso2LanguageCode: 'ur',
  ietfLanguageTag: 'ur-Arab-IN',
  parent: CultureInfoUrArab
};
