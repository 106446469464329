import { CultureInfo } from '../culture-info.model';
import { CultureInfoLkt } from './lkt';

/**
 * @ignore
 */
export const CultureInfoLktUS: CultureInfo = {
  name: 'lkt-US',
  displayName: 'Lakȟólʼiyapi (Mílahaŋska Tȟamákȟočhe)',
  iso3LanguageCode: 'lkt',
  iso2LanguageCode: 'lkt',
  ietfLanguageTag: 'lkt-US',
  parent: CultureInfoLkt
};
