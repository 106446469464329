import { CultureInfo } from '../culture-info.model';
import { CultureInfoBn } from './bn';

/**
 * @ignore
 */
export const CultureInfoBnIN: CultureInfo = {
  name: 'bn-IN',
  displayName: 'Bangla (India)',
  iso3LanguageCode: 'ben',
  iso2LanguageCode: 'bn',
  ietfLanguageTag: 'bn-IN',
  parent: CultureInfoBn
};
