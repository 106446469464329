import { CultureInfo } from '../culture-info.model';
import { CultureInfoSrCyrl } from './sr-Cyrl';

/**
 * @ignore
 */
export const CultureInfoSrCyrlRS: CultureInfo = {
  name: 'sr-Cyrl-RS',
  displayName: 'Serbian (Cyrillic, Serbia)',
  iso3LanguageCode: 'srp',
  iso2LanguageCode: 'sr',
  ietfLanguageTag: 'sr-Cyrl-RS',
  parent: CultureInfoSrCyrl
};
