import { DeploymentStage } from './deployment-stage.interface';
import { TargetUserGroup } from './target-user-group.enum';

/**
 * Describes the application environment
 */
export interface ApplicationEnvironment {
  /**
   * Gets the id of this application
   */
  id: string;

  /**
   * Gets the friendly name of this application
   */
  friendlyName: string;

  /**
   * Gets or sets the target user group that this platform instance targets.
   */
  targetUserGroup: TargetUserGroup | string;

  stage: DeploymentStage;
}
