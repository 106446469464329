import { EventSubscription, ObservableArrayChangedEvent } from '@trustedshops/tswp-core-common';
import {
  NavigationService,
  UserInteractionHandlerBase,
  NavigateFirstUserInteractionHandler,
  NavigationItem,
  ChildNavigationItem,
  UserInteractionHandler,
  UserInteractionType
} from '@trustedshops/tswp-core-ui';

/**
 * Pre-Defined user interaction for navigating to the first child of the navigation item.
 */
export class NavigateFirstUserInteractionHandlerImpl
  extends UserInteractionHandlerBase
  implements NavigateFirstUserInteractionHandler {
  private _subscription: EventSubscription<ObservableArrayChangedEvent<NavigationItem>>;

  //#region Private Fields
  private _initialized = false;
  //#endregion

  //#region Properties
  public get parentNavigationItemId(): string {
    return this._parentNavigationItemId;
  }

  public get childNavigationItemRegion(): string {
    return this._childNavigationItemRegion;
  }

  private _firstChildItem: NavigationItem;
  public get childInteraction(): UserInteractionHandler {
    return this._firstChildItem?.userInteraction;
  }
  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of NavigateFirstUserInteractionHandler
   *
   * @param parentNavigationItemId The id of the navigation item that holds the children to select the first one of.
   */
  public constructor(
    private readonly _navigationService: NavigationService,
    private readonly _parentNavigationItemId: string,
    private readonly _childNavigationItemRegion: string) {
    super();
    this.type = UserInteractionType.NavigateToFirstChild;

  }
  //#endregion

  //#region Public Methods
  public override async onDispose(): Promise<void> {
    if (!this._subscription) {
      return;
    }

    this._subscription?.unsubscribe();
    this._subscription = null;
  }

  public override async onInitialize(): Promise<void> {
    if (this._initialized) {
      return;
    }

    this._initialized = true;

    const parentItem = await this._navigationService
      .getItemById(this._parentNavigationItemId);

    const childItems = this._navigationService.getItemsForRegion(this._childNavigationItemRegion);

    this.checkForUpdates(parentItem, childItems);

    this._subscription = childItems.onChanged.subscribe(() =>
      this.checkForUpdates(parentItem, childItems));

  }
  //#endregion

  //#region Private Methods
  private checkForUpdates(parentItem: NavigationItem, childItems: NavigationItem[]): void {
    const sortedItems = [...childItems].sort((a, b) => a.order - b.order);
    const sortedChildren = sortedItems.filter(child => parentItem.children.includes(child as ChildNavigationItem));

    const firstItem = sortedChildren[0];
    if (!firstItem) {
      this._firstChildItem = undefined;
      return;
    }

    this._firstChildItem = firstItem;
  }
  //#endregion
}
