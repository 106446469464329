import { CultureInfo } from '../culture-info.model';
import { CultureInfoUr } from './ur';

/**
 * @ignore
 */
export const CultureInfoUrAran: CultureInfo = {
  name: 'ur-Aran',
  displayName: 'اردو',
  iso3LanguageCode: 'urd',
  iso2LanguageCode: 'ur',
  ietfLanguageTag: 'ur-Aran',
  parent: CultureInfoUr
};
