import { CultureInfo } from '../culture-info.model';
import { CultureInfoKaj } from './kaj';

/**
 * @ignore
 */
export const CultureInfoKajNG: CultureInfo = {
  name: 'kaj-NG',
  displayName: 'Kaje (NG)',
  iso3LanguageCode: 'kaj',
  iso2LanguageCode: 'kaj',
  ietfLanguageTag: 'kaj-NG',
  parent: CultureInfoKaj
};
