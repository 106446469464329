import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKsb: CultureInfo = {
  name: 'ksb',
  displayName: 'Kishambaa',
  iso3LanguageCode: 'ksb',
  iso2LanguageCode: 'ksb',
  ietfLanguageTag: 'ksb',
  parent: null
};
