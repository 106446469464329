import { Inject, Injectable } from '@angular/core';
import {
  TOKENS as AUTHORIZATION_TOKENS,
  Permission,
  PermissionRequest,
  PermissionService,
} from '@trustedshops/tswp-core-authorization';
import {
  AbstractPersistentEvent,
  TOKENS as COMMON_TOKENS,
  Event,
  ExportToken,
  InvalidOperationError,
  LogService,
} from '@trustedshops/tswp-core-common';
import { RxJsBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import {
  OrganizationalContainer,
  OrganizationalContainerProvider,
  OrganizationalContainerSelectionService,
  RendererInfo,
  TOKENS,
} from '@trustedshops/tswp-core-masterdata';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { combineLatest } from '../util/combine-latest';
import { OrganizationalContainerSelectionServiceConnector } from './organizational-container-selection-service-connector';

export interface FilteredContainerList {
  permissions: string[][];
  items: OrganizationalContainer<any>[];
}

/**
 * Selection backend for the organizational container selection control.
 * Manages current selection and rendering information.
 */
@Injectable()
@ExportToken(TOKENS.OrganizationalContainerSelectionService)
export class OrganizationalContainerSelectionServiceImpl
  implements
    OrganizationalContainerSelectionService,
    OrganizationalContainerSelectionServiceConnector
{
  private static readonly TYPE: string =
    'OrganizationalContainerSelectionService';

  private static readonly ALLOW_ALL: string[] = ['*'];

  private readonly _loadingOperations: Promise<any>[] = [];
  private readonly _currentSelection: AbstractPersistentEvent<
    OrganizationalContainer<any>[]
  > = new AbstractPersistentEvent<OrganizationalContainer<any>[]>([]);
  private readonly _fullContainerList: AbstractPersistentEvent<
    OrganizationalContainer<any>[]
  > = new AbstractPersistentEvent<OrganizationalContainer<any>[]>(undefined);
  private readonly _filteredContainerList: AbstractPersistentEvent<FilteredContainerList> =
    new AbstractPersistentEvent<FilteredContainerList>(undefined);
  private readonly _filteredContainerListItems: AbstractPersistentEvent<
    OrganizationalContainer<any>[]
  > = new AbstractPersistentEvent<OrganizationalContainer<any>[]>(undefined);
  private readonly _currentPermissionFilter: AbstractPersistentEvent<
    string[][]
  > = new AbstractPersistentEvent<string[][]>([
    OrganizationalContainerSelectionServiceImpl.ALLOW_ALL,
  ]);
  private readonly _currentContainerRenderer: AbstractPersistentEvent<RendererInfo>;

  private _resolveInitialization: (value: void | PromiseLike<void>) => void;

  /**
   * Gets the current HTML element name rendering an organizational container
   */
  public get currentContainerRenderer(): Event<RendererInfo> {
    return this._currentContainerRenderer;
  }

  /**
   * Gets the current HTML element name rendering an organizational container
   */
  public get defaultContainerRenderer(): RendererInfo {
    return this._defaultOrgContainerRenderer;
  }

  private _onShowSelectorList: () => void;
  public get onShowSelectorList(): () => void {
    return this._onShowSelectorList;
  }
  public set onShowSelectorList(v: () => void) {
    this._onShowSelectorList = v;
  }

  public constructor(
    @Inject(TOKENS.DefaultOrganizationalContainerRenderer)
    private readonly _defaultOrgContainerRenderer: RendererInfo,
    @Inject(TOKENS.OrganizationalContainerProvider)
    private readonly _organizationalContainerProvider: OrganizationalContainerProvider,
    @Inject(AUTHORIZATION_TOKENS.PermissionService)
    private readonly _permissionService: PermissionService<Permission>,
    @Inject(COMMON_TOKENS.LogService) private readonly _logService: LogService
  ) {
    this._currentContainerRenderer = new AbstractPersistentEvent<RendererInfo>(
      this._defaultOrgContainerRenderer
    );

    this._loadingOperations.push(
      new Promise<void>((resolve) => (this._resolveInitialization = resolve))
    );
  }

  public async initialize(): Promise<void> {
    this._logService.trace(
      OrganizationalContainerSelectionServiceImpl.TYPE,
      'Initializing OrganizationalContainerSelectionService...'
    );

    this._logService.trace(
      OrganizationalContainerSelectionServiceImpl.TYPE,
      'Fetching permissions and containers...'
    );
    combineLatest(
      this._currentPermissionFilter,
      this._fullContainerList
    ).subscribe(async ([permissions, containers]) => {
      this._logService.trace(
        OrganizationalContainerSelectionServiceImpl.TYPE,
        'Subscribed permissions',
        permissions
      );
      this._logService.trace(
        OrganizationalContainerSelectionServiceImpl.TYPE,
        'Subscribed containers',
        containers
      );
      if (!containers) {
        return;
      }

      const allowedContainers = (
        await Promise.all(
          containers.map((container) =>
            this.checkContainerVisible(container, permissions)
          )
        )
      )
        .filter(({ granted }) => granted)
        .map(({ container }) => container);

      this._logService.trace(
        OrganizationalContainerSelectionServiceImpl.TYPE,
        'Filtered allowedContainers',
        allowedContainers
      );
      this._filteredContainerList.emit({
        permissions,
        items: allowedContainers,
      });
      this._resolveInitialization();
    });

    this._logService.trace(
      OrganizationalContainerSelectionServiceImpl.TYPE,
      'Fetching _filteredContainerList...'
    );
    this._filteredContainerList.subscribe((filteredContainerList) => {
      this._logService.trace(
        OrganizationalContainerSelectionServiceImpl.TYPE,
        'Subscribed filteredContainerList',
        filteredContainerList
      );
      if (filteredContainerList !== undefined) {
        this._filteredContainerListItems.emit(filteredContainerList.items);
      }
    });

    this._logService.trace(
      OrganizationalContainerSelectionServiceImpl.TYPE,
      'Fetching _organizationalContainerProvider...'
    );
    this._organizationalContainerProvider
      .getCurrentSource()
      .subscribe(async (containerSource) => {
        this._logService.trace(
          OrganizationalContainerSelectionServiceImpl.TYPE,
          'Subscribed containerSource',
          containerSource
        );
        this._logService.trace(
          OrganizationalContainerSelectionServiceImpl.TYPE,
          'Fetching containers...'
        );
        const containerList = await containerSource.getContainers();
        this._logService.trace(
          OrganizationalContainerSelectionServiceImpl.TYPE,
          'Subscribed containerList',
          containerList
        );
        this._fullContainerList.emit(containerList);
      });
    this._logService.trace(
      OrganizationalContainerSelectionServiceImpl.TYPE,
      'Finished initialization'
    );
  }

  /**
   * Changes the rendering instance of all organizational containers
   *
   * @param rendererInfo The HTML element name to use to render the elements
   */
  public changeContainerRenderer(rendererInfo: RendererInfo): void {
    this._currentContainerRenderer.emit(rendererInfo);
  }

  /**
   * Sets the organizational containers to be selected and notifies subsribers listening to this list
   *
   * Note: If all containers are selected by providing an empty array
   * and the list of available containers only contains one item,
   * exactly that one container will be selected instead.
   *
   * @param selectedContainers The containers to select
   */
  public async selectContainers<T>(
    selectedContainers: OrganizationalContainer<T>[]
  ): Promise<void> {
    await Promise.all(this._loadingOperations);

    const { items: filteredContainerList }: FilteredContainerList =
      this._filteredContainerList.value;
    if (
      !selectedContainers.every((container) =>
        filteredContainerList.find(
          (available) =>
            available.id === container.id && available.type === container.type
        )
      )
    ) {
      throw new InvalidOperationError(
        'Selection of organizational containers failed: User cannot see one or more of the desired containers'
      );
    }

    const selectAllContainers =
      this.containsAllAvailableContainers(selectedContainers);

    if (selectAllContainers) {
      selectedContainers = [];
    }

    if (selectAllContainers && filteredContainerList.length === 1) {
      selectedContainers = [...filteredContainerList];
    }

    const currentIds = this._currentSelection.value.map((x) => x.id);
    const selectionLengthEqual =
      selectedContainers.length === currentIds.length;
    const selectionEqual = selectedContainers.every((x) =>
      currentIds.includes(x.id)
    );
    if (selectionLengthEqual && selectionEqual) {
      return;
    }

    this._currentSelection.emit([...selectedContainers]);
  }

  /**
   * Gets an event stream notifiying about changes in the selection of organizational containers
   */
  public getSelection<T>(): Event<OrganizationalContainer<T>[]> {
    return this._currentSelection as Event<OrganizationalContainer<T>[]>;
  }

  /**
   * Gets all organizational containers available for selection
   */

  public getAllContainers<T>(): Event<OrganizationalContainer<T>[]> {
    return this._filteredContainerListItems;
  }

  /**
   * Sets the permissions required to see a container
   */
  public async setRequiredPermissions(...actions: string[][]): Promise<void> {
    const permissionFilterStream = new Promise((resolve) =>
      (
        this._filteredContainerList.convertWith(
          RxJsBridge(BehaviorSubject)
        ) as Subject<FilteredContainerList>
      )
        .pipe(filter((x) => !!x))
        .pipe(filter(({ permissions }) => permissions === actions))
        .pipe(take(1))
        .subscribe(resolve)
    );

    this._currentPermissionFilter.emit(actions);
    await permissionFilterStream;
    this.reverifySelection();
  }

  public showSelectorUI(): void {
    if (!this.onShowSelectorList) {
      return;
    }

    this.onShowSelectorList();
  }

  private async reverifySelection(): Promise<void> {
    const selection = this._currentSelection.value;
    const accessibleContainers = await new Promise<
      OrganizationalContainer<any>[]
    >((resolve) => {
      const subscription = this._filteredContainerList.subscribe(
        ({ items: containers }) => {
          if (!containers) {
            return;
          }

          resolve(containers);
          subscription?.unsubscribe();
        }
      );
    });

    const selectAllContainers = this.containsAllAvailableContainers(selection);

    const allContainersAccessible = selection.every((container) =>
      accessibleContainers.find(
        (available) =>
          available.id === container.id &&
          available.resourceType === container.resourceType
      )
    );

    if (
      allContainersAccessible &&
      (accessibleContainers.length > 1 || selection.length > 0)
    ) {
      return;
    } else if (selectAllContainers && accessibleContainers.length === 1) {
      await this.selectContainers([...accessibleContainers]);
      return;
    }

    await this.selectContainers([]);
  }

  private containsAllAvailableContainers(
    selection: OrganizationalContainer<any>[]
  ): boolean {
    return (
      selection.length === 0 ||
      this._filteredContainerList.value.items.every((selectedContainer) =>
        selection
          .map((container) => container.id)
          .includes(selectedContainer.id)
      )
    );
  }

  private async checkContainerVisible(
    container: any,
    actions: string[][]
  ): Promise<{
    granted: boolean;
    container: OrganizationalContainer<any>;
  }> {
    for (const actionSet of actions) {
      const requests: PermissionRequest[] = actionSet.map((permission) => ({
        action: permission,
        resource: container.id,
        resourceType: container.resourceType,
      }));

      const requestResults = await this._permissionService.requestPermissions(
        requests
      );

      if (requestResults.every((x) => x.granted)) {
        return {
          container,
          granted: true,
        };
      }
    }

    return {
      container,
      granted: false,
    };
  }
}
