/**
 * Predefined user interaction for navigating to the first child of the navigation item.
 */
export interface UserInteractionHandler {
  //#region Properties
  type: string;
  //#endregion

  //#region Methods
  onInitialize?(): Promise<void>;
  onDispose?(): Promise<void>;
  //#endregion
}
