import { Stopwatch } from './stop-watch.class';

/**
 * Creates new Stopwatches
 * @exportToken TOKENS.StopwatchFactory
 * @doctab "Examples" "stop-watch.factory.examples.md"
 */
export interface StopwatchFactory {
  //#region Public Methods
  /**
   * Creates a new instance of a stopwatch
   */
  createStopwatch(): Stopwatch;
  //#endregion
}
