import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoSr: CultureInfo = {
  name: 'sr',
  displayName: 'Serbian',
  iso3LanguageCode: 'srp',
  iso2LanguageCode: 'sr',
  ietfLanguageTag: 'sr',
  parent: null
};
