import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UpgradePageComponent } from './upgrade-page.component';
import { UpgradePageInitializer } from './upgrade-page.bootstrapper';
import { TOKENS, TranslationFile } from '@trustedshops/tswp-core-common';
import { TswpCoreUiSharedModule } from '../../common/tswp-core-ui-shared.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [CommonModule, TswpCoreUiSharedModule, ReactiveFormsModule],
  declarations: [
    UpgradePageComponent,
  ],
  providers: [
    UpgradePageInitializer,
    {
      provide: TOKENS.DefaultTranslationServiceConfig,
      useValue: {
        translationKeyResolver: (translations: TranslationFile, key: string) =>
          translations[key],
        localeFileNameResolver: (locale: string) =>
          `assets/tswp-core-ui-implementation/i18n/${locale}.json`,
      },
    },
  ],
  exports: [UpgradePageComponent]
})
export class UpgradePageModule { }
