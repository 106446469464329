import { CultureInfo } from '../culture-info.model';
import { CultureInfoPt } from './pt';

/**
 * @ignore
 */
export const CultureInfoPtTL: CultureInfo = {
  name: 'pt-TL',
  displayName: 'português (Timor-Leste)',
  iso3LanguageCode: 'por',
  iso2LanguageCode: 'pt',
  ietfLanguageTag: 'pt-TL',
  parent: CultureInfoPt
};
