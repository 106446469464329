import { CultureInfo } from '../culture-info.model';
import { CultureInfoFa } from './fa';

/**
 * @ignore
 */
export const CultureInfoFaIR: CultureInfo = {
  name: 'fa-IR',
  displayName: 'Persian (Iran)',
  iso3LanguageCode: 'fas',
  iso2LanguageCode: 'fa',
  ietfLanguageTag: 'fa-IR',
  parent: CultureInfoFa
};
