import { CultureInfo } from '../culture-info.model';
import { CultureInfoAr } from './ar';

/**
 * @ignore
 */
export const CultureInfoArEG: CultureInfo = {
  name: 'ar-EG',
  displayName: 'Arabic (Egypt)',
  iso3LanguageCode: 'ara',
  iso2LanguageCode: 'ar',
  ietfLanguageTag: 'ar-EG',
  parent: CultureInfoAr
};
