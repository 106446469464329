import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoTs: CultureInfo = {
  name: 'ts',
  displayName: 'Tsonga',
  iso3LanguageCode: 'tso',
  iso2LanguageCode: 'ts',
  ietfLanguageTag: 'ts',
  parent: null
};
