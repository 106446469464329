import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoVi: CultureInfo = {
  name: 'vi',
  displayName: 'Vietnamese',
  iso3LanguageCode: 'vie',
  iso2LanguageCode: 'vi',
  ietfLanguageTag: 'vi',
  parent: null
};
