import { CultureInfo } from '../culture-info.model';
import { CultureInfoSl } from './sl';

/**
 * @ignore
 */
export const CultureInfoSlSI: CultureInfo = {
  name: 'sl-SI',
  displayName: 'Slovenian (Slovenia)',
  iso3LanguageCode: 'slv',
  iso2LanguageCode: 'sl',
  ietfLanguageTag: 'sl-SI',
  parent: CultureInfoSl
};
