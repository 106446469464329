export interface PermissionRequest {
  /**
   * Gets the fully qualified action to execute.
   */
  readonly action: string;

  /**
   * Gets the resource to execute the action within.
   */
  readonly resource: string;

  /**
   * Type of resource given in `resource`.
   */
  readonly resourceType: string;

  /**
   * Identity to check the permission for. If not set, the user currently logged in is used.
   */
  readonly identityId?: string;
}
