import { CultureInfo } from '../culture-info.model';
import { CultureInfoGuz } from './guz';

/**
 * @ignore
 */
export const CultureInfoGuzKE: CultureInfo = {
  name: 'guz-KE',
  displayName: 'Ekegusii (Kenya)',
  iso3LanguageCode: 'guz',
  iso2LanguageCode: 'guz',
  ietfLanguageTag: 'guz-KE',
  parent: CultureInfoGuz
};
