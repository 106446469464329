import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoTt: CultureInfo = {
  name: 'tt',
  displayName: 'Tatar',
  iso3LanguageCode: 'tat',
  iso2LanguageCode: 'tt',
  ietfLanguageTag: 'tt',
  parent: null
};
