import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoFi: CultureInfo = {
  name: 'fi',
  displayName: 'Finnish',
  iso3LanguageCode: 'fin',
  iso2LanguageCode: 'fi',
  ietfLanguageTag: 'fi',
  parent: null
};
