import { SortCallback } from '../models/sort-callback.type';

export class SortUtil {
  /**
   * Returns a sort callback for the Array.sort method while sorting
   * by a specific child property of an element within the array.
   *
   * @param selector A function that returns the value of the property to sort by
   * @param invert Indicates wether the sort order should be inverted.
   * @returns A value suitable for the expected inputs of Array.sort. See
   * https://developer.mozilla.org/de/docs/Web/JavaScript/Reference/Global_Objects/Array/sort
   */
  public static byProperty<T>(selector: (x: T) => any, invert = false): SortCallback<T> {
    return (a, b) => {
      const firstValue = !invert ? selector(a) : selector(b);
      const secondValue = !invert ? selector(b) : selector(a);

      return firstValue > secondValue
        ? 1
        : firstValue < secondValue
          ? -1
          : 0;
    };
  }
}
