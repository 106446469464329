import { NgModule } from '@angular/core';
import { IdentityServiceImpl } from './services/identity.service';
import { TOKENS } from '@trustedshops/tswp-core-authorization';
import { PermissionServiceImpl } from './services/permission.service';

@NgModule({
  providers: [
    { provide: TOKENS.IdentityService, useClass: IdentityServiceImpl },
    { provide: TOKENS.PermissionService, useClass: PermissionServiceImpl }
  ],
})
export class TswpCoreAuthorizationModule { }
