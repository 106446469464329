import { RxJsSubjectBridge } from '@trustedshops/tswp-core-common-eventing-rxjs';
import { KeycloakProfileData } from '@trustedshops/tswp-core-authorization-keycloak';
import { Identity, IdentityService, PermissionManager, Permission, TOKENS as AUTH_TOKENS } from '@trustedshops/tswp-core-authorization';
import {
  Configuration,
  DefaultApi as PermissionsApi,
  FetchParams,
  HTTPHeaders,
  RequestContext
} from '@trustedshops/ts-permissions-client-b2b';
import { Subject } from 'rxjs';
import { take, filter } from 'rxjs/operators';
import { ENVIRONMENT, Environment } from '../models/environment.type';
import { Inject } from '@angular/core';

export class B2bPermissionManager implements PermissionManager<Permission> {
  //#region Ctor
  public constructor(
    @Inject(ENVIRONMENT) private readonly _environment: Environment,
    @Inject(AUTH_TOKENS.IdentityService) private readonly _identityService: IdentityService) {
  }
  //#endregion

  //#region Public Methods
  public async getPermissionsForUser(identityRef: string): Promise<Permission[]> {
    const { permissions: basePath }: { permissions: string } = this._environment.apis;
    const permissionsApi = this.getApiClient(basePath);

    const permissions = (await permissionsApi.getPermissions({
      identityRef
    })).permissions.map<Permission>(x => ({
      actions: x.actions,
      resource: x.resource,
      resourceType: x.type
    }));

    return permissions;
  }
  //#endregion

  //#region Private Methods
  private getApiClient(basePath: string): PermissionsApi {
    const config = new Configuration({ basePath });

    let api = new PermissionsApi(config);
    api = api.withPreMiddleware(async (context) => {
      const { url, init }: RequestContext = context;
      const headers = init.headers as HTTPHeaders;

      headers['Authorization'] = `Bearer ${await this.getToken()}`;

      return { url, init } as FetchParams;
    });

    return api;
  }

  private async getIdentity(): Promise<Identity<KeycloakProfileData>> {
    return await this._identityService.identity
      .convertWith<Subject<Identity<KeycloakProfileData>>>(RxJsSubjectBridge)
      .pipe(
        filter(x => !!x),
        take(1))
      .toPromise();
  }

  private async getToken(): Promise<string> {
    const identity = await this.getIdentity();

    return await identity.profile.keycloak.token.encoded
      .convertWith<Subject<string>>(RxJsSubjectBridge)
      .pipe(
        filter(x => !!x),
        take(1))
      .toPromise();

  }
  //#endregion
}
