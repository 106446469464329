import { Command, Dictionary } from '@trustedshops/tswp-core-common';
import { ClickUserInteractionHandler } from './handlers/click-user-interaction-handler';
import { NavigateFirstUserInteractionHandler } from './handlers/navigate-first-user-interaction-handler';
import { NavigateUserInteractionHandler } from './handlers/navigate-user-interaction-handler';
import { UriLocationType } from './models/uri-location-type.enum';
import { UserInteractionType } from './models/user-interaction-type.enum';

export interface UserInteractionHandlerTypes {
  [UserInteractionType.Click]: {
    ctor: ClickUserInteractionHandler,
    args: { command: Command<any> }
  };

  [UserInteractionType.Navigate]: {
    ctor: NavigateUserInteractionHandler,
    args: {
      uri: string,
      locationType?: UriLocationType;
      queryParams?: Dictionary;
    }
  };

  [UserInteractionType.NavigateToFirstChild]: {
    ctor: NavigateFirstUserInteractionHandler,
    args: {
      parentNavigationItemId: string;
      childrenRegion: string;
    }
  };
}
