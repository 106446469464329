import { PluginHost } from './plugin-host.interface';

/**
 * Provides an instance of PluginHost
 */
export interface PluginHostProvider {
  /**
   * Provides an instance of PluginHost
   */
  provideHost(): PluginHost;
}
