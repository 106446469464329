import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { ChildRouteContentInitializer } from './route-content.bootstrapper';
import { RouteContentComponent } from './route-content.component';
import { RouterOutletHostComponent } from './router-outlet-host.component';

@NgModule({
  imports: [
    RouterModule.forChild([])
  ],
  declarations: [
    RouteContentComponent,
    RouterOutletHostComponent
  ],
  providers: [
    ChildRouteContentInitializer,
  ],
  exports: [
    RouterModule
  ]
})
export class RouteContentModule {
}
