import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoSc: CultureInfo = {
  name: 'sc',
  displayName: 'Sardu',
  iso3LanguageCode: 'srd',
  iso2LanguageCode: 'sc',
  ietfLanguageTag: 'sc',
  parent: null
};
