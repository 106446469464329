import { NavigationItem } from '../../models/navigation/navigation-item.interface';
import { NavigationRegionConfiguration } from '../../models/navigation-region/navigation-region-configuration.interface';
import { Event } from '@trustedshops/tswp-core-common';

/**
 * A service for controlling a specific navigation region
 */
export interface NavigationRegionService {
  /**
   * Gets an observable stream emitting the currently active item
   *
   * @deprecated This method is deprecated. Please use `getActiveItems()` in favor of this one.
   * @param region The region to get the active item for
   */
   getActiveItem(region: string): Event<NavigationItem>;

  /**
   * Gets an observable stream emitting the currently active items
   *
   * @param region The region to get the active item for
   */
  getActiveItems(region: string): Event<NavigationItem[]>;

  /**
   * Gets a value indicating if the specified region is in a collapsed state
   * @param region The region to request the status for
   */
  isRegionCollapsed(region: string): Event<boolean>;

  /**
   * Toggles the state of the region between collapsed and expanded
   * @param region The region to toggle the state for
   */
  toggleRegion(region: string): Promise<void>;

  /**
   * Sets the state of the region to expanded
   * @param region The region to toggle the state for
   */
  expandRegion(region: string): Promise<void>;

  /**
   * Sets the state of the region to collapsed
   * @param region The region to toggle the state for
   */
  collapseRegion(region: string): Promise<void>;

  /**
   * Registers a region to be managed by this service
   * @param config The region configuration to apply initially.
   */
  registerRegionConfig(config: NavigationRegionConfiguration): void;
}
