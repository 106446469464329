import { Type } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { v4 as uuid } from 'uuid';

const defaultTargettedClasses = '(Component|Service|Pipe|Directive)';
const parseTargettedClasses =
  (targettedClasses) =>
    targettedClasses && targettedClasses.reduce(
      (acc, curr, idx) =>
        idx < targettedClasses.length - 1 ?
          acc += curr + '|' :
          acc += curr + ')', '('
      );
const regexFiles = (targettedClasses) => new RegExp(`(?<![\\(|\\/])\\b[a-zA-Z]*${
  targettedClasses ?
    parseTargettedClasses(targettedClasses) :
    defaultTargettedClasses
  }[.| ](?![\\w\\s]*[\\)])`,'gm');

const isTargetToOwnClass = (stacktrace: string, targettedClasses: any) => regexFiles(targettedClasses).test(stacktrace);
const isFirstCall = (stacktrace: string) => (stacktrace.match(/.overrideSubscribe/g) || []).length === 1;

/**
 * Get class name from a fake error stacktrace
 *
 * @param {*} stacktrace
 * @returns
 */
 const regexNodeModulesFiles = () => new RegExp('^((?!node_modules).)*$', 'gm');
 const isNotTargetToNodeModulePackage = (stacktrace) => regexNodeModulesFiles().test(stacktrace);
const getClassName = (stacktrace, targettedClasses) => {
  const caller = stacktrace
    .split('\n')
    .find((line: string) => isTargetToOwnClass(line, targettedClasses) && isNotTargetToNodeModulePackage(line));

  if (!caller) {
    return null;
  }

  const callerTokens = caller
    .trim()
    .split(' ');

  return callerTokens.length === 4 ? callerTokens[2] : callerTokens[1].split('.')[0];
};

const valueChanges = new Subject();
const obSubscribed$ = new Subject();
const obUnsubscribed$ = new Subject();

const registerNewSubscription = (className, stack) => {
  if (!(window as any).subscriptionsMap[className]) {
    (window as any).subscriptionsMap[className] = [];
  }

  const id = uuid();
  (window as any).subscriptionsMap[className].push({
    id,
    stack: stack.replace(/^Error\n[ ]+at /, '')
  });

  valueChanges.next({valueChanges: 'valueChanges', className, stack, id, map: (window as any).subscriptionsMap});
  obSubscribed$.next({obSubscribed: 'obSubscribed', className, stack, id, map: (window as any).subscriptionsMap});

  return id;
};

const unregisterSubscription = (id, className, stack) => {
  if ((window as any).subscriptionsMap[className]) {
    (window as any).subscriptionsMap[className].splice((window as any).subscriptionsMap[className].findIndex(it => it.id === id), 1);
  }

  if ((window as any).subscriptionsMap[className] && !(window as any).subscriptionsMap[className].length) {
    delete (window as any).subscriptionsMap[className];
  }

  valueChanges.next((window as any).subscriptionsMap);
  obUnsubscribed$.next({obUnsubscribed: 'obUnsubscribed', className, stack, id, map: (window as any).subscriptionsMap});
}

// valueChanges.subscribe(x => console.log(x));
// obSubscribed$.subscribe(x => console.log(x));
// obUnsubscribed$.subscribe(x => console.log(x));
setInterval(() => {
  console.log(`Active subscriptions count: ${(Object
    .values((window as any).subscriptionsMap || {}) as { length: number }[])
    .reduce((prev: number, cur: { length: number }) => prev + cur.length, 0)}`);
}, 1000);

export function debugRxJs(Observable: Type<Observable<any>>) {
  (window as any).subscriptionsMap = {};
  const originalSubscribeFn = Observable.prototype.subscribe;

  Observable.prototype.subscribe = function overrideSubscribe(...args) {
    const stacktrace = new Error().stack;
    const handleSubscription =
      isTargetToOwnClass(stacktrace, undefined) && isFirstCall(stacktrace);

    if (!handleSubscription) {
      return originalSubscribeFn.call(this, ...args);
    }

    const className = getClassName(stacktrace, undefined);
    if (!className) {
      return originalSubscribeFn.call(this, ...args);
    }

    const id = registerNewSubscription(className, stacktrace);
    return originalSubscribeFn.call(this, ...args).add(() => {
      unregisterSubscription(id, className, stacktrace);
    });
  };
}
