<div class="form-field" [ngClass]="{'disabled': disabled}" *ngIf="isSingleLine">
  <mat-form-field appearance="outline">
    <span class="slot-wrapper prefix-slot" matPrefix>
      <slot name="prefix" #prefixSlot [ngClass]="{'empty-slot': isSlotEmpty(prefixSlotRef)}"></slot>
      <slot name="icon-prefix"  #iconPrefixSlot [ngClass]="{'empty-slot': isSlotEmpty(iconPrefixSlotRef)}"></slot>
      <slot name="button-prefix"  #buttonPrefixSlot [ngClass]="{'empty-slot': isSlotEmpty(buttonPrefixSlotRef)}"></slot>
    </span>
    <input matInput #input
                    [accept]="accept"
                    [autocomplete]="autocomplete"

                    [disabled]="disabled"
                    [maxlength]="maxlength"
                    [max]="max"
                    [min]="min"
                    [minlength]="minlength"
                    [multiple]="multiple"
                    [ngModel]="value"
                    [placeholder]="placeholder"
                    [readonly]="readonly"
                    [required]="required"
                    [pattern]="pattern"
                    [step]="step"
                    [type]="type"
                    (ngModelChange)="_updateValue($event, true)"
                    />
    <span class="slot-wrapper suffix-slot" matSuffix>
      <slot name="suffix" #suffixSlot [ngClass]="{'empty-slot': isSlotEmpty(suffixSlotRef)}"></slot>
      <slot name="icon-suffix" #iconSuffixSlot [ngClass]="{'empty-slot': isSlotEmpty(iconSuffixSlotRef)}"></slot>
      <slot name="button-suffix" #buttonSuffixSlot [ngClass]="{'empty-slot': isSlotEmpty(buttonSuffixSlotRef)}"></slot>
      <div *ngIf="type === 'number' && showStepper" class="stepper">
        <hls-button class="up-button" (click)="stepUp()">
          <hls-icon icon="action-add"></hls-icon>
        </hls-button>
        <hls-button class="down-button" (click)="stepDown()">
          <hls-icon icon="action-remove"></hls-icon>
        </hls-button>
      </div>
    </span>
  </mat-form-field>
</div>

<div class="input-container" *ngIf="type === InputType.Checkbox">
  <label>
    <input type="checkbox"
        class="input-container__input"
        [name]="name"
        [value]="value"

        [accept]="accept"
        [multiple]="multiple"
        [readonly]="readonly"
        [indeterminate]="indeterminate"
        [ngClass]="{ 'input-container__input--indeterminate': indeterminate }"
        [disabled]="disabled"
        [checked]="checkedInternal"
        [required]="required"
        (change)="updateCheckedState($event)" />
    <div class="input-container__input__after">
      <hls-icon *ngIf="indeterminate" icon="action-remove"></hls-icon>
      <hls-icon *ngIf="!indeterminate && checkedInternal" icon="feedback-check"></hls-icon>
    </div>
    <div class="label-content">
      <slot></slot>
    </div>
  </label>
</div>

<div class="input-container" *ngIf="type === InputType.Radio">
  <label>
    <input type="radio"
        class="input-container__input"
        [name]="name"
        [value]="value"

        [accept]="accept"
        [multiple]="multiple"
        [readonly]="readonly"
        [disabled]="disabled"
        [checked]="checkedInternal"
        [required]="required"
        (change)="updateCheckedState($event)" />
    <div class="label-content">
      <slot></slot>
    </div>
  </label>
</div>
