import { CultureInfo } from '../culture-info.model';
import { CultureInfoNyn } from './nyn';

/**
 * @ignore
 */
export const CultureInfoNynUG: CultureInfo = {
  name: 'nyn-UG',
  displayName: 'Runyankore (Uganda)',
  iso3LanguageCode: 'nyn',
  iso2LanguageCode: 'nyn',
  ietfLanguageTag: 'nyn-UG',
  parent: CultureInfoNyn
};
