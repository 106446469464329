import { Inject, Injectable } from '@angular/core';
import {
  CultureInfo, CultureInfoService, ExportToken,
  CurrentCultureInfoProvider, TOKENS, AbstractPersistentEvent } from '@trustedshops/tswp-core-common';
/**
 * Service getting the currently active culture in the platfrom
 */
@Injectable()
@ExportToken(TOKENS.CultureInfoService)
export class CultureInfoServiceImpl implements CultureInfoService {
  //#region Properties
  private _currentCulture: AbstractPersistentEvent<CultureInfo>;

  /**
   * Gets an event stream emitting the currently active culture
   */
  public get currentCulture(): AbstractPersistentEvent<CultureInfo> {
    return this._currentCultureInfoProvider.currentCulture;
  }
  //#endregion

  //#region Constructor
  /**
   * Creates a new instance of a CultureInfoServiceImpl
   * @param _currentCultureInfoProvider The culture provider used to emit current cultures from.
   */
  public constructor(
    @Inject(TOKENS.CurrentCultureInfoProvider)
    private readonly _currentCultureInfoProvider: CurrentCultureInfoProvider) { }
  //#endregion

  //#region Public Methods
  /**
   * Resolves the locales basic culture info defined for the platform.
   * Example: nl-BE will get nl-NL when configured this way.
   *
   * @param locale The locale to get the base culture for. Can be passed as localeIso2 or ietfLanguageTag
   */
  public resolveBaseCulture(locale: string): CultureInfo {
    return this._currentCultureInfoProvider.resolveBaseCulture(locale);
  }
  //#endregion
}
