import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpParams,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  HttpClientService,
  HttpHeaders as PlatformHttpHeaders,
  HttpInterceptor as PlatformHttpInterceptor,
  HttpParams as PlatformHttpParams,
  HttpRequest as PlatformHttpRequest,
  NextInterceptorHandler,
  NextInterceptorHandlerFunction,
  TOKENS,
} from '@trustedshops/tswp-core-common';
import {
  fromObservable,
  RxJsSubjectBridge,
} from '@trustedshops/tswp-core-common-eventing-rxjs';
import { Observable } from 'rxjs';

@Injectable()
export class WebPlatformHttpInterceptor implements HttpInterceptor {
  private readonly _httpClient: HttpClientService;

  public constructor(
    @Inject(TOKENS.HttpClientService) httpClient: HttpClientService,
  ) {
    this._httpClient = httpClient;
  }

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const interceptorHandlerFunction: NextInterceptorHandlerFunction<
      PlatformHttpInterceptor,
      PlatformHttpRequest<any>,
      any
    > = (interceptor, request, nextHandler) =>
      interceptor.beforeRequestSent(request, nextHandler);

    const platformHttpRequest: PlatformHttpRequest =
      this.fromAngularHttpRequest(req);

    const nextInterceptorHandler = new NextInterceptorHandler<
      PlatformHttpInterceptor,
      PlatformHttpRequest<any>,
      any
    >(
      0,
      interceptorHandlerFunction,
      this._httpClient.interceptors as any,
      (request) =>
        fromObservable(next.handle(this.toAngularHttpRequest(request))),
    );
    return nextInterceptorHandler
      .handle(platformHttpRequest)
      .convertWith<any>(RxJsSubjectBridge);
  }

  private toAngularHttpRequest(req: PlatformHttpRequest): HttpRequest<any> {
    return new HttpRequest(req.method, req.url, req.body, {
      headers: new HttpHeaders(req.headers),
      params: new HttpParams(req.params),
      reportProgress: req.reportProgress,
      responseType: req.responseType,
      withCredentials: req.withCredentials,
    });
  }

  private fromAngularHttpRequest(req: HttpRequest<any>): PlatformHttpRequest {
    const {
      url,
      body,
      headers,
      method,
      params,
      reportProgress,
      responseType,
      urlWithParams,
      withCredentials,
    }: HttpRequest<any> = req;

    const platformHeaders: PlatformHttpHeaders = {};
    headers.keys().forEach((key) => (platformHeaders[key] = headers.get(key)));

    const platformParams: PlatformHttpParams = {};
    params.keys().forEach((key) => (platformParams[key] = params.get(key)));

    return {
      url,
      body,
      headers: platformHeaders,
      method,
      params: platformParams,
      reportProgress,
      responseType,
      urlWithParams,
      withCredentials,
    };
  }
}
