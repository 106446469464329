import { CultureInfo } from '../culture-info.model';
import { CultureInfoMfe } from './mfe';

/**
 * @ignore
 */
export const CultureInfoMfeMU: CultureInfo = {
  name: 'mfe-MU',
  displayName: 'kreol morisien (Moris)',
  iso3LanguageCode: 'mfe',
  iso2LanguageCode: 'mfe',
  ietfLanguageTag: 'mfe-MU',
  parent: CultureInfoMfe
};
