import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoLuo: CultureInfo = {
  name: 'luo',
  displayName: 'Dholuo',
  iso3LanguageCode: 'luo',
  iso2LanguageCode: 'luo',
  ietfLanguageTag: 'luo',
  parent: null
};
