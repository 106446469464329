import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoGv: CultureInfo = {
  name: 'gv',
  displayName: 'Gaelg',
  iso3LanguageCode: 'glv',
  iso2LanguageCode: 'gv',
  ietfLanguageTag: 'gv',
  parent: null
};
