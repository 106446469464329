import { Component, ViewEncapsulation } from '@angular/core';
import { WebComponent } from '../../decorators/web-component.decorator';

/**
 * Loading indicator from the Helios Design system
 */
@WebComponent('hls-loading-indicator')
@Component({
  selector: 'hls-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss'],
  preserveWhitespaces: true,
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class LoadingIndicatorComponent {}
