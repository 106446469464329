import { CultureInfo } from '../culture-info.model';
import { CultureInfoEn } from './en';

/**
 * @ignore
 */
export const CultureInfoEnKN: CultureInfo = {
  name: 'en-KN',
  displayName: 'English (St. Kitts & Nevis)',
  iso3LanguageCode: 'eng',
  iso2LanguageCode: 'en',
  ietfLanguageTag: 'en-KN',
  parent: CultureInfoEn
};
