import { CultureInfo } from '../culture-info.model';
import { CultureInfoPs } from './ps';

/**
 * @ignore
 */
export const CultureInfoPsAF: CultureInfo = {
  name: 'ps-AF',
  displayName: 'Pashto (Afghanistan)',
  iso3LanguageCode: 'pus',
  iso2LanguageCode: 'ps',
  ietfLanguageTag: 'ps-AF',
  parent: CultureInfoPs
};
