import { CultureInfo } from '../culture-info.model';
import { CultureInfoAzCyrl } from './az-Cyrl';

/**
 * @ignore
 */
export const CultureInfoAzCyrlAZ: CultureInfo = {
  name: 'az-Cyrl-AZ',
  displayName: 'Azerbaijani (Cyrillic, Azerbaijan)',
  iso3LanguageCode: 'aze',
  iso2LanguageCode: 'az',
  ietfLanguageTag: 'az-Cyrl-AZ',
  parent: CultureInfoAzCyrl
};
