import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoJv: CultureInfo = {
  name: 'jv',
  displayName: 'Jawa',
  iso3LanguageCode: 'jav',
  iso2LanguageCode: 'jv',
  ietfLanguageTag: 'jv',
  parent: null
};
