import { CultureInfo } from '../culture-info.model';
import { CultureInfoSeh } from './seh';

/**
 * @ignore
 */
export const CultureInfoSehMZ: CultureInfo = {
  name: 'seh-MZ',
  displayName: 'sena (Moçambique)',
  iso3LanguageCode: 'seh',
  iso2LanguageCode: 'seh',
  ietfLanguageTag: 'seh-MZ',
  parent: CultureInfoSeh
};
