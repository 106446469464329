import { Event } from '@trustedshops/tswp-core-common';
import { UserInteractionHandler } from '../handlers/user-interaction-handler';

/**
 * Declares an user interaction renderer
 */
export interface UserInteractionRenderer<T extends UserInteractionHandler> {
  /**
   * Gets or sets the unterlying user interaction
   */
  interaction: T;

  /**
   * Gets or sets additional information about the rendering of the user interaction
   */
  renderingOptions: Event<any>;
}
