import {
  DomResourceService,
  TOKENS,
  ExportToken,
  ResourceType,
  DomResourceLoader,
  ArgumentError,
  ResourceInfo } from '@trustedshops/tswp-core-common';
import { Injectable } from '@angular/core';

/**
 * Extensible service responsible for loading and injecting scripts to the dom
 * @exportToken TOKENS.DomResourceService
 */
@Injectable()
@ExportToken(TOKENS.DomResourceService)
export class DomResourceServiceImpl implements DomResourceService {
  //#region Private Fields
  private readonly _resourceLoaders: Map<string, DomResourceLoader> = new Map();
  //#endregion

  //#region Public Methods
  /**
   * Loads a resource into the DOM.
   * @param resource Detailed data concering origin, type and many more metadata about the resource.
   */
  public async loadResource(resource: ResourceInfo): Promise<void> {
    if (!this._resourceLoaders.has(resource.type)) {
      throw new ArgumentError(`Could not resolve a resource loader for type "${resource.type}" on resource ${resource.key}`);
    }

    const loader = this._resourceLoaders.get(resource.type);
    await loader.load(resource);
  }

  /**
   * Registers a resource loader into the DomResourceService.
   * @param resourceType The type of resources the loader is capable of loading.
   * @param loader The instance of the loader.
   */
  public registerLoader(resourceType: ResourceType | string, loader: DomResourceLoader): DomResourceService {
    this._resourceLoaders
      .set(resourceType, loader);

    return this;
  }
  //#endregion
}
