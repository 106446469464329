/**
 * Enum describing the type of the resource
 */
export enum ResourceType {
  /**
   * JavaScript Resource
   */
  JavaScript = 'js',

  /**
   * CSS Resource
   */
  CascadingStyleSheet = 'css'
}
