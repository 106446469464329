import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoYav: CultureInfo = {
  name: 'yav',
  displayName: 'nuasue',
  iso3LanguageCode: 'yav',
  iso2LanguageCode: 'yav',
  ietfLanguageTag: 'yav',
  parent: null
};
