import { FeatureBookingConfig } from '../models/feature-booking-config.interface';

/**
 * Service for checking booked features
 */
export interface FeatureBookingService {

  /**
   * Initializes the service
   *
   * @param config Configuration for the service
   * @returns Promise that resolves when the service is initialized
   */
  initialize(config: FeatureBookingConfig): Promise<void>;

  /**
   * Checks if the account has a feature booked
   *
   * @param featureName The name of the feature to check
   * @returns A promise that resolves to `true` if the feature is booked, false otherwise
   */
  hasAccountFeatureBooked(featureName: string): Promise<boolean>;

  /**
   * Checks if the channel has a feature booked
   *
   * @param channelRef The channel reference to check
   * @param featureName The name of the feature to check
   * @returns A promise that resolves to `true` if the feature is booked, false otherwise
   */
  hasChannelFeatureBooked(channelRef: string, featureName: string): Promise<boolean>;

  /**
   * Check if the account is a free account
   *
   * @returns A promise that resolves to `true` if the account is free, `false` otherwise.
   */
  isFreeAccount(): Promise<boolean>;

  /**
   * Check if the account is an unverified account
   *
   * @returns A promise that resolves to `true` if the account is unverified, `false` otherwise.
   */
  isUnverifiedAccount(): Promise<boolean>;

  /**
   * Check if the account has `PAID` state
   *
   * @returns A promise that resolves to `true` if the account is free, `false` otherwise.
   */
  isPaidAccount(): Promise<boolean>;
}
