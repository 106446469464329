import { CultureInfo } from '../culture-info.model';
import { CultureInfoAs } from './as';

/**
 * @ignore
 */
export const CultureInfoAsIN: CultureInfo = {
  name: 'as-IN',
  displayName: 'Assamese (India)',
  iso3LanguageCode: 'asm',
  iso2LanguageCode: 'as',
  ietfLanguageTag: 'as-IN',
  parent: CultureInfoAs
};
