import { CultureInfo } from '../culture-info.model';
import { CultureInfoMl } from './ml';

/**
 * @ignore
 */
export const CultureInfoMlIN: CultureInfo = {
  name: 'ml-IN',
  displayName: 'Malayalam (India)',
  iso3LanguageCode: 'mal',
  iso2LanguageCode: 'ml',
  ietfLanguageTag: 'ml-IN',
  parent: CultureInfoMl
};
