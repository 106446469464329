import { CultureInfo } from '../culture-info.model';
import { CultureInfoIu } from './iu';

/**
 * @ignore
 */
export const CultureInfoIuCA: CultureInfo = {
  name: 'iu-CA',
  displayName: 'ᐃᓄᒃᑎᑐᑦ (ᑲᓇᑕᒥ)',
  iso3LanguageCode: 'iku',
  iso2LanguageCode: 'iu',
  ietfLanguageTag: 'iu-CA',
  parent: CultureInfoIu
};
