import { CultureInfo } from '../culture-info.model';
import { CultureInfoKkj } from './kkj';

/**
 * @ignore
 */
export const CultureInfoKkjCM: CultureInfo = {
  name: 'kkj-CM',
  displayName: 'kakɔ (Kamɛrun)',
  iso3LanguageCode: 'kkj',
  iso2LanguageCode: 'kkj',
  ietfLanguageTag: 'kkj-CM',
  parent: CultureInfoKkj
};
