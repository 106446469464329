import { CultureInfo } from '../culture-info.model';
import { CultureInfoYueHans } from './yue-Hans';

/**
 * @ignore
 */
export const CultureInfoYueHansCN: CultureInfo = {
  name: 'yue-Hans-CN',
  displayName: '简体粤语 (中国大陆)',
  iso3LanguageCode: 'yue',
  iso2LanguageCode: 'yue',
  ietfLanguageTag: 'yue-Hans-CN',
  parent: CultureInfoYueHans
};
