import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoSyr: CultureInfo = {
  name: 'syr',
  displayName: 'Syriac',
  iso3LanguageCode: 'syr',
  iso2LanguageCode: 'syr',
  ietfLanguageTag: 'syr',
  parent: null
};
