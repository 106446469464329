import { CultureInfo } from '../culture-info.model';
import { CultureInfoEn } from './en';

/**
 * @ignore
 */
export const CultureInfoEnMO: CultureInfo = {
  name: 'en-MO',
  displayName: 'English (Macao)',
  iso3LanguageCode: 'eng',
  iso2LanguageCode: 'en',
  ietfLanguageTag: 'en-MO',
  parent: CultureInfoEn
};
