import { ViewEncapsulation, Component, OnInit, ViewChild, Input, HostBinding } from '@angular/core';
import { WebComponent } from '../../decorators/web-component.decorator';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { HeliosControl } from '../helios-control';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { ToggleButtonGroupComponent } from './toggle-button-group.component';

/**
 * Button to be used inside a toggle button group
 * @default_slot Label of the button
 */
@WebComponent('hls-toggle-button')
@Component({
  selector: 'ng-hls-toggle-button',
  templateUrl: 'toggle-button.component.html',
  styleUrls: ['toggle-button.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom,
  standalone: false
})
export class ToggleButtonComponent extends HeliosControl implements OnInit {
  //#region Private Fields
  private readonly _currentGroup: BehaviorSubject<MatButtonToggleGroup> = new BehaviorSubject(null);
  //#endregion

  //#region Properties
  private _value: string = null;
  /**
   * Gets or sets the value that is emitted when checking this button
   */
  @Input()
  @HostBinding('attr.value')
  public get value(): string {
    return this._value;
  }
  public set value(v: string) {
    this._value = v;
  }

  /**
   * @ignore
   */
  @ViewChild('toggleButton', { read: MatButtonToggle })
  public set toggleButton(v: MatButtonToggle) {
    if (!v) {
      return;
    }

    this.rememberSubscription(this._currentGroup
      .pipe(filter(group => !!group))
      .subscribe(group => {
        v.buttonToggleGroup = group;
        v.checked = group._isPrechecked(v);
        if (v.buttonToggleGroup.disabled) {
          this.disabled = true;
        }
      }));
  }

  private _disabled = false;
  /**
   * Gets or sets a value indicating wether this particular button is disabled
   */
  @HostBinding('attr.disabled')
  @Input()
  public get disabled(): any {
    return this._disabled || null;
  }
  public set disabled(value: any) {
    this._disabled = coerceBooleanProperty(value);
  }

  /**
   * @ignore
   */
  @Input()
  public get $buttonToggleGroup(): MatButtonToggleGroup {
    return this._currentGroup.value;
  }
  public set $buttonToggleGroup(v: MatButtonToggleGroup) {
    this._currentGroup.next(v);
  }

  //#endregion

  //#region Public Methods
  public async ngOnInit(): Promise<void> {
    await super.ngOnInit();

    if (!this._elementRef?.nativeElement?.parentElement) {
      return;
    }

    let tempGroup;
    do {
      tempGroup = this._elementRef?.nativeElement?.parentElement as ToggleButtonGroupComponent;
      await new Promise(resolve => setTimeout(resolve, 50));
    } while (!tempGroup || !tempGroup.registerToggleButton);

    const buttonGroup = this._elementRef.nativeElement.parentElement as ToggleButtonGroupComponent;
    buttonGroup.registerToggleButton(this);
  }
  //#endregion
}
