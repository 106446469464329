import { CultureInfo } from '../culture-info.model';
import { CultureInfoIt } from './it';

/**
 * @ignore
 */
export const CultureInfoItCH: CultureInfo = {
  name: 'it-CH',
  displayName: 'Italian (Switzerland)',
  iso3LanguageCode: 'ita',
  iso2LanguageCode: 'it',
  ietfLanguageTag: 'it-CH',
  parent: CultureInfoIt
};
