import { CultureInfo } from '../culture-info.model';
import { CultureInfoGaa } from './gaa';

/**
 * @ignore
 */
export const CultureInfoGaaGH: CultureInfo = {
  name: 'gaa-GH',
  displayName: 'Gã (GH)',
  iso3LanguageCode: 'gaa',
  iso2LanguageCode: 'gaa',
  ietfLanguageTag: 'gaa-GH',
  parent: CultureInfoGaa
};
