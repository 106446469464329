import { LoggingTarget } from './logging-target.interface';
import { LogLevel } from './log-level.enum';

/**
 * Logging target forwarding incoming log entries to the console
 */
export class ConsoleLoggingTarget implements LoggingTarget {
  //#region Public Methods
  /**
   * Adds a log entry with `LogLevel.Trace`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  public trace(message: string, payload?: any): void {
    this.addEntry(message, payload, LogLevel.Trace);
  }

  /**
   * Adds a log entry with `LogLevel.Debug`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  public debug(message: string, payload?: any): void {
    this.addEntry(message, payload, LogLevel.Debug);
  }

  /**
   * Adds a log entry with `LogLevel.Information`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  public information(message: string, payload?: any): void {
    this.addEntry(message, payload, LogLevel.Information);
  }

  /**
   * Adds a log entry with `LogLevel.Warning`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  public warning(message: string, payload?: any): void {
    this.addEntry(message, payload, LogLevel.Warning);
  }

  /**
   * Adds a log entry with `LogLevel.Error`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  public error(message: string, payload?: any): void {
    this.addEntry(message, payload, LogLevel.Error);
  }

  /**
   * Adds a log entry with `LogLevel.Fatal`.
   *
   * @param logSource The source that this event is coming from
   * @param message The message that should be logged
   * @param payload The optional payload that should be attached to the log entry
   */
  public fatal(message: string, payload?: any): void {
    this.addEntry(message, payload, LogLevel.Fatal);
  }
  //#endregion

  //#region Private Methods
  /**
   * @ignore
   */
  private addEntry(message: string, payload: any, level: LogLevel): void {
    const coloredMessage = this.getColoredMessage(message, level);
    console[this.mapLevelToConsoleLevel(level)](coloredMessage.message, coloredMessage.style, payload);
  }

  /**
   * @ignore
   */
  private getColoredMessage(message: string, level: LogLevel): { message: string, style: string } {
    const style =  new Map<LogLevel, string>([
      [ LogLevel.Debug, 'color: #80A7C1' ],
      [ LogLevel.Error, 'color: #F55754' ],
      [ LogLevel.Fatal, 'color: #F95C5A' ],
      [ LogLevel.Information, 'color: #51B8E9' ],
      [ LogLevel.Trace, 'color: #8D758E' ],
      [ LogLevel.Warning, 'color: #FAC848' ]
    ]).get(level);
    return {
      message: `%c ${message}`,
      style
    };
  }

  /**
   * @ignore
   */
  private mapLevelToConsoleLevel(level: LogLevel): string {
    return new Map<LogLevel, string>([
      [ LogLevel.Debug, 'debug' ],
      [ LogLevel.Error, 'error' ],
      [ LogLevel.Fatal, 'error' ],
      [ LogLevel.Information, 'info' ],
      [ LogLevel.Trace, 'info' ],
      [ LogLevel.Warning, 'warn' ]
    ]).get(level);
  }
  //#endregion
}
