import { CultureInfo } from '../culture-info.model';
import { CultureInfoYueHant } from './yue-Hant';

/**
 * @ignore
 */
export const CultureInfoYueHantHK: CultureInfo = {
  name: 'yue-Hant-HK',
  displayName: '繁體廣東話 (香港)',
  iso3LanguageCode: 'yue',
  iso2LanguageCode: 'yue',
  ietfLanguageTag: 'yue-Hant-HK',
  parent: CultureInfoYueHant
};
