import { CultureInfo } from '../culture-info.model';
import { CultureInfoKu } from './ku';

/**
 * @ignore
 */
export const CultureInfoKuTR: CultureInfo = {
  name: 'ku-TR',
  displayName: 'kurdî (Tirkiye)',
  iso3LanguageCode: 'kur',
  iso2LanguageCode: 'ku',
  ietfLanguageTag: 'ku-TR',
  parent: CultureInfoKu
};
