import { PermissionRequest } from '../models/permissions/permission-request.interface';
import { GrantInfo } from '../models/permissions/grant-info.interface';
import { Permission } from '../models/permissions/permission.interface';
import { PermissionManager } from '../models/permissions/permission-manager.interface';

/**
 * Service for reading and managing permissions
 *
 * @template TPermissionDefinition The type of a single permission that is handled with this `PermissionService`
 */
export interface PermissionService<TPermissionDefinition extends Permission> {
  /**
   * Gets an array of permission information for the user currently logged in.
   *
   * @returns The array of permissions.
   */
  getPersonalPermissions(): Promise<TPermissionDefinition[]>;

  /**
   * Gets an array of permission information for the given user.
   *
   * @param The id of the identity to get the permissions for.
   * @returns The array of permissions.
   */
  getPermissions(identityId: string): Promise<TPermissionDefinition[]>;

  /**
   * Registers a permission manager to get and apply the permissions from/to.
   * Can only be called one time and is reserved by the platform.
   *
   * @param permissionsProvider The type of permissions provider to use.
   */
  registerPermissionManager<TManager extends PermissionManager<TPermissionDefinition>>(permissionsProvider: TManager): void;

  /**
   * Requests permissions given as `definition`.
   *
   * @param definition The definition of the permissions requested.
   * @returns A list of `GrantInfo` containing information about permissions that were granted.
   */
  requestPermissions(definition: PermissionRequest[]): Promise<GrantInfo<TPermissionDefinition>[]>;
}
