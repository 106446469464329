import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKok: CultureInfo = {
  name: 'kok',
  displayName: 'Konkani',
  iso3LanguageCode: 'kok',
  iso2LanguageCode: 'kok',
  ietfLanguageTag: 'kok',
  parent: null
};
