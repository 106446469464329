import { CultureInfo } from '../culture-info.model';
import { CultureInfoEs } from './es';

/**
 * @ignore
 */
export const CultureInfoEsKN: CultureInfo = {
  name: 'es-KN',
  displayName: 'español (San Cristóbal y Nieves)',
  iso3LanguageCode: 'spa',
  iso2LanguageCode: 'es',
  ietfLanguageTag: 'es-KN',
  parent: CultureInfoEs
};
