import { Inject, Injectable } from '@angular/core';
import { Permission, PermissionRequest, PermissionService, TOKENS } from '@trustedshops/tswp-core-authorization';
import { AbstractPersistentEvent } from '@trustedshops/tswp-core-common';
import { VisibilityGuardFactory } from '@trustedshops/tswp-core-ui';

@Injectable()
export class PermissionVisibilityGuard implements VisibilityGuardFactory {
  //#region Ctor
  public constructor(
    @Inject(TOKENS.PermissionService) private readonly _permissionService: PermissionService<Permission>) { }
  //#endregion

  //#region Public Methods
  public create(permissions: PermissionRequest[][]): AbstractPersistentEvent<boolean> {
    const event = new AbstractPersistentEvent(false);
    this.feedEvent(event, permissions);
    return event;
  }
  //#endregion

  //#region Private Methods
  private async feedEvent(event: AbstractPersistentEvent<boolean>, permissions: PermissionRequest[][]): Promise<void> {
    if (permissions.length === 0) {
      event.emit(true);
      return;
    }

    if (permissions.some(x => x.length === 0)) {
      event.emit(true);
      return;
    }

    const grantSets = await Promise.all(
      permissions.map(permissionSet =>
        this._permissionService.requestPermissions(permissionSet)));

    if (!grantSets.some(permissionSet =>
      permissionSet.every(({ granted }) => granted))) {
      return;
    }

    event.emit(true);
  }
  //#endregion
}
