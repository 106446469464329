import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoHa: CultureInfo = {
  name: 'ha',
  displayName: 'Hausa',
  iso3LanguageCode: 'hau',
  iso2LanguageCode: 'ha',
  ietfLanguageTag: 'ha',
  parent: null
};
