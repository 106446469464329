import { CultureInfo } from '../culture-info.model';
import { CultureInfoTa } from './ta';

/**
 * @ignore
 */
export const CultureInfoTaLK: CultureInfo = {
  name: 'ta-LK',
  displayName: 'Tamil (Sri Lanka)',
  iso3LanguageCode: 'tam',
  iso2LanguageCode: 'ta',
  ietfLanguageTag: 'ta-LK',
  parent: CultureInfoTa
};
