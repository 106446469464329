import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Injector, StaticProvider, Type, DoBootstrap } from '@angular/core';
import { createCustomElement } from '@angular/elements';

/**
 * Defines a module instantiating WebComponents from angular components.
 */
@Injectable()
export class WebComponentControlModule implements DoBootstrap {
  //#region Private Fields
  private readonly _injector: Injector;
  private readonly _document: Document;
  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of WebComponentModule
   *
   * @param injector The injector to use for instantiating the controls
   * @param document The document object to register the WebComponents at
   */
  public constructor(injector: Injector, @Inject(DOCUMENT) document: Document) {
    this._injector = injector;
    this._document = document;
  }
  //#endregion

  //#region Public Methods
  /**
   * Initializes this module and all associated custom elements
   */
  // eslint-disable-next-line @angular-eslint/contextual-lifecycle
  public ngDoBootstrap(): void {
    const components = (this.constructor as any).customElements as Array<Type<any>>;
    const isolatedProviders = (this.constructor as any).isolatedProviders as Array<StaticProvider>;

    components.forEach(component => {

      const customElement = createCustomElement(component, { injector: this._injector });

      this._document.defaultView.customElements
        .define((component as any).customElementSelector, customElement);
    });
  }
  //#endregion
}
