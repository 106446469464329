import { SubscriptionHandler } from './subscription-handler-delegate.type';

/**
 * Generic description of an event subscription implementation.
 */
export interface EventSubscription<TEmission> {

  /**
   * Gets the handler that this subscription was initialized with
   */
  readonly handler: SubscriptionHandler<TEmission>;

  /**
   * Throws an error on the event stream subscription
   */
  readonly error: SubscriptionHandler<Error>;

  /**
   * Removes this subscription from the event stream attached to.
   */
  unsubscribe(): void;
}
