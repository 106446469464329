import { DOCUMENT } from '@angular/common';
import { Directive, ElementRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { SubscriberComponent } from '../subscriber.component';
import { Subscription } from 'rxjs';
import { ElementMethodRegistration } from '../models/element-method-registration.interface';
import { HeliosThemes } from '../models/helios-themes.enum';
import { TswpUiLibraryConfigToken } from '../models/tswp-ui-library-config.token';
import { TswpUiLibraryConfig } from '../models/tswp-ui-library.config';


/**
 * Base class for all Helios controls that do not handle values
 */
@Directive({
  standalone: false
})
// tslint:disable-next-line: directive-class-suffix
export class HeliosControl extends SubscriberComponent implements OnDestroy, OnInit {
  //#region Private Fields
  private readonly _subscriptions: Subscription[] = [];
  protected readonly _document: Document;
  protected readonly _elementRef: ElementRef;
  protected readonly _heliosUri: string;
  protected readonly _config: TswpUiLibraryConfig;
  //#endregion

  //#region Properties
  /**
   * Gets the HTML Element that this control is attached to.
   */
  public get htmlElement(): HTMLElement {
    return this._elementRef?.nativeElement as HTMLElement;
  }

  public get theme(): HeliosThemes {
    if (!this.htmlElement) {
      return HeliosThemes.BusinessProducts;
    }

    return this.htmlElement?.getAttribute('helios-theme') as HeliosThemes || HeliosThemes.BusinessProducts;
  }
  //#endregion

  //#region Ctor
  /**
   * Creates a new instance of a HeliosControl
   *
   * @param document The document that this control is attached to
   * @param elementRef The control's element reference
   * @param config The configuration that this control was initialized with
   * @param changeDetector The change detector service that this control may use.
   */
  public constructor(
    @Inject(DOCUMENT) document: Document,
    elementRef: ElementRef,
    @Inject(TswpUiLibraryConfigToken) config: TswpUiLibraryConfig) {

    super();

    this._config = config;
    this._document = document;
    this._elementRef = elementRef;
    this._heliosUri = config.heliosUri;

    const heliosStyleSheet = document.createElement('link') as HTMLLinkElement;
    heliosStyleSheet.rel = 'stylesheet';
    heliosStyleSheet.href = config.heliosUri;

    const htmlElement = elementRef.nativeElement.shadowRoot as HTMLElement;
    htmlElement.appendChild(heliosStyleSheet);
    ((this.constructor as any).elementMethods || []).forEach(elementMethodRegistration => {
      this.registerElementMethod(elementMethodRegistration);
    });
  }
  //#endregion

  //#region Public Methods
  public async ngOnInit(): Promise<void> {
    this.applyTheme();

    if (this.theme && this.theme !== HeliosThemes.BusinessProducts) {
      const tswpCoreUiElementsLocation = this._config?.heliosThemeBase || (document.currentScript as any)?.src;
      if (!tswpCoreUiElementsLocation) {
        console.warn(`Could not resolve the theme location. Turning off theme "${this.theme}" for this element.`, this.htmlElement);
        return;
      }

      const heliosThemeStyleSheet = document.createElement('link') as HTMLLinkElement;
      heliosThemeStyleSheet.rel = 'stylesheet';
      heliosThemeStyleSheet.href =
        tswpCoreUiElementsLocation.substring(0, tswpCoreUiElementsLocation.lastIndexOf('/'))
        + `/theme/${this.theme}/${this.htmlElement.tagName.toLowerCase()}.css`;

      const shadowRoot = this._elementRef.nativeElement.shadowRoot as HTMLElement;
      shadowRoot.appendChild(heliosThemeStyleSheet);
    }
  }
  public ngOnDestroy(): void {
    this._subscriptions.forEach(subscription =>
      subscription?.unsubscribe());
  }

  /**
   * Returns a value indicating if all given slots passed as element reference have no children assigned.
   *
   * @param refs The element references of the slots to check.
   * @returns A value indicating if all slots are empty.
   */
  public isSlotEmpty(...refs: ElementRef[]): boolean {
    return refs.every(ref => !ref || !ref.nativeElement || !ref.nativeElement.assignedNodes().length);
  }
  //#endregion

  //#region Protected Methods
  /**
   * Binds a publicly available component method to the underlying HTML element.
   * @param name The name of the method to bind.
   */
  protected registerElementMethod(registration: ElementMethodRegistration): void {
    (this._elementRef.nativeElement as any)[registration.name] = (...args: any[]) =>
      (this as any)[registration.methodName](...args);
  }

  /**
   * Utility method to register a created subscription.
   * When the child class does not override/extend the `ngOnDestroy` method,
   * the registered subscriptions are going to be unsubscribed.
   *
   * @param subscription The subscription to unregister on component destruction
   */
  protected rememberSubscription(subscription: Subscription): SubscriberComponent {
    this._subscriptions.push(subscription);
    return this;
  }

  protected applyTheme(): void {
    if (!this.htmlElement) {
      return;
    }

    if (this.htmlElement?.getAttribute('helios-theme')) {
      return;
    }

    const theme = this.getThemeFromParents();
    if (theme) {
      this.htmlElement?.setAttribute('helios-theme', theme);
    }
  }

  public getThemeFromParents(): HeliosThemes {
    const parents = this.getParents(this.htmlElement);
    const themedElement = parents.find(x => x.hasAttribute && x.hasAttribute('helios-theme'));
    if (!themedElement) {
      return null;
    }

    return themedElement.getAttribute('helios-theme') as HeliosThemes || HeliosThemes.BusinessProducts;
  }

  public getParents(htmlElement: HTMLElement): HTMLElement[] {
    if (!htmlElement) {
      return [];
    }

    let parentElement = htmlElement.parentElement;
    if (!parentElement && htmlElement.parentNode && htmlElement.parentNode.nodeType === 11) {
      parentElement = (htmlElement.parentNode as any).host;
    }

    if (parentElement && parentElement === document.body) {
      return [parentElement];
    }

    if (!parentElement) {
      return [];
    }

    return [parentElement, ...this.getParents(parentElement)].filter(x => !!x);
  }
  //#endregion
}
