import { StopwatchInterval } from './stop-watch-interval.interface';
import { DateProvider } from '../../time/date-provider.interface';

/**
 * Provides a set of methods and properties that you can use to accurately measure elapsed time.
 * !!! Note "Use [StopwatchFactory](tswp-core-common/injectables/stop-watch.factory.html) to create a stop watch"
 */
export class Stopwatch {
  //#region Private Fields
  private _intervals: StopwatchInterval[] = [];
  //#endregion

  //#region Properties
  private _isRunning = false;
  /**
   * Gets a value indicating whether the Stopwatch timer is running.
   */
  public get isRunning(): boolean {
    return this._isRunning;
  }

  /**
   * Gets the total elapsed time measured by the current instance, in milliseconds.
   */
  public get elapsed(): number {
    if (!this._intervals.length) {
      return 0;
    }

    return this._intervals
      .map(interval => (interval.stopTime || this._dateProvider().getTime()) - interval.startTime)
      .reduce((previous, current) => (previous || 0) + current);
  }
  //#endregion

  //#region Ctor
  /**
   * Creates an instance of a Stopwatch
   * @param _dateProvider The date provider to use when stopping and starting time.
   */
  public constructor(private readonly _dateProvider: DateProvider) {
  }
  //#endregion

  //#region Public Methods
  /**
   * Starts, or resumes, measuring elapsed time for an interval.
   * @returns This instance of the stopwatch
   */
  public start(): Stopwatch {
    if (this._isRunning) {
      return this;
    }

    this._isRunning = true;
    this._intervals.push({ startTime: this._dateProvider().getTime(), stopTime: null });

    return this;
  }

  /**
   * Pauses measuring elapsed time for an interval.
   * @returns This instance of the stopwatch
   */
  public pause(): Stopwatch {
    if (!this._isRunning) {
      return this;
    }

    this._isRunning = false;
    this._intervals[this._intervals.length - 1].stopTime = this._dateProvider().getTime();

    return this;
  }

  /**
   * Stops time interval measurement and resets the elapsed time to zero.
   * @returns This instance of the stopwatch
   */
  public reset(): Stopwatch {
    this.pause();
    this._intervals = [];

    return this;
  }

  /**
   * Stops time interval measurement, resets the elapsed time to zero, and starts measuring elapsed time.
   * @returns The instance of the stopwatch
   */
  public restart(): Stopwatch {
    this.pause();
    this.reset();
    this.start();

    return this;
  }
  //#endregion
}
