import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoTh: CultureInfo = {
  name: 'th',
  displayName: 'Thai',
  iso3LanguageCode: 'tha',
  iso2LanguageCode: 'th',
  ietfLanguageTag: 'th',
  parent: null
};
