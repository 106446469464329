import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoFur: CultureInfo = {
  name: 'fur',
  displayName: 'furlan',
  iso3LanguageCode: 'fur',
  iso2LanguageCode: 'fur',
  ietfLanguageTag: 'fur',
  parent: null
};
