<div (click)="onOverlayClicked($event)" [ngClass]="overlayClass" class="overlay">
    <div (click)="onDialogClicked($event)" [ngClass]="dialogClass" class="dialog">
        <ng-container *ngIf="viewType === 'modal'">
            <div class="dialog-modal-content">
                <span class="icon-dashboard-illustration"></span>
                <div class="container">
                    <div class="header-lg">
                        {{ translationService.getTranslationForKey('upgrade.popup.heading') | subscribe }}
                    </div>
                    <div class="text-md" style="margin-top: 12px">
                        {{ translationService.getTranslationForKey('upgrade.popup.paragraph') | subscribe }}
                    </div>
                    <div class="text-lg label">
                        {{ translationService.getTranslationForKey('upgrade.popup.form.title') | subscribe }}
                    </div>
                    <input [class.invalid]="emailControl.invalid && emailControl.dirty"
                           [formControl]="emailControl"
                           class="input"
                           type="text"
                           placeholder="email@email.com">
                    <div *ngIf="emailControl.invalid && emailControl.dirty" class="error-message">
                        {{ translationService.getTranslationForKey('upgrade.popup.form.errors-message') | subscribe }}
                    </div>
                </div>
            </div>
            <div class="dialog-modal-actions">
                <button class="link" (click)="popupRef.close()">
                    {{ translationService.getTranslationForKey('upgrade.popup.actions.cancel') | subscribe }}
                </button>
                <button class="button button-primary"
                        [disabled]="(isLoading | async)"
                        (click)="handleShortContactFormSubmit()">
                    {{ translationService.getTranslationForKey('upgrade.popup.actions.submit') | subscribe }}
                </button>
            </div>
        </ng-container>
        <ng-container *ngIf="viewType === 'sidebar'">
            <button class="close-icon" (click)="onCloseClicked($event)"></button>
            <div class="sidebar-content-container">
                <upgrade-page (submitHubspotForm)="handleFullContactFormSubmit()" [variant]="'sidebar'"></upgrade-page>
            </div>
        </ng-container>
        <ng-container *ngIf="viewType === 'thank-you'">
            <button class="close-icon" (click)="onCloseClicked($event)"></button>
            <div class="container" style="height: 100%">
                <p class="header-lg">
                    {{ translationService.getTranslationForKey('upgrade.popup.success.title') | subscribe }}
                </p>
                <p class="text-lg" style="margin-top: 24px; width: 425px; text-align: center">
                    {{ translationService.getTranslationForKey('upgrade.popup.success.body') | subscribe }}
                </p>
            </div>
        </ng-container>
    </div>
</div>
