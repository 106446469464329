import { PluginRegistration } from '../models/plugin-registration.interface';
import { LoadPluginRequest } from '../models/load-plugin-request.interface';
import { PluginArrivalInfo } from '../models/plugin-arrival-info.interface';
import { Event } from '@trustedshops/tswp-core-common';
import { PluginContext } from '../models/plugin-context.interface';


/**
 * Handles plugin registrations and loads plugins
 */
export interface PluginService {
  /**
   * Gets a stream that emits newly created `PluginContext` objects.
   * Useful for attaching isolated services for plugins
   */
  readonly pluginContextBus: Event<PluginContext>;

  /**
   * Gets or sets the default time the plugin must be loaded within.
   * May be overriden by the loadPlugin or loadPluginByName call
   */
  pluginLoadTimeout: number;

  /**
   * Gets the plugins registered.
   */
  readonly registeredPlugins: PluginRegistration[];

  /**
   * Declares a new extra-ordinary service which is available on demand only.
   * @param token The token to request the service with
   * @param value The service instance.
   */
  enableExtraOrdinaryService<T>(token: string, value: T): void;

  /**
   * Loads an already registered plugin. If already loaded, returns without action.
   * @param request Name of the plugin
   * @param timeout Time that the plugin may respond back to the platform after loading
   */
  loadPluginByName(name: string, timeout?: number): Promise<PluginRegistration>;

  /**
   * Registers a plugin without loading.
   * @param request Request that defines what the plugin consists of
   */
  registerPlugin(request: LoadPluginRequest): PluginRegistration;

  /**
   * Registers and loads a plugin in one turn. If already loaded, returns without action.
   * @param request Request that defines what the plugin consists of
   * @param timeout Time that the plugin may respond back to the platform after loading
   */
  loadPlugin(request: LoadPluginRequest, timeout?: number): Promise<PluginRegistration>;

  /**
   * Notify PluginService that a specific plugin has loaded.
   * Usually called through PluginHosts registerPlugin Method
   * @param entryPoint The entrypoint loaded.
   */
  notifyPluginArrived(pluginArrivalInfo: PluginArrivalInfo): void;


  /**
   * Gets the full path to a plugin's static file being hosted within the plugin bundle.
   */
  getPluginFilePath(plugin: string, path: string): string;

  /**
   * Tries to find a registration for a plugin with the provided name
   *
   * @param name The name of the plugin to find the registration for
   * @returns undefined or null if not found. Instance of a `PluginRegistration` if found.
   */
  tryFindRegistration(name: string): PluginRegistration;
}
