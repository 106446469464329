import { CultureInfo } from '../culture-info.model';
import { CultureInfoKm } from './km';

/**
 * @ignore
 */
export const CultureInfoKmKH: CultureInfo = {
  name: 'km-KH',
  displayName: 'Khmer (Cambodia)',
  iso3LanguageCode: 'khm',
  iso2LanguageCode: 'km',
  ietfLanguageTag: 'km-KH',
  parent: CultureInfoKm
};
