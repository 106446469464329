export const TOKENS = {
  BreadcrumbsCompositionService: '@trustedshops/tswp-core-ui:BreadcrumbsCompositionService',
  RouteSourceService: '@trustedshops/tswp-core-ui:RouteSourceService',
  PageTitleCompositionService: '@trustedshops/tswp-core-ui:PageTitleCompositionService',
  BusyService: '@trustedshops/tswp-core-ui:BusyService',
  NavigationService: '@trustedshops/tswp-core-ui:NavigationService',
  VisibilityGuardFactoryRegistry: '@trustedshops/tswp-core-ui:VisibilityGuardFactoryRegistry',
  NavigationRegionService: '@trustedshops/tswp-core-ui:NavigationRegionService',
  HeliosUri: '@trustedshops/tswp-core-ui:HeliosUri',
  UpgradePopupService: '@trustedshops/tswp-core-ui:UpgradePopupService'
};
