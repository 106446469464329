import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoSo: CultureInfo = {
  name: 'so',
  displayName: 'Somali',
  iso3LanguageCode: 'som',
  iso2LanguageCode: 'so',
  ietfLanguageTag: 'so',
  parent: null
};
