import { CultureInfo } from '../culture-info.model';
import { CultureInfoRu } from './ru';

/**
 * @ignore
 */
export const CultureInfoRuBY: CultureInfo = {
  name: 'ru-BY',
  displayName: 'русский (Беларусь)',
  iso3LanguageCode: 'rus',
  iso2LanguageCode: 'ru',
  ietfLanguageTag: 'ru-BY',
  parent: CultureInfoRu
};
