import { CultureInfo } from '../culture-info.model';
import { CultureInfoEt } from './et';

/**
 * @ignore
 */
export const CultureInfoEtEE: CultureInfo = {
  name: 'et-EE',
  displayName: 'Estonian (Estonia)',
  iso3LanguageCode: 'est',
  iso2LanguageCode: 'et',
  ietfLanguageTag: 'et-EE',
  parent: CultureInfoEt
};
