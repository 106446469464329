import { CultureInfo } from '../culture-info.model';
import { CultureInfoUk } from './uk';

/**
 * @ignore
 */
export const CultureInfoUkUA: CultureInfo = {
  name: 'uk-UA',
  displayName: 'Ukrainian (Ukraine)',
  iso3LanguageCode: 'ukr',
  iso2LanguageCode: 'uk',
  ietfLanguageTag: 'uk-UA',
  parent: CultureInfoUk
};
