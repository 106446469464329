import { CultureInfo } from '../culture-info.model';
import { CultureInfoDsb } from './dsb';

/**
 * @ignore
 */
export const CultureInfoDsbDE: CultureInfo = {
  name: 'dsb-DE',
  displayName: 'Lower Sorbian (Germany)',
  iso3LanguageCode: 'dsb',
  iso2LanguageCode: 'dsb',
  ietfLanguageTag: 'dsb-DE',
  parent: CultureInfoDsb
};
