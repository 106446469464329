import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoSat: CultureInfo = {
  name: 'sat',
  displayName: 'ᱥᱟᱱᱛᱟᱲᱤ',
  iso3LanguageCode: 'sat',
  iso2LanguageCode: 'sat',
  ietfLanguageTag: 'sat',
  parent: null
};
