import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { B2BModalData } from '../../models/b2b-modal-data.interface';

/**
 * @alias B2BModalComponent
 * @description Component to display generic dialog as modal with title and subtitle
 */
@Component({
  selector: 'b2b-modal',
  templateUrl: './b2b-modal.component.html',
  styleUrls: ['./b2b-modal.component.scss'],
  standalone: false,
})
export class B2BModalComponent {
  constructor(
    public dialogRef: MatDialogRef<B2BModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: B2BModalData
  ) {}
}
