import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoLuy: CultureInfo = {
  name: 'luy',
  displayName: 'Luluhia',
  iso3LanguageCode: 'luy',
  iso2LanguageCode: 'luy',
  ietfLanguageTag: 'luy',
  parent: null
};
