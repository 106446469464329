import { CultureInfo } from '../culture-info.model';
import { CultureInfoSah } from './sah';

/**
 * @ignore
 */
export const CultureInfoSahRU: CultureInfo = {
  name: 'sah-RU',
  displayName: 'Sakha (Russia)',
  iso3LanguageCode: 'sah',
  iso2LanguageCode: 'sah',
  ietfLanguageTag: 'sah-RU',
  parent: CultureInfoSah
};
