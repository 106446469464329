import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoZu: CultureInfo = {
  name: 'zu',
  displayName: 'Zulu',
  iso3LanguageCode: 'zul',
  iso2LanguageCode: 'zu',
  ietfLanguageTag: 'zu',
  parent: null
};
