import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoKhq: CultureInfo = {
  name: 'khq',
  displayName: 'Koyra ciini',
  iso3LanguageCode: 'khq',
  iso2LanguageCode: 'khq',
  ietfLanguageTag: 'khq',
  parent: null
};
