import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoIs: CultureInfo = {
  name: 'is',
  displayName: 'Icelandic',
  iso3LanguageCode: 'isl',
  iso2LanguageCode: 'is',
  ietfLanguageTag: 'is',
  parent: null
};
