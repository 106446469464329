import {
  UserInteractionHandlerTypes,
  UserInteractionHandlerFactory,
  UserInteractionHandlerFactoryMethod,
  UserInteractionType
} from '@trustedshops/tswp-core-ui';

export class UserInteractionHandlerTypesMap {
  //#region Private Fields
  private readonly _handlerMappings: Map<UserInteractionType, UserInteractionHandlerFactoryMethod<any>> = new Map();
  //#endregion

  //#region Properties
  public set<T extends keyof UserInteractionHandlerTypes>(
    type: T,
    factory: UserInteractionHandlerFactoryMethod<T>): UserInteractionHandlerTypesMap {
    this._handlerMappings.set(type, factory);
    return this;
  }

  public get<T extends keyof UserInteractionHandlerTypes>(type: T): UserInteractionHandlerFactory<T> {
    return {
      create: this._handlerMappings.get(type)
    };
  }
  //#endregion
}
