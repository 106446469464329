import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoMer: CultureInfo = {
  name: 'mer',
  displayName: 'Kĩmĩrũ',
  iso3LanguageCode: 'mer',
  iso2LanguageCode: 'mer',
  ietfLanguageTag: 'mer',
  parent: null
};
