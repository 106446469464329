import { CultureInfo } from '../culture-info.model';

/**
 * @ignore
 */
export const CultureInfoJbo: CultureInfo = {
  name: 'jbo',
  displayName: 'la .lojban.',
  iso3LanguageCode: 'jbo',
  iso2LanguageCode: 'jbo',
  ietfLanguageTag: 'jbo',
  parent: null
};
