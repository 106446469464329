import { CultureInfo } from '../culture-info.model';
import { CultureInfoLt } from './lt';

/**
 * @ignore
 */
export const CultureInfoLtLT: CultureInfo = {
  name: 'lt-LT',
  displayName: 'Lithuanian (Lithuania)',
  iso3LanguageCode: 'lit',
  iso2LanguageCode: 'lt',
  ietfLanguageTag: 'lt-LT',
  parent: CultureInfoLt
};
