import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BootstrapCustomElements } from '../../decorators/bootstrap-custom-elements.decorator';
import { SharedModule } from '../../shared.module';
import { WebComponentControlModule } from '../../web-component-control.module';
import { ButtonComponent } from './button.component';

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [ButtonComponent],
  exports: [ButtonComponent],
  imports: [CommonModule, SharedModule]
})
@BootstrapCustomElements([ButtonComponent])
export class HlsButtonModule extends WebComponentControlModule { }
